import React from "react"
import styled from "styled-components";
import { LineContent, LineItem } from "../../../../../../utils/playbrush-client/types";
import { useAppSelector } from "../../../../../../app/hooks";
import { PageContext } from "../../../../../slices/types";
import { convertPrice } from "@utils";

const GAP = 24;

const PriceLine = styled.div`
    margin: 0 0 0 ${GAP}px;
    font-size: 12px;
    text-align: right;
    display: flex;
    flex-direction: column;
    text-align: right;
`;

const DiscountedPrice = styled.p`
    display: inline-block;
    margin: 4px 0;
    font-size: 12px;
    opacity: 0.4;

    span {
        text-decoration: line-through;
    }
`;

const Price = styled.span`
    margin: 0 0 0 4px;
    font-size: 16px;
    font-weight: bold;
`;

const PriceFootnote = styled.p`
    font-size: 10px;
    text-decoration: none;
    margin-right: 4px;
	
	@media screen and (min-width: 768px) {
        // margin: 0 0 24px;
	}
`

interface LineItemContentHardwareProps {
    lineContent: LineContent | LineItem;
    translations: PageContext['translations'];
    quantity: number;
    isSubscription: boolean;
    thankYouPage?: boolean;
    useTextualZero?: boolean;
}

const LineItemContentHardware = ({ lineContent, translations, quantity, isSubscription, thankYouPage, useTextualZero = false }: LineItemContentHardwareProps) => {
    const cart = useAppSelector(state => state.cart);

    return (
        <PriceLine>
            {lineContent.priceDefaultAmount > lineContent.priceAmount && (
                <DiscountedPrice>
                    {/* {`${translations.checkout.free} `} */}
                    <span>
                        {`${
                            lineContent.priceDefaultAmount === 0 && useTextualZero
                                ? translations.checkout.free
                                : convertPrice(((lineContent.priceDefaultAmount || 0) * quantity), cart.locale, cart.currency)
                        }`}
                    </span>
                </DiscountedPrice>
            )}
            <Price>
                {`${
                    lineContent.priceAmount === 0 && useTextualZero
                        ? translations.checkout.free
                        : convertPrice(((lineContent.priceAmount || 0) * quantity), cart.locale, cart.currency)
                }`}
            </Price>
            {!isSubscription && !thankYouPage && (
                <PriceFootnote>
                    {translations.line_item_shipping}
                </PriceFootnote>
            )}
        </PriceLine>
    )
};

export default LineItemContentHardware;