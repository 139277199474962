type ArticlesArray = Queries.PrismicCareersPageDataBodyAccordionWithFiltersItem[];

const getFilteredArticlesWithSearchTerm = (
    articles: ArticlesArray,
    searchTerm: string,
) => {
    return articles.filter((article) => {
        return article.accordion_title?.match(new RegExp(searchTerm, 'igm'));
    });
}

const getFilteredArticlesWithTag = (
    articles: ArticlesArray,
    tag: string,
) => {
    return articles.reduce<ArticlesArray>(
        (newItems, item) => {
            if (!item.tag) return newItems;
            return item.tag === tag as string ? [...newItems, item] : newItems;
        },
        []
    )
}

export const getFilteredArticles = (
    articles: ArticlesArray,
    tag: string,
    searchTerm: string,
): ArticlesArray => {
    let returnArticles = articles;

    if (tag) returnArticles = getFilteredArticlesWithTag(returnArticles, tag);
    if (searchTerm) returnArticles = getFilteredArticlesWithSearchTerm(returnArticles, searchTerm);
    
    return returnArticles;
}