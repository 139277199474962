import React from "react"
import styled from "styled-components";
import { LineItem as LineItemInterface } from "../../../../../utils/playbrush-client/types";
import { PageContext } from "../../../../slices/types";
import { OldProduct } from "../cart-list";
import LineContentItem from "./snippets/line-content-item";
import { getLineItemType, splitLineItemContent } from "../../../../../utils/cart/line-items";
import { LineItemContentLarge, LineItemFooter, LineItemSubscription, LineItemHardware } from "./snippets";

const GAP = 24;

const Article = styled.article`
    margin: 0 0 40px;
    border-bottom: 1px solid #CDD4DC;
    @media screen and (min-width: 768px) {
        
    }
`;

const LineItemName = styled.h5`
    margin: 0 0 24px;
`

const List = styled.ul`
    padding: 0;
`;

const SubList = styled.ul`
    padding: 0 ${GAP * 2}px;
`;

interface LineItemProps {
    lineItem: LineItemInterface,
    translations: PageContext['translations'],
    quickCart?: boolean,
    thankYouPage?: boolean,
    settings: PageContext['settings'];
    onRemove?: (v: OldProduct) => void,
}

const LineItem = ({ lineItem, translations, quickCart, thankYouPage, settings, onRemove }: LineItemProps) => {
    const { hardware, subscriptionItems, giftItem } = splitLineItemContent(lineItem.lineContent);
    const hasTrial = !!lineItem?.priceAmountAfterTrial;
    const isSubscription = subscriptionItems.length > 0;

    return (
        <>
            <Article>
                <LineItemName>
                    {lineItem.productNameMarketing}
                </LineItemName>
                <List>
                    {hardware && (
                        <LineItemContentLarge
                            content={hardware}
                            quantity={lineItem.quantity}
                            quickCart={quickCart}
                            translations={translations}
                            heading={getLineItemType(hardware, translations)}
                        >
                            <LineItemHardware
                                lineContent={hardware}
                                translations={translations}
                                quantity={lineItem.quantity}
                                isSubscription={isSubscription}
                                thankYouPage={thankYouPage}
                            />
                        </LineItemContentLarge>
                    )}
                    {giftItem && (
                        <LineItemContentLarge
                            content={giftItem}
                            quantity={lineItem.quantity}
                            quickCart={quickCart}
                            translations={translations}
                            heading={getLineItemType(giftItem, translations)}
                        >
                            <LineItemHardware
                                lineContent={giftItem}
                                useTextualZero={true}
                                translations={translations}
                                quantity={lineItem.quantity}
                                isSubscription={isSubscription}
                                thankYouPage={thankYouPage}
                            />
                        </LineItemContentLarge>
                    )}
                    {( isSubscription && (
                        <>
                            <LineItemContentLarge
                                content={lineItem}
                                quantity={lineItem.quantity}
                                quickCart={quickCart}
                                translations={translations}
                                isSubscription={lineItem.isSubscription}
                            >
                                <LineItemSubscription
                                    lineItem={lineItem}
                                    trial={hasTrial}
                                    translations={translations}
                                    quantity={lineItem.quantity}
                                    thankYouPage={thankYouPage}
                                    isSubscription={lineItem.isSubscription}
                                />
                            </LineItemContentLarge>
                            {!quickCart && subscriptionItems.length > 0 && (
                                <SubList>
                                    {subscriptionItems.map((subItem) => subItem.isSubscription ? (
                                        <LineContentItem key={`${lineItem.lineId}+${subItem.lineId}`} content={subItem} />
                                    ) : null)}
                                </SubList>
                            )}
                        </>
                    ))}
                </List>
                {!thankYouPage && <LineItemFooter lineItem={lineItem} quickCart={quickCart} onRemove={onRemove} settings={settings} />}
            </Article>
        </>
    )
};

export default LineItem;