import { SanitizedBundle } from "../../../../../utils";
import { LineItem } from "../../../../../utils/playbrush-client/types";
import { getBundleCollection } from "./get-bundle-collection";

export const getUpgradeBundleFromLineItem = (
    lineItem: LineItem,
    bundles: SanitizedBundle[][],
): SanitizedBundle | undefined => {
    const upgradeList = lineItem?.frontendLineData?.upgradeContext?.lib || []
    const upgradePosition = (lineItem?.frontendLineData?.upgradeContext?.position || 0) + 1;

    if (upgradePosition >= upgradeList.length) return;

    const upgradeContext = upgradeList[upgradePosition];
    const bundleCollections = getBundleCollection(bundles, upgradeContext);
    const upgradeBundles = bundleCollections[0] || [];
    const upgradeBundle = upgradeBundles[upgradePosition];

    return upgradeBundle;
}