import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { SliceProps } from "../../types";
import Button from "../../../snippets/button";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Wrapper from "../../../layout/wrapper";

import { facebookIconInline } from "../../../icons/facebook-icon-inline";
import { instagramIconInline } from "../../../icons/instagram-icon-inline";
import { bluetoothIconInline } from "../../../icons/bluetooth-icon-inline";
import { hasRichText } from "@utils";

const Section = styled.section`
    margin: 0 auto;
    z-index: 2;
    overflow: hidden;
    
    @media screen and (min-width: 768px) {
        min-height: 80vh;
        // margin: 137px auto 0;
    }
`

const InnerWrapper = styled.div`
    position: relative;
    margin: 0; 
    margin: 176px auto 0;
`

const ImagesHolder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        // position: absolute;
        top: 0;
        left: 0;
    }
`

const ImageController = styled.div<{
    desktop_position_x_axis: number,
    desktop_position_y_axis: number,
    desktop_z_index: number,
    desktop_width: number,
    mobile_position_x_axis: number,
    mobile_position_y_axis: number,
    mobile_z_index: number,
    mobile_width: number,
    hideOnDesktop: boolean,
    hideOnMobile: boolean,
}>`
    ${({
    desktop_position_x_axis,
    desktop_position_y_axis,
    desktop_z_index,
    desktop_width,
    mobile_position_x_axis,
    mobile_position_y_axis,
    mobile_z_index,
    mobile_width,
    hideOnDesktop,
    hideOnMobile,
}) => `
        display: ${hideOnMobile ? 'none' : 'block'};
        position: absolute;
        top: ${mobile_position_y_axis ? mobile_position_y_axis : desktop_position_y_axis}%;
        left: ${mobile_position_x_axis ? mobile_position_x_axis : desktop_position_x_axis}%;
        transform: translate(-50%, -50%);
        z-index: ${mobile_z_index ? mobile_z_index : desktop_z_index};
        width: ${mobile_width ? mobile_width : desktop_width}%;
        border-radius: 16px;

        @media screen and (min-width: 768px) {
            display: ${hideOnDesktop ? 'none' : 'block'};
            position: absolute;
            top: ${desktop_position_y_axis}%;
            left: ${desktop_position_x_axis}%;
            z-index: ${desktop_z_index};
            width: ${desktop_width}%;
        }
    `}
`

const ContentWrapper = styled.div<{
    content_position: boolean,
    upper_z_index: number,
    hasImagesOnMobile: boolean,
}>`
    ${({ content_position, upper_z_index, hasImagesOnMobile }) => `
        z-index: ${upper_z_index};
        position: relative;

        ${hasImagesOnMobile ? `
            padding: 300px 0 0;
            @media screen and (min-width: 768px) {
                padding: 0;
            }
        ` : `
            padding: 60px 0 0;
            @media screen and (min-width: 768px) {
                padding: 0;
            }
        `}
        
        @media screen and (min-width: 768px) {
            max-width: 710px;
        }

        ${!content_position ? `
            @media screen and (min-width: 768px) {
                margin: 0 auto;
                text-align: center;
            }
            ` : `
            @media screen and (min-width: 768px) {
                // margin: 0 80px;
            }
            `
        }`
    }
`

const Heading = styled.div`
    padding: 0 0 32px;

    em {
        color: #8193A8;
        font-style: normal;
    }
    
    h1 {
        font-weight: 400;
        font-size: 40px;
        line-height: 130%;
    }

    @media screen and (min-width: 768px) {
        padding: 40px 0 32px 0;

        h1 {
            font-size: 56px;
            font-weight: 400;
            line-height: 72.8px;
        }
    }
`

const Text = styled.div`
    padding: 0 0 32px 0;
`

const InstagramUsername = styled.div`
    display: block;
    padding: 24px 0 0 0;
    
    p {
        font-weight: 700;
        display: flex;

        &:before {
            padding: 0 5px 0 0;
            content: url(${instagramIconInline('#2BB573')});
        }
    }

    @media screen and (min-width: 768px) {
        display: inline-block;
        padding: 0 32px;
    }
`

const FacebookUsername = styled.div`
    display: block;
    padding: 24px 0 0 0;
    
    p {
        font-weight: 700;
        display: flex;
        
        &:before {
            padding: 0 5px 0 0;
            content: url(${facebookIconInline('#2BB573')});
        }
    }

    @media screen and (min-width: 768px) {
        display: inline-block;
        padding: 0 32px;
    }
`

const BluetoothBox = styled.div`
    display: flex;
    gap: 5%;
    align-items: center;
    padding: 0 16px;
`

const BluetoothText = styled.div`
    align-items: center;
    flex: 0 0 40%;

    &:before {
        padding: 0 5px 0 0;
        content: url(${bluetoothIconInline('#2BB573')});
    }

    @media screen and (min-width: 768px) {
        display: inline-flex;
        width: 100%;
    }
`

const BluetoothImage = styled.div`
    @media screen and (min-width: 768px) {
        display: none;
    }
`

const HeroImagecloud = (props: SliceProps<Queries.PrismicAboutPageDataBodyHeroImagecloud>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { content_position, heading, text, button_link, button_text, button_style, instagram_username, facebook_username, bluetooth_text, bluetooth_image } = primary;

    const highestZIndex: number = items.reduce((highestIndex: number, item): number => {
        const { desktop_z_index } = item || {};
        const index: number = desktop_z_index || 0;
        return index > highestIndex ? index : highestIndex;
    }, 0)

    const setButtonStyling = !!button_style ? 'outline' : 'solid';
    const [hasHeadingRichtext, headingRichtext] = hasRichText(heading?.richText);
    const [hasTextRichtext, textRichtext] = hasRichText(text?.richText);
    const [hasButtonRichtext, buttonRichtext] = hasRichText(button_text?.richText);
    const [hasInstagramRichtext, instagramRichtext] = hasRichText(instagram_username?.richText);
    const [hasFacebookRichtext, facebookRichtext] = hasRichText(facebook_username?.richText);
    const [hasBluetoothRichtext, bluetoothRichtext] = hasRichText(bluetooth_text?.richText);

    const hasImagesOnMobile = items.reduce<boolean>((bool, item) => (!item?.hide_on_mobile ? true : bool), false);

    return (
        <Section id="hero-imagecloud">
            <ImagesHolder>
                {items.map((props, index: number) => {
                    const {
                        image,
                        desktop_position_x_axis,
                        desktop_position_y_axis,
                        desktop_z_index,
                        desktop_width,
                        mobile_position_x_axis,
                        mobile_position_y_axis,
                        mobile_z_index,
                        mobile_width,
                        hide_on_desktop,
                        hide_on_mobile
                    } = props || {};

                    return (
                        <ImageController
                            key={`image-${index}`}
                            desktop_position_x_axis={desktop_position_x_axis || 0}
                            desktop_position_y_axis={desktop_position_y_axis || 0}
                            desktop_z_index={desktop_z_index || 0}
                            desktop_width={desktop_width || 0}
                            mobile_position_x_axis={mobile_position_x_axis || 0}
                            mobile_position_y_axis={mobile_position_y_axis || 0}
                            mobile_z_index={mobile_z_index || 0}
                            mobile_width={mobile_width || 0}
                            hideOnDesktop={!!hide_on_desktop}
                            hideOnMobile={!!hide_on_mobile}
                        >
                            <GatsbyImage
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image?.alt || ''}
                            />
                        </ImageController>
                    )
                })}
            </ImagesHolder>
            <Wrapper>
                <InnerWrapper>
                    <ContentWrapper
                        content_position={content_position as boolean}
                        upper_z_index={highestZIndex}
                        hasImagesOnMobile={hasImagesOnMobile}
                    >
                        {hasHeadingRichtext && <Heading>{RichText.render(headingRichtext)}</Heading>}
                        {hasTextRichtext && <Text>{RichText.render(textRichtext)}</Text>}
                        {button_link?.url && hasButtonRichtext && (
                            <Button
                                buttonStyle={setButtonStyling}
                                url={button_link.url}
                            >
                                {RichText.render(buttonRichtext)}
                            </Button>
                        )}
                        {hasInstagramRichtext && <InstagramUsername>{RichText.render(instagramRichtext)}</InstagramUsername>}
                        {hasFacebookRichtext && <FacebookUsername>{RichText.render(facebookRichtext)}</FacebookUsername>}

                        {hasBluetoothRichtext &&
                            <BluetoothBox>
                                <BluetoothText>{RichText.render(bluetoothRichtext)}</BluetoothText>
                                <BluetoothImage>
                                    <GatsbyImage
                                        image={(bluetooth_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={bluetooth_image?.alt || ''}
                                    />
                                </BluetoothImage>
                            </BluetoothBox>
                        }
                    </ContentWrapper>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default HeroImagecloud