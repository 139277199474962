import {
    ComparisonGrid,
    FeatureGrid,
    VideoGrid,
    BrushFeatures,
    FeaturedBrushes,
    IconBanner
} from '../../components/slices/product-page';

import { FeaturedProducts, PageWideVideo, Testimonials, Accordion, TextSection, NewsletterPopUp } from '../../components/slices/generic';

export const components = {
    video_section: PageWideVideo,
    comparison_grid: ComparisonGrid,
    featured_products_1: FeaturedProducts,
    featured_brushes: FeaturedBrushes,
    feature_grid: FeatureGrid,
    app_section: VideoGrid,
    brush_features: BrushFeatures,
    testimonials: Testimonials,
    accordion: Accordion,
    plain_text: TextSection,
    icon_banner: IconBanner,
    'email_pop-up': NewsletterPopUp,
}