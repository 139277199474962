import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const MinusIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconSvg>
);

export default MinusIcon;
