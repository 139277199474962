import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components";
import CartIcon from "../../icons/cart-icon"

import { useAppSelector } from "../../../app/hooks";
import { isMobile } from "@utils";

const CartButtonWrapper = styled.button`
    position: relative;
    background: #fff;
    padding: 12px;
    outline: 0;
    border: 0;
    cursor: pointer;
    border-radius: 16px;
    height: 48px;
    width: 48px;
    top: 1px !important;
`

const CartQuantity = styled.span`
	position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
	background: var(--light-green);
	color: white;
	font-size: 12px;
	border-radius: 50%;
`

interface CartButtonProps {
    openCart: () => void;
}

const CartButton = ({ openCart }: CartButtonProps) => {
    const [lineItemCount, lang] = useAppSelector(state => [state.cart.lineItemCount, state.general.lang]);

    const handleClick = () => {
        if (isMobile()) {
            navigate(`/${lang}/cart`);
        } else {
            openCart();
        }
    }

    return (
        <CartButtonWrapper type="button" onClick={handleClick}>
            <CartQuantity>
                {lineItemCount}
            </CartQuantity>
            <CartIcon />
        </CartButtonWrapper>
    )
};

export default CartButton;