export const DownloadIconInline = (color: any): string => {
    const encodedColor: string = color.replace('#', '%23');
    return (`"data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    %3E%3Cpath
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12.607 4.51978C12.607 3.96749 12.1592 3.51978 11.607 3.51978C11.0547 3.51978 10.607 3.96749 10.607 4.51978V13.6099L6.70004 9.62552C6.31337 9.23118 5.68644 9.23118 5.29977 9.62552C4.9131 10.0199 4.9131 10.6592 5.29977 11.0535L10.9068 16.7717L11.607 17.4857L12.3071 16.7717L17.964 11.0028C18.3506 10.6084 18.3506 9.96907 17.964 9.57474C17.5773 9.1804 16.9504 9.1804 16.5637 9.57474L12.607 13.6099V4.51978Z' 
    fill='${encodedColor}'
    /%3E%3Cpath 
    d='M17.6074 20.1569H5.60742' stroke='${encodedColor}' strokeWidth='2' strokeLinecap='round'/%3E%3C/svg%3E%0A"
    `)
};