import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Wrapper from "../../../layout/wrapper";
import { DownloadIconInline } from "../../../icons/download-icon-inline";
import { hasRichText, renderRich } from "@utils";

const Section = styled.section`
    margin: 80px 0 0 0;    
    
    @media screen and (min-width: 768px) {
        margin: 120px 0 0 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 0 auto;
    background: #EAF0F6;
    border-radius: 16px;
    padding: 24px;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 10%;
        padding: 32px;
    }
`

const ContentContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex: 1 0 50%;
    order: 1;

    @media screen and (min-width: 768px) {
        order: 0;
    }
`

const Title = styled.div`
    width: 100%;
    margin: 0 auto 40px;
    
    p {
        font-size: 24px;
    }
`

const ImageContainer = styled.div`
    flex: 0 0 100%;
    order: 0;

    @media screen and (min-width: 768px) {
        flex: 0 1 auto;
        order: 1;
    }
`

const Image = styled(GatsbyImage)`
    width: auto;

    @media screen and (min-width: 768px) {
        max-width: 414px;
    }
`

const Item = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 12px 0;
    gap: 10px;

    @media screen and (min-width: 768px) {
        flex-wrap: wrap;
    }
`

const DownloadLink = styled.a`
    display: flex;
    color: var(--light-green);
    font-size: 16px;
    text-decoration: none;
    align-items: center;

    p {
        font-weight: 600;
    }

    &::before {
        content: url(${DownloadIconInline('#2BB573')});
        padding: 0 8px 0 0;
    }
`

const DownloadSize = styled.span`
    color: #8193A8;
`

const LastUpdate = styled.span`
    color: #8193A8;
    width: 100%;

    p {
        display: inline-flex;
        color: #8193A8;
        padding: 0 5px 0 0;
    }

    @media screen and (min-width: 768px) {
        width: auto;
    }
`

const Button = styled.a`
    font-size: 14px;
    padding: 16px 43px;
    border-radius: 16px;
    border: none;
    text-decoration: none;
    color: var(--light-greenpink);
    
    background: none;
    border: 1px solid var(--light-green);
    
    p {
        display: inline;
        color: var(--light-green);
        font-weight: 600;
    }
    
    @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }
    
    &:hover {
        cursor: pointer;
    }    
`




const DownloadSection = (props: SliceProps<Queries.PrismicPressAndMediaPageDataBodyDownloadSection>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { download_all_link, download_all_button_text, title, image, last_update_text } = primary;

    const download_all_link_size = download_all_link?.size || 0;
    const download_all_link_size_mb = (download_all_link_size / 1000000);
    const downloadable_file_size_mb_decimal = (download_all_link_size_mb.toFixed(2));

    const [titleHasRichText, titleRichText] = hasRichText(title?.richText);
    const [downloadAllButtonTextHasRichText, downloadAllButtonTextRichText] = hasRichText(download_all_button_text?.richText);
    const [lastUpdateTextHasRichText, lastUpdateTextRichText] = hasRichText(last_update_text?.richText);


    return (
        <Section id="download-section">
            <Wrapper>
                <InnerWrapper>
                    <ContentContainer>
                        {titleHasRichText && (<Title>{renderRich(titleRichText)}</Title>)}
                        {items && items.map((item, index: number) => {
                            const downloadable_file_size = item?.downloadable_file?.size || 0;
                            const downloadable_file_size_mb = (downloadable_file_size / 1000000);
                            const downloadable_file_size_mb_decimal = (downloadable_file_size_mb.toFixed(2));
                            const [downloadTextHasRichText, downloadTextRichText] = hasRichText(item?.download_text?.richText);
                            const last_update = item?.last_update || '';

                            return (
                                <Item
                                    key={`download-link-${index}`}
                                >
                                    {downloadTextHasRichText &&
                                        <DownloadLink
                                            href={item.downloadable_file?.url || ''}
                                            target="_blank"
                                        >
                                            {renderRich(downloadTextRichText)}
                                        </DownloadLink>
                                    }
                                    <DownloadSize>
                                        {downloadable_file_size_mb_decimal} MB
                                    </DownloadSize>
                                    {lastUpdateTextHasRichText &&
                                        <LastUpdate>
                                            {renderRich(lastUpdateTextRichText)}
                                            {last_update}
                                        </LastUpdate>
                                    }
                                </Item>
                            )
                        })}

                        {download_all_link && downloadAllButtonTextHasRichText &&
                            <Button
                                href={download_all_link?.url || ''}
                                target="_blank"
                            >
                                {renderRich(downloadAllButtonTextRichText)} ({downloadable_file_size_mb_decimal} MB)
                            </Button>
                        }
                    </ContentContainer>
                    <ImageContainer>
                        {image?.gatsbyImageData && (
                            <Image
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image.alt || ''}
                            />
                        )}
                    </ImageContainer>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default DownloadSection