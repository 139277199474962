import dayjs from "dayjs";
import React, { useState } from "react"
import styled from "styled-components";
import { OrderContent } from "../../../../utils/playbrush-client/types";
import { baseInterpolator } from "@utils";
import { PageContext } from "../../../slices/types";

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(1fr, 5);
    grid-gap: 16px;
    grid-gap-row: 48px;
    grid-gap-column: 16px;
    margin: 0 0 80px;
`

const OrderNumber = styled.div`
    grid-column: 1 / 3;
`

const OrderDate = styled.div`
    grid-column: 3 / 6;
`

const ShippingAddress = styled.div`
    grid-column: 1 / 6;
    grid-row: 2 / 3;
`

const Heading = styled.h5`
    margin: 0 0 24px;
`

const SubHeading = styled.p`
    margin: 0 0 24px;
`


interface OrderInfoProps {
    data: Queries.PrismicThankYouPageDataType;
    order?: OrderContent;
    translations: PageContext['translations'];
    settings: PageContext['settings'];
}

const OrderInfo = ({ data, order, translations }: OrderInfoProps) => {

    const shippingLineOne = `${order?.shippingName}, ${order?.shippingAddressLine1}, ${order?.shippingAddressPostalCode} ${order?.shippingAddressState} ${order?.shippingAddressCity}, ${order?.shippingAddressCountry}`;
    const shippingLineTwo = `${order?.email}`;
    const minDate = dayjs(order?.createdAt).add(2, 'day').format('DD-MM-YYYY');
    const maxDate = dayjs(order?.createdAt).add(5, 'day').format('DD-MM-YYYY');

    return (
        <Container>
            <OrderNumber>
                <Heading>{data?.order_number}</Heading>
                {order && <SubHeading className="big-text">{order.orderId}</SubHeading>}
            </OrderNumber>
            <OrderDate>
                {/* <Heading>{data?.order_date}</Heading> */}
                {/* {order && <SubHeading className="big-text">{baseInterpolator(translations?.between_and, {
                    min_date: minDate,
                    max_date: maxDate,
                })}</SubHeading>} */}
                {/* {order && <SubHeading className="big-text">{minDate + ' - ' + maxDate}</SubHeading>} */}
            </OrderDate>
            <ShippingAddress>
                <Heading>{data?.shipping_address}</Heading>
                <p>{shippingLineOne}</p>
                <p>{shippingLineTwo}</p>
            </ShippingAddress>
        </Container>
    )
};

export default OrderInfo;