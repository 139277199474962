import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const PaymentIcon = ({ color = '#fff' }: { color?: string }) => (
    <IconSvg width="42" height="31" viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="28.5" y="22.5" width="13" height="8" rx="1.5" stroke={color} />
        <path d="M32 23L32 20C32 18.3431 33.3431 17 35 17C36.6569 17 38 18.3431 38 20L38 23" stroke={color} />
        <path d="M1 6H41V5H1V6Z" fill={color} />
        <path d="M1 11H41V10H1V11Z" fill={color} />
        <path d="M3 1H39C40.1046 1 41 1.89543 41 3V19H42V3C42 1.34315 40.6569 0 39 0H3C1.34315 0 0 1.34315 0 3V24C0 25.6569 1.34315 27 3 27H25V26H3C1.89543 26 1 25.1046 1 24V3C1 1.89543 1.89543 1 3 1Z" fill={color} />
    </IconSvg>
);

export default PaymentIcon;