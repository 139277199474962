import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';

import Button from "../../../snippets/button";
import { SliceProps } from "../../types";
import { arrowRightIconSmallInline } from "../../../icons/arrow-right-icon-small-inline";

const Section = styled.section`
    max-width: 1440px;
    padding: 0 16px 0;
    margin: 20px auto 0;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 340px auto;
        padding: 0 80px;
        margin: 120px auto 0;
    }
`

const TextContainer = styled.div`
    padding: 0 90px 40px 0;
`

const Heading = styled.div`
    margin: 0 0 24px;
`

const Description = styled.div`
    margin: 0 0 24px;

    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
    }
`

const DownloadLink = styled.a`
    color: var(--light-green);

    p {
        display: inline-flex;
        align-items: center;
        color: var(--light-green);
        
        &::after {
            content: url(${arrowRightIconSmallInline('#2BB573')});
            padding: 5px 0 0 0;
        }
    }
`

const NewsContainer = styled.div``

const NewsItem = styled.article`
    padding: 35px 0;
    border-top: 2px solid var(--bg-green);

    &:last-child {
        border-bottom: 2px solid var(--bg-green);
    }
`

const ItemTitle = styled.div`
    display: flex;
    justift-content: space-between;
    align-items: center;
    margin: 0 0 16px;
    
    h4 {
        font-size: 24px;
    }
    
    @media screen and (min-width: 768px) {
        margin: 0 0 24px;
    }
`

const ItemContent = styled.div`
    p {
        font-size: 16px;
    }    
    
    @media screen and (min-width: 768px) {
        p {
            font-size:
        }
    }
`

const NewsSection = (props: SliceProps<Queries.PrismicPressAndMediaPageDataBodyNewsSection>) => {
    const { context: { audience }, slice: { items, primary } } = props;
    const { heading, description, button_text, button_link } = primary;

    return (
        <Section id="news-section">
            <TextContainer>
                {description?.richText && (
                    <Description>{RichText.render(description.richText)}</Description>
                )}
                {button_link && button_text?.richText && (
                    <Button url={button_link?.url || ''} buttonStyle={'outline'}>
                        {RichText.render(button_text.richText)}
                    </Button>
                )}
            </TextContainer>
            <NewsContainer>
                {heading?.richText && (
                    <Heading>{RichText.render(heading.richText)}</Heading>
                )}
                {items && items.map((item, index: number) => {
                    return (
                        <NewsItem key={`news-item-${index}`}>
                            <ItemContent>
                                {item?.title && item.title?.richText && (
                                    <ItemTitle>
                                        {RichText.render(item.title.richText)}
                                    </ItemTitle>
                                )}
                                {item?.description && item.description?.richText && (
                                    <Description>
                                        {RichText.render(item.description.richText)}
                                    </Description>
                                )}
                                {item?.link?.url && (
                                    <DownloadLink
                                        href={item?.link?.url || ''}
                                    >
                                        {RichText.render(item?.link_text?.richText)}
                                    </DownloadLink>
                                )}
                            </ItemContent>
                        </NewsItem>
                    )
                })}
            </NewsContainer>
        </Section>
    )
};

export default NewsSection