import TeamGrid from '../../components/slices/about-page/team-grid/team-grid';
import { BenefitsGrid, CtaTeaser, FeatureGrid, HorizontalScroll, HeroImagecloud, NewsletterPopUp } from '../../components/slices/generic';
import { FeaturedArticles } from '../../components/slices/magazine-overview-page';

export const components = {
    hero___imagecloud: HeroImagecloud,
    benefits_grid: BenefitsGrid,
    cta_teaser: CtaTeaser,
    feature_grid: FeatureGrid,
    horizontal_scroll: HorizontalScroll,
    related_articles: FeaturedArticles,
    team_grid: TeamGrid,
    'email_pop-up': NewsletterPopUp,
}
