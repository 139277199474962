import { WrapPageBrowser } from './src/components/layout/wrap-page-browser';
import { WrapRootBrowser } from './src/components/layout/wrap-root-browser';

function scrollToAnchor(location) {
    if (location && location.hash) {
        const item = document.querySelector(`${location.hash}`);

        item?.scrollIntoView({
            behavior: "smooth",
        })
    }
  
    return true
}

export const onRouteUpdate = ({ location }) => {
    setTimeout(() => scrollToAnchor(location), 250);
};
export const wrapPageElement = WrapPageBrowser;
export const wrapRootElement = WrapRootBrowser;