import { IGatsbyImageData } from "gatsby-plugin-image";

export interface CleanVariantImage {
    alt?: string;
    gatsbyImageData?: IGatsbyImageData;
}

interface ExtendedBundles extends Queries.PrismicBundleDataType {
    doc_id: string;
}

export interface CleanVariant {
    color: string;
    brush_type: string;
    size: string;
    images: CleanVariantImage[];
    bundles: ExtendedBundles[];
    stripe_variant?: Queries.PrismicProductVariantDataStripeVariantIntegrationType;
}

export const getCleanVariants = (variants: Queries.PrismicProductDataVariant[]): CleanVariant[] => {
    const cleanVariants: CleanVariant[] = variants?.map(
        (v: Queries.PrismicProductDataVariant) => {
            const variantDocument = v?.product_variant?.document;
            const variant: Queries.PrismicProductVariantDataType = (variantDocument?.data || {}) as Queries.PrismicProductVariantDataType;

            const bundles = variant?.bundles?.map(
                (b: Queries.PrismicProductVariantDataBundles | null) => {
                    const bundleDocument = b?.bundle?.document;
                    const doc_id = variantDocument?.id || '';

                    return {
                        ...bundleDocument?.data as Queries.PrismicBundleDataType,
                        doc_id: doc_id,
                    };
                }
            );

            const images = variant?.image?.map(
                (i) => {
                    return {
                        alt: i?.variant_image?.alt as string,
                        gatsbyImageData: i?.variant_image?.gatsbyImageData as IGatsbyImageData | undefined,
                    };
                }
            );

            return {
                color: variant.color || '',
                brush_type: variant.brush_type || '',
                size: variant.size || '',
                stripe_variant: variant.stripe_variant as Queries.PrismicProductVariantDataStripeVariantIntegrationType | undefined,
                bundles: bundles || [],
                images: images || [],
            };
        }
    );

    return cleanVariants;
}

export const getCleanVariantsNew = (variants: Queries.PrismicProductDataVariant[]): CleanVariant[] => {
    const cleanVariants: CleanVariant[] = variants?.map(
        (v: Queries.PrismicProductDataVariant) => {
            const variantDocument = v?.product_variant?.document;
            const variant: Queries.PrismicProductVariantDataType = (variantDocument?.data || {}) as Queries.PrismicProductVariantDataType;

            const bundles = variant?.bundles?.map(
                (b: Queries.PrismicProductVariantDataBundles | null) => {
                    const bundleDocument = b?.bundle?.document;
                    const doc_id = variantDocument?.id || '';

                    return {
                        ...bundleDocument?.data as Queries.PrismicBundleDataType,
                        doc_id: doc_id,
                    };
                }
            );

            const images = variant?.image?.map(
                (i) => {
                    return {
                        alt: i?.variant_image?.alt as string,
                        gatsbyImageData: i?.variant_image?.gatsbyImageData as IGatsbyImageData | undefined,
                    };
                }
            );

            return {
                color: variant.color || '',
                brush_type: variant.brush_type || '',
                size: variant.size || '',
                stripe_variant: variant.stripe_variant as Queries.PrismicProductVariantDataStripeVariantIntegrationType | undefined,
                bundles: bundles || [],
                images: images || [],
            };
        }
    );

    return cleanVariants;
}
