import React from "react"
import styled from "styled-components";
import { concatSubscriptionHeading } from "../../../../../../utils/cart/line-items";
import { LineContent, LineItem } from "../../../../../../utils/playbrush-client/types";
import { PageContext } from "../../../../../slices/types";

const GAP = 24;

const Item = styled.li`
    padding: 0;
    list-style: none;
`;

// const ItemType = styled.p`
//     // display: flex;
//     // justify-content: space-between;
//     // align-items: center;
//     margin-bottom: ${GAP / 2}px;

//     font-weight: bold;
//     flex: 0 0 100%;
// `;

const DetailsWrapper = styled.div<{ small?: boolean }>`
    ${({ small }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${small ? GAP / 2 : GAP}px;
    `}
`;

const Image = styled.img<{ small: boolean }>`
    ${({ small }) => `
        flex: 0 0 40px;
        border-radius: 7px;
        object-cover: fit;

        ${small ? `
            width: 24px;
            height: 32px;
        ` : `
            width: 32px;
            height: 48px;
        `}

        @media screen and (min-width: 768px) {
            border-radius: 16px;
            flex: 0 0 80px;

            ${small ? `
                flex: 0 0 64px;
                width: 40px;
                height: 72px;
            ` : `
                flex: 0 0 64px;
                width: 64px;
                height: 80px;
            `}
        }
    `}
`;

const Details = styled.div`
    margin: 0 0 0 ${GAP}px;
    flex: 1 1 auto;
`;

const Name = styled.p<{ small: boolean }>`
    ${({ small }) => `
        font-weight: bold;

        ${small ? `
            font-size: 12px;
        ` : `
            font-size: 16px;
        `}
    `}
`;

const Description = styled.p`
    font-size: 16px;
`;

interface LineItemContentLargeProps {
    content: LineContent | LineItem;
    children: React.ReactNode | React.ReactNode[];
    quantity: number;
    translations: PageContext['translations'];
    isSubscription?: boolean;
    quickCart?: boolean;
    heading?: string;
}

const LineItemContentLarge = ({ content, quantity, isSubscription, quickCart, translations, children }: LineItemContentLargeProps) => {
    const subscriptionText = concatSubscriptionHeading(content, translations);
    const small = !!quickCart

    return (
        <Item>
            {/* {heading && <ItemType>{heading}</ItemType>} */}
            <DetailsWrapper small={small}>
                <Image src={content.image} small={small} />
                <Details>
                    <Name small={small}>
                        {`${quantity}x ${!!isSubscription ? subscriptionText : content.productNameMarketing}`}
                    </Name>
                    {!small && content.description && (
                        <Description>{content.description}</Description>
                    )}
                </Details>
                {children}
            </DetailsWrapper>
        </Item>
    )
};

export default LineItemContentLarge;