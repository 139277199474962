import React, { useEffect } from "react"
import styled from "styled-components";
import { SliceProps } from "../../types";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";
import { externalLinkIconInline } from "../../../icons/external-link-icon-inline";

import { hasRichText, renderRich } from "../../../../utils/common/rich-text"

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-Width: 768px) {
        margin: 120px auto 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-Width: 768px) {
        flex-wrap: nowrap;
    }
`

const HeadingContainer = styled.div`
    flex: 0 1 100%;

    @media screen and (min-Width: 768px) {
        flex: 0 1 80%;
    }
`

const Heading = styled.div`
    padding: 0 0 40px 0;
`

const Description = styled.div`
`

const ButtonWrapper = styled.div`
    display: none;
    
    @media screen and (min-Width: 768px) {
        display: block;
        flex: 1 1 50%;
        text-align: right;
    }
`

const PostGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 40px 0 0 0;
    gap: 16px;
    
    @media screen and (min-Width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

const Post = styled.article`
    border-radius: 16px;
`

const SeeMoreButtonWrapper = styled.div`
    margin: 64px 0 20px 0;
    
    p {
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: url(${externalLinkIconInline('#ffffff')});
            padding: 5px 0 0 5px; 
        }
    }
`

const MobileButtonWrapper = styled.div`
    display: block;
    
    @media screen and (min-Width: 768px) {
        display: none;
    }
`

const SocialMediaEmbedGrid = (props: SliceProps<Queries.PrismicAmbassadorPageDataBodySocialMediaEmbedGrid>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading, description, button_text, button_url, see_more_button_text, see_more_button_url } = primary;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);
    const [SeeMoreButtonTextHasRichText, SeeMoreButtonTextRichText] = hasRichText(see_more_button_text?.richText);

    useEffect(() => {
        // @ts-ignore;
        window?.gatsbyLoadInstagram();
    }, [])

    return (
        <Section id="social-media-embed-grid">
            <Wrapper>
                <InnerWrapper>
                    <HeadingContainer>
                        {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                        {descriptionHasRichText && (<Description>{renderRich(descriptionRichText)}</Description>)}
                    </HeadingContainer>
                    {buttonTextHasRichText && button_url &&
                        <ButtonWrapper>
                            <Button
                                buttonStyle="outline"
                                url={button_url?.url || '' as string}
                                buttonFullWidth={'full-mobile'}
                            >
                                {renderRich(buttonTextRichText)}
                            </Button>
                        </ButtonWrapper>
                    }
                </InnerWrapper>
                <PostGrid>
                    {items.map((item, index: number) => {

                        const embedText = item?.embed_code || '';

                        const instaPost = embedText.replace(/<script[^<]{0,}<\/script>/, '');

                        return <Post key={`post-${index}`} dangerouslySetInnerHTML={{ __html: instaPost }} />
                    })}
                </PostGrid>
                {SeeMoreButtonTextHasRichText && see_more_button_url &&
                    <SeeMoreButtonWrapper>
                        <Button
                            buttonStyle="solid"
                            url={see_more_button_url?.url || '' as string}
                            buttonFullWidth={'full-mobile'}

                        >
                            {renderRich(SeeMoreButtonTextRichText)}
                        </Button>
                    </SeeMoreButtonWrapper>
                }
                {buttonTextHasRichText && button_url &&
                    <MobileButtonWrapper>
                        <Button
                            buttonStyle="outline"
                            url={button_url?.url || '' as string}
                            buttonFullWidth={'full-mobile'}
                        >
                            {renderRich(buttonTextRichText)}
                        </Button>
                    </MobileButtonWrapper>
                }
            </Wrapper>
        </Section>
    )
};

export default SocialMediaEmbedGrid