import React, { useEffect, useState } from 'react'
import styled from "styled-components";

import { hasRichText, renderRich } from "@utils";

import Button from "../../../snippets/button";
import { SliceProps } from "../../types";

import Wrapper from "../../../layout/wrapper";
import { getFilteredArticles } from './utils';
import AccordionItem from './snippets/accordion-item';
import SearchBar from './snippets/accordion-search-bar';

const Section = styled.section`
    margin: 80px auto 0;
    
    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const HeadingWrapper = styled.div``

const SubheadingWrapper = styled.div``

const Heading = styled.div`
    margin: 0 auto 36px;
    text-align: center;
    
    h1 {
        font-size: 40px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.01em;
        text-align: left;
        
        @media screen and (min-width: 768px) {
            font-size: 56px;
            text-align: center;
        }
    }

`

const SubHeading = styled.h2`
    margin: 0 0 16px 0;

    @media screen and (min-width: 768px) {
        margin: 0 0 40px 0;
    }
`

const InnerWrapper = styled.div`
    display: grid;
    
    @media screen and (min-width: 768px) {
        grid-template-columns: 250px auto;
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 450px auto;
    }
`

const TagsWrapper = styled.div`
    margin: 0 0 40px 0;
`

const TagList = styled.ul`
    padding-inline-start: 0;
`

const Tag = styled.li`
    display: inline-block;
    list-style-type: none;
    width: auto;
    height: 52px;
    margin: 0 5px 5px 0;
    
    &:hover {
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        display: block;
        width: 100%;
        margin: 0 0 24px 0;
    }
`

const TagText = styled.button<{ isSelected: boolean }>`
    border: 1px solid var(--light-green);
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;

    ${({ isSelected }) => isSelected ? `
        background-color: var(--light-green);
        color: white;
    ` : `
         background: transparent;
    `}
`

const AccordionContainer = styled.div`
`

const ContactBottom = styled.div`
    width: 100%;
    margin: 48px 0 0 0;

    @media screen and (min-width: 768px) {    
        width: 50%;
        margin: 80px 0 0 0;
    }
`

const Subtext = styled.div`
    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        margin: 0 0 20px 0;
    }
`

type ArticlesArray = Queries.PrismicCareersPageDataBodyAccordionWithFiltersItem[];

const AccordionWithFilters = (props: SliceProps<Queries.PrismicCareersPageDataBodyAccordionWithFilters>) => {
    const { context: { translations }, slice: { primary, items, slice_label } } = props;
    const { heading, subtext, button_text, button_link } = primary;
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [subtextHasRichText, subtextRichText] = hasRichText(subtext?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    const [activeTag, setActiveTag] = useState<string>();
    const [searchTerm, setSearchTerm] = useState("");
    const [activeArticles, setActiveArticles] = useState<ArticlesArray>([]);

    const cleanTags = items.reduce<string[]>((tags, { tag }) => {
        if (!tag) return tags;
        return tags.includes(tag) ? tags : [...tags, tag];
    }, []);

    /** SEARCH FUNCTION */
    const handleChange = (e: any) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const selectTag = (tag: string) => {
        tag === activeTag ? setActiveTag(undefined) : setActiveTag(tag);
    }

    useEffect(() => {
        if (!items?.length) return;
        const filterArticles = getFilteredArticles(items as ArticlesArray, activeTag as string, searchTerm);
        setActiveArticles(filterArticles);
    }, [searchTerm, activeTag])

    return (
        <Section id="accordion-with-filters">
            <Wrapper>
                {slice_label === 'accordion-careers' ? (
                    <SubheadingWrapper>
                        {items.length > 0 ? (
                            <SubHeading>{activeArticles?.length} {translations?.open_positions || 'open position(s)'}</SubHeading>
                        ) : (
                            <SubHeading>{translations?.no_open_positions || 'no open position(s)'}</SubHeading>
                        )
                        }
                    </SubheadingWrapper>
                ) : (
                    <HeadingWrapper>
                        {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                        <SearchBar handleChange={handleChange} />
                    </HeadingWrapper>
                )
                }
                <InnerWrapper>
                    <TagsWrapper>
                        {slice_label != 'accordion-careers' && (<SubHeading>FAQ</SubHeading>)}
                        <TagList>
                            {cleanTags.map((tag, index) => (
                                <Tag key={`tag-${index}`}>
                                    <TagText
                                        type="button"
                                        isSelected={tag === activeTag}
                                        onClick={() => selectTag(tag)}
                                    >
                                        {tag}
                                    </TagText>
                                </Tag>)
                            )}
                        </TagList>
                    </TagsWrapper>
                    <AccordionContainer>
                        {activeArticles && activeArticles.map((item, index) => (
                            <AccordionItem
                                key={index}
                                article={item}
                                translations={translations}
                                hideFeedback={slice_label != 'accordion-careers'}
                                index={index}
                            />
                        ))}
                        {subtextHasRichText &&
                            <ContactBottom>
                                {subtextHasRichText && (<Subtext>{renderRich(subtextRichText)}</Subtext>)}
                                {button_link && button_link?.url && buttonTextHasRichText && (
                                    <Button
                                        buttonStyle="outline"
                                        url={button_link?.url || ''}
                                    >
                                        {buttonTextHasRichText && renderRich(buttonTextRichText)}
                                    </Button>
                                )}
                            </ContactBottom>
                        }
                    </AccordionContainer>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default AccordionWithFilters