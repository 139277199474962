import React from "react";
import { hasRichText, renderRich } from "@utils";
import styled from "styled-components";

const Cell = styled.div<{ isHeader: boolean }>`
    padding: 8px;
    outline: 1px solid var(--light-green);
    outline-offset: -0.5px;

    ${({ isHeader }) => isHeader ? `
        background: var(--light-green);
        * {
            color: white;
            margin-bottom: 0;
        }
    ` : ``};
`

interface CookieDetailsProps {
    isHeader: boolean;
    row: Queries.PrismicTextPageDataBodyCookieDetailsItem;
}

const CookieDetails = (props: CookieDetailsProps) => {
    const { row, isHeader } = props;
    const { cookie_subdomain, cookie, cookie_duration, cookies_used } = row;

    const [cookieSubdomainHasRichText, cookieSubdomainRichText] = hasRichText(cookie_subdomain?.richText);
    const [cookieHasRichText, cookieRichText] = hasRichText(cookie?.richText);
    const [cookiesUsedHasRichText, cookiesUsedRichText] = hasRichText(cookies_used?.richText);
    const [cookieDurationHasRichText, cookieDurationRichText] = hasRichText(cookie_duration?.richText);

    return (
        <>
            <Cell isHeader={isHeader}>{cookieSubdomainHasRichText ? renderRich(cookieSubdomainRichText) : <div />}</Cell>
            <Cell isHeader={isHeader}>{cookiesUsedHasRichText ? renderRich(cookiesUsedRichText) : <div />}</Cell>
            <Cell isHeader={isHeader}>{cookieHasRichText ? renderRich(cookieRichText) : <div />}</Cell>
            <Cell isHeader={isHeader}>{cookieDurationHasRichText ? renderRich(cookieDurationRichText) : <div />}</Cell>
        </>
    );
};

export default CookieDetails