import { Locale, constants } from "@constants";

const localePattern = /^\/(\w{2}-\w{2})/m

export const shouldSwitchLang = (
    currentLang: Locale,
    path?: string
): {
    shouldSwitch: boolean,
    lang: Locale
} => {
    if (!path) return { shouldSwitch: false, lang: currentLang };

    const matchData = path.match(localePattern);
    const newLocale = matchData?.[1];
    const hasNewLocale = !!newLocale;

    const shouldSwitch = hasNewLocale && newLocale !== currentLang;

    return ({
        shouldSwitch,
        lang: newLocale as Locale,
    });
}
