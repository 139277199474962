import React from "react";
import styled from "styled-components";
import VariantBlock from "./variant-block";
import { VariantSelectorProps } from "../../types";
import { CleanVariant } from "../../../../../utils/product/get-clean-variants";
import { useAppSelector } from "../../../../../app/hooks";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
	
	@media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 32px;
	}
`

const Spacer = styled.div`
    margin: 40px 0;
    border: 1px solid var(--light-green);
    opacity: 0.2;
	
	@media screen and (min-width: 768px) {
        display: none;
	}
`

const VariantSelector = ({ register, variants, translations, shouldShowCompareBrushes }: VariantSelectorProps) => {
    const [selectedColor, selectedBrush] = useAppSelector(state => [state.product.selectedColor, state.product.selectedBrush]);

    const colors = variants?.reduce((colorArr: string[], variant: CleanVariant) => {
        if (!variant?.color) return colorArr;
        return colorArr.includes(variant.color) ? colorArr : [...colorArr, variant.color];
    }, []) || [];

    const brushTypes = variants?.reduce((brushTypeArr: string[], variant: CleanVariant) => {
        if (!variant?.brush_type) return brushTypeArr;
        return brushTypeArr.includes(variant.brush_type) ? brushTypeArr : [...brushTypeArr, variant.brush_type];
    }, []) || [];

    const sizes = variants?.reduce<string[]>((sizes, variant) => {
        if (sizes.includes(variant?.size || '') || !variant?.size) return sizes;
        return [...sizes, variant?.size || ''];
    }, []);

    const hasColorOptions = !!colors?.length;
    const hasBrushOptions = brushTypes?.length > 1;
    const hasSizeOptions = !!sizes && sizes.length > 0;
    const shouldShow = hasColorOptions || hasBrushOptions || hasSizeOptions;

    const currentlyAvailableBrushTypes = variants?.reduce((brushTypeArr: string[], variant: CleanVariant) => {
        if (!variant?.brush_type) return brushTypeArr;

        if (variant.color === selectedColor) {
            return brushTypeArr.includes(variant.brush_type) ? brushTypeArr : [...brushTypeArr, variant.brush_type];
        } else {
            return brushTypeArr;
        }
    }, []) || [];

    return (
        <>
            {shouldShow && (
                <Container>
                    {hasColorOptions && (
                        <>
                            <VariantBlock
                                title={translations.select_color}
                                options={colors}
                                forType={'color'}
                                register={register}
                                translations={translations}
                                currentlyAvailableBrushTypes={currentlyAvailableBrushTypes}
                            />
                            <Spacer />
                        </>
                    )}
                    {hasBrushOptions && (
                        <>
                            <VariantBlock
                                title={translations.select_brush}
                                linkText={translations.compare_brushes}
                                options={brushTypes}
                                forType={'brush'}
                                url={shouldShowCompareBrushes ? '#compare-brushes' : undefined}
                                register={register}
                                translations={translations}
                                currentlyAvailableBrushTypes={currentlyAvailableBrushTypes}
                            />
                            <Spacer />
                        </>
                    )}
                    {hasSizeOptions && (
                        <>
                            <VariantBlock
                                title={translations.select_size}
                                options={sizes}
                                forType={'size'}
                                register={register}
                                translations={translations}
                                currentlyAvailableBrushTypes={currentlyAvailableBrushTypes}
                            />
                            <Spacer />
                        </>
                    )}
                </Container>
            )}
        </>
    )
}

export default VariantSelector;