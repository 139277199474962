import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ToothIcon = ({ color = 'white' }: { color?: string }) => (
    <IconSvg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M32.4327 8.61775C32.0723 4.15018 28.4696 0.475804 24.0738 0.042689C22.4884 -0.101466 20.9033 0.114766 19.4621 0.763157C17.6608 1.55573 15.6432 1.55573 13.8415 0.763157C12.4003 0.114767 10.8152 -0.101451 9.22984 0.042689C4.69047 0.474854 1.0879 4.14987 0.727353 8.61775C0.583197 10.6353 1.08774 12.6529 2.09649 14.3103C3.17735 16.0395 3.68188 17.9854 3.60984 20.075V21.5883C3.60984 29.6593 6.49203 36 10.095 36C10.8155 36 11.6083 35.7117 12.2567 35.2074C12.9051 34.7029 13.2655 33.9103 13.1213 33.0457C13.0489 32.1084 12.9769 31.1718 12.9769 30.235C12.9769 24.6866 14.8503 20.8679 16.5798 20.8679C18.309 20.8679 20.1828 24.6868 20.1828 30.235C20.1828 31.1718 20.1107 32.1085 19.9665 33.0451C19.8945 33.8377 20.1828 34.7023 20.8312 35.2068C21.5516 35.7114 22.2724 35.9994 22.9929 35.9994C26.5958 35.9994 29.478 29.6584 29.478 21.5877V20.0744C29.4059 17.9848 29.9105 16.0392 30.9914 14.3097C32.0722 12.5807 32.5764 10.6352 32.4323 8.61767L32.4327 8.61775ZM29.7667 13.5175C28.5417 15.463 27.9654 17.7688 28.0375 20.0748V21.516C28.0375 29.1541 25.3713 34.4863 22.9936 34.4863C22.5612 34.4863 22.129 34.3421 21.6965 33.9817C21.4803 33.7655 21.3361 33.4772 21.4082 33.1892C21.5524 32.1804 21.6244 31.1716 21.6244 30.1628C21.6244 23.966 19.4627 19.3543 16.5805 19.3543C13.6984 19.3543 11.5367 23.966 11.5367 30.1628C11.5367 31.1716 11.6087 32.1804 11.7529 33.1892C11.825 33.4775 11.6808 33.8376 11.4646 33.9817C10.9597 34.4142 10.5275 34.5584 10.0951 34.5584C7.71716 34.5584 5.05123 29.2262 5.05123 21.5881V20.1468C5.19538 17.7689 4.54669 15.5351 3.32201 13.5896C2.3853 12.1483 2.02491 10.4911 2.16905 8.76162C2.45736 5.01452 5.48373 1.84398 9.23052 1.4839C10.5997 1.33975 11.8968 1.55598 13.1214 2.06022C15.2832 2.99693 17.7332 2.99693 19.8949 2.06022C21.1199 1.55567 22.417 1.33975 23.7858 1.4839C27.5329 1.84429 30.5592 5.01475 30.8473 8.76162C31.0638 10.4191 30.7034 12.0763 29.7667 13.5175L29.7667 13.5175Z" 
            fill={color}/>
    </IconSvg>
);

export default ToothIcon;