import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const Gift = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.593765 16.7721H1.06839V35.4008C1.06839 35.7602 1.30549 36 1.66177 36H26.3394C26.6953 36 26.9328 35.7606 26.9328 35.4008L26.932 16.7721H27.4066C27.7625 16.7721 28 16.5327 28 16.1729V10.4826C28 10.1232 27.7629 9.8834 27.4066 9.8834L0.593382 9.88382C0.237518 9.88382 0 10.1232 0 10.483V16.1733C0 16.5327 0.237104 16.7721 0.593382 16.7721L0.593765 16.7721ZM2.25476 16.7721H13.4072V34.8018H2.25476V16.7721ZM25.746 34.8018H14.5936V16.7721H25.746V34.8018ZM26.814 15.5741H14.5938V11.0816H26.814V15.5741ZM1.18719 11.0816H13.4074V15.5741H1.18719V11.0816ZM11.865 9.04515C12.9922 9.04515 13.8818 8.86548 13.9415 8.86548H14.0007H14.0598C14.119 8.86548 15.0091 9.04515 16.1363 9.04515C17.6193 9.04515 19.6361 8.74557 21.06 7.24806C21.8313 6.46923 22.3059 5.39128 22.3059 4.25308C22.3059 3.11488 21.8905 2.0369 21.06 1.2581C20.2295 0.479302 19.2211 0 18.0939 0C16.9667 0 15.8991 0.419499 15.1278 1.2581C14.594 1.7971 14.2382 2.3963 13.9415 3.0552C13.7044 2.39628 13.2889 1.79709 12.7552 1.2581C11.9839 0.479281 10.9163 0 9.78912 0C8.66191 0 7.59434 0.419499 6.82305 1.2581C6.05175 2.03693 5.57709 3.11488 5.57709 4.25308C5.57709 5.39128 5.99254 6.46925 6.82305 7.24806C8.3653 8.74554 10.3821 9.04515 11.8652 9.04515H11.865ZM16.0174 2.15692C16.6108 1.55775 17.3225 1.2586 18.153 1.2586C18.9835 1.2586 19.6952 1.55818 20.2885 2.15692C20.8819 2.75608 21.1782 3.47471 21.1782 4.3133C21.1782 5.15187 20.8815 5.87058 20.2885 6.46968C18.6276 8.14687 15.7802 7.90738 14.7719 7.78747C14.5344 6.70907 14.2973 3.834 16.0174 2.15681V2.15692ZM7.77175 2.15692C8.36513 1.55775 9.07682 1.2586 9.90732 1.2586C10.7378 1.2586 11.4496 1.55818 12.0429 2.15692C13.7039 3.8341 13.4667 6.70917 13.348 7.72736C12.3395 7.90703 9.49228 8.08669 7.83128 6.40957C7.2379 5.8104 6.94163 5.09178 6.94163 4.25319C6.94122 3.41462 7.17874 2.69591 7.7717 2.15692H7.77175Z"
            fill={color}
        />
    </IconSvg>

);

export default Gift;
