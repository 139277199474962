import React from "react";
import CrossIcon from "./cross-icon";
import CheckIcon from "./check-icon";


const ComparisonIcons = (iconName: string): JSX.Element | null => {
    switch (iconName) {
        case "cross": return <CrossIcon />;
        case "check": return <CheckIcon />;
        default: return null;
    }
}

export default ComparisonIcons;