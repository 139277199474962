import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react';
import { Link } from 'gatsby';
import { Provider } from 'react-redux';
// @ts-ignore
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../../app/store';
import linkResolver from '../../utils/prismic/link-resolver'
import componentResolver from '../../utils/prismic/component-resolver'
import GlobalStyle from './global-style';

export const WrapPageBrowser = ({ element }: any) => (
    <Provider store={store}>
        <GlobalStyle />
        <PrismicProvider
            linkResolver={linkResolver}
            internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}
        >
            <PrismicPreviewProvider
                repositoryConfigs={[
                    {
                        repositoryName: 'playbrush',
                        linkResolver: linkResolver,
                        componentResolver: componentResolver,
                    },
                ]}
            >
                <PersistGate loading={null} persistor={persistor}>
                    {element}
                </PersistGate>
            </PrismicPreviewProvider>
        </PrismicProvider>
    </Provider>
)