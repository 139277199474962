import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg``

const LinkShare = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.6963 29.4212C19.2942 28.8244 20.2628 28.826 20.8591 29.4239C21.4559 30.0218 21.4543 30.9898 20.8564 31.5861C18.6511 33.7862 17.4318 35.0033 17.2009 35.2343L15.0104 37.4248C11.5767 40.8585 6.00893 40.8585 2.57526 37.4248C-0.858419 33.9906 -0.858419 28.4233 2.57526 24.9891L11.2257 16.3387C14.6599 12.905 20.2271 12.905 23.6613 16.3387C24.2582 16.936 24.2582 17.9041 23.6613 18.5015C23.064 19.0983 22.0959 19.0983 21.4986 18.5015C19.2591 16.262 15.628 16.262 13.3884 18.5015L4.73803 27.1519C2.49852 29.3914 2.49852 33.0225 4.73803 35.262C6.97754 37.5015 10.6087 37.5015 12.8482 35.262L15.0381 33.0715C15.2706 32.8395 16.4894 31.6235 18.6964 29.4213L18.6963 29.4212Z"
            fill={color}
        />
        <path
            d="M21.3026 10.5788C20.7047 11.1751 19.7367 11.1741 19.1399 10.5762C18.5436 9.97827 18.5446 9.01019 19.1425 8.41338C21.3479 6.21333 22.5677 4.99677 22.7986 4.7652L24.9886 2.57526C28.4228 -0.858419 33.99 -0.858419 37.4242 2.57526C40.8579 6.00893 40.8579 11.5767 37.4242 15.0104L28.7733 23.6614C25.3396 27.095 19.7718 27.095 16.3382 23.6614C15.7408 23.064 15.7408 22.0959 16.3382 21.4986C16.9355 20.9012 17.9036 20.9012 18.5009 21.4986C20.7404 23.7381 24.371 23.7381 26.6107 21.4986L35.2616 12.8482C37.5011 10.6087 37.5011 6.97754 35.2616 4.73803C33.0221 2.49852 29.391 2.49852 27.1515 4.73803L24.9615 6.92798C24.729 7.16052 23.5103 8.37652 21.3027 10.5788L21.3026 10.5788Z"
            fill={color}
        />
    </IconSvg>
);

export default LinkShare;
