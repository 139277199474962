import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import Button from "../../../snippets/button";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { concatMenuUrl } from "./utils";
import { getFormattedProductQueryString } from "@utils";

const breakPoint = 1140;

const PullDownWrapper = styled.li`
    padding: 0 32px 12px;
    width: 100%;

	@media screen and (max-width: ${breakPoint}px) {
		display: none;
	}
`

const PullDownContent = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    padding: 32px 0;
`

const Divider = styled.div`
	opacity: .1;
    border-bottom: 1px solid #2BB573; 
`

const Column = styled.div`
	flex-grow: 1;
	max-width: 300px;

	&:first-child {
		flex: 0 0 25%;
		padding: 0 50px 0 0;
	}

    * {
        text-decoration: none;
    }

    &:hover {
        h5 {
            color: var(--light-green)
        }
    }
`

const Heading = styled.div`
	margin: 0 0 16px 0;
`

const Description = styled.div`
	margin: 0 0 24px 0;
`

const ItemHeading = styled.div`
	margin: 0 0 4px 0;
`

const ItemDescription = styled.div`
	margin: 0 0 8px 0;
`

const Image = styled(GatsbyImage)`
    width: 200px;
    max-height: 200px;
    margin: 0 0 8px 0;
`

interface MegaMenuProps {
    menu: Queries.PrismicHeaderMenuItem['data'];
    page: string;
}

const MegaMenu = ({ menu, page }: MegaMenuProps) => {
    const { title, description, button_url, button_text, link_to_section, button_bundle_preselect_index, button_bundle_type_preselect } = menu || {}

    const buttonLink = 
            (button_url?.document as Queries.PrismicProductPage)?.data && button_url?.url
        ? `${
            button_url.url
            }${
            getFormattedProductQueryString(
                0,
                'mega-menu',
                page,
                button_bundle_preselect_index as number,
                button_bundle_type_preselect as string
            )
            }`
        : button_url?.url

    const buttonUrl = concatMenuUrl(buttonLink, link_to_section);

    return (
        <PullDownWrapper>
            <Divider />
            <PullDownContent>
                <Column>
                    {title?.richText && (
                        <Heading>{RichText.render(title.richText)}</Heading>
                    )}
                    {description?.richText && (
                        <Description>{RichText.render(description.richText)}</Description>
                    )}
                    {buttonUrl && button_text && (
                        <Button buttonStyle={"outline"}
                            url={buttonUrl}
                        >
                            {button_text}
                        </Button>
                    )}
                </Column>
                {menu?.item?.map((item, index) => {
                    const { item_title, item_description, mega_menu_image, link, link_to_section, bundle_preselect_index, bundle_type_preselect } = item || {};

                    const menuLink = 
                        (link?.document as Queries.PrismicProductPage)?.data && link?.url ?
                        `${
                            link.url
                        }${
                            getFormattedProductQueryString(
                                index + 1,
                                'mega-menu',
                                page,
                                bundle_preselect_index as number,
                                bundle_type_preselect as string
                            )
                        }` : link?.url

                    const url = concatMenuUrl(menuLink, link_to_section);

                    const LinkElement = !!link_to_section ? Link : AnchorLink

                    return (
                        <Column key={`${index}-megamenu-column`}>
                            <LinkElement to={url || '#'}>
                                {mega_menu_image?.gatsbyImageData && (
                                    <Image
                                        image={(mega_menu_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={mega_menu_image.alt || ''}
                                        objectFit={"cover"}
                                    />
                                )}
                                {item_title?.richText && (
                                    <ItemHeading>{RichText.render(item_title.richText)}</ItemHeading>
                                )}
                                {item_description?.richText && (
                                    <ItemDescription>{RichText.render(item_description.richText)}</ItemDescription>
                                )}
                            </LinkElement>
                        </Column>
                    )
                }
                )}
            </PullDownContent>
        </PullDownWrapper>
    )
};

export default MegaMenu;