import { LinkResolver } from 'prismic-reactjs';
import { getAudienceTag } from './get-audience-tag';

export interface PrismicDocument {
    id: string;
    uid: string;
    type: string;
    tags: string[];
    lang: string;
    isBroken: boolean;
}

const linkResolver: LinkResolver = (doc: PrismicDocument): string => {

    // URL for a category type
    if (doc.type === 'homepage') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}` : `/${doc.lang}`;
    }

    if (doc.type === 'text_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'product_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'product_overview_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'how_it_works_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'magazine_overview') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'magazine_blog_page') {
        return `/${doc.lang}/magazine/${doc.uid}`;
    }

    if (doc.type === 'help_centre_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'contact_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'about_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'press_and_media_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'careers_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'dentist_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'referrals_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'im_handel_page') {
        return `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'thank_you_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'landing_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    if (doc.type === 'ambassador_page') {
        const audience = getAudienceTag(doc.tags);
        return audience ? `/${doc.lang}/${audience}/${doc.uid}` : `/${doc.lang}/${doc.uid}`;
    }

    // Backup for all other types
    const audience = getAudienceTag(doc.tags);
    if (doc.lang && audience && doc.uid) {
        return `/${doc.lang}/${audience}/${doc.uid}`;
    } else if (doc.lang && audience) {
        return `/${doc.lang}/${audience}`;
    } else if (doc.lang) {
        return `/${doc.lang}`;
    } else {
        return '/';
    }
}

export default linkResolver;