import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const GraphIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.6654 22.6663H1.33203V1.33301"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.33203 18L11.9987 8L15.9987 12L21.9987 6"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconSvg>
);

export default GraphIcon;