import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";

import { SliceProps } from "../../types";

const Section = styled.section`
    gap: 80px;
    margin: 80px 0 0;
    padding: 40px 0 80px;
    background: #F8F8F8;
	
	@media screen and (min-width: 768px) {
        // margin: 120px -80px 0;
        margin: 120px 0;
		padding: 60px 0;
	}
`

const InnerWrapper = styled.div`
    display: flex;
    @media screen and (min-width: 768px) {
        gap: 124px;
    }
`

const ContentWrapper = styled.div`
`

const Heading = styled.div`
    margin: 0 0 40px;
`

const Description = styled.div`
    margin: 0 0 35px;
    p {
        font-size: 24px;
        line-height: 36px; 
    }
`

const ImageWrapper = styled.div`
    display: none;
    
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        max-width: 50%;
    }
`

const Image = styled(GatsbyImage)`
    width: 100%;
`

const MobileImageWrapper = styled.div`
    margin: 0 0 60px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
        display: none;
    }
`

const MobileImage = styled(GatsbyImage)`
`

const BrushFeatures = (props: SliceProps<Queries.PrismicProductPageDataBodyBrushFeatures>) => {
    const {
        context: {
            audience
        },
        slice: {
            primary: {
                heading,
                description,
                button_text,
                button_url,
                image,
                mobile_image
            },
        }
    } = props;

    return (
        <Section id="brush-features">
            <Wrapper>
                <InnerWrapper>
                    <ContentWrapper>
                        <Heading>
                            {heading?.richText && RichText.render(heading.richText)}
                        </Heading>
                        <MobileImageWrapper>
                            {mobile_image?.gatsbyImageData && (
                                <MobileImage
                                    image={(mobile_image.gatsbyImageData as unknown) as IGatsbyImageData}
                                    alt={mobile_image.alt || ''}
                                />
                            )}
                        </MobileImageWrapper>
                        <Description>
                            {description?.richText && RichText.render(description.richText)}
                        </Description>
                        {button_text && button_url &&
                            <Button
                                url={button_url?.url || '' as string}
                                buttonStyle={"outline"}
                            >
                                {button_text?.richText && RichText.render(button_text.richText)}
                            </Button>
                        }
                    </ContentWrapper>
                    <ImageWrapper>
                        {image?.gatsbyImageData && (
                            <Image
                                image={(image.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image.alt || ''}
                            />
                        )}
                    </ImageWrapper>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default BrushFeatures