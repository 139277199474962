import { Settings } from "../../components/slices/types";
import { Cart } from "../playbrush-client/types";

export const getThankYouPage = (lineItems: Cart['lineItems'], settings: Settings, audience?: string) => {
    const hasKidsInCart = !!lineItems.find((lineItem) => lineItem.tags.includes('kids'));
    const shouldBeAdult = !hasKidsInCart && ( !audience || audience === 'adults') && settings?.link_to_thank_you_adults?.url;

    if (shouldBeAdult) {
        return (window.location?.origin as string || '') + settings?.link_to_thank_you_adults?.url || '' + '/';
    } else {
        return (location?.origin as string || '') + settings?.link_to_thank_you?.url || '' + '/';
    }
}
