export const arrowRightIconSmallInline = (color: any): string => {
    const encodedColor: string = color.replace('#', '%23');
    return (`"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.34326L17.6569 12.0001L12.0498 17.6072' 
    stroke='${
            encodedColor
        }' strokeWidth='2' strokeLinecap='round'/%3E%3Cpath d='M16.758 12H6.34375' 
    stroke='${
            encodedColor
        }' strokeWidth='2' strokeLinecap='round'/%3E%3C/svg%3E%0A"
    `)
};