import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const CrossIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.75857 0.343146C1.36804 -0.0473785 0.734879 -0.0473788 0.344354 0.343146C-0.0461701 0.73367 -0.0461702 1.36684 0.344354 1.75736L4.58699 6L0.344354 10.2426C-0.0461702 10.6332 -0.0461701 11.2663 0.344354 11.6569C0.734879 12.0474 1.36804 12.0474 1.75857 11.6569L6.00121 7.41421L10.2438 11.6569C10.6344 12.0474 11.2675 12.0474 11.6581 11.6569C12.0486 11.2663 12.0486 10.6332 11.6581 10.2426L7.41542 6L11.6581 1.75736C12.0486 1.36683 12.0486 0.73367 11.6581 0.343146C11.2675 -0.0473784 10.6344 -0.0473788 10.2438 0.343146L6.00121 4.58579L1.75857 0.343146Z"
            fill={color} />
    </IconSvg>
);

export default CrossIcon;
