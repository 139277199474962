import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const GlobeIcon = ({ color = '#8193A8', size = '38' }: { color?: string, size?: string | number }) => (
    <IconSvg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 38 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19 37.0867C28.9411 37.0867 37 29.0278 37 19.0867C37 9.14554 28.9411 1.08667 19 1.08667C9.05887 1.08667 1 9.14554 1 19.0867C1 29.0278 9.05887 37.0867 19 37.0867Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 19.0867H37" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.0008 1.08667C23.5031 6.0157 26.0617 12.4123 26.2008 19.0867C26.0617 25.761 23.5031 32.1576 19.0008 37.0867C14.4985 32.1576 11.9398 25.761 11.8008 19.0867C11.9398 12.4123 14.4985 6.0157 19.0008 1.08667Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
);

export default GlobeIcon;
