import { RichText } from "prismic-reactjs";
import React, { useState } from "react"
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
// import { hasRichText, renderRich } from "@utils";
import linkResolver from '../../../../utils/prismic/link-resolver';

import { SliceProps } from "../../types";

const Section = styled.section`
    position: relative;
    gap: 80px;
    margin: 0 0 32px;
    padding: 110px 0 0;
	
	@media screen and (min-width: 768px) {
        padding: 130px 80px 120px;
        margin: 0;
	}
`

const InnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;
    gap: 16px;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        padding: 0;
    }
`

const ContentWrapper = styled.div`
    flex: 1 1 45%;
`

const MediaWrapper = styled.div`
    flex: 1 1 45%;
`

const Heading = styled.div`
    margin: 0 0 40px;
`

const Description = styled.div`
    margin: 0 0 35px;
    p {
        font-size: 24px;
        line-height: 36px; 
    }
`

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    
    @media screen and (min-width: 768px) {
        // flex-wrap: nowrap;
        gap: 16px;
    }

    button {
        border-color: var(--light-green);
        color: var(--light-green);

        p {
            color: var(--light-green);
            font-weight: 600;
        }
    }
`

const ButtonWrapper = styled.div<{ selected: boolean }>`
    button {
        border 1px solid var(--light-green);

        p {
            white-space: nowrap;
        }

        ${({ selected }) => selected ? `
            background: var(--light-green);
            p {
                color: #ffffff;
            }
        ` : `
            background: transparent;
            color: var(--light-green);
        `}
    }
`


const TableWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: baseline;
    
    @media screen and (min-width: 768px) {
        justify-content: flex-end;
    }
`

const Table = styled.table`
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
    overflow: scroll;

    // @media screen and (min-width: 768px) {
    //     width: 75%;
    // }
`

const FirstTableBody = styled.tbody`
    * {
        font-weight: 700;
    }

    tr {
        th {
            opacity: 0;
        }

        td {
            padding: 24px 8px 24px 0px;
    
            @media screen and (min-width: 768px) {
                padding: 24px 16px 24px ;
            }
        }

        &:last-child {
            * {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
        background: white;
        
        position: absolute;
        top: 0;
        left: 0;
    }
`

const TableBodyWrapper = styled.div`
    overflow: scroll;
`

const TableBody = styled.tbody`
    // tr {
    //     th:first-child,
    //     td:first-child {
    //         padding: 24px 8px 24px 0px;
    //         opacity: 0;
            
    //         @media screen and (min-width: 768px) {
    //             padding: 24px 16px 24px ;
    //             // display: none;
    //         }
    //     }
    // }
`

const TableHead = styled.th`
    padding: 28px 0;
    
    p {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
    }
`

const TableRow = styled.tr`
    border-bottom: 1px solid #EAF0F6;

    &:last-child {
        border: none;
    }
`


const TableData = styled.td`
    padding: 16px 8px 16px 8px;

    @media screen and (min-width: 768px) {
        padding: 24px 64px 24px 0;
    }
`

const ColumnContent = styled.div`
    width: 175px;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
        width: auto;
        padding: 0px;
    }
`

const ImageWrapper = styled.div`
    display: none;
    
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
`

const Image = styled(GatsbyImage)`
    width: 100%;
`

const MobileImageWrapper = styled.div`
    margin: 20px 0 60px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
        display: none;
    }
`

const MobileImage = styled(GatsbyImage)`
`

const CompareBrushFeatures = (props: SliceProps<Queries.PrismicHowItWorksPageDataBodyBrushFeatures>) => {
    const { context, slice } = props;
    const { primary, items } = slice;
    const { audience, translations } = context;

    const columnData: Queries.PrismicHowItWorksPageDataBodyBrushFeaturesItem[] = items.map((data) => data);
    const [selectedColumn, setSelectedColumn] = useState(columnData.find((data) => data?.product_name?.richText.length > 0));

    const table_content: Queries.PrismicCompareBrushFeaturesTableItemDataType[] = items.map(({ table_content }) => {
        const { document } = table_content || {};
        const { data } = document || {};
        return data as Queries.PrismicCompareBrushFeaturesTableItemDataType;
    });

    const columns: Queries.PrismicCompareBrushFeaturesTableItemDataRow[] = table_content.map((column) => (column?.row as unknown) as Queries.PrismicCompareBrushFeaturesTableItemDataRow);
    const rows: Queries.PrismicCompareBrushFeaturesTableItemDataRow[][] = [];

    table_content.forEach((column) => {
        (column?.row || []).forEach((row, index: number) => {
            if (!rows[index]) rows[index] = [];

            rows[index].push(row as Queries.PrismicCompareBrushFeaturesTableItemDataRow);
        })
    })

    const handleSwitch = (data: Queries.PrismicHowItWorksPageDataBodyBrushFeaturesItem | void) => {
        const hasProduct = !!data?.product_url?.url;
        const compareGridColumn = columnData.find((column) => {
            return !column?.product_url?.url
        });

        if (hasProduct && data) {
            setSelectedColumn(data);
        } else {
            setSelectedColumn(
                compareGridColumn
            );
        }
    }

    return (
        <Section id="compare-brush-features">
            <InnerWrapper>
                <ContentWrapper>
                    <Heading>
                        {primary.heading?.richText && RichText.render(primary.heading.richText)}
                    </Heading>
                    <Description>
                        {primary.description?.richText && RichText.render(primary.description.richText)}
                    </Description>
                    <Buttons>
                        {columnData.map((data, i) => data?.product_name?.richText.length > 0 ? (
                            <ButtonWrapper selected={data.product_url?.url === selectedColumn?.product_url?.url} key={`${i}-button`}>
                                <Button
                                    type={'button'}
                                    key={`comparison-header__${i}`}
                                    onClick={() => handleSwitch(data)}
                                >
                                    {RichText.render(data?.product_name?.richText)}
                                </Button>
                            </ButtonWrapper>
                        ) : null)}
                        <ButtonWrapper selected={!selectedColumn?.product_url?.url}>
                            <Button
                                onClick={() => handleSwitch()}
                                buttonStyle={"outline"}
                            >
                                <p>{translations?.compare}</p>
                            </Button>
                        </ButtonWrapper>
                    </Buttons>
                </ContentWrapper>
                <MediaWrapper>
                    {(selectedColumn?.image?.gatsbyImageData || selectedColumn?.mobile_image?.gatsbyImageData) && (
                        <>
                            <ImageWrapper>
                                {selectedColumn.image?.gatsbyImageData && (
                                    <Image
                                        image={(selectedColumn.image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={selectedColumn.image?.alt || ''}
                                    />
                                )}
                            </ImageWrapper>
                            <MobileImageWrapper>
                                {selectedColumn.mobile_image?.gatsbyImageData && (
                                    <Image
                                        image={(selectedColumn.mobile_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={selectedColumn.mobile_image?.alt || ''}
                                    />
                                )}
                            </MobileImageWrapper>
                        </>
                    )}
                </MediaWrapper>
            </InnerWrapper>
            {!selectedColumn?.product_url?.url && (
                <TableWrapper>
                    {/* <Table>
                        <FirstTableBody>
                            <TableRow>
                                <TableHead key={`comparison-header__main`}>
                                    <ColumnContent><p aria-hidden="true">placeholder</p></ColumnContent>
                                </TableHead>
                            </TableRow>
                            {rows.map((row, i) => (
                                <TableRow key={`row-${i}-${row.length}`}>
                                    {row.map((column, idx) => (
                                        idx === 0
                                            ? (
                                                <TableData key={`column-${i}-${idx}-${row.length}-main`}>
                                                    <ColumnContent>
                                                        {RichText.render(column?.content?.richText)}
                                                    </ColumnContent>
                                                </TableData>
                                            ) : null
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                {columnData.map((data, idx) => (
                                    idx === 0 ? (
                                        <TableData key={`comparison-footer__${idx}`}>
                                            <Button url={'url'} buttonStyle={'outline'}>
                                                <p>{translations?.view_product}</p>
                                            </Button>
                                        </TableData>
                                    ) : null
                                ))}
                            </TableRow>
                        </FirstTableBody>
                    </Table> */}
                    <TableBodyWrapper>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    {columnData.map((data, idx) => (
                                        <TableHead key={`comparison-header__${idx}`}>
                                            <ColumnContent>
                                                {RichText.render(data?.product_name?.richText)}
                                            </ColumnContent>
                                        </TableHead>
                                    ))}
                                </TableRow>
                                {rows.map((row, i) => (
                                    <TableRow key={`row-${i}-${row.length}`}>
                                        {row.map((column, idx) => (
                                            <TableData key={`column-${i}-${idx}-${row.length}`}>
                                                <ColumnContent>
                                                    {/* {RichText.render(column?.content?.richText)} */}
                                                    <RichText render={column?.content?.richText} linkResolver={linkResolver} />
                                                </ColumnContent>
                                            </TableData>
                                        ))}
                                    </TableRow>
                                ))}
                                <TableRow>
                                    {columnData.map((data, idx) => (
                                        <TableData key={`comparison-footer__${idx}`}>
                                            {data.product_url?.url && (
                                                <Button url={data.product_url?.url || ''} buttonStyle={'outline'}>
                                                    {translations?.view_product}
                                                </Button>
                                            )}
                                        </TableData>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableBodyWrapper>
                </TableWrapper>
            )}
        </Section>
    )
}

export default CompareBrushFeatures