import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";

const Section = styled.section`
    @media screen and (min-width: 768px) {
        margin: 40px auto 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 124px;
    }
`

const ContentContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex: 1 1 50%;
`

const Text = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;
`

const ImageContainer = styled.div`
    flex: 0 0 100%;

    @media screen and (min-width: 768px) {
        flex: 0 1 50%;
    }
`

const Image = styled(GatsbyImage)`
    width: auto;

    @media screen and (min-width: 768px) {
        max-width: 524px;
        max-height: 393px;
    }
`


const TextImageRightFull = (props: SliceProps<Queries.PrismicPressAndMediaPageDataBodyTextImageRightFullWidth>) => {
    const { context: { audience }, slice: { primary } } = props;
    const {
        heading,
        description,
        image
    } = primary;

    return (
        <Section id="text-image-right">
            <Wrapper>
                <InnerWrapper>
                    <ContentContainer>
                        {heading?.richText && (
                            <Text className="rt">{RichText.render(heading.richText)}</Text>
                        )}
                        {description?.richText && (
                            <Text>{RichText.render(description.richText)}</Text>
                        )}
                    </ContentContainer>
                    <ImageContainer>
                        {image?.gatsbyImageData && (
                            <Image
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image.alt || ''}
                            />
                        )}
                    </ImageContainer>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default TextImageRightFull