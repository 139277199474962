import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const EnveloppeIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.25 0.5H2.75C2.02065 0.5 1.32118 0.789731 0.805456 1.30546C0.289731 1.82118 0 2.52065 0 3.25V30.75C0 31.4793 0.289731 32.1788 0.805456 32.6945C1.32118 33.2103 2.02065 33.5 2.75 33.5H41.25C41.9793 33.5 42.6788 33.2103 43.1945 32.6945C43.7103 32.1788 44 31.4793 44 30.75V3.25C44 2.52065 43.7103 1.82118 43.1945 1.30546C42.6788 0.789731 41.9793 0.5 41.25 0.5ZM39.1325 30.75H5.0325L14.6575 20.795L12.6775 18.8838L2.75 29.155V5.34L19.8413 22.3487C20.3565 22.8609 21.0535 23.1484 21.78 23.1484C22.5065 23.1484 23.2035 22.8609 23.7188 22.3487L41.25 4.91375V28.9762L31.13 18.8563L29.1912 20.795L39.1325 30.75ZM4.55125 3.25H39.0225L21.78 20.3962L4.55125 3.25Z"
            fill={color} />
    </IconSvg>
);

export default EnveloppeIcon;

