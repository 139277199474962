import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import { hasRichText, renderRich } from "@utils";
import Button from "../../../snippets/button";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setBrush } from "../../../../app/slices/product-reducer";

const Section = styled.section<{ backgroundColor: string; }>`
    ${({ backgroundColor }) => `
        padding: 40px 16px;
        background: ${backgroundColor ? backgroundColor : 'white'};
        margin: 80px 0 0;

        @media screen and (min-width: 768px) {
            margin: 120px auto 0;
            padding: 80px;
        }
    `}
`

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    max-width: 1440px;

	@media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
	}
`

const Heading = styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 0;
    
    @media screen and (min-width: 768px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 0;
    }

    * {
        // word-break: normal;
    }
`

const BackToTop = styled.div`
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin: 20px 0;
    text-decoration: underline;
    color: #4E6684;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    
    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        align-self: end;
        cursor: pointer;
        text-align: right;
        margin: 0 0 15px 0;
    }
`

const Products = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: nowrap;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 40px 0 0;
    overflow-x: scroll;

    @media screen and (min-width: 768px) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        margin: 0;
    }
`

const Product = styled.article`
    flex: 1 0 50%;
    padding: 20px;
    // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    // border-radius: 24px;
    margin-bottom: 48px;
    // background: var(--bg-light-grey-two);
    
    @media screen and (min-width: 768px) {
        flex: 0 1 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0;
        padding: 40px;
        box-shadow: none;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    
    button {
        margin-top: auto;

        @media screen and (min-width: 768px) {
            align-self: start;
        }
    }
`

const Image = styled(GatsbyImage)`
    min-width: 200px;
    margin: 0 0 24px 0;

    @media screen and (min-width: 768px) {
        margin: 0 24px 0 0;
    }
`

const Title = styled.div`
    margin: 0 0 16px 0;
    h1 {
        font-size: 32px !important;
    }
`

const Description = styled.div`
    margin: 0 0 24px 0;
    
    @media screen and (min-width: 768px) {
        margin-bottom: 24px;
    }
    
    p {
        font-size: 14px;
        line-height: 21px;
        margin: 0 0 20px 0;
    }

    li {
        font-size: 14px;
        line-height: 21px;
    }
`

const FeaturedBrushes = (props: SliceProps<Queries.PrismicProductPageDataBodyFeaturedBrushes>) => {
    const [product, cart] = useAppSelector(state => [state.product, state.cart]);
    const dispatch = useAppDispatch();
    const {
        context: {
            audience,
            translations,
        },
        slice: {
            primary: {
                background_color,
                heading,
            },
            items
        }
    } = props;

    const brushTypes = product?.variants?.reduce<string[]>((brushTypes, variant) => {
        if (brushTypes.includes(variant?.brush_type || '')) return brushTypes;
        return [...brushTypes, variant?.brush_type || ''];
    }, []);

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    const selectNewBrush = (brush?: string) => {
        if (brush && product.selectedBrush !== brush) dispatch(setBrush(brush));
        // window[`scrollTo`]({ top: 0, behavior: `smooth` });
    }

    return (
        <Section id="compare-brushes" backgroundColor={background_color as string}>
            <InnerWrapper>
                <Heading>
                    {heading && RichText.render(heading.richText)}
                </Heading>
                <BackToTop onClick={handleClick}>{translations?.back_to_top}</BackToTop>
                <Products>
                    {items.map((
                        {
                            product_title,
                            product_image,
                            description,
                            link,
                            button_text
                        },
                        index
                    ) => {
                        const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);
                        const currentBrushType = (brushTypes || [])[index];

                        return (
                            <Product key={`${index}-featured-product`}>
                                {product_image && product_image.gatsbyImageData && (
                                    <Image
                                        image={(product_image.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={product_image.alt || ''}
                                    />
                                )}
                                <Content>
                                    {product_title && product_title.richText && (
                                        <Title>
                                            {product_title.richText && RichText.render(product_title.richText)}
                                        </Title>
                                    )}
                                    {description && description.richText && (
                                        <Description>
                                            {description.richText && RichText.render(description.richText)}
                                        </Description>
                                    )}
                                    {currentBrushType && (
                                        <Button
                                            buttonStyle={product.selectedBrush === currentBrushType ? 'full' : 'outline'}
                                            onClick={() => selectNewBrush(currentBrushType)}
                                        >
                                            {translations?.select_brush}
                                        </Button>
                                    )}
                                    {/* {link && link?.url && buttonTextHasRichText &&
                                        <Button
                                            url={link?.url || ''}
                                            buttonStyle={'outline'}
                                            buttonFullWidth={'full'}
                                        >
                                            {renderRich(buttonTextRichText)}
                                        </Button>
                                    } */}
                                </Content>
                            </Product>
                        )
                    })}
                </Products>
            </InnerWrapper>
        </Section>
    )
}

export default FeaturedBrushes