import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const CircularArrow = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.92 1.34954C10.92 1.8733 11.345 2.29823 11.8687 2.29823C19.7164 2.29823 26.1018 8.68355 26.1018 16.5313C26.1018 24.379 19.7164 30.7643 11.8687 30.7643L3.9545 30.7643L8.41599 26.3341C8.78841 25.9652 8.78973 25.3638 8.42084 24.9922C8.05151 24.6202 7.45097 24.6176 7.07898 24.9874L1.00599 31.0166C0.818909 31.202 0.725798 31.446 0.725798 31.6895C0.725798 31.9313 0.81802 32.1727 1.00158 32.358L7.02949 38.4319C7.39838 38.8039 7.99936 38.8066 8.37135 38.4368C8.74377 38.0679 8.74641 37.4678 8.3762 37.0949L3.97605 32.6612L11.9018 32.6621C11.9058 32.6621 11.9093 32.6608 11.9133 32.6608C20.7874 32.6374 28 25.411 28 16.5312C28 7.63641 20.7639 0.40039 11.8692 0.40039C11.3455 0.40039 10.92 0.825776 10.92 1.34954Z"
            fill={color} />
    </IconSvg>
);

export default CircularArrow;
