import { CleanVariant } from "../../utils/product/get-clean-variants";
import { IState } from "./product-reducer";

export const setVariantsAction = (state: any, payload: Queries.PrismicProductVariantDataType[]) => {
    state.variants = payload;
    return state;
}

const switchVariant = (state: any, color: string, brush: string, size: string) => {
    const bundleIndex = state.selectedVariant?.bundles?.findIndex(
        (item: any) => item.bundle?._id === state.selectedBundle
    );

    const selectedVariant =
    state.variants.find(
        (variant: CleanVariant) => (variant.color === color && variant.brush_type === brush && variant.size === size)
    ) ||
    state.variants.find(
        (variant: CleanVariant) => (variant.color === color && variant.brush_type === brush)
    ) ||
    state.variants.find(
        (variant: CleanVariant) => (variant.color === color)
    ) ||
    state.variants.find(
        (variant: CleanVariant) => (variant.size === size)
    );

    state.selectedVariant = selectedVariant;
    state.selectedColor = selectedVariant?.color;
    state.selectedBrush = selectedVariant?.brush_type;
    state.selectedSize = selectedVariant?.size;

    const firstBundle = state.selectedVariant?.bundles[bundleIndex] || state.selectedVariant?.bundles[0];
    state.selectedBundle = firstBundle?.bundle?._id;

    return state;
}

export const setColorAction = (state: any, payload: string) =>
    switchVariant(state, payload, state.selectedBrush, state.selectedSize);

export const setBrushAction = (state: any, payload: string) =>
    switchVariant(state, state.selectedColor, payload, state.selectedSize);

export const setSizeAction = (state: any, payload: string) =>
    switchVariant(state, state.selectedColor, payload, state.selectedSize);

export const setBundleAction = (state: any, payload: string) => {
    state.selectedBundle = payload;
    return state;
}

export const setInitialStateAction = (state: any, payload: IState) => {
    return state;
}
