import { RichText } from "prismic-reactjs";
import React, { useState } from "react"
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import Button from "../../../snippets/button";

import getIcon from "../../../icons/icon-renderer";
import PlayIcon from "../../../icons/play-icon";
import PauseIcon from "../../../icons/pause-icon";

import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";

import { hasRichText, renderRich } from "@utils";

const Section = styled.section`
    position: relative;
    margin: 80px auto 0;
    
    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
    }
`

const Heading = styled.div`
    margin: 0 0 16px 0;
    max-width: 850px;

    @media screen and (min-width: 768px) {
        margin: 0 0 32px 0;
    }
`

const Description = styled.div`
    display:block;
    margin: 0 0 40px 0;
    max-width: 850px;
`

const ContentWrapper = styled.div<{ audience: string }>`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    
    @media screen and (min-width: 768px) {
        margin: 40px 0 0 0;
        grid-template-columns: 45vw auto;
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr auto;
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: 45vw auto;
    }    

`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 0 64px 0;
    gap: 16px;
    order: 1;
    overflow-x: scroll;
    
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
	    grid-template-rows: repeat(2, 1fr);
        overflow-x: clip;
    }
`

const Item = styled.div<{ isSelected: boolean }>`
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 28px;
    overflow: hidden;
    width: 240px;

    position: relative;
    
    transition: border-radius .3s ease-out;

    &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        z-index: -2;
        background-color: var(--light-green);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
        transition: all .5s ease-out;
        transition-delay: .1s;
        background-color: var(--bg-light-grey);
    }

    @media screen and (min-width: 768px) {
        width: auto;
    }
    &:hover {
        cursor: pointer;
    }

    * {
        transition: color .15s ease-out;
    }

    ${({ isSelected }) => isSelected ? `
        border-radius: 16px;
        
        &::after {
            border-radius: 100%;
            inset: 100%;
        }

        * {
            color: white;
        }
    ` : ``}
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 28px 0;
`

const Playpause = styled.div<{ isSelected: boolean }>`
    ${({ isSelected }) => isSelected ? `
        display: flex;
        ` : `display: none;`}
`

const Title = styled.div`
    width: 100%;
    margin: 0 0 16px 0;
`

const ItemDescription = styled.div`
    width: 100%;
`

const MediaHolder = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    order: 0;
    margin: 0 auto 24px;
    
    @media screen and (min-width: 768px) {
        order: 2;
        padding: 0;
        margin: 0 0 64px 0;
        flex: 1 0 auto;
    }
`

const ImageWrapper = styled.div`
    .gatsby-image-wrapper {
        top: 0;
    }
`
    
 const Image = styled(GatsbyImage)`
    position: sticky;
    top: 128px;
    
    @media screen and (min-width: 768px) {
        max-width: 400px;
    }
`

const Video = styled.video`
    display: block;
    width: 100%;
    
    @media screen and (min-width: 768px) {
        width: 90%;
    }
`

const Subheading = styled.div`
    p {
        font-size: 24px;
        line-height: 36px;
        margin: 0 0 24px 0;
    }
`

const VideoGrid = (props: SliceProps<Queries.PrismicProductPageDataBodyAppSection>) => {

    const { context, slice } = props;
    const { audience } = context;
    const { primary, items } = slice;
    const { heading, subheading, description, button_text, button_url } = primary || {};

    const { id, data } = items[0]?.grid_item?.document || {};
    const { is_video, image, video } = (data || {}) as Queries.PrismicVideoGridItemDataType;
    const initialPayload = {
        image,
        video,
        is_video
    }
    const [currentMedia, setCurrentMedia] = useState(initialPayload);
    const [active, setActive] = useState<string>(id as string);

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
    const [subheadingHasRichText, subheadingRichText] = hasRichText(subheading?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    return (
        <Section id="video-grid">
            <Wrapper>
                {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                <ContentWrapper audience={audience}>
                    <Grid>
                        {items.map((props, index) => {

                            const { grid_item } = props;
                            const { document } = grid_item || {};
                            const id = document?.id;
                            const { data } = (document || {}) as Queries.PrismicVideoGridItem;
                            const { is_video, image, video, icon, description: itemDescription, title } = data || {};

                            const [titleHasRichText, titleRichText] = hasRichText(title?.richText);
                            const [itemDescriptionHasRichText, itemDescriptionRichText] = hasRichText(itemDescription?.richText);

                            const payload = {
                                image,
                                video,
                                is_video
                            }

                            const playing = true;

                            return (
                                <Item
                                    key={index}
                                    onClick={() => {
                                        setCurrentMedia(payload);
                                        setActive(id as string);
                                    }}
                                    isSelected={id === active}
                                >
                                    <IconWrapper>
                                        {getIcon(icon as string, id === active ? 'white' : 'var(--light-green)', index)}

                                        {/* <Playpause isSelected={id === active}>
                                            {is_video && playing ? (
                                                <PlayIcon color="var(--light-green)" />
                                            ) : is_video && !playing ? (
                                                <PauseIcon color="var(--light-green)" />
                                            ) : (
                                                ``
                                            )
                                            }
                                        </Playpause> */}
                                    </IconWrapper>
                                    {titleHasRichText && <Title>{renderRich(titleRichText)}</Title>}
                                    {itemDescriptionHasRichText && <ItemDescription>{renderRich(itemDescriptionRichText)}</ItemDescription>}
                                </Item>
                            )
                        }
                        )}
                    </Grid>
                    <MediaHolder>
                        {currentMedia.is_video ? (
                            <Video
                                src={currentMedia?.video as string}
                                autoPlay
                            />
                        ) : (
                            <>
                                {currentMedia?.image?.gatsbyImageData && (
                                    <ImageWrapper>
                                        <Image
                                            image={(currentMedia?.image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                            alt={currentMedia?.image?.alt || ''}
                                        />
                                    </ImageWrapper>
                                )}
                            </>
                        )}
                    </MediaHolder>
                </ContentWrapper>
                {subheadingHasRichText && <Subheading>{renderRich(subheadingRichText)}</Subheading>}

                {buttonTextHasRichText && button_url?.url &&
                    <Button
                        buttonStyle={"outline"}
                        url={button_url?.url || '' as string}
                    >

                        {renderRich(buttonTextRichText)}
                    </Button>
                }
            </Wrapper>
        </Section>
    )
}

export default VideoGrid