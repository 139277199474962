

import { PayloadAction } from '@reduxjs/toolkit'
import { convertCurrency } from '../../utils';
import { convertPrice } from '../../utils';

import { Cart } from '../../utils/playbrush-client/types';
import { InitialStateLineItem } from './cart-types';

export const setCartAction = (state: any, action: PayloadAction<Cart>) => {
    const [locale, currency] = convertCurrency(action.payload.currency);

    const newLineItems: InitialStateLineItem[] =
        action.payload.lineItems.map(lineItem => {
            const { lineContent } = lineItem;
            return ({
                ...lineItem,
                locale,
                lineContent: lineContent?.map(content => ({
                    ...content,
                    amount: convertPrice(
                        content?.priceAmount || 0,
                        locale,
                        currency
                    ),
                    amountAfterTrial: convertPrice(
                        content?.priceAmountAfterTrial || 0,
                        locale,
                        currency
                    ),
                    amountOneTime: convertPrice(
                        content?.priceAmountOneTime || 0,
                        locale,
                        currency
                    ),
                    amountRecurring: convertPrice(
                        content?.priceAmountRecurring || 0,
                        locale,
                        currency
                    ),
                    defaultAmount: convertPrice(
                        content?.priceDefaultAmount || 0,
                        locale,
                        currency
                    ),
                    originalAmountAfterTrial: convertPrice(
                        content?.priceOriginalAfterTrial || 0,
                        locale,
                        currency
                    ),
                    originalAmountOneTime: convertPrice(
                        content?.priceOriginalOneTime || 0,
                        locale,
                        currency
                    ),
                    originalAmountRecurring: convertPrice(
                        content?.priceOriginalRecurring || 0,
                        locale,
                        currency
                    ),
                    tags: content.tags || [],
                    frontendLineData: content?.frontendLineData
                        ? JSON.parse((content.frontendLineData as unknown) as string)
                        : {},
                })) || [],
                currencyCode: currency,
                amount: convertPrice(
                    lineItem.priceAmount || 0,
                    locale,
                    currency
                ),
                amountAfterTrial: convertPrice(
                    lineItem.priceAmountAfterTrial || 0,
                    locale,
                    currency
                ),
                amountOneTime: convertPrice(
                    lineItem.priceAmountOneTime || 0,
                    locale,
                    currency
                ),
                amountRecurring: convertPrice(
                    lineItem.priceAmountRecurring || 0,
                    locale,
                    currency
                ),
                defaultAmount: convertPrice(
                    lineItem.priceDefaultAmount || 0,
                    locale,
                    currency
                ),
                originalAmountAfterTrial: convertPrice(
                    lineItem.priceOriginalAfterTrial || 0,
                    locale,
                    currency
                ),
                originalAmountOneTime: convertPrice(
                    lineItem.priceOriginalOneTime || 0,
                    locale,
                    currency
                ),
                originalAmountRecurring: convertPrice(
                    lineItem.priceOriginalRecurring || 0,
                    locale,
                    currency
                ),
                tags: lineItem.tags || [],
                frontendLineData: lineItem.frontendLineData
                    ? JSON.parse((lineItem.frontendLineData as unknown) as string)
                    : {},
            })
        })

    state = {
        ...state,
        ...action.payload,
        lineItems: newLineItems,
        lineItemCount: newLineItems.reduce((total, lineItem) => total + lineItem.quantity, 0),
        frontendData: action.payload?.frontendData
            ? JSON.parse(action.payload.frontendData)
            : {},
    };

    state.locale = locale;
    state.currencyCode = currency;

    state.couponCode = action.payload.couponCode;

    state.subtotal = convertPrice(
        action.payload.amountSubtotal,
        locale,
        currency
    );

    state.total = convertPrice(
        action.payload.amountTotal,
        locale,
        currency
    );

    state.discount = convertPrice(
        action.payload.amountDiscount,
        locale,
        currency
    );

    state.shipping = convertPrice(
        action.payload.amountShipping,
        locale,
        currency
    );

    state.tax = convertPrice(
        action.payload.amountTax,
        locale,
        currency
    );
    return state;
}

export const setCountryAction = (state: any, action: PayloadAction<string>) => {
    return {...state, country: action.payload};
}

export const setCurrencyAction = (state: any, action: PayloadAction<string>) => {
    return {...state, currency: action.payload};
}

export const setLanguageAction = (state: any, action: PayloadAction<string>) => {
    return {...state, language: action.payload};
}
