import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";
import LogoImage from "../../../../images/logo";

import { useAppSelector } from "../../../../app/hooks";

const LinkToHome = styled(Link)`
    font-size: 0;
    line-height: 0;
    padding: 11px;

    @media screen and (min-width: 1140px) {
        padding: 4px 0 0 0;
    }

    svg {
        height: 33px;
    
        @media screen and (min-width: 1140px) {
            height: 49px;
        }
    }
`;

const Logo = () => {
    const [audience, lang] = useAppSelector(state => [state.general.audience, state.general.lang]);

    return (
        <LinkToHome to={`/${lang}/${audience}`}>
            <LogoImage />
        </LinkToHome>
    )
};

export default Logo;