import React from 'react';
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import { SliceProps } from "../../types";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const TrustpilotEmbed = styled.div``

const TrustpilotCompanyReviews = ({ context }: SliceProps<Queries.PrismicHomepageDataBodyTextVideo>) => {
	const { lang } = context;
	
	const language = lang === "de-eu" ? 'de-AT' : 'en-gb';

	const ref = React.useRef(null);
	React.useEffect(() => {
	if (window.Trustpilot) {
		window.Trustpilot.loadFromElement(ref.current, true);
	}
	}, []);
	
	return (
		<Section>
			<Wrapper>
				{lang === "de-eu" ? (
					<TrustpilotEmbed
						ref={ref}
						className="trustpilot-widget"
						data-locale={language}
						data-template-id="53aa8912dec7e10d38f59f36" 
						data-businessunit-id="6002998122fd1c000182be61" 
						data-style-height="140px" 
						data-style-width="100%" 
						data-theme="light" 
						data-stars="4,5" 
						data-review-languages="de"
					>
						<a href="https://at.trustpilot.com/review/www.playbrush.com" target="_blank" rel="noopener">
							Trustpilot
						</a>
					</TrustpilotEmbed>
					):(
					<TrustpilotEmbed
						ref={ref}
						className="trustpilot-widget"
						data-locale={language}
						data-template-id="53aa8912dec7e10d38f59f36" 
						data-businessunit-id="6002998122fd1c000182be61" 
						data-style-height="140px" 
						data-style-width="100%" 
						data-theme="light" 
						data-stars="4,5" 
						data-review-languages="en"
					>
						<a href="https://uk.trustpilot.com/review/www.playbrush.com" target="_blank" rel="noopener">
							Trustpilot
						</a>
					</TrustpilotEmbed>
					)
				}
			</Wrapper>
		</Section>
	);
};
export default TrustpilotCompanyReviews;