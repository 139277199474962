import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components";
import { useAppSelector } from "../../../../../app/hooks";

const ListItem = styled.li`
    position: relative;
    list-style: none;
	
	@media screen and (min-width: 768px) {

	}
`

const Wrapper = styled.button`
    position: relative;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
`

const Quantity = styled.div`
    position: absolute;
    top: -4.17%;
    right: -8.33%;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 4px;
    background-color: white;
    padding: 0 4px;
    
	@media screen and (min-width: 768px) {

	}
`

// const Image = styled(GatsbyImage)`
const Image = styled.img`
    width: 64px;
    height: 64px;
`

const Text = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    max-width: 64px;

	@media screen and (min-width: 768px) {

	}
`

const Note = styled.p<{ left: string, right: string }>`
    ${({ left = '50%', right = '50%' }) => `
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        width: auto;

        position: absolute;
        top: 72px;
        left: ${left};
        right: ${right};
        // transform: translateX(-50%);
        background-color: var(--light-green);
        color: white;
        padding: 16px;
        border-radius: 16px;
        z-index: 9;
        cursor: pointer;

        @media screen and (min-width: 768px) {

        }
    `}
`

const Tail = styled.svg`
    position: absolute;
    top: 56px;
    left: 18px;
`

const BubbleTail = () => {

    return (
        <Tail
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5359 2.00001C12.0755 -0.666661 15.9245 -0.666667 17.4641 2L26.9904 18.5C28.53 21.1667 26.6055 24.5 23.5263 24.5L4.47373 24.5C1.39453 24.5 -0.529982 21.1667 1.00962 18.5L10.5359 2.00001Z"
                fill="#2BB573"
            />
        </Tail>
    )
}

function getBundleItemTranslation(item: any, key: string, translationKey: string) {
    if (!item || !key || !translationKey) return;

    return item?.translations?.[key]?.[translationKey];
}

const BundleListItem = ({ item, translationTag }: any) => {
    const lang = useAppSelector(state => state.general.lang);

    const itemRef = useRef(null);
    const [display, setDisplay] = useState(false);

    // const [width, setWidth] = useState(false);
    const [left, setLeft] = useState('-80px');
    const [right, setRight] = useState('-80px');

    const image = item.image;
    const title = getBundleItemTranslation(item, 'prodImageTitle', translationTag);
    const quantity = (getBundleItemTranslation(item, 'prodImageCornerNote', translationTag) || '').replaceAll('"', '');
    const note = getBundleItemTranslation(item, 'prodImageTooltipText', translationTag);

    const turnOnDisplay = () => setDisplay(true);
    const turnOffDisplay = () => setDisplay(false);

    useEffect(() => {
        const { innerWidth } = window || {};
        if (innerWidth < 768) {
            // @ts-ignore
            const { left, right } = itemRef?.current?.getBoundingClientRect() || {};
            const newLeft = left - 8;
            const newRight = (innerWidth - right) - 8;
            setLeft(`-${newLeft}px`);
            setRight(`-${newRight}px`);
        }
    }, []);

    return (
        <ListItem ref={itemRef}>
            <Wrapper
                type="button"
                onMouseEnter={turnOnDisplay}
                onMouseLeave={turnOffDisplay}
                onClick={turnOnDisplay}
            >
                <Image src={image || 'https://via.placeholder.com/150'} alt={'item-image'} />
                <Quantity>
                    {`x${quantity}`}
                </Quantity>
            </Wrapper>
            <Text>{title}</Text>
            {display && (
                <>
                    <BubbleTail></BubbleTail>
                    <Note
                        left={left}
                        right={right}
                        onClick={turnOffDisplay}
                    >
                        {note}
                    </Note>
                </>
            )}
        </ListItem>
    )
}

export default BundleListItem;