import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { RichText } from 'prismic-reactjs';
import Button from "../../../snippets/button";
import getIcon from "../../../icons/icon-renderer";

import Wrapper from "../../../layout/wrapper";

import { hasRichText, renderRich } from "@utils";

const Section = styled.section`
    max-width: 1440px;
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
    }
`

const HeadingContainer = styled.div`
    flex: 0 1 100%;

    @media screen and (min-width: 768px) {
        flex: 0 1 70%;
    }
`

const Heading = styled.div`
    padding: 0 0 40px 0;
`

const Description = styled.div`
`

const ButtonWrapper = styled.div`
    display: none;
    
    @media screen and (min-width: 768px) {
        display: block;
        flex: 1 1 50%;
        text-align: right;
    }
`

const Grid = styled.div<{ numberOfItems: number }>`
    display: grid;
    column-gap: 40px;
    row-gap: 74px;
    margin: 86px 0 0 0;

    @media screen and (min-width: 768px) {
        row-gap: 86px;
    }

    ${({ numberOfItems }) => `
        ${numberOfItems % 4 === 0 ? `
            grid-template-columns: repeat(1, 1fr);

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(4, 1fr);
            }
        ` : `
            grid-template-columns: repeat(1, 1fr);
            
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
            }
        `}
    `}
`

const Item = styled.div`
    text-align: center;
`

const IconTitle = styled.div`
    margin: 22px auto 0;
    max-width: 70%;
`

const MobileButtonWrapper = styled.div`
    display: block;
    margin: 64px 0 0 0;
        
    @media screen and (min-width: 768px) {
        display: none;
    }
`


const IconGrid = (props: SliceProps<Queries.PrismicAmbassadorPageDataBodyIconGrid>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading, description, button_text, button_url } = primary;

    const numberOfItems = items.length;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    return (
        <Section>
            <Wrapper>
                {headingHasRichText &&
                    <InnerWrapper>
                        <HeadingContainer>
                            {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                            {descriptionHasRichText && renderRich(descriptionRichText)}
                        </HeadingContainer>
                        {buttonTextHasRichText && button_url &&
                            <ButtonWrapper>
                                <Button
                                    buttonStyle={'outline'}
                                    target={button_url?.target || '' as string}
                                    url={button_url.url || ''}
                                    buttonFullWidth={'full-mobile'}
                                >
                                    {renderRich(buttonTextRichText)}
                                </Button>
                            </ButtonWrapper>
                        }
                    </InnerWrapper>
                }
                <Grid numberOfItems={numberOfItems}>
                    {items && items?.map((
                        {
                            icon,
                            icon_title
                        },
                        index
                    ) => (
                        <Item
                            key={`${index}-icon-item`}
                        >
                            {icon && getIcon(icon, 'var(--light-green)', index)}
                            <IconTitle>
                                {icon_title?.richText && RichText.render(icon_title.richText)}
                            </IconTitle>
                        </Item>
                    ))}
                </Grid>
                {buttonTextHasRichText && button_url &&
                    <MobileButtonWrapper>
                        <Button
                            buttonStyle="outline"
                            target={button_url?.url || '' as string}
                        >
                            {renderRich(buttonTextRichText)}
                        </Button>
                    </MobileButtonWrapper>
                }
            </Wrapper>
        </Section>
    )
};

export default IconGrid