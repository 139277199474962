import React from "react";
import styled from "styled-components";

const Tags = styled.article`
    display: flex;
	
	@media screen and (min-width: 768px) {

	}
`

const Tag = styled.p<{ borderColor: string }>`
    ${({ borderColor = 'var(--light-green)' }) => `
        display: flex;
        align-items: center;
        border: 1px solid ${borderColor};
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 24px;
        margin-right: 8px;
        text-align: center;
        line-height: 16px;
    `}
`

const TagSection = ({ tags = [] }: { tags: Queries.PrismicProductDataTags[] }) => {

    return (
        <Tags>
            {tags.reduce((array, { border_color, tag }): any => border_color && tag ? [...array, (
                <Tag key={tag} borderColor={border_color || "var(--light-green)"}>
                    {tag}
                </Tag>
            )] : array, [])}
        </Tags>
    )
}

export default TagSection;