import React from "react"
import styled from "styled-components";
import { Link } from "gatsby"

import { useAppSelector } from "../../../app/hooks";
import { PageContext } from "../../slices/types";
import { getPricingInfo } from "./utils";

import {
    OrderSummaryCouponForm,
    OrderSummaryPriceOverview,
    OrderSummaryFooter
} from './snippets';

const Summary = styled.div`
    flex: 0 1 auto;
    text-align: right;
    
    @media screen and (min-width: 768px) {
         flex: 0 0 419px;
         position: sticky;
         top: 120px;
         align-self: flex-start;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex: 0 0 100%;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    padding: 16px;
    margin: 0 0 16px;
    background: #F8F8F8;
    border: 1px solid #CDD4DC;
    border-radius: 16px;
    row-gap: 16px;

    @media screen and (min-width: 768px) {
        padding: 32px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 100%;
`

const Divider = styled.hr`
    margin: 0;
    border: 0;
    border-bottom: 1px solid #CDD4DC;
    width: 100%;

    @media screen and (min-width: 768px) {
        margin: 8px 0;
    }
`

const HelpUrl = styled(
    (props) => (
        <Link
            to={props.to}
            target={props.target}
            className={props.className}
        >
            {props.children}
        </Link>
    )
)`
    display: none;
    
    @media screen and (min-width: 768px) {
        display: block;
        color: var(--light-green);
    }
`

const PaymentDescription = styled.p`
    margin: 0 0 8px;
    text-align: left;
    font-size: 12px;
`

const OrderSummary = ({ data, translations, settings }: {
    data: Queries.PrismicCartPageDataType,
    translations: PageContext['translations'],
    settings: PageContext['settings'],
}) => {
    const cart = useAppSelector(state => state.cart);

    const { freeMonthExists, hasSubscriptionBrushHeads, renewalSentence } = getPricingInfo(data, cart);

    return (
        <Summary>
            <InnerWrapper>
                <Header>
                    <h4>{data?.summary_title}</h4>
                    <HelpUrl to={settings?.link_to_help?.url || ''}>
                        {data?.help_text}
                    </HelpUrl>
                </Header>
                <Divider />
                <OrderSummaryCouponForm data={data} />
                <OrderSummaryPriceOverview data={data} translations={translations} />
                <Divider />
                <OrderSummaryFooter data={data} translations={translations} />
            </InnerWrapper>
            {freeMonthExists && (
                <PaymentDescription dangerouslySetInnerHTML={{ __html: renewalSentence }} />
            )}
            {hasSubscriptionBrushHeads && freeMonthExists && (
                <PaymentDescription>{data?.brush_heads_trial_disclaimer}</PaymentDescription>
            )}
        </Summary>
    )
};

export default OrderSummary;