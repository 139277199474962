export default `
    mutation( $orderId: String, $checkoutId: String) {
        orderInfo(
            orderId: $orderId
            checkoutId: $checkoutId
        ) {
            order {
                amountDiscount
                amountShipping
                amountSubtotal
                amountTax
                amountTotal
                billingAddressCity
                billingAddressCountry
                billingAddressLine1
                billingAddressLine2
                billingAddressPostalCode
                billingAddressState
                billingName
                country
                couponcode
                createdAt
                currency
                currencySymbol
                email
                hasSubscription
                hasTrial
                stripeInvoiceId
                modifiedAt
                orderId
                orderItems {
                    bundleId
                    bundleName
                    currencySymbol
                    image
                    priceAmount
                    priceDefaultAmount
                    priceId
                    productId
                    productName
                    quantity
                    tags
                }
                shippingAddressCity
                shippingAddressCountry
                shippingAddressLine1
                shippingAddressLine2
                shippingAddressPostalCode
                shippingAddressState
                shippingName
                stripeSubscriptionId
                tags
                userId
            }
            cart {
                checkoutId
                currency
                currencySymbol
                couponCode
                customerId
                lineItems {
                currencySymbol
                description
                frontendLineData
                image
                infoDelivery
                infoExplainer
                infoPayment
                isSubscription
                lineContent {
                    currencySymbol
                    description
                    frontendLineData
                    image
                    infoDelivery
                    infoExplainer
                    infoPayment
                    isSubscription
                    lineId
                    priceAmount
                    priceAmountAfterTrial
                    priceAmountOneTime
                    priceAmountRecurring
                    priceDefaultAmount
                    priceOriginalAfterTrial
                    priceOriginalOneTime
                    priceOriginalRecurring
                    product
                    productName
                    productNameMarketing
                    productNameOrder
                    quantity
                    recurringInterval
                    recurringIntervalCount
                    recurringTrialPeriodDays
                    tags
                }
                lineId
                priceAmount
                priceAmountAfterTrial
                priceAmountOneTime
                priceAmountRecurring
                priceDefaultAmount
                priceOriginalAfterTrial
                priceOriginalOneTime
                priceOriginalRecurring
                product
                productName
                productNameMarketing
                productNameOrder
                quantity
                recurringInterval
                recurringIntervalCount
                recurringTrialPeriodDays
                tags
                }
                amountSubtotal
                amountTotal
                amountDiscount
                amountShipping
                amountTax
                language
            }
        }
    }
`