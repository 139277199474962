import { BenefitsGrid, CtaTeaser, CtaTeaserImage, FeatureGrid, HeroImagecloud, NewsletterPopUp, Testimonials } from '../../components/slices/generic';
import { TextVideo } from '../../components/slices/home';
import { MapSection } from '../../components/slices/im-handel-page';
import { FeaturedArticles } from '../../components/slices/magazine-overview-page';

export const components = {
    hero___imagecloud: HeroImagecloud,
    text___video: TextVideo,
    benefits_grid: BenefitsGrid,
    testimonials: Testimonials,
    cta_teaser: CtaTeaser,
    map_section: MapSection,
    feature_grid: FeatureGrid,
    cta_teaser_image: CtaTeaserImage,
    related_articles: FeaturedArticles,
    'email_pop-up': NewsletterPopUp,
}
