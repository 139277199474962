import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const LinkedinIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8332 9.672V15.5251H12.5004V10.0908C12.5004 8.75325 12.0004 7.75013 10.7504 7.75013C10.3699 7.76325 10.0018 7.88794 9.69148 8.10841C9.38117 8.32903 9.14258 8.63576 9.00508 8.99076L9.00039 9.00482C8.9257 9.27841 8.89773 9.56263 8.91758 9.84544V9.83763V15.5267H5.58477C5.58477 15.5267 5.66758 6.32982 5.58477 5.32513H8.91758V6.747C9.21883 6.22107 9.65773 5.78747 10.1873 5.49247C10.7168 5.19763 11.3166 5.05278 11.9223 5.07357H11.916C14.166 5.07357 15.8332 6.57825 15.8332 9.672ZM2.0832 0.475129C1.83992 0.452941 1.59477 0.481848 1.36336 0.55966C1.1318 0.637473 0.919141 0.762786 0.738828 0.927473C0.558359 1.092 0.414298 1.29247 0.315704 1.51591C0.21711 1.73935 0.166172 1.98091 0.166016 2.22513V2.25638C0.166016 3.21419 0.942578 3.98919 1.89883 3.98919L2.00352 3.98607H1.99883C2.24211 4.00825 2.48727 3.9795 2.71867 3.90153C2.95023 3.82372 3.16289 3.69841 3.3432 3.53372C3.52367 3.36919 3.66773 3.16872 3.76633 2.94529C3.86492 2.72185 3.91586 2.48028 3.91602 2.23607V2.22982V2.20482C3.91602 1.247 3.13945 0.472004 2.1832 0.472004L2.07852 0.475129H2.0832ZM0.333204 15.5251H3.66602V5.32357H0.333204V15.5251Z" 
            fill={color}/>
    </IconSvg>
    
);

export default LinkedinIcon;