import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const AwardIcon = ({ color = '#8193A8' }: { color?: string }) => (
    <IconSvg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M33.4965 3.44027H29.0858C29.1408 2.17233 29.1408 1.40048 29.1408 1.40048C29.1408 1.06974 28.9205 0.848999 28.5894 0.848999H28.5344H28.4794H5.65463C5.3239 0.848999 5.10315 1.06936 5.10315 1.40048C5.10315 1.40048 5.10315 2.17231 5.15814 3.44027H0.747469C0.471729 3.44027 0.195989 3.66063 0.195989 3.99175C0.195989 4.10212 0.140996 7.02412 1.02282 10.1665C2.23572 14.4117 4.66164 16.8926 7.96938 17.3887H8.02437H8.07936C10.0641 21.8543 12.9311 24.5005 16.5146 25.107V34.259H13.5926C13.2618 34.259 13.0411 34.4794 13.0411 34.8105V36.1888C11.2767 36.5196 9.9538 38.0632 9.9538 39.8827V40.5995C9.9538 40.9303 10.1742 41.151 10.5053 41.151H23.9575C24.2883 41.151 24.509 40.9306 24.509 40.5995V39.8827C24.509 37.9529 23.0757 36.4093 21.2563 36.1339V34.8106C21.2563 34.4799 21.0359 34.2591 20.7048 34.2591H17.6175L17.6183 25.1071C21.2571 24.5556 24.1237 21.9094 26.1639 17.3888H26.2189C29.527 16.8927 31.9524 14.4118 33.1654 10.1666C34.0476 7.02383 33.9923 4.10182 33.9923 3.99185C34.048 3.71611 33.7723 3.44037 33.4966 3.44037L33.4965 3.44027ZM23.4625 39.8827V40.048H11.1128V39.8827C11.1128 38.394 12.3257 37.2364 13.7591 37.2364H20.761C22.2492 37.2364 23.4623 38.4493 23.4623 39.8827H23.4625ZM20.1547 36.1335H14.2004V35.4166H20.2097V36.1335H20.1547ZM1.2995 4.54272H5.26902C5.43439 6.69288 5.76512 9.66982 6.53696 12.6472C6.86769 13.9151 7.2538 15.0731 7.63951 16.1756C2.01592 14.9077 1.35443 6.7482 1.2995 4.54272ZM17.1224 24.1147C7.25378 22.9567 6.2614 5.3697 6.20627 2.00664H28.0386C27.9832 5.31437 27.046 22.9569 17.1224 24.1147ZM26.6602 16.2306C27.1013 15.128 27.432 13.97 27.7628 12.7021C28.5346 9.72515 28.8653 6.74781 29.0307 4.59765H32.9453C32.8903 6.7482 32.2838 14.9074 26.6602 16.2306Z" 
            fill={color}
        />
    </IconSvg>
);

export default AwardIcon;