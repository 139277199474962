import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { SliceProps } from "../../types";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";

const Section = styled.section`
    margin: 80px 0 0 0;    
    
    @media screen and (min-width: 768px) {
        margin: 120px 0 0 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 0 auto;
    background: #EAF0F6;
    border-radius: 16px;
    padding: 24px;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 10%;
        padding: 32px;
    }
`

const ContentContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex: 1 0 50%;
    order: 1;

    @media screen and (min-width: 768px) {
        order: 0;
    }
`

const Title = styled.div`
    width: 100%;
    margin: 0 auto 20px;
    
    h3 {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }
`

const ImageContainer = styled.div`
    flex: 0 1 100%;
    order: 0;

    @media screen and (min-width: 768px) {
        flex: 0 1 auto;
        order: 1;
    }
`

const Image = styled(GatsbyImage)`
    width: auto;

    @media screen and (min-width: 768px) {
        max-width: 414px;
    }
`


const CtaTeaserImage = (props: SliceProps<Queries.PrismicMagazineOverviewDataBodyCtaTeaserImage>) => {
    const { slice } = props;
    const { primary } = slice;
    const { button_link, button_text, title, image } = primary;

    return (
        <Section id="cta-teaser-image">
            <Wrapper>
                <InnerWrapper>
                    <ContentContainer>
                        {title?.richText && (
                            <Title>{RichText.render(title.richText)}</Title>
                        )}
                        {button_link?.url && button_text?.richText &&
                            <Button
                                url={button_link.url || ''}
                                buttonStyle={'outline'}
                                buttonFullWidth={'full-mobile'}
                                target={button_link?.target || '_self'}
                            >
                                {button_text?.richText && RichText.render(button_text.richText)}
                            </Button>
                        }
                    </ContentContainer>
                    {image?.gatsbyImageData && (
                        <ImageContainer>
                            <Image
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image.alt || ''}
                            />
                        </ImageContainer>
                    )}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default CtaTeaserImage