import React from "react";
import styled from "styled-components";
import { hasRichText, renderRich } from "../../../../utils/common/rich-text";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MobileWrapper = styled.div<{ background: string }>`
    max-width: 100%;
    padding: 24px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(17.5px);
    border-radius: 16px;
    // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

    ${({ background }) => background ? `
        background: url('${background}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    ` : ``
    }
`

const MobileContentWrapper = styled.div`
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(17.5px);
    border-radius: 16px;
    padding: 24px;
`

// const MobileImage = styled(GatsbyImage)`
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: -1;
//     width: 340px;
//     height: 100%;
//     border-radius: 16px;

//     img {
//         border-radius: 16px;
//     }
// `

const Title = styled.div`
    padding: 0 0 8px 0;
    * {
        // word-break: break-all;
    }
`



const ReadingTime = styled.div`
    padding: 0 0 24px 0;
`

const Slide = ({
    article_title,
    button_link,
    button_text,
    description,
    image,
    reading_time1

}: Queries.PrismicMagazineBlogPageDataBodyRelatedArticlesItem) => {

    const [articleTitleTitleHasRichText, articleTitleTitleRichText] = hasRichText(article_title?.richText);
    const [readingTimeHasRichText, readingTimeRichText] = hasRichText(reading_time1?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    const backgroundImage = image?.gatsbyImageData as unknown as IGatsbyImageData;
    const backgroundUrl = backgroundImage?.images?.fallback?.src || '';

    return (
        <>
            {/* {image?.gatsbyImageData && (
                <MobileImage
                    image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                    alt={image.alt || ''}
                    objectFit={"cover"}
                />
            )} */}
            {image && image.gatsbyImageData &&
                <MobileWrapper background={backgroundUrl}>
                    <MobileContentWrapper>
                        {articleTitleTitleHasRichText && <Title>{renderRich(articleTitleTitleRichText)}</Title>}
                        {readingTimeHasRichText && <ReadingTime>{renderRich(readingTimeRichText)}</ReadingTime>}
                        {descriptionHasRichText && <ReadingTime>{renderRich(descriptionRichText)}</ReadingTime>}
                        {button_link?.url && button_text && buttonTextHasRichText &&
                            <Button
                                url={button_link?.url || ''}
                                buttonStyle={'solid'}
                            >
                                {buttonTextHasRichText && renderRich(buttonTextRichText)}
                            </Button>
                        }
                    </MobileContentWrapper>
                </MobileWrapper>
            }
        </>
    )
}

export default Slide
