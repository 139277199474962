import React from "react"
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setBrush, setColor } from "../../../../../app/slices/filter-reducer";
import { BundlesConfig, Translations } from "../../../../../components/slices/types";
import BaseModal from "../../../../snippets/modal/base-modal";
import { hasRichText, renderRich } from "@utils";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const Container = styled.div`
    margin: 0 0 36px;

	@media screen and (min-width: 768px) {

	}
`

const Header = styled.header`
    margin: 0 0 12px;
    display: flex;
    justify-content: space-between;

	@media screen and (min-width: 768px) {

	}
`

const Fieldset = styled.fieldset`
    display: flex;
    border: 0;
    padding: 0;
	
	@media screen and (min-width: 768px) {

	}
`

const BlockTitle = styled.h5`
    margin: 0 8px 0 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem; 
	
	@media screen and (min-width: 768px) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
	}
`

const BlockTitleSuffix = styled.span`
    font-weight: 400;
`

const ModalImageWrapper = styled.div`
    margin: 32px 0 24px;
`

const BlockLink = styled.a`
    font-weight: 400;
    text-align: right;
	
	@media screen and (min-width: 768px) {
	}
`

const Label = styled.label<{ reducedMargins: boolean}>`
    margin-right: ${({ reducedMargins }) => reducedMargins ? '24px' : '28px'};
    cursor: pointer;
	
	@media screen and (min-width: 768px) {

	}
`

const Input = styled.input`
    display: none;

    // & + div:hover,
    // & + p:hover,
    // &:checked + div,
    // &:checked + p {
    //     border-color: var(--light-green);
    //     color: var(--light-green);
    //     cursor: pointer;
    // }
	
	@media screen and (min-width: 768px) {

	}
`

const Color = styled.div<{ selected: boolean, color: string; }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-right: 8px;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: ${({ color }) => color};
    }

    ${({ selected }) => selected ? `
        border-color: black;
    ` : ''}

    @media screen and (min-width: 768px) {

    }
`

const BrushInput = styled.span<{ selected: boolean, disabled?: boolean }>`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #231F20;
    margin-right: 8px;

    ${({ selected }) => selected ? `
        background-color: #231F20;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            right: 7px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: white;
        }
    ` : ''}
	
	@media screen and (min-width: 768px) {

	}
`

const Brush = styled.p`
    display: flex;
    align-items: center;
    color: black;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
	
	@media screen and (min-width: 768px) {

	}
`

export interface VariantBlockProps {
    forType: string;
    title: string;
    url?: string;
    linkText?: string;
    options: string[];
    translations: Translations;
    config: BundlesConfig;
    brushModal?: any;
};

const VariantBlock = ({ forType, url, options, title, linkText, config, brushModal, translations }: VariantBlockProps) => {
    const isForBrush = forType === 'brush';
    const isForColor = forType === 'color';
    const [selectedColor, selectedBrush, lang, audience] = useAppSelector(state => [state.filter.selectedColor, state.filter.selectedBrush, state.general.lang, state.general.audience]);
    const dispatch = useAppDispatch();
    const [modalContentTopHasRichText, modalContentTopRichText] = hasRichText(brushModal?.subscription_modal_content_top?.richText);
    const [modalContentBottomHasRichText, modalContentBottomRichText] = hasRichText(brushModal?.subscription_modal_content_bottom?.richText);
    const { gatsbyImageData: modalImage, alt: modalImageAlt } =  brushModal?.subscription_modal_image || {}
    const hasModalImage =  !!modalImage
    const translationKey = lang === 'de-eu' ? 'de' : 'en';

    const containsVariant = (option: string): boolean => {
        return isForBrush ? selectedBrush === option : selectedColor === option;
    }

    const selectNewVariant = (v: string) => {
        isForBrush ? dispatch(setBrush(v)) : dispatch(setColor(v));
    }

    const getInput = (type: string, option: string) => {
        switch (type) {
            case 'color':
                return (
                    <Color selected={containsVariant(option)} color={config.translations?.[`${option}_hex`]} />
                )
            case 'brush':
            case 'size':
                return (
                    <>
                        <Brush>
                            <BrushInput selected={containsVariant(option)}/>
                            {config.translations?.[`${option}_${translationKey}`]}
                        </Brush>
                    </>
                )
            default:
                return null;
        }
    }

    return (
        <Container>
            <Header>
                <BlockTitle>
                    {title}
                    {isForColor && <BlockTitleSuffix>{': '+config.translations?.[`${selectedColor}_${translationKey}`]}</BlockTitleSuffix>}
                </BlockTitle>
                {url && options.length > 1 && (
                    <BlockLink href={url}>{linkText}</BlockLink>
                )}
                {isForBrush && audience === 'kids' && (
                    <BaseModal buttonText={translations.compare_brushes}>
                        <div>
                            {modalContentTopHasRichText && <div>{renderRich(modalContentTopRichText)}</div>}
                            {hasModalImage && (
                                <ModalImageWrapper>
                                    <GatsbyImage image={(modalImage as unknown) as IGatsbyImageData} alt={modalImageAlt || ''} />
                                </ModalImageWrapper>
                            )}
                            {modalContentBottomHasRichText && <div>{renderRich(modalContentBottomRichText)}</div>}
                        </div>
                    </BaseModal>
                )}
            </Header>
            <Fieldset id={forType}>
                {options && options.map((option: string, index: number) => (
                    <Label key={`${index}-option`} reducedMargins={isForBrush} onClick={() => selectNewVariant(option)}>
                        <Input
                            type="radio"
                            value={option}
                        />
                        {getInput(forType, option)}
                    </Label>
                ))}
            </Fieldset>
        </Container>
    )
}

export default VariantBlock;