interface PrismicProductTag {
    tag?: string;
    border_color?: string;
}

interface ProductTag {
    tag: string;
    border_color: string;
}

export const getCleanPrismicBundleTags = (tags: Queries.PrismicBundleDataTags[]): ProductTag[] => {
    return tags.reduce((arr: any, tag: any) => {
        const tagIsFilledIn = tag?.border_color && tag?.tag;

        return tagIsFilledIn ? [...arr, tag] : arr;
    }, []);
}