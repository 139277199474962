import { FrontendLineData } from "../playbrush-client/types";
import { GAEvent, GEvent, LogConfig } from "./types";


export const getEcommerceEvent = (
    event: GEvent,
    data: FrontendLineData['trackingContext'][],
    config?: LogConfig,
): GAEvent => {
    const newEvent: GAEvent = {
        event: event,
        ecommerce: {
            items: []
        },
    }
    if (newEvent?.ecommerce) {
        newEvent.ecommerce.items = data
        if (config?.value) newEvent.ecommerce.value = config?.value;
        if (config?.currency) newEvent.ecommerce.currency = config?.currency;
        if (config?.transaction_id) newEvent.ecommerce.transaction_id = config?.transaction_id;
        if (config?.affiliation) newEvent.ecommerce.affiliation = config?.affiliation;
        if (config?.tax) newEvent.ecommerce.tax = config?.tax;
        if (config?.shipping) newEvent.ecommerce.shipping = config?.shipping;
        if (config?.coupon) newEvent.ecommerce.coupon = config?.coupon;
    };

    return newEvent;
}
