import { PageContext } from "../../../components/slices/types";
import { LineItem } from "../../playbrush-client/types";


export const getIntervalTranslation = (lineItem: LineItem, translations: PageContext['translations']): string => {
    const subscriptionLineItem = lineItem.lineContent.find((content) => content.isSubscription);
    if (subscriptionLineItem?.recurringIntervalCount === 1) {
        return translations.subscription_line_item_monthly || "month";
    } else if (subscriptionLineItem?.recurringIntervalCount === 6) {
        return translations.subscription_line_item_every_6_months || "every 6 months";
    } else if (subscriptionLineItem?.recurringIntervalCount === 12) {
        return translations.subscription_line_item_yearly || "year";
    } else {
        return translations.subscription_line_item_monthly || "month";
    }
}
