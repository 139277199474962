import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const YoutubeIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5009 1.14375C18.0976 0.809844 17.6175 0.581718 17.104 0.479687L17.0837 0.476562C15.9165 0.309375 14.7509 0.309375 13.5837 0.226562H6.58371C5.34933 0.226562 4.13527 0.317188 2.94933 0.492188L3.08371 0.476562C2.55152 0.539375 2.05402 0.773594 1.66652 1.14375L1.66808 1.14219C1.27746 1.49219 0.986832 1.94531 0.839957 2.45937L0.835269 2.47969C0.700894 3.09844 0.583707 3.86875 0.508707 4.65156L0.502456 4.73594C0.430987 5.4925 0.402831 6.25266 0.418081 7.0125V6.99219L0.414957 7.36875C0.414957 8.03125 0.446207 8.68594 0.508707 9.33281L0.502456 9.25C0.583706 10.1188 0.700894 10.8891 0.860269 11.6438L0.836832 11.5063C0.95402 12.0531 1.25246 12.5156 1.66496 12.8406L1.66964 12.8438C2.06183 13.1687 2.53996 13.4031 3.06652 13.5078L3.08683 13.5109C4.25402 13.6781 5.41964 13.6781 6.58683 13.7609H13.5868C14.8212 13.7609 16.0353 13.6703 17.2212 13.4953L17.0868 13.5109C17.6415 13.4422 18.129 13.2016 18.504 12.8438L18.5025 12.8453C18.8931 12.4953 19.1837 12.0422 19.3306 11.5281L19.3353 11.5078C19.4696 10.8891 19.5868 10.1188 19.6618 9.33594L19.6681 9.25156V6.99688C19.725 5.46969 19.6065 3.94094 19.315 2.44062L19.3353 2.56562C19.1792 2.02984 18.8925 1.54125 18.5009 1.14375ZM7.33371 9.92656V3.40469L7.66652 3.57187C8.58371 4.07187 9.49933 4.48906 10.4165 4.99375C11.4993 5.57656 12.5837 6.07656 13.6665 6.66563L13.9993 6.83281L13.6665 7L7.33371 10.1766V9.92656Z" 
            fill={color}/>
    </IconSvg>

    
);

export default YoutubeIcon;