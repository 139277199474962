import React, { useState } from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
// import VideoPlayer from "../../../snippets/video-player/video-player";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import { getCookie, setCookie } from "@utils";
import { constants } from "@constants";
import Button from "../../../../components/snippets/button";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Flair = styled(GatsbyImage)`
    width: 140px;
    height: 140px;
    display: block;
    margin: 0 0 32px 16px;

    @media screen and (min-width: 768px) {
		margin-bottom: 24px;
	}
`

const YouTubeEmbed = styled.div`
    line-height: 50%;

    iframe {
        max-width: 100%;
    }
`

const Consent = styled.div`
    width: 100%;
    height: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ConsentText = styled.p`
    margin: 0 0 16px;
    text-align: center;
    max-width: 320px;
`

const PageWideVideo = (props: SliceProps<Queries.PrismicHomepageDataBodyVideoSection>) => {
    const { context, slice } = props;
    const { audience, translations } = context;
    const { primary } = slice;
    const { video, flair } = primary;
    const { document } = video as Queries.PrismicLinkType;
    const { src, use_autoplay, mute_audio, loop, plays_inline, placeholder_image, youtube_embed_url } = (document?.data || {}) as Queries.PrismicVideoDataType;
    const showFlair = audience === 'kids';

    const YoutubeEmbedUrl = youtube_embed_url;

    const [hasConsent, setConsent] = useState(!!getCookie(constants.cookies.YOUTUBE_COOKIE));

    const consent = () => {
        setConsent(true);
        setCookie(constants.cookies.YOUTUBE_COOKIE, 1);
    }

    return (
        <Section>
            <Wrapper>
                {showFlair && flair?.gatsbyImageData && (
                    <Flair image={(flair?.gatsbyImageData as unknown) as IGatsbyImageData} alt={flair?.alt || ''} />
                )}
                {YoutubeEmbedUrl &&
                    <>
                        {hasConsent
                            ? (
                                <YouTubeEmbed dangerouslySetInnerHTML={{ __html: YoutubeEmbedUrl }} />
                            )
                            : (
                                <Consent>
                                    <ConsentText>
                                        {translations?.youtube_consent_text}
                                    </ConsentText>
                                    <Button type="button" onClick={consent}>
                                        {translations?.youtube_consent_button_text}
                                    </Button>
                                </Consent>
                            )
                        }
                    </>
                }
                {/* {video?.document?.data && (
                    <VideoPlayer
                        src={src || ''}
                        use_autoplay={use_autoplay}
                        mute_audio={mute_audio}
                        loop={loop}
                        plays_inline={plays_inline}
                        placeholder_image={placeholder_image}
                        button_size={'large'}
                    />
                )} */}
            </Wrapper>
        </Section>
    )
}

export default PageWideVideo