import { CtaTeaser, CtaTeaserImage, HeroImagecloud, HorizontalScroll, NewsletterPopUp } from '../../components/slices/generic';
import AccordionWithFilters from '../../components/slices/generic/accordion-with-filters/accordion-with-filters';
import TextSectionSmall from '../../components/slices/generic/text-section-small/text-section-small';

export const components = {
    hero___imagecloud: HeroImagecloud,
    horizontal_scroll: HorizontalScroll,
    cta_teaser: CtaTeaser,
    cta_teaser_image: CtaTeaserImage,
    text_section_small: TextSectionSmall,
    accordion_with_filters: AccordionWithFilters,
    'email_pop-up': NewsletterPopUp,
}
