import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";

const Section = styled.section`
    margin: 80px 0 0 0;    
    
    @media screen and (min-width: 768px) {
        margin: 120px 0 0 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 124px;
        max-width: 1064px;
    }
`

const ContentContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex: 1 0 50%;
    order: 1;

    @media screen and (min-width: 768px) {
        order: 0;
    }
`

const Text = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;
`

const ImageContainer = styled.div`
    flex: 0 0 100%;
    order: 0;

    @media screen and (min-width: 768px) {
        flex: 0 1 50%;
        order: 1;
    }
`

const Image = styled(GatsbyImage)`
    width: auto;

    @media screen and (min-width: 768px) {
        max-width: 100%;
    }
`


const TextImageRight = (props: SliceProps<Queries.PrismicHowItWorksPageDataBodyPlainText>) => {
    const { context: { audience }, slice: { primary } } = props;
    const {
        text,
        button__text,
        button_url,
        image
    } = primary;

    return (
        <Section id="text-image-right">
            <Wrapper>
                <InnerWrapper>
                    <ContentContainer>
                        {text?.richText && (
                            <Text className="rt">{RichText.render(text.richText)}</Text>
                        )}
                        {button_url && button__text && button__text.richText &&
                            <Button
                                url={button_url?.url || ''}
                                buttonStyle={'outline'}
                                buttonFullWidth={'full-mobile'}
                            >
                                {button__text?.richText && RichText.render(button__text.richText)}
                            </Button>
                        }
                    </ContentContainer>
                    <ImageContainer>
                        {image?.gatsbyImageData && (
                            <Image
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image.alt || ''}
                            />
                        )}
                    </ImageContainer>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default TextImageRight