import { constants } from "@constants";

export const documentIsProductType = (type?: string): boolean => {
    if (!type) return false;
    const isProductPage = type === constants.page.type.PRODUCT_PAGE;
    const isProductOverviewPage = type === constants.page.type.PRODUCT_OVERVIEW_PAGE;

    return isProductPage || isProductOverviewPage;
}

export const linksToProductFlow = (link?: Queries.Maybe<Queries.PrismicLinkType>) => {
    if (!link) return false;

    const { document } = link || {}
    const { type } = document || {};

    return documentIsProductType(type);
}