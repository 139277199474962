import React from "react"
import styled from "styled-components";
import { PageContext } from "../../../components/slices/types";
import { convertPrice } from "../..";
import { LineItem } from "../../playbrush-client/types";
import { getIntervalTranslation } from "./get-interval-translation";

const Price = styled.span`
    margin: 0 0 0 4px;
    font-size: 12px;
    font-weight: bold;
`;

export const concatTrialText = (
    lineItem: LineItem,
    translations: PageContext['translations'],
    locale: string,
    currency: string
) => {
    const prefix = translations.subscription_line_item_trial_prefix;
    const price = convertPrice((lineItem.priceAmountAfterTrial || 0) * lineItem.quantity, locale, currency)
    const interval = getIntervalTranslation(lineItem, translations);

    return (
        <>
            {`${prefix} ${interval}`}
            <Price>{price}</Price>
        </>
    );
}