import React, { useState } from "react"
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCart } from "../../../../app/slices/cart-reducer";
import { PageContext } from "../../../slices/types";
import { removeDiscountCode, getPricingInfo } from "../utils";
import BinIcon from "../../../icons/bin-icon";
import { convertPrice } from "@utils";

const Subtotal = styled.p`
    width: 50%;
    text-align: left;
`

const SubtotalPrice = styled.p`
    width: 50%;
    text-align: right;
`

const Shipping = styled.p`
    width: 50%;
    text-align: left;

    &.subscription {
        color: #00A944;
    }
`

const ShippingPrice = styled.p`
    width: 50%;
    text-align: right;
    
    &.subscription {
        color: #00A944;
    }
`

const ShippingInNextStep = styled.p`
    width: 100%;
    text-align: left;
    font-size: 12px;
`

const Coupon = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    outline: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    cursor: pointer;

    &:hover * {
        color: var(--light-green);
    }
`

const CouponCode = styled.p`
    text-align: left;
    color: #00A944;
`

const CouponPrice = styled.p<{ hovering: boolean }>`
    flex: 1 0 50%;
    text-align: right;
    color: #00A944;

    ${({ hovering }) => hovering ? `
        margin-right: 4px;
    ` : ''}
`

const OrderSummaryPriceOverview = ({ data, translations }: {
    data: Queries.PrismicCartPageDataType,
    translations: PageContext['translations'],
}) => {
    const cart = useAppSelector(state => state.cart);
    const dispatch = useAppDispatch();
    const [hovering, setHovering] = useState(false);

    const { coupon, hasCoupon } = getPricingInfo(data, cart);

    const removeCoupon = async () => {
        removeDiscountCode(cart, (newCart) => dispatch(setCart(newCart)));
    }

    return (
        <>
            <Subtotal>{translations.checkout.subtotal}</Subtotal>
            <SubtotalPrice>{convertPrice(cart.amountSubtotal || 0, cart.locale, cart.currency)}</SubtotalPrice>
            {/* <Shipping>{data?.shipping}</Shipping> */}
            {/* <ShippingPrice>{cart?.shipping}</ShippingPrice> */}
            <ShippingInNextStep>{translations.shipping_in_next_step}</ShippingInNextStep>
            {hasCoupon && (
                <Coupon
                    type="button"
                    onClick={removeCoupon}
                    onMouseEnter={() => setHovering(true)}
                    onMouseOut={() => setHovering(false)}
                >
                    <CouponCode>{coupon?.toUpperCase()}</CouponCode>
                    <CouponPrice hovering={hovering}>- {convertPrice(cart.amountDiscount || 0, cart.locale, cart.currency)}</CouponPrice>
                    {hovering && <BinIcon size={16} color={'var(--light-green)'} />}
                </Coupon>
            )}
        </>
    )
};

export default OrderSummaryPriceOverview;