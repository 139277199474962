import { RichText, RichTextBlock } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import ArrowUp from "../icons/arrow-up-icon";

const BackToTop = styled.aside`
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    right: 16px;
    top: 100px;
    z-index: 50;

    @media screen and (min-width: 768px) {
        display: none;
    }
`

const Button = styled.a``

const ButtonText = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 12px 0 0;
`

const IconWrapper = styled.div`
    padding: 11px 16px;
    border-radius: 16px;
    border: 1px solid var(--light-green);
`

const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
}

interface ButtonProps {
    text?: string;
}

const ToTopButton = ({
    text
}: ButtonProps) => {
    return (
        <BackToTop id="to-top-button" onClick={handleClick} >
            <Button>
                <ButtonText>
                    {text}
                </ButtonText>
            </Button>
            <IconWrapper>
                <ArrowUp />
            </IconWrapper>
        </BackToTop>
    )
}

export default ToTopButton