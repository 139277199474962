import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../app/hooks";
import styled from "styled-components";
import { CURRENCY_PREFERENCE_KEY, LanguageSelectOption, languageOptions } from "./utils/options";
import { getDisplayCurrency } from "./utils/get-display-currency";
import { getOptionFromLang } from "./utils/get-option-from-lang";
import { setCountry, setCurrency, setLanguage } from "../../../../app/slices/cart-reducer";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import Flag from "./utils/flag";
import { setToSessionStorage } from "@utils";
import { dropdownIconInline } from "../../../icons/drowndown-icon-inline";

const Selector = styled.div`
    position: relative;
    display: inline-grid;
    border-radius: 26px;
    background-color: var(--light-green);
    z-index: 2;

    * {
        font-weight: bold;
    }
`

const Label = styled.label<{ selected: boolean }>`
    display: flex;
    align-items: center;
    margin: 3px;
    padding: 8px 12px;
    border-radius: 24px;
    z-index: 1;
    cursor: pointer;

    ${({ selected }) => selected ? `
        background-color: white;
        color: var(--light-green);
    ` : ``}
`

const List = styled.ul<{ selected: boolean }>`
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    z-index: -1;
    border-radius: 24px 24px 0 0;
    list-style: none;
    background-color: var(--light-green);
    padding: 0 0 26px;
    
    ${({ selected }) => !selected ? `
        display: none;
    ` : `
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    `}
`

const ListItem = styled.li`
    display: flex;
    align-items: center;
    padding: 13px;
    margin: 3px;
    border-radius: 24px;
    cursor: pointer;
    
    &:hover {
        background-color: rgba(0,0,0,.2);
    }
`

const CurrentLanguage = styled.span<{ selected: boolean }>`
    &:after {
        content: url(${dropdownIconInline('#FFFFFF')});
        padding: 0 0 0 8px;
    }

    ${({ selected }) => selected ? `
        color: var(--light-green) !important;
        
        &:after {
            content: url(${dropdownIconInline('#2BB573')});
            transform: rotate(180deg);
        }

    ` : ``}
`

const CloseArea = styled.aside`
    position: fixed;
    inset: 0;
    z-index: 1;
`

const LanguageSelect = ({ location }: { location: any }) => {
    const lang = location.pathname?.match(/\w{2}\-\w{2}/)?.[0];
    const isGodView = /product-configurator/.test(location.pathname);

    const [country, language, currency, audience] = useAppSelector((state) => [state.cart.country, state.cart.language, state.cart.currency, state.general.audience]);
    const dispatch = useDispatch();
    const [current, setCurrent] = useState<LanguageSelectOption>(getOptionFromLang(lang) as LanguageSelectOption);
    const [selected, setSelected] = useState(false);

    const toggleDrawer = () => {
        setSelected(!selected);
    }

    const handleSelect = (option: LanguageSelectOption) => {
        setCurrent(option);
        setToSessionStorage(CURRENCY_PREFERENCE_KEY, option.currency);
        toggleDrawer();
    }

    const handleSwitch = () => {
        if (!current) return;

        if (country !== current?.country) {
            dispatch(setCountry(current.country));
        }

        if (currency !== current?.currency) {
            dispatch(setCurrency(current.currency));
        }

        if (language !== current?.language) {
            dispatch(setLanguage(current.language));
        }
    }

    useEffect(() => {
        if (!current) return;

        const newLang = current.language === 'EN' ? 'en-gb' : 'de-eu';
        
        if (lang !== newLang) {
            handleSwitch();

            if (typeof window !== undefined) {

                if (isGodView) {
                    window.location.pathname = `/${newLang}/product-configurator`;
                } else {
                    window.location.pathname = `/${newLang}/${audience}`;
                }
            }
        } else if (lang === newLang) {
            handleSwitch();
            // TODO: Turned off to check if necessary
            // typeof window !== undefined && window?.scrollTo(0,0);
        }
    }, [current]);

    const filteredLanguageOptions = languageOptions.filter((option) => !(
            option.country === current.country &&
            option.currency === current.currency &&
            option.language === current.language
        )
    )

    return (
        <>
            {selected && (
                <CloseArea onClick={toggleDrawer}/>
            )}
            <Selector>
                <List selected={selected}>
                    {filteredLanguageOptions.map((option) => (
                        <ListItem key={`${option.country}-${option.currency}`} onClick={() => handleSelect(option)}>
                            <Flag country={option.country} />
                            {option.country} / {getDisplayCurrency(option.currency)}
                        </ListItem>
                    ))}
                </List>
                {current && (
                    <Label selected={selected} onClick={toggleDrawer}>
                        <Flag country={current.country} />
                        <CurrentLanguage selected={selected}>{current.country} / {getDisplayCurrency(current.currency)}</CurrentLanguage>
                    </Label>
                )}
            </Selector>
        </>
    )
};

export default LanguageSelect;