import React from "react";
import styled from "styled-components";
import PlusIcon from "../../../../icons/plus-icon";
import BundleListItem from "./bundle-list-item";
import VariantBlock from "./variant-block";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 24px;
    flex-wrap: wrap;
    gap: 32px;

	@media screen and (min-width: 768px) {
        margin-top: 8px;
	}
`

const IconContainer = styled.div`
    display: none;
    align-items: center;
    height: 64px;
    margin: 0 8px;
	
	@media screen and (min-width: 768px) {
        // display: flex;
	}
`

const BundleList = ({ register, items }: any) => {

    return (
        <Container>
            {items?.map(({ product_blob }: any, index: number) => (
                <React.Fragment key={index}>
                    {index !== 0 ? (
                        <IconContainer>
                            <PlusIcon />
                        </IconContainer>
                    ) : null}
                    <BundleListItem item={product_blob} />
                </React.Fragment>
            ))}
        </Container>
    )
}

export default BundleList;