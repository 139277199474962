import SocialMediaEmbedGrid from "../../components/slices/ambassador-page/social-media-embed-grid/social-media-embed-grid"
import { Accordion, CtaTeaser, FeaturedProducts, FeatureGrid, HeroImagecloud, IconGrid, NewsletterPopUp, PageWideVideo } from "../../components/slices/generic"

export const components = {
    hero___imagecloud: HeroImagecloud,
    icon_grid: IconGrid,
    cta_teaser: CtaTeaser,
    feature_grid: FeatureGrid,
    accordion: Accordion,
    featured_products: FeaturedProducts,
    video_section: PageWideVideo,
    social_media_embed_grid: SocialMediaEmbedGrid,
    'email_pop-up': NewsletterPopUp,
}
