import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const LoopIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.4255 6.01979C25.4255 6.46674 25.7881 6.82934 26.235 6.82934C32.9318 6.82934 38.3806 12.2782 38.3806 18.975C38.3806 25.6717 32.9318 31.1206 26.235 31.1206L19.4815 31.1206L23.2886 27.3401C23.6064 27.0253 23.6076 26.5121 23.2928 26.1951C22.9776 25.8776 22.4652 25.8754 22.1477 26.1909L16.9654 31.3359C16.8057 31.4941 16.7263 31.7023 16.7263 31.9102C16.7263 32.1165 16.805 32.3225 16.9616 32.4806L22.1055 37.6637C22.4203 37.9811 22.9331 37.9834 23.2506 37.6679C23.5684 37.3531 23.5706 36.841 23.2547 36.5228L19.4999 32.7393L26.2632 32.7401C26.2666 32.7401 26.2696 32.739 26.273 32.739C33.8456 32.719 40.0005 26.5524 40.0005 18.9749C40.0005 11.3846 33.8256 5.20984 26.2354 5.20984C25.7885 5.20984 25.4255 5.57282 25.4255 6.01977L25.4255 6.01979Z"
            fill={color} />
        <path d="M17.5185 1.38036L21.273 5.16343L14.5104 5.16343C14.507 5.16343 14.504 5.16456 14.5006 5.16456C6.92797 5.18414 0.773128 11.3511 0.773128 18.9286C0.773127 26.5189 6.94802 32.6937 14.5382 32.6937C14.9851 32.6937 15.3477 32.3311 15.3477 31.8841C15.3477 31.4372 14.9851 31.0746 14.5382 31.0746C7.8414 31.0746 2.39254 25.6257 2.39254 18.929C2.39254 12.2322 7.8414 6.78332 14.5382 6.78332L21.2929 6.78295L17.4849 10.5638C17.3253 10.7219 17.2458 10.9302 17.2458 11.138C17.2458 11.3444 17.3245 11.5511 17.4812 11.7085C17.7963 12.0259 18.3088 12.0282 18.6262 11.7126L23.8086 6.5676C24.1264 6.25282 24.1275 5.74072 23.8127 5.42254L18.6688 0.239438C18.354 -0.0779868 17.8412 -0.0802425 17.5238 0.235296C17.2056 0.550456 17.2026 1.06255 17.5185 1.38074L17.5185 1.38036Z"
            fill={color} />
    </IconSvg>
);

export default LoopIcon;
