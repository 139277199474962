import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import ComparisonIcons from "../../../icons/comparison-icons";
import { SliceProps } from "../../types";
import Button from "../../../snippets/button";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setBundle } from "../../../../app/slices/product-reducer";

const Section = styled.section`
    position: relative;
    padding: 0;
    margin: 80px 0 32px;
	padding: 0 0 0 16px;

	
	@media screen and (min-width: 768px) {
        margin: 120px auto;
	}
    @media screen and (min-width: 1024px) {
        padding: 0;
    }
`

const Heading = styled.div`
    padding: 0;
    margin: 0 0 40px;
`

const InnerWrapper = styled.div<{ audience: string }>`
    overflow:auto;
`

const Table = styled.table`
    width:100%;
    border-collapse: collapse; 
    table-layout: fixed;
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    border-bottom: 1px solid #EAF0F6;    
    
    &:last-child {
        border-bottom: none;
    }
`

const TableData = styled.td<{
    highlighted: boolean,
    needsTopRoundedCorners: boolean
    needsBottomRoundedCorners: boolean
}>` ${({
    highlighted,
    needsTopRoundedCorners,
    needsBottomRoundedCorners
}) => `
        width: 289px;
        padding: 27px 20px 21px 20px;

        &:first-child {
            display: none;
        }

        ${highlighted ? `
            background: #F8F8F8;

            h5 {
                color: var(--light-green);
            }
        ` : ''}

        // ${needsTopRoundedCorners ? `
        //     border-radius: 16px 16px 0 0;
        // ` : ''}

        // ${needsBottomRoundedCorners ? `
        //     border-radius: 0 0 16px 16px;
        // ` : ''}
        
        @media screen and (min-width: 768px) {
            max-width: 360px;

            &:first-child {
                display: table-cell;
                max-width: 200px;
            }
        }
    `}
`

const IconWrapper = styled.span`
    float: left;
    margin-right: 16px;
`

const ContentWrapper = styled.div`
    display: inline-block;
    max-width: 80%;
`

const ComparisonGrid = (props: SliceProps<Queries.PrismicProductPageDataBodyComparisonGrid>) => {
    const [selectedVariant, selectedBundle] = useAppSelector(state => [state?.product?.selectedVariant, state?.product?.selectedBundle]);
    const dispatch = useAppDispatch();

    const {
        context: {
            audience,
            translations,
            page,
        },
        slice: {
            primary: {
                heading,
            },
            items
        }
    } = props;

    const onProductPage = page === 'product';

    const columns: Queries.PrismicComparisonGridColumnDataType[] = items.map(({ columns }) => {
        const { document } = columns || {};
        const { data } = document || {};
        return data as Queries.PrismicComparisonGridColumnDataType;
    });

    const highLightedColumnIndex = columns?.findIndex((column: any) => column?.highlighted);
    const rows: any[][] = [];

    columns.forEach((column) => {
        (column?.row || []).forEach((row, index: number) => {
            if (!rows[index]) rows[index] = [];
            rows[index].push(row);
        })
    })

    return (
        <Section id="compare-plans">
            <Wrapper full>
                <Heading>
                    {heading && RichText.render(heading.richText)}
                </Heading>
                <InnerWrapper audience={audience}>
                    <Table>
                        <TableBody>
                            <>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={`comp-${rowIndex}`}>
                                        {row.map((data, columnIndex) => (
                                            <TableData
                                                key={`comp-${rowIndex}-${columnIndex}`}
                                                highlighted={highLightedColumnIndex === columnIndex}
                                                needsTopRoundedCorners={
                                                    highLightedColumnIndex === columnIndex &&
                                                    rowIndex === 0
                                                }
                                                needsBottomRoundedCorners={
                                                    (!onProductPage) &&
                                                    highLightedColumnIndex === columnIndex &&
                                                    rowIndex === rows.length - 1
                                                }
                                            >
                                                {data.icon && (
                                                    <IconWrapper>
                                                        {ComparisonIcons(data.icon)}
                                                    </IconWrapper>
                                                )}

                                                <ContentWrapper>
                                                    {data?.content && RichText.render(data?.content?.richText)}
                                                </ContentWrapper>
                                            </TableData>
                                        ))}
                                    </TableRow>
                                ))}
                            </>
                            {onProductPage && (
                                <TableRow key={`comp-${rows.length + 1}`}>
                                    {columns.map((column, index): JSX.Element => {
                                        const first = index === 0;
                                        const isHighlightedTable = highLightedColumnIndex === index;
                                        const bundleIndex = index - 1 < 0 ? 0 : index - 1;
                                        const relativeBundle = (selectedVariant?.bundles || [])[bundleIndex];
                                        const id = relativeBundle?.bundle?._id;

                                        const buttonStyle = id === selectedBundle ? 'filled' : 'outline';

                                        return (
                                            <TableData
                                                key={index}
                                                highlighted={isHighlightedTable}
                                                needsTopRoundedCorners={false}
                                                needsBottomRoundedCorners={isHighlightedTable}
                                            >
                                                {!first && (
                                                    <Button
                                                        buttonFullWidth={'full'}
                                                        buttonStyle={buttonStyle}
                                                        onClick={() => dispatch(setBundle(id as string))}
                                                    >
                                                        {translations?.add_subscription}
                                                    </Button>
                                                )}
                                            </TableData>
                                        )
                                    })}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default ComparisonGrid