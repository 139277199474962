import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const PowerButtonIcon = ({ color = '#8193A8', size = '36' }: { color?: string, size?: string | number }) => (
    <IconSvg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 36 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M29.4555 9.43762C31.7202 11.7031 33.2624 14.5892 33.8869 17.7311C34.5115 20.873 34.1904 24.1295 32.9643 27.0889C31.7382 30.0483 29.6621 32.5777 26.9986 34.3572C24.335 36.1368 21.2036 37.0867 18.0003 37.0867C14.7969 37.0867 11.6655 36.1368 9.00195 34.3572C6.3384 32.5777 4.26232 30.0483 3.03621 27.0889C1.8101 24.1295 1.48903 20.873 2.11359 17.7311C2.73814 14.5892 4.28028 11.7031 6.54502 9.43762" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.0098 1.08667V19.0839" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
);

export default PowerButtonIcon;
