import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const SendIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.9989 0.529437C40.0084 0.388087 39.9564 0.249222 39.8561 0.14861C39.7554 0.0483522 39.6165 -0.00372259 39.4751 0.00584525C39.4329 -0.00194842 39.39 -0.00194842 39.3479 0.00584525H39.1891L0.353547 16.6702C0.159337 16.7623 0.0271403 16.9487 0.00410485 17.1623C-0.0217668 17.3801 0.076048 17.5945 0.258212 17.7178L13.4834 26.5101L22.2793 39.7301C22.3909 39.8934 22.5738 39.9936 22.7716 40C22.9853 39.9766 23.1713 39.8445 23.2638 39.6507L39.982 0.81524V0.656531C39.9951 0.615438 40.0004 0.572217 39.998 0.529356L39.9989 0.529437ZM36.8234 2.33862L13.8333 25.3196L1.83019 17.3842L36.8234 2.33862ZM22.661 38.1595L14.7224 26.1612L37.6654 3.18023L22.661 38.1595Z"
            fill={color} />
    </IconSvg>
);

export default SendIcon;

