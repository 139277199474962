exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-page-about-page-tsx": () => import("./../../../src/templates/about-page/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-about-page-tsx" */),
  "component---src-templates-careers-page-careers-page-tsx": () => import("./../../../src/templates/careers-page/careers-page.tsx" /* webpackChunkName: "component---src-templates-careers-page-careers-page-tsx" */),
  "component---src-templates-cart-page-cart-page-tsx": () => import("./../../../src/templates/cart-page/cart-page.tsx" /* webpackChunkName: "component---src-templates-cart-page-cart-page-tsx" */),
  "component---src-templates-contact-page-contact-page-tsx": () => import("./../../../src/templates/contact-page/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-contact-page-tsx" */),
  "component---src-templates-dentist-page-dentist-page-tsx": () => import("./../../../src/templates/dentist-page/dentist-page.tsx" /* webpackChunkName: "component---src-templates-dentist-page-dentist-page-tsx" */),
  "component---src-templates-help-centre-page-help-centre-page-tsx": () => import("./../../../src/templates/help-centre-page/help-centre-page.tsx" /* webpackChunkName: "component---src-templates-help-centre-page-help-centre-page-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/home/home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-how-it-works-page-how-it-works-page-tsx": () => import("./../../../src/templates/how-it-works-page/how-it-works-page.tsx" /* webpackChunkName: "component---src-templates-how-it-works-page-how-it-works-page-tsx" */),
  "component---src-templates-im-handel-page-im-handel-page-tsx": () => import("./../../../src/templates/im-handel-page/im-handel-page.tsx" /* webpackChunkName: "component---src-templates-im-handel-page-im-handel-page-tsx" */),
  "component---src-templates-landing-page-landing-page-tsx": () => import("./../../../src/templates/landing-page/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-tsx" */),
  "component---src-templates-magazine-blog-page-magazine-blog-page-tsx": () => import("./../../../src/templates/magazine-blog-page/magazine-blog-page.tsx" /* webpackChunkName: "component---src-templates-magazine-blog-page-magazine-blog-page-tsx" */),
  "component---src-templates-magazine-overview-page-magazine-overview-page-tsx": () => import("./../../../src/templates/magazine-overview-page/magazine-overview-page.tsx" /* webpackChunkName: "component---src-templates-magazine-overview-page-magazine-overview-page-tsx" */),
  "component---src-templates-press-and-media-page-press-and-media-page-tsx": () => import("./../../../src/templates/press-and-media-page/press-and-media-page.tsx" /* webpackChunkName: "component---src-templates-press-and-media-page-press-and-media-page-tsx" */),
  "component---src-templates-product-configurator-product-configurator-page-tsx": () => import("./../../../src/templates/product-configurator/product-configurator-page.tsx" /* webpackChunkName: "component---src-templates-product-configurator-product-configurator-page-tsx" */),
  "component---src-templates-product-overview-page-product-overview-page-tsx": () => import("./../../../src/templates/product-overview-page/product-overview-page.tsx" /* webpackChunkName: "component---src-templates-product-overview-page-product-overview-page-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/product-page/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */),
  "component---src-templates-referrals-page-referrals-page-tsx": () => import("./../../../src/templates/referrals-page/referrals-page.tsx" /* webpackChunkName: "component---src-templates-referrals-page-referrals-page-tsx" */),
  "component---src-templates-text-page-text-page-tsx": () => import("./../../../src/templates/text-page/text-page.tsx" /* webpackChunkName: "component---src-templates-text-page-text-page-tsx" */),
  "component---src-templates-thank-you-page-thank-you-page-tsx": () => import("./../../../src/templates/thank-you-page/thank-you-page.tsx" /* webpackChunkName: "component---src-templates-thank-you-page-thank-you-page-tsx" */)
}

