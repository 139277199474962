import React, { useEffect } from "react"
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { hasRichText, renderRich } from "@utils";
import Map from "./map";

const Section = styled.section`
    margin: 120px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Content = styled.div`
    @media screen and (min-width: 768px) {
        max-width: 70%;
    }
`

const Heading = styled.div`
    padding: 0 0 16px 0;

    h1 {
        font-weight: 400;
        font-size: 40px;
        line-height: 130%;
    }

    @media screen and (min-width: 768px) {
        h1 {
            font-size: 56px;
            line-height: 72.8px;
            font-weight: 400;
            text-align: left;
        }
    }
`

const Description = styled.div`
    padding: 0 0 40px 0;
    
    p   {
        font-weight: 700;
    }
`

const MapSection = (props: SliceProps<Queries.PrismicImHandelPageDataBodyMapSection>) => {
    const { slice } = props;
    const { primary } = slice;
    const { heading, description } = primary;
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);

    return (
        <Section id="map-section">
            <Wrapper>
                <Content>
                    {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                    {descriptionHasRichText && (<Description>{renderRich(descriptionRichText)}</Description>)}
                </Content>
                <Map />
            </Wrapper>
        </Section>
    )
};

export default MapSection