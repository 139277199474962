import React, { useState } from "react"
import styled from "styled-components";
import { PageContext, Settings } from "../../../slices/types";
import ProductUpsellItem from "./form-snippets/product-upsell-item";

const Article = styled.article`
	padding: 40px 0 40px;

	@media screen and (min-width: 768px) {

	}
`

const Heading = styled.div`
    padding: 0 0 16px;
`

const List = styled.ul`
	padding: 0;
`

interface ProductUpsellProps {
    title?: string | null;
    description?: string | null;
    items?: Queries.PrismicProductPageDataUpsellItems[];
    location: Record<string, unknown>;
    translations: PageContext['translations'];
    settings: Settings;
}

const ProductUpsell = ({
    title,
    description,
    items = [],
    location,
    translations,
    settings,
}: ProductUpsellProps) => {
    const hasTitle = title && title !== '';
    const hasDescription = description && description !== '';
    // @ts-ignore
    const hasItems = !!items?.length && !!items?.[0].upsell_bundle_item?.document?.data;
    const shouldShow = hasTitle || hasDescription || hasItems;

    return shouldShow ? (
        <Article>
            <Heading>
                {hasTitle  && <h3>{title}</h3>}
                {hasDescription && <p>{description}</p>}
            </Heading>
            <List>
                {items?.map(({ upsell_bundle_item, external_link_override }, i) => {
                    // @ts-ignore
                    const prismicBundle = upsell_bundle_item?.document?.data
                    const bundle = prismicBundle?.bundle as Queries.PrismicBundleDataBundleIntegrationType;
                    const itemTitle = prismicBundle?.title;
                    const itemDescription = prismicBundle?.description?.text;
                    
                    return (
                        <ProductUpsellItem
                            key={i}
                            position={i}
                            bundle={bundle}
                            externalLinkOverride={external_link_override as string}
                            title={itemTitle}
                            description={itemDescription}
                            translations={translations}
                            location={location}
                            settings={settings}
                        />
                    )
                })}
            </List>
        </Article>
    ) : null;
}

export default ProductUpsell;