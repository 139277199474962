import React, { useRef } from "react"
import styled from "styled-components";

import Wrapper from "../../../layout/wrapper";
import Testimonial from "./testimonial";

import Slider, { Settings } from "react-slick";
import { RichText } from "prismic-reactjs";

import ArrowLeft from "../../../icons/arrow-icon-left-icon";
import ArrowRight from "../../../icons/arrow-icon-right-icon";
import { SliceProps } from "../../types";

const Section = styled.section`
    padding: 0;
    margin: 80px auto 0;
    
    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
        overflow: hidden;
    }
`

const SliderWrapper = styled(Slider)`
    .slick-list {
        overflow-x: scroll;
	}
	.slick-slide {
		height: auto;
		margin: 50px 16px 0 0;
        
        &:nth-child(odd) {
            transform: translate(0, -50px);
        }
    }
	.slick-track {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
	}
    @media screen and (min-width: 768px) {
        .slick-list {
            overflow: visible;
        }
    }
`

const HeaderWrapper = styled.article`
	@media screen and (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 40px 0;
	}
`

const Heading = styled.div`
@media screen and (max-width: 768px) {
        margin: 0 0 80px;
    }`

const Pagination = styled.div`
	display: none;
	gap: 16px;

    @media screen and (min-width: 768px) {
		display: flex;
	}
`

const PaginationButton = styled.button`
	// border: 1px solid var(--light-green);
    border: none;
	border-radius: 100px;
	width: 64px;
	height: 48px;
	background: var(--light-green);

    &:hover {
        cursor: pointer;
        background: var(--black);
    }
	svg {
		width: 28px;
		height: 18px;
	}
`

const Testimonials = (props: SliceProps<Queries.PrismicHomepageDataBodyTestimonials>) => {
    const { context, slice } = props;
    const { primary, items } = slice;
    const { audience } = context;
    const { use_wide_layout } = primary;
    const sliderRef = useRef<Slider | null>(null)
    const sliderSettings: Settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: use_wide_layout ? 1.4 : 4.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.15,
                }
            }
        ]
    };

    const next = () => {
        sliderRef?.current?.slickNext();
    }

    const previous = () => {
        sliderRef?.current?.slickPrev();
    }

    return (
        <Section id="testimonials">
            <Wrapper>
                <HeaderWrapper>
                    <Heading>
                        {
                            primary?.heading?.richText &&
                            RichText.render(primary.heading.richText)
                        }
                    </Heading>
                    {items.length > 1 && (
                        <Pagination>
                            <PaginationButton type="button" onClick={previous}>
                                <ArrowLeft />
                            </PaginationButton>
                            <PaginationButton type="button" onClick={next}>
                                <ArrowRight />
                            </PaginationButton>
                        </Pagination>
                    )
                    }
                </HeaderWrapper>
            <SliderWrapper ref={sliderRef} {...sliderSettings}>
                {items && items.map((item, index: number) => (
                    <Testimonial
                        key={`${index}`}
                        audience={audience}
                        useWide={!!(use_wide_layout)}
                        item={item}
                    />
                ))}
            </SliderWrapper>
            </Wrapper>
        </Section >
    )
}

export default Testimonials
