import { langToCountry, langToLanguage } from "@utils";
import Client from "../../../../utils/playbrush-client/client";
import { Cart } from "../../../../utils/playbrush-client/types";

export const addDiscountCode = async (cart: Cart, couponCode: string, currency: string, lang: string): Promise<Cart> => {
    return new Promise(async (resolve) => {
        let payload: { lineId: string, quantity: number }[] = cart.lineItems.map((lineItem) => ({
            lineId: lineItem.lineId,
            quantity: lineItem.quantity,
            frontendLineData: JSON.stringify(lineItem.frontendLineData),
        }));

        const frontendData = {
            ...(cart.frontendData as unknown) as Record<string, unknown>,
            thankYouPage: ((cart.frontendData as unknown) as { thankYouPage: string })?.thankYouPage || '',
        };

        const newCart = await Client.addDiscount({
            itemList: payload,
            cartId: cart.cartId,
            couponCode,
            currency,
            country: langToCountry(lang),
            language: langToLanguage(lang),
            // @ts-ignore
            frontendData: JSON.stringify(frontendData),
        })

        resolve(newCart);
    })
}