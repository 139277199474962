import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from "@prismicio/react"
import { ProductSection } from "../../components/sections"
import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"
import styled from "styled-components";

import { components } from "./components"
import { BundlePB, BundlesConfig, PageContext } from "../../components/slices/types"
import ToTopButton from "../../components/snippets/to-top-button";
import { useAppSelector } from "../../app/hooks"
import { logPageView, getSanitizedBundles } from "@utils";
import linkResolver from '../../utils/prismic/link-resolver';
import { IconBanner } from "../../components/slices/product-page";

const Product = styled.section``

const ProductPage = (props: PageProps<Queries.ProductPageQuery, PageContext>) => {
    const globalAudience = useAppSelector(state => state.general.audience);
    const { data, location, pageContext } = props;
    const { allPrismicModal, prismicProductPage, allPrismicProductVariant, allPrismicHeader, prismicFooter } = data;
    const { lang, data: productPageData } = prismicProductPage || {};
    const { page_title, page_description, body, page_image, prismic_product, upsell_items, upsell_description, upsell_title, show_floating_selector } = productPageData || {};
    const bundles = getSanitizedBundles(allPrismicProductVariant.nodes as Queries.PrismicProductVariant[]);
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings, bundles: bundlesForFilter, bundlesConfig } = pageContext;
    const page = 'product';
    const pageImage = page_image?.url || '';

    const productDoc = prismic_product?.document;
    const { data: product } = (productDoc || {}) as Queries.PrismicProduct;

    useEffect(() => {
        logPageView({ content_group: page });
    }, []);

    const modalModel = allPrismicModal.nodes.find((node) => node.data.body.find((slice) => slice.slice_type === "brush_comparison_modal"));
    const brushModal = modalModel?.data.body.find((slice) => slice.slice_type === "brush_comparison_modal")?.primary;

    return (
        <Layout
            bundles={bundles}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            page={page}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={page_title || 'Product'} description={page_description || 'Product page'} lang={lang?.split('-')[0]} image={pageImage} canonicalUrl={`https://playbrush.com${location.pathname}`} />
            {/* {sliceList.map((slice: any, index: number) => sliceRenderer(slice, 'adults'))} */}
            <ProductSection
                bundlesConfig={bundlesConfig as BundlesConfig}
                bundles={bundlesForFilter as BundlePB[]}
                filterString={product?.bundle_filter as string}
                product={product}
                translations={translations}
                settings={settings}
                location={(location as unknown) as Record<string, unknown>}
                upsell={{
                    upsell_title,
                    upsell_description,
                    upsell_items: upsell_items as Queries.PrismicProductPageDataUpsellItems[],
                }}
                showFloatingSelector={show_floating_selector as boolean}
                // @ts-ignore
                slices={body}
                brushModal={brushModal}
            />
            <Product>
                <SliceZone
                    slices={body}
                    // @ts-ignore
                    components={components}
                    context={{
                        audience,
                        translations,
                        page,
                    }}
                />
                {translations?.back_to_top && <ToTopButton text={translations.back_to_top} />}
            </Product>
        </Layout>
    )
}

export const query = graphql`
    query ProductPage($id: String, $lang: String) {
        allPrismicModal(filter: {lang: {eq: $lang}}) {
            nodes {
                data {
                    body {
                        ... on PrismicModalDataBodyBrushComparisonModal {
                            id
                            slice_type
                            primary {
                                subscription_modal_content_bottom {
                                    richText
                                }
                                subscription_modal_content_top {
                                    richText
                                }
                                subscription_modal_image {
                                    gatsbyImageData
                                    alt
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicProductPage(id: {eq: $id}, lang: {eq: $lang}) {
            _previewable
            href
            url
            prismicId
            tags
            type
            lang
            uid
            data {
                page_title
                page_image {
                    url
                }
                page_description
                upsell_title
                upsell_description
                show_floating_selector
                upsell_items {
                    external_link_override
                    upsell_bundle_item {
                        document {
                            ... on PrismicBundle {
                                _previewable
                                id
                                data {
                                    title
                                    description {
                                        text
                                    }
                                    bundle {
                                        _id
                                        currency
                                        name_marketing_EN
                                        name_marketing_DE
                                        price_amount
                                        price_amount_after_trial
                                        price_amount_one_time
                                        price_amount_one_time_crossed_eur
                                        price_amount_one_time_crossed_gbp
                                        price_amount_one_time_crossed_usd
                                        price_amount_one_time_current_eur
                                        price_amount_one_time_current_gbp
                                        price_amount_one_time_current_usd
                                        price_amount_pay_after_trial_crossed_eur
                                        price_amount_pay_after_trial_crossed_gbp
                                        price_amount_pay_after_trial_crossed_usd
                                        price_amount_pay_after_trial_current_eur
                                        price_amount_pay_after_trial_current_gbp
                                        price_amount_pay_after_trial_current_usd
                                        price_amount_pay_now_crossed_eur
                                        price_amount_pay_now_crossed_gbp
                                        price_amount_pay_now_current_eur
                                        price_amount_pay_now_current_gbp
                                        price_amount_recurring
                                        price_amount_recurring_crossed_eur
                                        price_amount_recurring_current_eur
                                        price_amount_recurring_current_gbp
                                        price_amount_recurring_current_usd
                                        price_amount_recurring_crossed_usd
                                        price_amount_recurring_crossed_gbp
                                        price_amount_pay_now_current_usd
                                        price_amount_pay_now_crossed_usd
                                        price_original
                                        price_original_after_trial
                                        price_original_one_time
                                        price_original_recurring
                                        price_shipping_gbp
                                        price_shipping_eur
                                        price_shipping_usd
                                        recurring_interval
                                        recurring_interval_count
                                        recurring_interval_count_eur
                                        recurring_interval_count_gbp
                                        recurring_interval_count_usd
                                        recurring_interval_eur
                                        recurring_interval_gbp
                                        recurring_interval_usd
                                        recurring_trial_period_days
                                        recurring_trial_period_days_eur
                                        recurring_trial_period_days_gbp
                                        recurring_trial_period_days_usd
                                        savings
                                        savings_eur
                                        savings_gbp
                                        savings_usd
                                        image
                                    }
                                }
                            }
                        }
                    }
                }
                prismic_product {
                    document {
                        ... on PrismicProduct {
                            _previewable
                            id
                            data {
                                variant {
                                    product_variant {
                                        document {
                                            ... on PrismicProductVariant {
                                                _previewable
                                                id
                                                data {
                                                    stripe_variant {
                                                        _id
                                                        active
                                                        default_price
                                                        price_amount
                                                        price_original
                                                        price_shipping_eur
                                                        name
                                                        image
                                                        description
                                                        id
                                                        tags
                                                        metadata {
                                                            tags
                                                        }
                                                    }
                                                    image {
                                                        variant_image {
                                                            gatsbyImageData
                                                            alt
                                                        }
                                                    }
                                                    color
                                                    brush_type
                                                    size
                                                    bundles {
                                                        bundle {
                                                            document {
                                                                ... on PrismicBundle {
                                                                    id
                                                                    data {
                                                                        description {
                                                                            richText
                                                                        }
                                                                        images {
                                                                            image {
                                                                                gatsbyImageData
                                                                                alt
                                                                            }
                                                                        }
                                                                        title
                                                                        tags {
                                                                            border_color
                                                                            tag
                                                                        }
                                                                        manual_bundle_items {
                                                                            thumbnail_image {
                                                                                gatsbyImageData
                                                                                alt
                                                                            }
                                                                            quantity
                                                                            bundle_item_title
                                                                            bundle_item_hover_text
                                                                        }
                                                                        bundle {
                                                                            _id
                                                                            price_amount
                                                                            price_amount_after_trial
                                                                            price_original
                                                                            is_crossed_price_visible
                                                                            price_amount_one_time_crossed_eur
                                                                            price_amount_one_time_crossed_gbp
                                                                            price_amount_one_time_crossed_usd
                                                                            price_amount_one_time_current_eur
                                                                            price_amount_one_time_current_gbp
                                                                            price_amount_one_time_current_usd
                                                                            price_amount_pay_after_trial_crossed_eur
                                                                            price_amount_pay_after_trial_crossed_gbp
                                                                            price_amount_pay_after_trial_crossed_usd
                                                                            price_amount_pay_after_trial_current_eur
                                                                            price_amount_pay_after_trial_current_gbp
                                                                            price_amount_pay_after_trial_current_usd
                                                                            price_amount_pay_now_crossed_eur
                                                                            price_amount_pay_now_crossed_gbp
                                                                            price_amount_pay_now_crossed_usd
                                                                            price_amount_pay_now_current_eur
                                                                            price_amount_pay_now_current_gbp
                                                                            price_amount_pay_now_current_usd
                                                                            price_amount_recurring_crossed_eur
                                                                            price_amount_recurring_crossed_gbp
                                                                            price_amount_recurring_crossed_usd
                                                                            price_amount_recurring_current_eur
                                                                            price_amount_recurring_current_gbp
                                                                            price_amount_recurring_current_usd
                                                                            recurring_interval_count_eur
                                                                            recurring_interval_count_gbp
                                                                            recurring_interval_count_usd
                                                                            recurring_trial_period_days_gbp
                                                                            recurring_interval_eur
                                                                            recurring_interval_gbp
                                                                            recurring_interval_usd
                                                                            recurring_trial_period_days_eur
                                                                            recurring_trial_period_days_usd
                                                                            savings_eur
                                                                            savings_gbp
                                                                            savings_usd
                                                                            items_extended {
                                                                                unit_amount
                                                                                recurring_trial_period_days
                                                                                type
                                                                                recurring_interval_count
                                                                                recurring_interval
                                                                                product_blob {
                                                                                    tags
                                                                                    price_recurring_trial_period_days
                                                                                    price_recurring_interval_count
                                                                                    price_recurring_interval
                                                                                    price_original_after_trial
                                                                                    price_original
                                                                                    price_amount_after_trial
                                                                                    price_amount
                                                                                    name
                                                                                    default_price
                                                                                    image
                                                                                    description
                                                                                    active
                                                                                    metadata {
                                                                                        prod_image_corner_note_EN
                                                                                        prod_image_title_DE
                                                                                        prod_image_title_EN
                                                                                        prod_image_tooltip_text_DE
                                                                                        prod_image_tooltip_text_EN
                                                                                        prod_marketing_title_DE
                                                                                        prod_marketing_title_EN
                                                                                        prod_order_title_DE
                                                                                        prod_order_title_EN
                                                                                        tags
                                                                                        prod_description_DE
                                                                                        prod_description_EN
                                                                                        prod_image_corner_note_DE
                                                                                    }
                                                                                    _id
                                                                                    description_de
                                                                                    description_en
                                                                                }
                                                                                product
                                                                                price_default_blob {
                                                                                    active
                                                                                    currency
                                                                                    _id
                                                                                    product
                                                                                    recurring_interval
                                                                                    recurring_interval_count
                                                                                    recurring_trial_period_days
                                                                                    type
                                                                                    unit_amount
                                                                                }
                                                                                currency
                                                                                active
                                                                                _id
                                                                            }
                                                                            savings
                                                                            tags
                                                                            name_order_EN
                                                                            name_order_DE
                                                                            name_marketing_EN
                                                                            name_marketing_DE
                                                                            name
                                                                            image
                                                                            id
                                                                            currency
                                                                            recurring_interval
                                                                            recurring_trial_period_days
                                                                            price_amount_one_time
                                                                            price_amount_recurring
                                                                            price_original_after_trial
                                                                            price_original_one_time
                                                                            price_original_recurring
                                                                            price_shipping_eur
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                star_rating
                                bundle_filter
                                is_bundle
                                description {
                                    richText
                                }
                                tags {
                                    border_color
                                    tag
                                }
                                title {
                                    richText
                                    text
                                }
                                images {
                                    image {
                                      gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                }
                body {
                    ... on PrismicProductPageDataBodyIconBanner {
                        id
                        items {
                            icon
                            icon_title {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                        }
                        slice_type
                    }
                    ... on PrismicProductPageDataBodyEmailPopUp {
                        id
                        slice_type
                        primary {
                            delay
                            success_message
                            subscription_form {
                                document {
                                    ... on PrismicSubscriptionForm {
                                        id
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyComparisonGrid {
                        id
                        primary {
                            heading {
                                richText
                            }
                        }
                        slice_type
                        items {
                            columns {
                                document {
                                    ... on PrismicComparisonGridColumn {
                                        id
                                        data {
                                            highlighted
                                            row {
                                                content {
                                                    richText
                                                }
                                                icon
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyFeaturedProducts1 {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                        }
                        items {
                            column_layout
                            background_color
                            use_gradient
                            description {
                                richText
                            }
                            button_text {
                                richText
                            }
                            link {
                                url
                                document {
                                    ... on PrismicProductPage {
                                        id
                                        data {
                                            prismic_product {
                                                document {
                                                    ... on PrismicProduct {
                                                        id
                                                        data {
                                                            is_bundle
                                                            bundle_filter
                                                            variant {
                                                                product_variant {
                                                                    document {
                                                                        ... on PrismicProductVariant {
                                                                            id
                                                                            data {
                                                                                stripe_variant {
                                                                                    default_price
                                                                                    name
                                                                                    price_amount
                                                                                    price_amount_pay_now_current_eur
                                                                                    price_amount_pay_now_current_gbp
                                                                                    price_amount_pay_now_current_usd
                                                                                    id
                                                                                    _id
                                                                                    price_original
                                                                                }
                                                                                bundles {
                                                                                    bundle {
                                                                                        document {
                                                                                            ... on PrismicBundle {
                                                                                                id
                                                                                                data {
                                                                                                    bundle {
                                                                                                        price_amount_one_time_current_eur
                                                                                                        price_amount_one_time_current_gbp
                                                                                                        price_amount_one_time_current_usd
                                                                                                        price_amount
                                                                                                        name
                                                                                                        price_original
                                                                                                        _id
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            price {
                                richText
                            }
                            product_image {
                                gatsbyImageData
                            }
                            product_title {
                                richText
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyFeatureGrid {
                        id
                        items {
                            image {
                                alt
                                gatsbyImageData
                            }
                            feature {
                                richText
                            }
                            title {
                                richText
                            }
                            subtitle {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                        }
                        slice_type
                    }
                    ... on PrismicProductPageDataBodyAppSection {
                        id
                        slice_type
                        items {
                            grid_item {
                                document {
                                    ... on PrismicVideoGridItem {
                                        id
                                        data {
                                            is_video
                                            icon
                                            video
                                            title {
                                                richText
                                            }
                                            image {
                                                alt
                                                gatsbyImageData
                                            }
                                            description {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        primary {
                            button_text {
                                richText
                            }
                            button_url {
                                url
                            }
                            description {
                                richText
                            }
                            heading {
                                richText
                            }
                            subheading {
                                richText
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyBrushFeatures {
                        id
                        slice_type
                        primary {
                            button_text {
                                richText
                            }
                            button_url {
                                url
                                target
                            }
                            description {
                                richText
                            }
                            heading {
                                richText
                            }
                            image {
                                gatsbyImageData
                                alt
                            }
                            mobile_image {
                                gatsbyImageData
                                alt
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyVideoSection {
                        id
                        slice_type
                        primary {
                            flair {
                                gatsbyImageData
                                alt
                            }
                            video {
                                document {
                                    ... on PrismicVideo {
                                        id
                                        data {
                                            loop
                                            mute_audio
                                            plays_inline
                                            src
                                            youtube_embed_url
                                            use_autoplay
                                            placeholder_image {
                                                alt
                                                gatsbyImageData
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyFeaturedBrushes {
                        id
                        slice_type
                        primary {
                            background_color
                            heading {
                                richText
                            }
                        }
                        items {
                            description {
                                richText
                            }
                            product_image {
                                gatsbyImageData
                                alt
                            }
                            product_title {
                                richText
                            }
                            link {
                                target
                                url
                            }
                            button_text {
                                richText
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyTestimonials {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                            use_wide_layout
                        }
                        items {
                            testimonial {
                                document {
                                    ... on PrismicTestimonials {
                                        id
                                        data {
                                        avatar {
                                            alt
                                            gatsbyImageData
                                        }
                                        dental_professional
                                        links {
                                            link_text {
                                                richText
                                            }
                                            link_url {
                                            target
                                            url
                                            }
                                        }
                                        quote {
                                            richText
                                        }
                                        quoted_person
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicProductPageDataBodyAccordion {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                            description {
                                richText
                            }
                            link {
                                url
                            }
                            button_text {
                                richText
                            }
                        }
                        items {
                            accordion_description {
                                richText
                            }
                            accordion_title
                        }
                    }
                    ... on PrismicProductPageDataBodyPlainText {
                        id
                        slice_type
                        primary {
                            text {
                                richText
                            }
                            full_width
                        }
                    }
                }
            }
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_original_after_trial
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                tags
                                                price_recurring_trial_period_days
                                                price_recurring_interval_count
                                                price_recurring_interval
                                                price_original_after_trial
                                                price_original
                                                price_amount_after_trial
                                                price_amount
                                                name
                                                default_price
                                                image
                                                description
                                                active
                                                metadata {
                                                    prod_image_corner_note_DE
                                                    prod_image_corner_note_EN
                                                    prod_image_title_DE
                                                    prod_image_title_EN
                                                    prod_image_tooltip_text_DE
                                                    prod_image_tooltip_text_EN
                                                    prod_marketing_title_DE
                                                    prod_marketing_title_EN
                                                    prod_order_title_DE
                                                    prod_order_title_EN
                                                    tags
                                                }
                                                _id
                                                }
                                                product
                                                price_default_blob {
                                                active
                                                currency
                                                _id
                                                product
                                                recurring_interval
                                                recurring_interval_count
                                                recurring_trial_period_days
                                                type
                                                unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        menu_bundle_type_preselect
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        bundle_preselect_index
                                        button_bundle_type_preselect
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                            bundle_type_preselect
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(
    ProductPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
    }]
);