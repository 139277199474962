import { FeaturedProducts, TwoImageGrid, CtaTeaser, NewsletterPopUp } from "../../components/slices/generic"

import { FeaturedArticles } from "../../components/slices/magazine-overview-page"
import { TwoColumnBlog, BlogTextSection, BlogVideo } from "../../components/slices/magazine-blog-page"

export const components = {
    featured_products_1: FeaturedProducts,
    related_articles: FeaturedArticles,
    two_images_grid: TwoImageGrid,
    cta_teaser: CtaTeaser,
    plain_text: BlogTextSection,
    two_column_blog: TwoColumnBlog,
    text___video: BlogVideo,
    'email_pop-up': NewsletterPopUp,
}
