import React, { useState } from "react"
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';

import MinusIcon from "../../../icons/minus-icon";
import PlusIcon from "../../../icons/plus-icon";
import ThumbsUp from "../../../icons/thumbs-up-icon";
import ThumbsDown from "../../../icons/thumbs-down-icon";

import Button from "../../../snippets/button";
import { SliceProps } from "../../types";

import { getCookie, setCookie, hasRichText, renderRich } from "@utils";
import linkResolver from '../../../../utils/prismic/link-resolver';

import Wrapper from "../../../layout/wrapper";

const Section = styled.section<{ hasPrimary: boolean }>`
    ${({ hasPrimary }) => `
        margin: 0 auto;
        max-width: 1440px;
        padding: 80px 0 0;

        // @media screen and (min-width: 768px) {
        //     padding: 120px 80px 0;
        // }

        ${hasPrimary ? `
            @media screen and (min-width: 1024px) {
                display: grid;
                grid-template-columns: 340px auto;
            }
        ` : ''}
    `}
`

const TextContainer = styled.div`
    width: 100%;
    
    @media screen and (min-width: 768px) {
        width: auto;
        padding: 0 90px 40px 0;
    }
`

const AccordionContainer = styled.div`
    margin: 30px 0 0;
`

const Heading = styled.div`
    margin: 0 0 24px;
    
    h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 150%;
    }

    @media screen and (min-width: 768px) {
        h2 {
            font-size: 56px;
        }
    }
`

const Description = styled.div`
    margin: 0 0 24px;

    p {
        font-weight: 400;
        line-height: 150%;
        font-size: 16px;
    }

    @media screen and (min-width: 768px) {
        p {
            font-size: 24px;
        }
    }
`

const AccordionItem = styled.article`
    padding: 35px 0;
    border-top: 2px solid var(--light-green);

    &:last-child {
        border-bottom: 2px solid var(--light-green);
    }
`

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

const ItemHeading = styled.h4`
    flex: 1 1 auto;
    font-size: 14px;
`

const ItemIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 100px;
    border: 1px solid var(--light-green);
    width: 42px;
    height: 42px;
    flex: 0 0 42px;
`

const VoteButton = styled.button`
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
`

const ItemContent = styled.div<{ isActive: boolean }>`
    ${({ isActive }) => !isActive ? `
        display: none;
    ` : ''}
`

const FaqDescription = styled.div`
    padding: 32px 0 36px 0;
    width: 100%;
`

const Feedback = styled.div`
    display: flex;
    column-gap: 15px;
`

const Helpful = styled.span`
    font-weight: 700;
`

const convertTitle = (title: string): string => {
    return title.toLowerCase().replace(/[\s\?\!\@\#\$\%\^\&\'\"\*\(\)\:\;\<\>\[\]\{\}\+\=\`\~]/gm, '');
}

const getVoteId = (str: string, index: number): string => {
    return `_vote_${convertTitle(str)}-${index}`
}

// const parseVoteID = (voteId: string): [string, string] => {
//     const [id, encodedTitle] = voteId.split('_vote_');
//     return [id, decodeURI(encodedTitle)];
// }

const Accordion = (props: SliceProps<Queries.PrismicProductPageDataBodyAccordion>) => {
    const { context: { audience, translations }, slice: { id, items, primary } } = props;
    const { heading, description, link, button_text } = primary;
    const [activeItems, setActiveItems] = useState<number[]>([]);
    const [hasUpdated, setHasUpdated] = useState<number>(0);
    const hasPrimary = !!(heading?.richText || description?.richText || link?.url || button_text?.richText);

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
    const [buttonHasRichText, buttonRichText] = hasRichText(button_text?.richText);

    const handleUpVote = (title: string, index: number) => {
        const voteId = getVoteId(title, index);
        setCookie(voteId, 'upvote');
        setHasUpdated(hasUpdated + 1);
    }

    const handleDownVote = (title: string, index: number) => {
        const voteId = getVoteId(title, index);
        setCookie(voteId, 'downvote');
        setHasUpdated(hasUpdated + 1);
    }

    const handleOpening = (n: number, id: string) => {
        let newArray: number[];
        if (activeItems.includes(n)) {
            newArray = activeItems.filter(n2 => n2 !== n);
        } else {
            newArray = [...activeItems, n];
        }
        setActiveItems(newArray);
    }

    return (
        <Wrapper>
            <Section hasPrimary={hasPrimary}>
                {heading && hasPrimary && (
                    <TextContainer>
                        {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                        {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                        {link?.url && buttonHasRichText && (
                            <Button url={link.url || ''}
                                buttonStyle={'outline'}
                                buttonFullWidth={''}
                            >
                                {renderRich(buttonRichText)}
                            </Button>
                        )}
                    </TextContainer>
                )}
                <AccordionContainer>
                    {items && items.map((item, index: number) => {
                        const voteId = getVoteId((item.accordion_title || '') as string, index);
                        const cookie = getCookie(voteId);

                        return (
                            <AccordionItem
                                id={`accordion-item-${index}`}
                                key={`${item.accordion_title}-${index}`}
                            >
                                <ItemHeader
                                    onClick={() => handleOpening(index, `accordion-item-${index}`)}
                                >
                                    {item.accordion_title && (
                                        <ItemHeading>
                                            {item.accordion_title}
                                        </ItemHeading>
                                    )}
                                    <ItemIcon>
                                        {activeItems.includes(index) ? (
                                            <MinusIcon />
                                        ) : (
                                            <PlusIcon />
                                        )
                                        }
                                    </ItemIcon>
                                </ItemHeader>

                                <ItemContent isActive={activeItems.includes(index)}>
                                    {item.accordion_description && item.accordion_description.richText && (
                                        <FaqDescription className="rt">
                                            <RichText render={item.accordion_description.richText} linkResolver={linkResolver} />
                                        </FaqDescription>
                                    )}
                                    <Feedback>
                                        {!!cookie ? (
                                            <Helpful>
                                                {translations?.thank_you_for_your_feedback}
                                            </Helpful>
                                        ) : (
                                            <>
                                                <Helpful>
                                                    {translations?.was_this_helpful}
                                                </Helpful>
                                                <VoteButton onClick={() => handleUpVote((item.accordion_title || '') as string, index)}>
                                                    <ThumbsUp />
                                                </VoteButton>
                                                <VoteButton onClick={() => handleDownVote((item.accordion_title || '') as string, index)}>
                                                    <ThumbsDown />
                                                </VoteButton>
                                            </>
                                        )}
                                    </Feedback>
                                </ItemContent>
                            </AccordionItem>
                        )
                    })}
                </AccordionContainer>
            </Section>
        </Wrapper>
    )
};

export default Accordion