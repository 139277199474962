import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg``

const FacebookShare = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.30376 0C3.2578 0 0 3.2578 0 7.30376V32.6963C0 36.7422 3.2578 40 7.30376 40H21.0663V24.3625H16.9313V18.7325H21.0663V13.9225C21.0663 10.1435 23.5094 6.67376 29.1375 6.67376C31.4162 6.67376 33.1013 6.89252 33.1013 6.89252L32.9688 12.15C32.9688 12.15 31.2502 12.1338 29.375 12.1338C27.3454 12.1338 27.02 13.0689 27.02 14.6213V18.7326H33.13L32.8638 24.3626H27.02V40.0001H32.6962C36.7422 40.0001 40 36.7423 40 32.6963V7.3038C40 3.25784 36.7422 4e-05 32.6962 4e-05H7.30372L7.30376 0Z"
            fill={color}
        />
    </IconSvg>
);

export default FacebookShare;
