import React, { useState } from "react"
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCart } from "../../../../app/slices/cart-reducer";
import Client from "../../../../utils/playbrush-client/client";
import { PageContext } from "../../../slices/types";
import LineItem from "./line-item/line-item";
import { getThankYouPage, langToCountry, langToLanguage } from "@utils";

const CartOverview = styled.div`
    flex: 1 1 auto;
    margin: 0 0 80px 0;
`

const CartHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
`

const CartHeading = styled.h1`
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
`
const ItemCounter = styled.div`
    font-size: 24px;
    line-height: 36px;
`

const DeletedItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #E4E4E4;
    border-radius: 16px;
    padding: 40px 18px;
    margin: 24px 0 0 0;

    @media screen and (min-width: 768px) {
        padding: 65px 0;
        margin: 120px 0;
    }
`

const DeletedItemName = styled.p`
    width: 100%;
    text-align: center;
    padding: 0 0 8px 0;
`

const UndoButton = styled.button`
    color: var(--light-green);
    text-decoration: underline;
    background-color: transparent;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
`

export interface OldProduct {
    title?: string,
    lineId?: string,
    audience?: string,
}

interface CartListProps {
    data: Queries.PrismicCartPageDataType;
    translations: PageContext['translations'];
    settings: PageContext['settings'];
}

const CartList = ({ data, translations, settings }: CartListProps) => {
    const [cart, lang] = useAppSelector(state => [state.cart, state.general.lang]);
    const dispatch = useAppDispatch();
    const [oldProduct, setOldProduct] = useState<OldProduct>();

    const undo = async () => {
        let payload: { lineId: string, quantity: number }[] = cart.lineItems.map((lineItem) => ({
            lineId: lineItem.lineId,
            quantity: lineItem.quantity,
            frontendLineData: JSON.stringify(lineItem.frontendLineData),
        }));

        oldProduct?.lineId && payload.push({ lineId: oldProduct.lineId, quantity: 1 });

        const frontendData = {
            ...(cart.frontendData as unknown) as Record<string, unknown>,
            thankYouPage: getThankYouPage(
                cart.lineItems,
                settings,
                oldProduct?.audience,
            ),
        };

        const newCart = await Client.addProduct({
            itemList: payload,
            cartId: cart.cartId,
            couponCode: cart.couponCode,
            frontendData: JSON.stringify(frontendData),
            currency: cart.currency,
            language: langToLanguage(lang),
            country: langToCountry(lang),
        })

        dispatch(setCart(newCart))
        setOldProduct(undefined);
    }

    const handleRemove = (product: OldProduct) => setOldProduct(product);

    return (
        <CartOverview>
            <CartHeader>
                <CartHeading>{data?.title}</CartHeading>
                <ItemCounter>{cart.lineItemCount} {cart.lineItemCount === 1 ? data?.item : data?.items}</ItemCounter>
            </CartHeader>
            {cart.lineItems.map((item) => (
                <LineItem
                    key={`${item.lineId}`}
                    lineItem={item}
                    translations={translations}
                    settings={settings}
                    onRemove={handleRemove}
                />
            ))}
            {!!oldProduct?.lineId && (
                <DeletedItem>
                    {/* <DeletedItemName>{oldProduct.title} was removed from your cart</DeletedItemName>
                    <UndoButton type="button" onClick={undo}>Undo</UndoButton> */}
                    <DeletedItemName>{oldProduct.title} {translations.was_removed_from_your_cart}</DeletedItemName>
                    <UndoButton type="button" onClick={undo}>{translations.undo_remove_from_cart}</UndoButton>
                </DeletedItem>
            )}
        </CartOverview>
    )
};

export default CartList;