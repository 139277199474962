import {
    Hero,
    MediaSlider,
    TextVideo,
} from '../../components/slices/home';

import {
    FeaturedProducts,
    PageWideVideo,
    Testimonials,
    NewsletterPopUp,
    HorizontalScroll,
    TextImageRight,
    TrustpilotCompanyReviews,
    Countdown,
} from '../../components/slices/generic';

export const components = {
    hero: Hero,
    featured_products: FeaturedProducts,
    media_slider: MediaSlider,
    text___video: TextVideo,
    video_section: PageWideVideo,
    testimonials: Testimonials,
    horizontal_scroll: HorizontalScroll,
    text_image_right: TextImageRight,
    countdown: Countdown,
    trustpilot_company_reviews: TrustpilotCompanyReviews,
    'email_pop-up': NewsletterPopUp,
}
