import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const PlusIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5V19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 12H19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconSvg>
);

export default PlusIcon;
