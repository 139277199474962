export const isDark = (hex: string) => {
    const color = hex.substring(1);      // strip #
    const rgb = parseInt(color, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    // var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    const luma = (r + g + b) / 3;

    if (luma < 128) {
        return true;
    } else {
        return false;
    }
}