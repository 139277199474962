import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import styled from "styled-components";

const Tags = styled.article`
    display: flex;
	
	@media screen and (min-width: 768px) {

	}
`

const Tag = styled.p<{ borderColor: string }>`
    ${({ borderColor = 'var(--light-green)' }) => `
        display: flex;
        align-items: center;
        border: 1px solid ${borderColor};
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 24px;
        margin-right: 8px;
        text-align: center;
        line-height: 16px;
    `}
`

const TagSection = ({
    locale,
    tags = [],
}: {
    locale: string,
    tags: {
        colour?: string,
        text?: string,
        lang?: string,
    }[]
}) => {
    const language = useAppSelector(state => state.cart.language);

    return (
        <Tags>
            {tags.reduce((array, { colour, lang, text }, index): any => {
                return (language === lang ? [...array, (
                    <Tag key={`${text?.toLowerCase()}-${index}`} borderColor={colour || "var(--light-green)"}>
                        {text}
                    </Tag>
                )] : array)
            }, [])}
        </Tags>
    )
}

export default TagSection;