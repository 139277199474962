import { LineItem } from "../../playbrush-client/types";

export const splitLineItemContent = (lineItemContent: LineItem['lineContent']) => {
    const hardware = lineItemContent.find((content) => {
        return (
            (
                content.tags.includes('toothbrush') ||
                content.tags.includes('brush_heads')
            ) && !content.tags.includes('subscription')
        )
    });

    const subscriptionItems = lineItemContent.filter((content) => content.product !== hardware?.product && !content.tags.includes("gift"));
    
    const giftItem = lineItemContent.find((content) => content.tags?.includes("gift"));

    return {
        hardware,
        subscriptionItems,
        giftItem,
    }
}