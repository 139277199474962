import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ArrowRight = ({ color = "white" }: { color?: string }) => (
    <IconSvg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 9.99971C0 9.7345 0.105357 9.48014 0.292893 9.29261C0.48043 9.10507 0.734784 8.99971 1 8.99971L24.586 8.99971L18.292 2.70771C18.1042 2.51994 17.9987 2.26526 17.9987 1.99971C17.9987 1.73416 18.1042 1.47949 18.292 1.29171C18.4798 1.10394 18.7345 0.998451 19 0.998451C19.2656 0.998451 19.5202 1.10394 19.708 1.29171L27.708 9.29171C27.8011 9.38461 27.875 9.49496 27.9254 9.61645C27.9758 9.73794 28.0018 9.86818 28.0018 9.99971C28.0018 10.1312 27.9758 10.2615 27.9254 10.383C27.875 10.5045 27.8011 10.6148 27.708 10.7077L19.708 18.7077C19.5202 18.8955 19.2656 19.001 19 19.001C18.7345 19.001 18.4798 18.8955 18.292 18.7077C18.1042 18.5199 17.9987 18.2653 17.9987 17.9997C17.9987 17.7342 18.1042 17.4795 18.292 17.2917L24.586 10.9997L1 10.9997C0.734784 10.9997 0.48043 10.8944 0.292893 10.7068C0.105357 10.5193 0 10.2649 0 9.99971Z"
            fill={color}
        />
    </IconSvg>

);

export default ArrowRight;
