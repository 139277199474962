import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BundlePB } from '../../components/slices/types';

export interface IState {
    bundles: BundlePB[];
    disallowedTags: string[]
    productType: string;
    hardwareType?: string;
    colors?: string[];
    brushes?: string[];
    sizes?: string[];
    selectedBundle?: string;
    selectedColor?: string;
    selectedBrush?: string;
    selectedSize?: string;
}

const initialState: IState = {
    bundles: [],
    disallowedTags: [],
    productType: '',
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setColor: (state, action: PayloadAction<string>) => {
            return {...state, selectedColor: action.payload};
        },
        setBrush: (state, action: PayloadAction<string>) => {
            return {...state, selectedBrush: action.payload};
        },
        setSize: (state, action: PayloadAction<string>) => {
            return {...state, selectedSize: action.payload};
        },
        setBundle: (state, action: PayloadAction<string>) => {
            return {...state, selectedBundle: action.payload};
        },
        setBundles: (state, action: PayloadAction<BundlePB[]>) => {
            return {...state, bundles: action.payload};
        },
        setInitialState: (state, action: PayloadAction<Omit<IState, 'bundles'>>) => {
            return {...state, ...action.payload};
        },
    }
})

export const {
    setColor,
    setBrush,
    setSize,
    setBundle,
    setBundles,
    setInitialState,
} = filterSlice.actions

export default filterSlice.reducer