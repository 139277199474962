import React from "react"
import styled from "styled-components";

import Button from "../../button";
import { useAppSelector } from "../../../../app/hooks";
import { PageContext } from "../../../slices/types";
import { getTotalPriceFromCart } from "@utils";

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F8F8F8;
    padding: 16px;
    row-gap: 16px;
    z-index: 99;

    @media screen and (min-width: 768px) {
        position: relative;
        width: 100%;
        padding: 0;
    }
`

const TotalTranslation = styled.span`
    margin: 0 5px 0 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
`

const TotalVat = styled.span`
    font-size: 12px;
`

const TotalPrice = styled.p`
    flex: 1 0 auto;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
`

const OrderSummaryFooter = ({ data, translations }: {
    data: Queries.PrismicCartPageDataType,
    translations: PageContext['translations'],
}) => {
    const cart = useAppSelector(state => state.cart);
    const totalPrice = getTotalPriceFromCart(cart);

    return (
        <Footer>
            <div>
                <TotalTranslation>{translations.checkout.total}</TotalTranslation>
                <TotalVat>{translations.checkout.inc_tax}</TotalVat>
            </div>
            <TotalPrice>{totalPrice}</TotalPrice>
            <Button
                url={process.env.GATSBY_CHECKOUT_URL ? `${process.env.GATSBY_CHECKOUT_URL}/${cart.cartId}` : `https://checkout.playbrush.com/checkout/${cart.cartId}`}
                buttonStyle={"solid"}
                buttonFullWidth="full"
            >
                {data?.go_to_checkout}
            </Button>
        </Footer>
    )
};

export default OrderSummaryFooter;