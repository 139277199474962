import { userTimeZoneToCountryCode } from "@utils";
import Client from "../../../../utils/playbrush-client/client";
import { Cart } from "../../../../utils/playbrush-client/types";

export const removeDiscountCode = async (
    cart: Cart,
    callBack: (cart: Cart) => void,
) => {
    let payload: { lineId: string, quantity: number }[] = cart.lineItems.map((lineItem) => ({
        lineId: lineItem.lineId,
        quantity: lineItem.quantity,
        frontendLineData: JSON.stringify(lineItem.frontendLineData),
    }));

    const frontendData = {
        ...(cart.frontendData as unknown) as Record<string, unknown>,
        thankYouPage: ((cart.frontendData as unknown) as { thankYouPage: string })?.thankYouPage || '',
    };

    const newCart = await Client.addDiscount({
        itemList: payload,
        cartId: cart.cartId,
        couponCode: '',
        country: userTimeZoneToCountryCode(),
        // @ts-ignore
        frontendData: JSON.stringify(frontendData),
    })

    return callBack(newCart);
}