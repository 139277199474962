import React, { useState } from 'react'
import styled from "styled-components";
import MinusIcon from "../../../../icons/minus-icon";
import PlusIcon from "../../../../icons/plus-icon";
import ThumbsUp from "../../../../icons/thumbs-up-icon";
import ThumbsDown from "../../../../icons/thumbs-down-icon";
import { hasRichText, renderRich } from "../../../../../utils/common/rich-text";
import Button from "../../../../snippets/button";
import { RichText } from "prismic-reactjs";
import { getCookie, setCookie } from "@utils";
import { PageContext } from '../../../types';
import { getAccordionItemFeedbackId } from '../utils';
import linkResolver from '../../../../../utils/prismic/link-resolver';

const Article = styled.article`
    padding: 35px 0;
    border-top: 2px solid var(--light-green);

    &:last-of-type {
        border-bottom: 2px solid var(--light-green);
    }
`

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 16px;
`

const ItemHeading = styled.h4`
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 24px;
`

const ItemIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 100px;
    border: 1px solid var(--light-green);
    width: 42px;
    height: 42px;
    flex: 0 0 42px;
`

const ItemContent = styled.div<{ isActive: boolean }>`
    max-width: 736px;
    ${({ isActive }) => !isActive ? `
        display: none;
    ` : ''}
`

const FaqDescription = styled.div`
    padding: 32px 0 18px 0;
    width: 100%;
    
    p.block-img {
        display: inline-flex;
        padding: 28px 16px 0 0;
    }
    
    img {
        max-height: 235px;
    }

    iframe {
        width: 100%;
        height: 400px;
        margin: 28px 0;
    }
`

const Feedback = styled.div`
    margin: 36px 0 0;
    display: flex;
    column-gap: 15px;
`

const Helpful = styled.span`
    font-weight: 700;
`

const VoteButton = styled.button`
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
`

interface AccordionItemProps {
    translations?: PageContext['translations'];
    article: Queries.PrismicCareersPageDataBodyAccordionWithFiltersItem;
    index: number;
    hideFeedback: boolean;
}

const AccordionItem = ({
    translations,
    article,
    index,
    hideFeedback,
}: AccordionItemProps) => {
    const [buttonHasRichText, buttonRichText] = hasRichText(article.button_text?.richText);
    const [accordionDescriptionHasRichText, accordionDescriptionRichText] = hasRichText(article.accordion_description?.richText);
    const [hasUpdated, setHasUpdated] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    const voteId = getAccordionItemFeedbackId((article.accordion_title || '') as string, index);
    const cookie = getCookie(voteId);

    const handleUpVote = (title: string, index: number) => {
        const voteId = getAccordionItemFeedbackId(title, index);
        setCookie(voteId, 'upvote');
        setHasUpdated(hasUpdated + 1);
    }

    const handleDownVote = (title: string, index: number) => {
        const voteId = getAccordionItemFeedbackId(title, index);
        setCookie(voteId, 'downvote');
        setHasUpdated(hasUpdated + 1);
    }

    const handleOpening = () => {
        setOpen(!open);
    }

    return (
        <Article key={`${article.accordion_title}-${index}`}>
            <ItemHeader onClick={handleOpening}>
                {article.accordion_title && (
                    <ItemHeading>
                        {article.accordion_title}
                    </ItemHeading>
                )}

                <ItemIcon>
                    {open ? (
                        <MinusIcon />
                    ) : (
                        <PlusIcon />
                    )
                    }
                </ItemIcon>
            </ItemHeader>
            <ItemContent isActive={open}>
                {accordionDescriptionHasRichText && (
                    <FaqDescription className="rt">
                        <RichText render={accordionDescriptionRichText} linkResolver={linkResolver} />
                    </FaqDescription>
                )}
                {article.button_link && article.button_link?.url && buttonHasRichText && (
                    <Button
                        buttonStyle={'solid'}
                        buttonFullWidth={'full-mobile'}
                        url={article.button_link?.url || ''}
                    >
                        {buttonHasRichText && renderRich(buttonRichText)}
                    </Button>
                )
                }
                {hideFeedback && (
                    <Feedback>
                        {!!cookie ? (
                            <Helpful>
                                {translations?.thank_you_for_your_feedback}
                            </Helpful>
                        ) : (
                            <>
                                <Helpful>
                                    {translations?.was_this_helpful}
                                </Helpful>
                                <VoteButton onClick={() => handleUpVote((article.accordion_title || '') as string, index)}>
                                    <ThumbsUp />
                                </VoteButton>
                                <VoteButton onClick={() => handleDownVote((article.accordion_title || '') as string, index)}>
                                    <ThumbsDown />
                                </VoteButton>
                            </>
                        )}
                    </Feedback>
                )}
            </ItemContent>
        </Article>
    )
};

export default AccordionItem;