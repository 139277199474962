import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import { WebStorage } from 'redux-persist/lib/types';
import { persistReducer, persistStore } from 'redux-persist';

import generalReducer from './slices/general-reducer'
import cartReducer, { setInitialState } from './slices/cart-reducer'
import bundlesReducer from './slices/bundles-reducer'
import productReducer from './slices/product-reducer'
import filterReducer from './slices/filter-reducer'
import Client from "../utils/playbrush-client/client";

export function createPersistStorage(): WebStorage {
    const isServer = typeof window === 'undefined';
  
    // Returns noop (dummy) storage.
    if (isServer) {
      return {
        getItem() {
          return Promise.resolve(null);
        },
        setItem() {
          return Promise.resolve();
        },
        removeItem() {
          return Promise.resolve();
        },
      };
    }
  
    return createWebStorage('local');
}

const reducers = combineReducers({
    general: generalReducer,
    cart: cartReducer,
    bundles: bundlesReducer,
    product: productReducer,
    filter: filterReducer,
})

const persistConfig = {
    key: process.env.BUILD || 'production',
    blacklist: ['bundles', 'general', 'filter'],
    storage: createPersistStorage(),
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleWare) => getDefaultMiddleWare({
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store, null, async () => {
    const { cart } = store.getState();
    const response = await Client.checkCart(cart as any);
    if (!(response?.cart?.cartId)) store.dispatch(setInitialState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
