import React, { useState, useRef } from "react"
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { GatsbyImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from "framer-motion";

import Wrapper from '../../../layout/wrapper';
import { SliceProps } from "../../types";
import { isMobile } from "@utils";
import Button from "../../../snippets/button";

const Section = styled.section`
    height: auto;
    position: relative;
    margin: 120px 0 0;
    
    @media screen and (min-width: 768px) {
        margin: 0 auto;
        padding: 24px 0 48px;
    }
`

const AnimationWrapper = styled.div`
    position: sticky;
    top: 0px;
    padding: 0 0 40px;

    @media screen and (min-width: 768px) {
        top: 40px;
        padding: 120px 0 0;
    }
}
`

const ExtendedWrapper = styled.div`
    // overflow: hidden;
    // padding: 0;
`

const articleStyling = `
    display: grid;
    grid-template-columns: auto;

    right: 0;
    left: 0;

    border-radius: 16px;
    // overflow: hidden;
    top: 0;

    @media screen and (min-width: 768px) {
        grid-template-columns: auto 0;
        grid-column-gap: 80px;
        // margin: 0 0 32px;
    }
    @media screen and (min-width: 1024px) {
        grid-template-columns: auto 425px;
    }
`

const InnerArticle: any = styled.div<{ bgc: string }>`
    ${articleStyling}

    min-width: 80%;
    overflow: hidden;

    ${({ bgc }) => bgc ? `
            background-color: ${bgc};
        `: ''}
`
const AnimatedInnerArticle: any = styled(motion.div)`${articleStyling}`

const Article: any = styled.article<{ bgc: string }>`
    position: relative;
    overflow: hidden;
    
    * {
        color: white;
        background-color: var(--light-green);
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-wrap: nowrap;
        align-items: start;
        width: 100%;
        overflow-x: scroll;

        ${InnerArticle} {
            margin-left: 16px;

            &:last-child {
                margin-right: 16px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        border-radius: 16px;
        ${({ bgc }) => bgc ? `
            // background-color: ${bgc};
            background-color: var(--light-green);
        `: ''}
    }
`

const ImageWrapper = styled.picture<{ audience: string }>`
    padding: 24px;
    
    // @media screen and (max-width: 375px) {
    //     display: none;
    //     img {
    //         display: none;
    //         // border-radius: 16px 16px 0 0;
    //     }
    // }
    

    // @media screen and (min-width: 768px) {
    //     img {
    //         display: block;
    //     }
    // }

    ${({ audience }) => `
        ${audience === 'kids' ? `
            overflow: hidden;
        ` : ''}
    `}
`

const BodyWrapper = styled.div`
    padding: 24px;

    @media screen and (min-width: 768px) {
        grid-row: 1 / 2;
        padding: 64px 0 64px 64px;
    }
`

// const Image: any = styled.img``;
// const Video: any = styled.video``

const RichHeading: any = styled.div`
    margin: 0 0 16px;
`

const RichSubHeading: any = styled.div`
    margin: 0 0 16px 0;
`

const RichBody: any = styled.div``

const Navigation = styled.nav`
    display: none;
    margin: 24px 0 0;
    gap: 16px;

    * {
        flex: 0 1 25%;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-evenly;
    }
`

// const NavBlock = styled.div`
//     padding: 0 16px 0 0;

//     &:last-child {
//         padding: 0;
//     }
// `;

// const NavTitle = styled.h5`
//     margin: 0 0 12px;
// `;

// const NavTrack = styled.div`
//     height: 2px;
//     background-color: rgba(205, 212, 220, 2);
// `;

// const NavPercentage = styled.div<{ percentage: number }>`
//     height: 100%;
//     background-color: var(--light-green);
//     width: ${({ percentage }) => percentage}%;
//     transition: all 500ms ease;
// `;


const HorizontalScroll = (props: SliceProps<Queries.PrismicHomepageDataBodyHorizontalScroll>) => {
    const onMobile = isMobile();
    const containerRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [percentage, setPercentage] = useState(0);
    const { context: { audience }, slice: { primary: { heading }, items = [] } } = props;
    // const itemCount = items.length;

    // const getValues = () => {
    //     const { height: parentHeight }: any = containerRef?.current?.getBoundingClientRect() || { height: 0 };
    //     const { offsetTop: parentOffset }: any = containerRef?.current || { offsetTop: 0 };
    //     const { height: childHeight }: any = wrapperRef?.current?.getBoundingClientRect() || { height: 0 };

    //     const scrollableHeight = Math.round(parentHeight - childHeight);
    //     const scrollOffset = window.scrollY - parentOffset;
    //     const scrollValue = scrollOffset < 0 ? 0 : scrollOffset;

    //     return {
    //         scrollableHeight,
    //         scrollValue,
    //     }
    // }
    // const handleScroll = (e: any) => {
    //     // if (!inBrowser) return;
    //     const { scrollableHeight, scrollValue }: any = getValues();

    //     const slideIndex = Math.floor(scrollValue / (scrollableHeight / itemCount));

    //     const newIndex = slideIndex > itemCount - 1 ? itemCount - 1 : slideIndex;
    //     const newPercentage = getPercentageValue(newIndex);
    //     setCurrentIndex(newIndex);
    //     setPercentage(newPercentage)
    //     return;
    // };

    // const throttledHandleScroll = throttle(handleScroll, 50);

    // useEffect(() => {
    //     if (inBrowser) window?.addEventListener('scroll', throttledHandleScroll);
    //     return () => {
    //         if (inBrowser) window?.removeEventListener('scroll', throttledHandleScroll);
    //     };
    // }, [wrapperRef])

    // const getPercentageValue = (index: number): number => {
    //     // if (index > currentIndex || !inBrowser) return 0;
    //     const { scrollableHeight, scrollValue }: any = getValues();

    //     const sectionHeight = scrollableHeight / itemCount;
    //     const value = scrollValue % sectionHeight;
    //     const returnValue = value / sectionHeight * 100;

    //     return returnValue;
    // }

    return (
        <Wrapper>
            <Section ref={containerRef}>
                <AnimationWrapper>
                    <ExtendedWrapper ref={wrapperRef}>
                        {heading?.richText && (
                            <RichHeading>
                                {RichText.render(heading?.richText)}
                            </RichHeading>
                        )}
                        <Article bgc={items[currentIndex]?.background_color}>
                            {onMobile ? (
                                <>
                                    {items.map((article: any, index: number) => (
                                        <InnerArticle key={index} bgc={article?.background_color}>
                                            {!article.use_image && article?.image?.gatsbyImageData ? (
                                                <ImageWrapper audience={audience}>
                                                    {/* @ts-ignore */}
                                                    <GatsbyImage image={article.image.gatsbyImageData} alt={article.image.alt || ""} />
                                                </ImageWrapper>
                                            ) : (
                                                // <Video />
                                                <></>
                                            )}
                                            <BodyWrapper>
                                                {article?.heading?.richText && (
                                                    <RichSubHeading>
                                                        {RichText.render(article.heading.richText)}
                                                    </RichSubHeading>
                                                )}
                                                {article?.body_text?.richText && (
                                                    <RichBody className={"rt"}>
                                                        {RichText.render(article.body_text.richText)}
                                                    </RichBody>
                                                )}
                                                {article?.cta_text && article?.cta_link.url && 
                                                    <Button
                                                        buttonStyle={'outline'}
                                                        buttonFullWidth={'full-mobile'}
                                                        url={article.cta_link.url}
                                                        target={article?.cta_link?.target || '_self'}
                                                    >
                                                        {article.cta_text}
                                                    </Button>
                                                }
                                            </BodyWrapper>
                                        </InnerArticle>
                                    ))}
                                </>
                            ) : (
                                <AnimatePresence>
                                    {items.map((article: any, index: number) => index === currentIndex ? (
                                        <AnimatedInnerArticle
                                            key={index}
                                            initial={{ opacity: 0, position: 'absolute' }}
                                            animate={{ opacity: 1, position: 'initial' }}
                                            exit={{ opacity: 0, position: 'absolute' }}
                                        >
                                            {!article.use_image && article?.image?.gatsbyImageData ? (
                                                <ImageWrapper audience={audience}>
                                                    {/* @ts-ignore */}
                                                    <GatsbyImage image={article.image.gatsbyImageData} alt={article.image.alt || ""} />
                                                </ImageWrapper>
                                            ) : (
                                                // <Video />
                                                <></>
                                            )}
                                            <BodyWrapper>
                                                {article?.heading?.richText && (
                                                    <RichSubHeading>
                                                        {RichText.render(article.heading.richText)}
                                                    </RichSubHeading>
                                                )}
                                                {article?.body_text?.richText && (
                                                    <RichBody className={"rt"}>
                                                        {RichText.render(article.body_text.richText)}
                                                    </RichBody>
                                                )}
                                                {article?.cta_text && article?.cta_link.url && 
                                                    <Button
                                                        buttonStyle={'outline'}
                                                        buttonFullWidth={'full-mobile'}
                                                        url={article?.cta_link.url}
                                                        target={article?.cta_link?.target || '_self'}
                                                    >
                                                        {article?.cta_text}
                                                    </Button>
                                                }
                                            </BodyWrapper>
                                        </AnimatedInnerArticle>
                                    ) : null)}
                                </AnimatePresence>
                            )}
                        </Article>
                        <Navigation>
                            {items.map((article: any, index: number) => (
                                <Button
                                    key={index}
                                    buttonStyle={currentIndex === index ? 'filled' : 'outline'}
                                    buttonFullWidth={'full-mobile'}
                                    onClick={() => setCurrentIndex(index)}
                                >
                                    {
                                        article?.heading?.text ||
                                        (
                                            `${index + 1}`.length > 1 ? index + 1 : `0${index + 1}`
                                        )
                                    }
                                </Button>
                            ))}
                        </Navigation>
                    </ExtendedWrapper>
                </AnimationWrapper>
            </Section>
        </Wrapper>
    )
}

export default HorizontalScroll