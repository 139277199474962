import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const PauseIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.15256e-07 18L0 0H6L6 18H7.15256e-07ZM10 18L10 0H16V18H10Z"
            fill={color}
        />
    </IconSvg>
);

export default PauseIcon;