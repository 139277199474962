import React from "react";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Quotation from "../../../icons/quotation-icon";
import Dentist from "../../../icons/dentist-icon";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { Link } from "gatsby";

const Wrapper = styled.article`
`

const Slide = styled.div`
	height: 100%;
    position: relative;
`

const TestimonialContainer = styled.div<{ useWide: boolean }>`
	display: block;
    position: relative;
	padding: 60px 40px 40px;

    ${({ useWide }) => `
        ${useWide ? `
            background: #EAF0F6;
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-template-rows: repeat(4, auto);
            padding: 32px;
            column-gap: 16px;

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(2, auto);
                grid-template-rows: repeat(4, auto);
                column-gap: 64px;
                padding: 64px;
            }

        ` : `
            background: var(--bg-light-grey);
        `}
    `}
`

const QuotationMark = styled.div<{ useWide: boolean }>`
	margin: 0 0 10px 0;
    justify-self: end;

    svg {
        width: 22px;
        height: 17px;
    }
    

    @media screen and (min-width: 768px) {
        margin: 0 0 24px 0;

        svg {
            width: 34px;
            height: 27px;
        }
    }

    ${({ useWide }) => `
        ${useWide ? `
            grid-column: 2 / 3;
            grid-row: 1 / 2;

            @media screen and (min-width: 768px) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }
        ` : `
            ''
        `}
    `}
`

const Quote = styled.div<{ useWide: boolean }>`
	font-weight: 400;
	margin: 0 0 16px 0;
	width: 100%;
    padding: 16px 0 0 0;

    ${({ useWide }) => `
        ${useWide ? `
            grid-column: 1 / 3;
            grid-row: 3 / 4;

            @media screen and (min-width: 1200px) {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                font-weight: 700;
            }
        ` : `
            ''
        `}
    `}
`

const Author = styled.p<{ useWide: boolean }>`
	margin: 0;
    padding: 16px 0 0;

    ${({ useWide }) => `
        ${useWide ? `
            font-weight: 600;
            grid-column: 1 / 3;
            grid-row: 2 / 3;

            @media screen and (min-width: 768px) {
                grid-column: 2 / 3;
                grid-row: 3 / 4;
            }
        ` : `
            ''
        `}
    `}
`

const Avatar = styled(GatsbyImage) <{ audience: string }>`
    ${({ audience }) => `   
        ${audience === 'adults' ? `
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        ` : `
            transform: translateY(50%);
        `}
        display: block;	
        margin: 0 auto;
        width: 104px;
        height: 104px;
        border-radius: 50%;
        z-index: 1;

        img {
            border-radius: 50%;
        }
    `}
`

const Badge = styled.aside`
	position: absolute;
    top: 24px;
    right: 24px;
`

const Urls = styled.div`
    grid-column: 1 / 3;
    grid-row: 4 / 5;

    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 4 / 5; 
}
`

const Url = styled(Link)`
    display: inline-flex;
    color: var(--light-green);
    margin: 0 16px 0 0;

    p {
        color: var(--light-green);
    }
`

const ExternalUrl = styled.a`
    display: inline-flex;
    color: var(--light-green);
    margin: 0 16px 0 0;
    
    p {
        color: var(--light-green);
    }
`

const LargeImage = styled(GatsbyImage)`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background: #000;
    width: 80px;
    height: 80px;

    @media screen and (min-width: 1200px) {
        grid-column: 1 / 2;
        grid-row: 1 / 5;
        margin: 0 auto;
        width: 284px;
        height: 380px;
    }
`

const Testimonial = (props: {
    item: Queries.PrismicHomepageDataBodyTestimonialsItem,
    audience: string,
    key: string;
    useWide?: boolean,
}) => {
    const { audience, item, key, useWide } = props || {};
    const { testimonial } = item;
    const { document } = testimonial || {};
    const { data } = (document || {}) as Queries.PrismicTestimonials;
    const {
        avatar,
        dental_professional,
        quote,
        quoted_person,
        links
    } = data || {};

    return (
        <Wrapper key={key} >
            <Slide>
                {avatar?.gatsbyImageData && !useWide && dental_professional && (
                    <Avatar
                        audience={audience}
                        image={(avatar?.gatsbyImageData as unknown) as IGatsbyImageData}
                        alt={avatar?.alt || ''}
                    />
                )}
                <TestimonialContainer
                    useWide={useWide as boolean}
                >
                    {avatar?.gatsbyImageData && useWide && (
                        <LargeImage
                            image={(avatar?.gatsbyImageData as unknown) as IGatsbyImageData}
                            alt={avatar?.alt || ''}
                        />
                    )}
                    {dental_professional && !useWide && (
                        <Badge>
                            <Dentist />
                        </Badge>
                    )}

                    {quote?.richText && (
                        <>
                            <QuotationMark
                                useWide={useWide as boolean}
                            >
                                <Quotation />
                            </QuotationMark>

                            <Quote
                                useWide={useWide as boolean}
                            >
                                {RichText.render(quote.richText)}
                            </Quote>
                            <Author
                                useWide={useWide as boolean}
                            >
                               — {quoted_person ? quoted_person : ''}
                            </Author>
                        </>
                    )}

                    {useWide && links &&
                        <Urls>
                            {useWide && links && links?.map((link, index: number) => link?.link_url?.url?.charAt(0) === '/' ? (
                                <Url
                                    key={`${index}`}
                                    to={link?.link_url?.url}
                                >
                                    {RichText.render(link?.link_text?.richText)}
                                </Url>
                            ) : (
                                <ExternalUrl
                                    key={`${index}`}
                                    href={link?.link_url?.url || ''}
                                    target={link?.link_url?.target || '_self'}
                                >
                                    {RichText.render(link?.link_text?.richText)}
                                </ExternalUrl>
                            ))}
                        </Urls>
                    }
                </TestimonialContainer>
            </Slide>
        </Wrapper>
    );
}

export default Testimonial;
