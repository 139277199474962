import { RichTextBlock } from "prismic-reactjs";

type Props = Queries.PrismicProductVariant[];

interface DisplayData {
    title: string | null;
    description: { richText: RichTextBlock[] } | null;
    tags?: Queries.PrismicBundleDataTags[];
}

export interface SanitizedBundle {
    id: string;
    displayData: DisplayData;
    bundle: Queries.PrismicBundleDataBundleIntegrationType;
    meta: {
        lineId: string;
    }
}

export const getSanitizedBundles = (bundleNodes: Props): SanitizedBundle[][] => {
    return bundleNodes?.reduce<SanitizedBundle[][]>((bundlesArr, props) => {
        const { data, id }: { id: string, data: Queries.PrismicProductVariantDataType } = props;
        const { bundles, stripe_variant } = data;

        const newBundles = bundles?.reduce<SanitizedBundle[]>((bundleArr, bundleData) => {
            const { bundle: variantBundles } = bundleData || {};
            const { data } = (variantBundles?.document || {}) as Queries.PrismicBundle;
            const { bundle, title, description, tags } = data || {};

            if (bundle) {
                return [
                    ...bundleArr,
                    {
                        id,
                        bundle,
                        displayData: {
                            title,
                            description,
                            tags: tags as Queries.PrismicBundleDataTags[],
                        },
                        meta: {
                            lineId: stripe_variant?.default_price as string || '',
                        },
                    }
                ];
            } else {
                return bundleArr;
            }
        }, []);

        if (newBundles && newBundles.length > 0) {
            return [
                ...bundlesArr,
                newBundles,
            ]
        } else {
            return bundlesArr;
        }
    }, []);
}