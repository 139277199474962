import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ArrowUp = ({ color = "var(--light-green)" }: { color?: string }) => (
    <IconSvg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.34375 8L7.0006 2.34315L12.6077 7.9502" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M7 3.24204L7 13.6562" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </IconSvg>

);

export default ArrowUp;