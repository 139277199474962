import React, { useEffect, useRef } from "react"
import styled from "styled-components";

const Container = styled.div``

const IFrame = styled.iframe`
    border: 0;
    outline: 0;
`

const Map = () => {
    const container = useRef<HTMLDivElement>(null);
    const width = container?.current?.getBoundingClientRect()?.width;

    return (
        <Container ref={container}>
            <IFrame
                width={width}
                height={456}
                srcDoc={`
                <html>
                    <head>
                        <style>
                            body {
                                margin: 0;
                            }
                        </style>
                    </head>
                    <body>
                        <div data-stockist-widget-tag="u5278">Loading store locator
                            from <a href="http://stockist.co">Stockist store locator</a>...
                        </div>
                        <script>
                            (function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;
                            k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);
                            })(document,'script','//stockist.co/embed/v1/widget.min.js');
                        </script>
                    </body>
                </html>
            `}/>
        </Container>
    )
};

export default Map;