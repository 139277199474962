import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg``

const WhatsappShare = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.025 23.9701C28.5283 23.7217 26.0933 22.5251 25.64 22.3584C25.1867 22.1934 24.8567 22.1117 24.525 22.6084C24.195 23.1017 23.2467 24.2184 22.9583 24.5484C22.6683 24.8801 22.38 24.9201 21.885 24.6734C21.39 24.4234 19.7933 23.9017 17.9017 22.2151C16.43 20.9017 15.435 19.2801 15.1467 18.7834C14.8583 18.2884 15.115 18.0201 15.3633 17.7734C15.5867 17.5517 15.8583 17.1951 16.1067 16.9067C16.355 16.6167 16.4367 16.4101 16.6017 16.0784C16.7683 15.7484 16.685 15.4601 16.56 15.2117C16.4367 14.9634 15.4467 12.5251 15.0333 11.5334C14.6317 10.5684 14.2233 10.7001 13.92 10.6834C13.63 10.6701 13.3 10.6667 12.97 10.6667C12.64 10.6667 12.1033 10.7901 11.65 11.2867C11.195 11.7817 9.91667 12.9801 9.91667 15.4184C9.91667 17.8551 11.69 20.2101 11.9383 20.5417C12.1867 20.8717 15.43 25.8751 20.3983 28.0201C21.5817 28.5301 22.5033 28.8351 23.2217 29.0617C24.4083 29.4401 25.4883 29.3867 26.3417 29.2584C27.2917 29.1167 29.2717 28.0601 29.685 26.9034C30.0967 25.7467 30.0967 24.7551 29.9733 24.5484C29.85 24.3417 29.52 24.2184 29.0233 23.9701H29.025ZM19.9883 36.3084H19.9817C17.0307 36.3089 14.134 35.5157 11.595 34.0117L10.995 33.6551L4.75833 35.2917L6.42333 29.2117L6.03167 28.5884C4.3819 25.9623 3.50883 22.923 3.51333 19.8217C3.51667 10.7384 10.9067 3.3484 19.995 3.3484C24.395 3.3484 28.5317 5.06507 31.6417 8.1784C33.1756 9.70604 34.3915 11.5227 35.2188 13.5232C36.0461 15.5238 36.4686 17.6685 36.4617 19.8334C36.4583 28.9167 29.0683 36.3084 19.9883 36.3084ZM34.0083 5.8134C32.172 3.96494 29.9871 2.4993 27.5801 1.5014C25.1732 0.503508 22.5922 -0.00679375 19.9867 6.82959e-05C9.06333 6.82959e-05 0.17 8.89174 0.166667 19.8201C0.161605 23.298 1.07396 26.7157 2.81167 29.7284L0 40.0001L10.5067 37.2434C13.4131 38.8269 16.6702 39.6567 19.98 39.6567H19.9883C30.9117 39.6567 39.805 30.7651 39.8083 19.8351C39.8164 17.2305 39.3079 14.6502 38.3124 12.2435C37.3168 9.83668 35.8539 7.65119 34.0083 5.8134Z"
            fill={color}
        />
    </IconSvg>
);

export default WhatsappShare;
