import { getFromSessionStorage } from "./get-from-session-storage";
import { supportsCookies } from "./supports-cookies";
import { supportsSessionStorage } from "./supports-session-storage";

export const getCookie = (key: string): string | void | null => {
    if (supportsCookies()) {
        const pattern = new RegExp(`${key}=([^;]{0,})`);
        if (!pattern.test(document?.cookie || '')) return;
        const matchData = document?.cookie?.match(pattern);

        if (matchData) return matchData[1];
    } else if (supportsSessionStorage()) {
        return getFromSessionStorage(key)
    }
}