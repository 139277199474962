import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"
import Cart from "../../components/sections/cart-page/cart";
import { PageContext } from "../../components/slices/types"
import { SliceZone } from "@prismicio/react"
import { components } from "./components"
import { useAppSelector } from "../../app/hooks"
import { getSanitizedBundles, logEcommerceEvent, logPageView } from "@utils";
import linkResolver from '../../utils/prismic/link-resolver';

const CartPage = (props: PageProps<Queries.CartPageQuery, PageContext>) => {
    const [globalAudience, cart] = useAppSelector(state => [state.general.audience, state.cart]);
    const { data, location, pageContext } = props;
    const { prismicCartPage, allPrismicHeader, prismicFooter, allPrismicProductVariant } = data;
    const { lang, data: pageData } = prismicCartPage || {};
    const { page_title, page_description, page_image, body } = pageData || {}
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings } = pageContext;
    const bundles = getSanitizedBundles(allPrismicProductVariant.nodes as Queries.PrismicProductVariant[]);
    const page = 'cart';
    const pageImage = page_image?.url || '';

    useEffect(() => {
        const trackingItems = cart.lineItems.map(l => l?.frontendLineData?.trackingContext);
        logEcommerceEvent(trackingItems, 'view_cart', {
            value: (cart.amountTotal || 0) / 100,
            currency: cart.currencyCode || 'EUR',
        });

        logPageView({ content_group: page });
    }, []);

    return (
        <Layout
            hideFooter={true}
            bundles={bundles}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={page_title || 'Cart'} description={page_description || 'Cart page'} lang={lang?.split('-')[0]} image={pageImage} canonicalUrl={`https://playbrush.com${location.pathname}`} />
            <Cart translations={translations} settings={settings} data={pageData as Queries.PrismicCartPageDataType} />
            <SliceZone
                slices={body}
                // @ts-ignore
                components={components}
                context={{
                    audience,
                    translations,
                    page,
                }}
            />
        </Layout>
    )
}

export const query = graphql`
    query CartPage($id: String, $lang: String) {
        prismicCartPage(id: {eq: $id}, lang: {eq: $lang}) {
            _previewable
            href
            url
            prismicId
            tags
            type
            uid
            lang
            data {
                page_title
                page_image {
                    url
                }
                page_description
                discount
                enter_discount_code
                apply
                discount_code_error
                go_to_checkout
                help_text
                item
                items
                page_description
                shipping
                subscription_renewal
                subtotal
                summary_title
                tax_line
                title
                total
                upgrade
                upgrade_modal_title
                brush_heads_trial_disclaimer
                body {
                    ... on PrismicCartPageDataBodyEmailPopUp {
                        id
                        slice_type
                        primary {
                            delay
                            success_message
                            subscription_form {
                                document {
                                    ... on PrismicSubscriptionForm {
                                        id
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                    tags
                                                    price_recurring_trial_period_days
                                                    price_recurring_interval_count
                                                    price_recurring_interval
                                                    price_original_after_trial
                                                    price_original
                                                    price_amount_after_trial
                                                    price_amount
                                                    name
                                                    default_price
                                                    image
                                                    description
                                                    active
                                                    metadata {
                                                        prod_image_corner_note_EN
                                                        prod_image_title_DE
                                                        prod_image_title_EN
                                                        prod_image_tooltip_text_DE
                                                        prod_image_tooltip_text_EN
                                                        prod_marketing_title_DE
                                                        prod_marketing_title_EN
                                                        prod_order_title_DE
                                                        prod_order_title_EN
                                                        tags
                                                        prod_description_DE
                                                        prod_description_EN
                                                        prod_image_corner_note_DE
                                                    }
                                                    _id
                                                    description_de
                                                    description_en
                                                }
                                                product
                                                price_default_blob {
                                                    active
                                                    currency
                                                    _id
                                                    product
                                                    recurring_interval
                                                    recurring_interval_count
                                                    recurring_trial_period_days
                                                    type
                                                    unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                            price_amount_one_time
                                            price_amount_recurring
                                            price_original_after_trial
                                            price_original_one_time
                                            price_original_recurring
                                            price_shipping_eur
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        menu_bundle_type_preselect
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        bundle_preselect_index
                                        button_bundle_type_preselect
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                            bundle_type_preselect
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(
    CartPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
    }]
);
