import { Audience, Locale } from '@constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
    audience: Audience;
    lang: Locale;
    notificationBannerOpen: boolean;
} = {
    audience: 'kids',
    lang: 'de-eu',
    notificationBannerOpen: true,
};

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setAudience: (state, action: PayloadAction<Audience>) => {
            state.audience = action.payload
            return state;
        },
        setLang: (state, action: PayloadAction<Locale>) => {
            state.lang = action.payload
            return state;
        },
        setNotificationBannerOpen: (state, action: PayloadAction<boolean>) => {
            state.notificationBannerOpen = action.payload
            return state;
        },
    }
})

export const { setAudience, setLang, setNotificationBannerOpen } = generalSlice.actions
export default generalSlice.reducer