import styled from "styled-components";

const Wrapper = styled.div<{ full?: boolean }>`
    ${({ full }) => `
        ${full ? '' : `
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 16px;
        `}
        @media screen and (min-width: 1024px) {
            padding: 0 80px;
        }
    `}
`

export default Wrapper;