import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import { hasRichText, renderRich } from "@utils";

import { SliceProps } from "../../types";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Heading = styled.div`
    margin: 0 16px 40px;

    @media screen and (min-width: 768px) {
        margin: 0 0 40px;
    }

`

const InnerWrapper = styled.div<{ numberOfItems: number }>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 48px;
    overflow-x: scroll;
    padding: 0 16px 48px;

    ${({ numberOfItems }) => numberOfItems < 3 && `
        grid-template-columns: repeat(2, 1fr);
    `}

    ${({ numberOfItems }) => numberOfItems > 3 && `
        grid-template-columns: repeat(4, 1fr);
    `}
    
    @media screen and (min-width: 768px) {
        padding: 0;
    }
`

const FeatureItem = styled.article`
    width: 75vw;
    
    @media screen and (min-width: 768px) {
        width: auto;
    }

    @media screen and (max-width: 768px) {
        button,
        a {
            width: calc(100% - 80px);
        }
    }
`

const FeatureImage = styled.div``

const Image = styled(GatsbyImage)`
    width: 100%;
`

const Title = styled.div`
    margin: 16px 0 0 0;
    
    h3 {
        font-weight: 400;
    }

    h5 {
        font-size: 20px;
    }

    @media screen and (min-width: 768px) {
        margin: 30px 0 0 0;
    }
`

const Subtitle = styled.div`
    margin: 4px 0 0 0;
`

const FeatureDescription = styled.div`
    margin: 24px 0 0 0;

    ul {
        padding-inline-start: 20px;
    }
`

const FeatureGrid = (props: SliceProps<Queries.PrismicProductOverviewPageDataBodyFeatureGrid>) => {
    // const {context: {audience},slice: {primary: {heading,section_id},items,}} = props;

    const { context, slice } = props;
    const { primary, items } = slice || {};
    const { heading, section_id } = primary;
    const { audience } = context;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    return (
        <Section id={`feature-grid-${section_id}`}>
            <Wrapper full>
                {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                <InnerWrapper numberOfItems={items.length}>
                    {items.map(({ feature, title, subtitle, image, button_text, button_url }: any, i: number) => (
                        <FeatureItem key={`item-${i}`}>
                            <FeatureImage>
                                {image?.gatsbyImageData && (
                                    <Image image={image.gatsbyImageData} alt={image.alt || ''} />
                                )}
                            </FeatureImage>
                            {title && title.richText &&
                                <Title>
                                    {title?.richText && RichText.render(title.richText)}
                                </Title>
                            }
                            {subtitle && subtitle.richText &&
                                <Subtitle>
                                    {subtitle?.richText && RichText.render(subtitle.richText)}
                                </Subtitle>
                            }
                            {feature && feature.richText &&
                                <FeatureDescription>
                                    {feature?.richText && RichText.render(feature.richText)}
                                </FeatureDescription>
                            }
                            {button_text && button_url?.url &&
                                <Button
                                    url={button_url?.url || ''}
                                    buttonStyle="outline"
                                >
                                    {button_text?.richText && RichText.render(button_text?.richText)}
                                </Button>
                            }
                        </FeatureItem>
                    ))}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default FeatureGrid;