import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const Carrat = () => (
    <IconSvg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 7L7 0.999999L1 7"
            stroke="#2BB573"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconSvg>
);

export default Carrat;
