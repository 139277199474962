import React from 'react';
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { hasRichText, renderRich } from "@utils";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import ReferralBox from '../../../snippets/referral-box/referral-box';

const Section = styled.section`
    @media screen and (min-width: 768px) {
        padding: 174px 0 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // gap: 40px;
    
    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 124px;
    }
`

const ContentWrapper = styled.div`
    flex: 1 0 50%;
`

const ImageWrapper = styled.div`
    flex: 1 0 100%;

    @media screen and (min-width: 768px) {
        flex: 1 1 auto;
    }
`

const Heading = styled.div`
    margin: 0 0 18px 0;
    padding: 130px 0 0 0;
    
    em {
        color: #8193A8;
        font-style: normal;
    }
    
    @media screen and (min-width: 768px) {
        margin: 0 0 32px 0;
        padding: 0;
    }
`

const Description = styled.div`
    margin: 0 0 32px 0;

    @media screen and (min-width: 768px) {
        margin: 0 0 40px 0;
    }
`

const Image = styled(GatsbyImage)`
    max-width: 100%;
`

const FormWrapper = styled.div<{ isLoading: boolean }>`
    ${({ isLoading }) => isLoading ? `
        & > * {
            opacity: 0.6;
            pointer-events: none;
        }
    ` : `
        background: white;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        padding: 24px;
        max-width: 510px;
    `}
`

const FormTitle = styled.div`
    margin: 0 0 16px 0;

    @media screen and (min-width: 768px) {
    }
`

const StyledInput = styled.input`
    background-color: #EAF0F6;
    padding: 16px;
    border: 0;
    outline: 0;
    font-size: 24px;
    line-height: 150%;
    border-radius: 16px;
    width: 100%;

    &::placeholder {
        font-size: 24px;
        color: var(--light-green);
        vertical-align: middle;
    }
`

const StyledOptinLabel = styled.label`
    display: flex;
    color: var(--light-green);
    padding: 16px 0 0 0;
    font-size: 16px;
`

const StyledOptinInput = styled.span<{ optedin: boolean }>`
    position: relative;
    height: 16px;
    flex: 0 0 16px;
    margin-right: 16px;
    background-color: white;
    box-shadow: 0px 0px 1px 1px rgb(255 255 255 / 80%);
    border-radius: 4px;
    border: 2px solid var(--light-green);

    &:hover {
        cursor: pointer;
    }

    ${({ optedin }) => optedin ? `
        border: 2px solid var(--light-green);
        
        &::after {
            content: '';
            position: absolute;
            top: 4px;
            right: 4px;
            bottom: 4px;
            left: 4px;
            background-color: var(--light-green);
        }
    ` : ``}
`

const StyledOptin = styled.span`
    color: white;
    font-size: 11px;
    line-height: 1.3;
    
    * {
        color: var(--light-green);
    }

    a {
        color: #f8758a;
        font-size: 11px;
        line-height: 1.2;
        text-decoration: underline;

        &:hover {
            color: white;
        }
    }
`

const Confirmation = styled.div``

const SocialIcons = styled.div`
    display: flex;
    gap: 20px;
    padding: 24px 0 0 0;

    svg {
        width: 40px;
        height: 40px;
    }
`

const ReferralHero = (props: SliceProps<Queries.PrismicReferralsPageDataBodyReferralHero>) => {
    const { slice, context } = props;
    const { location } = context;
    const { primary } = slice;
    const { heading, description, image } = primary;
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);

    return (
        <Section id="referral-hero">
            <Wrapper>
                <InnerWrapper>
                    <ContentWrapper>
                        {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                        {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                        <ReferralBox
                            data={(primary as unknown) as Queries.PrismicThankYouPageDataType}
                            location={location as Record<string, unknown>}
                        />
                    </ContentWrapper>
                    <ImageWrapper>
                        <Image
                            image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                            alt={image?.alt || ''}
                            objectFit={"cover"}
                        />
                    </ImageWrapper>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default ReferralHero