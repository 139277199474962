import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const handleButtonStyle = (buttonStyle: string): string => {
    switch (buttonStyle) {
        case "outline": {
            return `
				background: none;
				border: 1px solid var(--light-green);
				color: var(--light-green);
                p {
                    color: var(--light-green);
                }
			`
        }
        case "solid": {
            return `
				color: white;
				background: var(--light-green);
				border: 1px solid var(--light-green);

                p {
                    color: white;
                }
                &:hover {
                    background: var(--black);
                    border: 1px solid var(--black);
                }
			`
        }
        case "outline-success": {
            return `
				background: none;
				border: 1px solid var(--dark-green);
				color: var(--dark-green);
                p {
                    color: var(--dark-green);
                }
			`
        }
        case "solid-success": {
            return `
				color: white;
				background: var(--dark-green);
				border: 1px solid var(--dark-green);

                p {
                    color: white;
                }
			`
        }
        default: {
            return `
				color: white;
				background: var(--light-green);
				border: 1px solid var(--light-green);

                p {
                    color: white;
                }
                &:hover {
                    background: var(--black);
                    border: 1px solid var(--black);
                }
			`
        }
    }
}

const handleButtonFullWidth = (buttonFullWidth: string): string => {
    switch (buttonFullWidth) {
        case "full": {
            return `
				width: 100%;
                box-sizing: border-box;
			`
        }
        case "full-mobile": {
            return `
                @media screen and (max-width: 768px) {
                    width: 100%;
                    box-sizing: border-box;
                }
			`
        }
        case "full-desktop": {
            return `
                @media screen and (min-width: 768px) {
                    width: 100%;
                    box-sizing: border-box;
                }
			`
        }
        default: {
            return `
				width: auto;
			`
        }
    }
}

const StyledButton = styled.button<{
    buttonStyle: string,
    buttonFullWidth: string,
    isLoading: boolean
    disabled: boolean
}>`
	font-weight: 600;
	font-size: 14px;
	padding: 16px 43px;
	border-radius: 100px;
	border: none;

    &:hover {
        cursor: pointer;
    }

    p {
        display: flex;
        align-items: center;
        justify-content: center;
    }
	
	${({ buttonStyle }: any) => handleButtonStyle(buttonStyle)}
	${({ buttonFullWidth }: any) => handleButtonFullWidth(buttonFullWidth)}
	${({ isLoading }: any) => isLoading ? `
        opacity: 0.4;
        pointer-events: none;
    ` : ''}

    ${({ disabled }: any) => disabled ? `
        opacity: 1;
        pointer-events: none;
        background-color: #E4E4E4;
        border-color: #E4E4E4;
        color: white;
    ` : ''}

	
	@media screen and (min-width: 768px) {
		font-size: 16px;
		line-height: 20px;
	}
`

const StyledExternalLink = styled.a<{ buttonStyle: string, buttonFullWidth: string }>`
    ${({ buttonStyle, buttonFullWidth }) => `
        display: inline-block;
        background: var(--light-green);
        padding: 18px 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        text-align: center;
        text-decoration: none;
        ${handleButtonStyle(buttonStyle)}
        ${handleButtonFullWidth(buttonFullWidth)}

        @media screen and (max-width: 768px) {
            display: block;
            font-size: 14px;
        }
    `}
`

const StyledLink = styled(
    (props) => (
        <Link
            to={props.to}
            target={props.target}
            className={props.className}
        >
            {props.children}
        </Link>
    )
) <{
    buttonStyle: string,
    buttonFullWidth: string,
    isLoading: boolean
}>`
    ${({ buttonStyle, buttonFullWidth, loading }) => `
        display: inline-block;
        background: var(--light-green);
        padding: 18px 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        text-align: center;
        text-decoration: none;
        
        ${loading ? `
            opacity: 0.4;
        ` : `
            &:hover {
                cursor: pointer;
            }
        `}
        ${handleButtonStyle(buttonStyle)}
        ${handleButtonFullWidth(buttonFullWidth)}

        @media screen and (max-width: 768px) {
            display: inline-block;
            font-size: 14px;
        }
    `}
`

const StyledAnchorLink = styled(
    (props) => (
        <Link
            to={props.to}
            target={props.target}
            className={props.className}
        >
            {props.children}
        </Link>
    )
) <{
    buttonStyle: string,
    buttonFullWidth: string,
    isLoading: boolean
}>`
    ${({ buttonStyle, buttonFullWidth, loading }) => `
        display: inline-block;
        background: var(--light-green);
        padding: 18px 40px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        text-align: center;
        text-decoration: none;
        
        ${loading ? `
            opacity: 0.4;
        ` : `
            &:hover {
                cursor: pointer;
            }
        `}
        ${handleButtonStyle(buttonStyle)}
        ${handleButtonFullWidth(buttonFullWidth)}

        @media screen and (max-width: 768px) {
            display: inline-block;
            font-size: 14px;
        }
    `}
`

interface ButtonProps {
    type?: "button" | "submit" | "reset";
    buttonStyle?: string;
    buttonFullWidth?: string;
    url?: string;
    target?: string;
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    loading?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
    type = 'button',
    buttonStyle = 'solid',
    buttonFullWidth = '',
    disabled = false,
    url,
    target,
    children,
    onClick,
    onSubmit,
    loading = false,
}: ButtonProps) => {
    if (url && /\./.test(url)) {
        return (
            <StyledExternalLink
                href={url}
                buttonStyle={buttonStyle}
                buttonFullWidth={buttonFullWidth}
                target={target}
            >
                {children}
            </StyledExternalLink>
        )
    } else if (url && /\./.test(url) && /#/.test(url)) {
        return (
            <StyledAnchorLink
                to={url}
                buttonStyle={buttonStyle}
                buttonFullWidth={buttonFullWidth}
                target={target}
            >
                {children}
            </StyledAnchorLink>
        )
    } else if (url) {
        return (
            <StyledLink
                to={url}
                buttonStyle={buttonStyle}
                buttonFullWidth={buttonFullWidth}
                target={target}
                isLoading={loading}
                disabled={disabled}
            >
                {children}
            </StyledLink>
        )
    } else {
        return (
            <StyledButton
                type={type}
                buttonStyle={buttonStyle}
                buttonFullWidth={buttonFullWidth}
                onClick={onClick}
                onSubmit={onSubmit}
                disabled={disabled || loading}
                isLoading={loading}
            >
                {children}
            </StyledButton>
        )
    }
}

export default Button