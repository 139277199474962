import React from "react"
import styled from "styled-components";
import { LineContent } from "../../../../../../utils/playbrush-client/types";

const GAP = 24;

const Item = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${GAP}px;
`;

const Image = styled.img`
    flex: 0 0 40px;
    height: 48px;
    border-radius: 7px;
    object-cover: fit;

    @media screen and (min-width: 768px) {
        border-radius: 14px;
        flex: 0 0 64px;
        width: 64px;
        height: 76px;
    }
`;

const Details = styled.div`
    margin: 0 0 0 ${GAP}px;
    flex: 1 1 auto;
`;

const Name = styled.p`
    font-size: 16px;
    font-weight: bold;
`;

const Description = styled.p`
    font-size: 16px;
`;

interface LineContentItemProps {
    content: LineContent;
}

const LineContentItem = ({ content }: LineContentItemProps) => {
    return (
        <Item key={`${content.id}`}>
            <Image src={content.image} />
            <Details>
                <Name>{`${content.productNameMarketing}`}</Name>
                {content.description && (
                    <Description>
                        {content.description}
                    </Description>
                )}
            </Details>
        </Item>
    )
};

export default LineContentItem;