import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { arrowRightIconSmallInline } from "../../../icons/arrow-right-icon-small-inline";

import { hasRichText, renderRich } from "@utils";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const ContentWrapper = styled.div`
    max-width: 848px;
`

const Heading = styled.div`
    padding: 0 0 18px 0;

    @media screen and (min-width: 768px) {
        padding: 0 0 40px 0;
    }
`

const Text = styled.div`
    padding: 0 0 40px 0;
    
    @media screen and (min-width: 768px) {
        padding: 0 0 64px 0;
    }
`

const Subtitle = styled.div`
    text-align: center;
    margin: 0 0 45px 0;
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
    }
`

const ItemGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    overflow-x: scroll;

    @media screen and (min-width: 768px) {
        row-gap: 45px;
        overflow-x: hidden;
        grid-template-columns: repeat(3, 1fr);
    }
`

const Item = styled.article`
    background: #F8F8F8;
    padding: 24px;
    border-radius: 16px;
    width: 254px;
    grid-row: 1 / 2;

    @media screen and (min-width: 768px) {
        width: auto;
        grid-row: unset;
    }
`

const ItemTitle = styled.div`
    margin: 0 0 16px 0;
    
    h5 {
        font-size: 24px;
    }
`

const ItemDescription = styled.div`
    margin: 0 0 45px 0;
`

const LinkWrapper = styled.div`
    width: 100%;
    text-align: right;
`

const Link = styled.a`
    color: var(--light-green);
    
    p {
        display: inline-flex;
        align-items: center;
        color: var(--light-green);
        
        &:after { 
            content: url(${arrowRightIconSmallInline('#2BB573')});
            padding: 5px 0 0 0;
        }
    }
`


const BenefitsGrid = (props: SliceProps<Queries.PrismicPressAndMediaPageDataBodyBenefitsGrid>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading, text, subtitle } = primary;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [textHasRichText, textRichText] = hasRichText(text?.richText);
    const [subtitleHasRichText, subtitleRichText] = hasRichText(subtitle?.richText);

    return (
        <Section id="benefits-grid">
            <Wrapper>
                <ContentWrapper>
                    {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                    {textHasRichText && (<Text className={"rt"}>{renderRich(textRichText)}</Text>)}
                </ContentWrapper>
                {subtitleHasRichText && (<Subtitle>{renderRich(subtitleRichText)}</Subtitle>)}
                <ItemGrid>
                    {items && items?.map((item, index: number) => {
                        const [itemTitleHasRichText, itemTitleRichText] = hasRichText(item?.item_title?.richText);
                        const [itemDescriptionHasRichText, itemDescriptionRichText] = hasRichText(item?.item_description?.richText);
                        const [linkTextHasRichText, linkTextRichText] = hasRichText(item?.link_text?.richText);

                        return (
                            <Item key={`item-${index}`}>
                                {itemTitleHasRichText && (<ItemTitle>{renderRich(itemTitleRichText)}</ItemTitle>)}
                                {itemDescriptionHasRichText && (<ItemDescription>{renderRich(itemDescriptionRichText)}</ItemDescription>)}

                                {item?.link && linkTextHasRichText && (
                                    <LinkWrapper>
                                        <Link
                                            href={item.link.url || ``}
                                        >
                                            {renderRich(linkTextRichText)}
                                        </Link>
                                    </LinkWrapper>
                                )}
                            </Item>
                        )
                    })}
                </ItemGrid>
            </Wrapper>
        </Section>
    )
};

export default BenefitsGrid