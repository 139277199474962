import React from "react";
import ArrowLeft from "./arrow-icon-left-icon"
import ArrowRight from "./arrow-icon-right-icon"
import ArrowUp from "./arrow-up-icon"
import AwardIcon from "./award-icon"
import BluetoothIcon from "./bluetooth-icon"
import BoxIcon from "./box-icon"
import BrushIcon from "./brush-icon"
import CircularArrow from "./circular-icon"
import CouponIcon from "./coupon-icon"
import CrownIcon from "./crown-icon"
import EnveloppeIcon from "./enveloppe-icon"
import EnveloppeIconAt from "./enveloppe-icon-at"
import ExternalLink from "./external-link-icon"
import FourIcon from "./four-icon"
import FacebookIcon from "./facebook-icon"
import GameControllerIcon from "./game-controller-icon"
import GiftIcon from "./gift-icon"
import GlobeIcon from "./globe-icon"
import GraphIcon from "./graph-icon"
import GroupIcon from "./group-icon"
import InstagramIcon from "./instagram-icon"
import LightbulbIcon from "./lightbulb-icon"
import LinkIcon from "./link-icon"
import LinkedinIcon from "./linkedin-icon"
import LoopIcon from "./loop-icon"
import MedalIcon from "./medal-icon"
import MedalCleanIcon from "./medal-clean-icon"
import OneIcon from "./one-icon";
import PiggyBankIcon from "./piggy-bank-icon"
import PowerButtonIcon from "./power-button-icon"
import SendIcon from "./send-icon"
import ShippingIcon from "./shipping-icon"
import SwissIcon from "./swiss-icon"
import ThumbsUpIcon from "./thumbs-up-icon"
import ThumbsUpCleanIcon from "./thumbs-up-clean-icon"
import TrophyIcon from "./trophy-icon"
import ToothIcon from "./tooth-icon"
import ToothIconCheck from "./tooth-check-icon"
import ThreeIcon from "./three-icon"
import TwoIcon from "./two-icon"
import PaymentIcon from "./payment-icon"
import PauseIcon from "./pause-icon"
import PlayIcon from "./play-icon"
import WhatsappIcon from "./whatsapp-icon"
import WorldMessageIcon from "./world-message-icon"
import YoutubeIcon from "./youtube-icon"

const getIcon = (iconName: string, color: string, i: number): JSX.Element | null => {
    switch (iconName) {
        case "arrow-left": return <ArrowLeft key={`${i}-color`} color={color} />;
        case "arrow-right": return <ArrowRight key={`${i}-color`} color={color} />;
        case "arrow-up": return <ArrowUp key={`${i}-color`} color={color} />;
        case "award": return <AwardIcon key={`${i}-color`} color={color} />;
        case "bluetooth": return <BluetoothIcon key={`${i}-color`} color={color} />;
        case "box": return <BoxIcon key={`${i}-color`} color={color} />;
        case "brush-icon": return <BrushIcon key={`${i}-color`} color={color} />;
        case "circular-arrow": return <CircularArrow key={`${i}-color`} color={color} />;
        case "coupon": return <CouponIcon key={`${i}-color`} color={color} />;
        case "crown": return <CrownIcon key={`${i}-color`} color={color} />;
        case "enveloppe": return <EnveloppeIcon key={`${i}-color`} color={color} />;
        case "enveloppe-at": return <EnveloppeIconAt key={`${i}-color`} color={color} />;
        case "external-link": return <ExternalLink key={`${i}-color`} color={color} />;
        case "four": return <FourIcon key={`${i}-color`} color={color} />;
        case "facebook": return <FacebookIcon key={`${i}-color`} color={color} />;
        case "group": return <GroupIcon key={`${i}-color`} color={color} />;
        case "globe": return <GlobeIcon key={`${i}-color`} color={color} />;
        case "game-controller": return <GameControllerIcon key={`${i}-color`} color={color} />;
        case "gift": return <GiftIcon key={`${i}-color`} color={color} />;
        case "graph": return <GraphIcon key={`${i}-color`} color={color} />;
        case "group": return <GroupIcon key={`${i}-color`} color={color} />;
        case "instagram": return <InstagramIcon key={`${i}-color`} color={color} />;
        case "lightbulb": return <LightbulbIcon key={`${i}-color`} color={color} />;
        case "link": return <LinkIcon key={`${i}-color`} color={color} />;
        case "linkedin": return <LinkedinIcon key={`${i}-color`} color={color} />;
        case "loop": return <LoopIcon key={`${i}-color`} color={color} />;
        case "medal": return <MedalIcon key={`${i}-color`} color={color} />;
        case "medal-clean": return <MedalCleanIcon key={`${i}-color`} color={color} />;
        case "one": return <OneIcon key={`${i}-color`} color={color} />;
        case "power-button": return <PowerButtonIcon key={`${i}-color`} color={color} />;
        case "piggy-bank": return <PiggyBankIcon key={`${i}-color`} color={color} />;
        case "send": return <SendIcon key={`${i}-color`} color={color} />;
        case "shipping": return <ShippingIcon key={`${i}-color`} color={color} />;
        case "swiss": return <SwissIcon key={`${i}-color`} color={color} />;
        case "thumbs-up": return <ThumbsUpIcon key={`${i}-color`} color={color} />;
        case "thumbs-up-clean": return <ThumbsUpCleanIcon key={`${i}-color`} color={color} />;
        case "trophy": return <TrophyIcon key={`${i}-color`} color={color} />;
        case "tooth": return <ToothIcon key={`${i}-color`} color={color} />;
        case "tooth-check": return <ToothIconCheck key={`${i}-color`} color={color} />;
        case "three": return <ThreeIcon key={`${i}-color`} color={color} />;
        case "two": return <TwoIcon key={`${i}-color`} color={color} />;
        case "payment": return <PaymentIcon key={`${i}-color`} color={color} />;
        case "pause": return <PauseIcon key={`${i}-color`} color={color} />;
        case "play": return <PlayIcon key={`${i}-color`} color={color} />;
        case "whatsapp": return <WhatsappIcon key={`${i}-color`} color={color} />;
        case "world-message": return <WorldMessageIcon key={`${i}-color`} color={color} />;
        case "youtube": return <YoutubeIcon key={`${i}-color`} color={color} />;
        default: return null;
    }
}

export default getIcon;