import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { getStars } from "@utils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageWrapper = styled.div`
    padding: 16px;
`

const StarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 0;

    * {
        text-align: center;
    }
`

const Stars = styled.div`
	width: 100%;
    display: flex;
    justify-content: center;
    margin: 24px 0 20px;

    svg {
        width: 21.5px;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
`

const SlideUrl = styled.a``

const Slide = ({
    use_rating,
    logo,
    stars,
    stars_info,
    url
}: Queries.PrismicHomepageDataBodyMediaSliderItem) => {
    return (
        <>
            {use_rating && stars ? (
                <StarWrapper>
                    <Stars>
                        {getStars(stars)}
                    </Stars>
                    {stars_info?.richText && RichText.render(stars_info?.richText)}
                </StarWrapper>
            ) : logo?.gatsbyImageData ? (
                <ImageWrapper>
                    {url?.url ? (
                        <SlideUrl
                            href={url.url}
                            target={url?.target || '_self'}
                        >
                            <GatsbyImage
                                image={(logo?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={logo?.alt || ''}
                            />
                        </SlideUrl>
                    ) : (
                        <GatsbyImage
                            image={(logo?.gatsbyImageData as unknown) as IGatsbyImageData}
                            alt={logo?.alt || ''}
                        />
                    )
                    }
                </ImageWrapper>
            ) : null}
        </>
    )
}

export default Slide
