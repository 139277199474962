import { PageContext } from "../../../components/slices/types";
import { LineItem } from "../../playbrush-client/types";

export const concatSubscriptionHeading = (
    lineItem: LineItem,
    translations: PageContext['translations'],
) => {
    const { group_title_subscription_abo, group_title_subscription_game, group_title_subscription_brush_head } = translations.checkout;

    const games = lineItem?.lineContent?.find(({ tags }) => tags.includes('subscription') && tags.includes('games_sub'));
    const brushheads = lineItem?.lineContent?.find(({ tags }) => tags.includes('subscription') && tags.includes('brush_heads'));

    return `${group_title_subscription_abo}${games || brushheads ? ': ' : ''}${games ? group_title_subscription_game : ''}${games && brushheads ? ' & ' : ''}${brushheads ? group_title_subscription_brush_head : ''}`;
}