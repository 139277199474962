import { PageContext } from "../../../components/slices/types";
import { LineContent, LineItem } from "../../playbrush-client/types";


export const getLineItemType = (lineContent: LineContent | LineItem, translations: PageContext['translations']) => {
    const isBrush = lineContent.tags.includes('toothbrush');
    const isBrushHead = lineContent.tags.includes('brush_heads');

    if (isBrush) {
        return translations.checkout.brush;
    } else if (isBrushHead) {
        return translations.checkout.brush_head;
    } else {
        return;
    }
}