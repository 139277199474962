import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";

import Wrapper from "../../layout/wrapper";
import { PageContext } from "../../slices/types";
import Button from "../../snippets/button";
import OrderSummary from "../../snippets/order-summary/order-summary";
import CartList from "./components/cart-list";

const CartWrapper = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 130px 16px;
    margin: 0 auto;
    column-gap: 90px;

    @media screen and (min-width: 1024px) {
        flex-wrap: nowrap;
        padding: 200px 80px 0;
        margin: 0 auto;
    }
`

const EmptyCart = styled.div`
    // position: relative;
    text-align: center;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding=-bottom: 120px;
    }
`;

const EmptyCartFlair = styled.div`
    text-align: center;
    width: 100%;
    margin: 0 0 40px;
`;

const EmptyCartHeading = styled.h1`
    margin: 0 0 24px;
    // word-break: break-word;
`;
const EmptyCartText = styled.p`
    margin: 0 0 40px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: 32px;
    }
`;

const Cart = ({ data, translations, settings }: { data: Queries.PrismicCartPageDataType, translations: PageContext['translations'], settings: PageContext['settings'] }) => {
    const cart = useAppSelector(state => state.cart);

    return (
        <Wrapper>
            <CartWrapper>
                {cart.lineItemCount > 0 ? (
                    <>
                        <CartList data={data} translations={translations} settings={settings} />
                        <OrderSummary data={data} translations={translations} settings={settings} />
                    </>
                ) : (
                    <EmptyCart>
                        <EmptyCartFlair>
                            <GatsbyImage
                                image={(settings?.empty_cart_flair?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={(settings?.empty_cart_flair?.alt as unknown) as string}
                            />
                        </EmptyCartFlair>
                        <EmptyCartHeading>{translations.empty_cart_title}</EmptyCartHeading>
                        <EmptyCartText>{translations.empty_cart_text}</EmptyCartText>
                        <Button url={settings?.link_to_products?.url as string || ''} buttonStyle="solid">
                            {translations.empty_cart_button_text}
                        </Button>
                    </EmptyCart>
                )}
            </CartWrapper>
        </Wrapper >
    )
};

export default Cart;