import * as React from 'react'
import LanguageModal from '../snippets/modal/language-modal'

export const WrapRootBrowser = ({ element }: any) => (
    <>
        <script
            type='text/javascript'
            dangerouslySetInnerHTML={{ __html: `
                window.fwSettings={
                    widget_id: 17000000627,
                };
                
                !function(){if('function'!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
            `}}
        />
        {element}
        <LanguageModal/>
        <script
            type='text/javascript'
            src="https://widget.freshworks.com/widgets/17000000627.js"
            async
            defer
        />
        <script
            type='text/javascript'
            dangerouslySetInnerHTML={{ __html: `
                window.FreshworksWidget("setLabels", {
                    de: {
                        banner: "Wilkommen beim Support",
                        launcher: "Hilfe",
                        contact_form: {
                            title: "Kontaktiere uns",
                            submit: "Senden",
                            confirmation: "Danke für dein Feedback!"
                        }
                    }
                });
            `}}
        />
        <script
            type='text/javascript'
            dangerouslySetInnerHTML={{ __html: `
                window.FreshworksWidget("setLabels", {
                    it: {
                        banner: "Benvenuto al supporto",
                        launcher: "Aiuto",
                        contact_form: {
                            title: "Contattateci",
                            submit: "Inviare",
                            confirmation: "Grazie per il vostro feedback"
                        }
                    }
                });
            `}}
        />
        <script
            type='text/javascript'
            dangerouslySetInnerHTML={{ __html: `
                window.FreshworksWidget("setLabels", {
                    fr: {
                        banner: "Bienvenue au Support",
                        launcher: "Assistance",
                        contact_form: {
                            title: "Contactez-nous",
                            submit: "Envoyer",
                            confirmation: "Merci pour ton message"
                        }
                    }
                });
            `}}
        />
    </>
)
