import React, { SyntheticEvent, useState } from "react"
import styled from "styled-components";
import CopyIcon, { IconSvg } from "../../icons/copy-icon";
import { copyToClipboard } from "@utils";

export const CodeButton = styled.button`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    padding: 8px 16px;
    background-color: #EAF0F6;
    border-radius: 16px;
    border: 0;
    outline: 0;

    &:active {
        filter: brightness(0.8);
    }

    & > ${IconSvg} {
        margin-left: 8px;
    }
`;

interface ReferralCodeProps {
    code?: string;
    placeholder?: string;
    useIcon?: boolean;
}

const ReferralCode = ({
    code,
    placeholder,
    useIcon = true,
}: ReferralCodeProps) => {

    const copy = async () => {
        await copyToClipboard(code || '');
    }

    return (
        <CodeButton type="button" onClick={copy}>
            {code ? code : placeholder}
            {useIcon && <CopyIcon />}
        </CodeButton>
    )
};

export default ReferralCode;