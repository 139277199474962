import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { hasRichText, renderRich } from "@utils";
import Wrapper from "../../../layout/wrapper";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const InnerWrapper = styled.div`
    @media screen and (min-width: 768px) {
        max-width: 60%;    
    }
`

const Heading = styled.div`
        margin: 0 0 40px 0;
`

const TextWrapper = styled.div`
        margin: 0 0 16px 0;
`

const Text = styled.div<{ big_text: boolean }>`
    ${({ big_text }) => `
        ${big_text ? `
            p {
                font-size: 24px;
                line-height: 36px;
            }
        ` : ''
        }
    `}
`

const TextSectionSmall = (props: SliceProps<Queries.PrismicCareersPageDataBodyTextSectionSmall>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading } = primary;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    return (
        <Section>
            <Wrapper>
                <InnerWrapper>
                    {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
                    {items && items.map((item, index: number) => {
                        const [textHasRichText, textRichText] = hasRichText(item.text?.richText);

                        return (
                            <TextWrapper key={`text-section-${index}`}>
                                {textHasRichText && (<Text big_text={item?.big_text as boolean} >{renderRich(textRichText)}</Text>)}
                            </TextWrapper>
                        )
                    })}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default TextSectionSmall