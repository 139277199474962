import React from "react"
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import { Link } from "gatsby"

import { dropdownIconInline } from "../../../icons/drowndown-icon-inline";

import { MenuItem } from "../types";
import { concatMenuUrl } from "../mega-menu/utils";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getFormattedProductQueryString } from "@utils";

const MenuLink = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    font-size: 24px;
    padding: 0;

    &:hover {
        color: var(--light-green);
    }

    &.dropdown:after {
        content: url(${dropdownIconInline('#2BB573')});
        padding: 0 0 0 8px;
    }
`

const Description = styled.div`
    margin: 8px 0 24px 0;
    
    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
`

const Item = styled.div`
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 0px;
    margin: 0 0 24px 0;
`

const Image = styled(GatsbyImage)`
    max-width: 80px;
	max-height: 80px;
	margin: 0 0 8px 0;
`

const Content = styled.div`
    grid-area: 1 / 2 / 2 / 3;
`

const ItemHeading = styled.div`
	margin: 0 0 4px 0;

    h5 {
        font-size: 16px;
        line-height: 24px;
    }
`

const ItemDescription = styled.div`
	margin: 0 0 8px 0;
    
    p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
    }
`

const ButtonWrapper = styled.div`
    a {
        font-size: 16px;
        line-height: 20px;
    }
`

const Spacer = styled.div`
    margin: 24px 0;
    border-bottom: 1px solid #2BB57310;
    width: 100%;
`

interface MobileNavProps extends MenuItem {
    link: Queries.PrismicLinkType
    page: string;
    mega_menu_items: Queries.PrismicHeaderMenuItem;
    selectedId: string;
    handleActiveMenu: (id: string, menu?: Queries.PrismicHeaderMenuItem['data']) => void;
}

const MobileNavigationItem = (props: MobileNavProps) => {
    const { link_text, page, mega_menu_items: doc, selectedId, handleActiveMenu } = props;
    const id = doc?.id;
    const docUrl = concatMenuUrl(doc?.data?.button_url?.url, doc?.data?.link_to_section);

    return (
        <>
            <MenuLink className="dropdown" onClick={() => handleActiveMenu(id, doc?.data as Queries.PrismicHeaderMenuItem['data'])}>
                {link_text}
            </MenuLink>
            {selectedId != id && (
                <Spacer />
            )}
            {selectedId === id && (
                <>
                    {doc.data.description?.richText && (
                        <Description>{RichText.render(doc.data.description.richText)}</Description>
                    )}

                    {doc?.data?.item?.map((item, index) => {
                        const { item_title, item_description, mega_menu_image, link, link_to_section, bundle_preselect_index, bundle_type_preselect } = item || {};

                        const menuLink = 
                        (link?.document as Queries.PrismicProductPage)?.data && link?.url ?
                        `${
                            link.url
                        }${
                            getFormattedProductQueryString(
                                index + 1,
                                'mega-menu',
                                page,
                                bundle_preselect_index as number,
                                bundle_type_preselect as string
                            )
                        }` : link?.url

                        const url = concatMenuUrl(menuLink, link_to_section);

                        const LinkElement = !!link_to_section ? Link : AnchorLink;

                        return (
                            <React.Fragment key={`${index}-dropdown-item`}>
                                <LinkElement to={url || '#'} target={link?.target || "_self"}>
                                    <Item>
                                        {mega_menu_image?.gatsbyImageData && (
                                            <Image
                                                image={(mega_menu_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                                alt={mega_menu_image.alt || ''}
                                            />
                                        )}
                                        <Content>
                                            {item_title?.richText && (
                                                <ItemHeading>{RichText.render(item_title.richText)}</ItemHeading>
                                            )}
                                            {item_description?.richText && (
                                                <ItemDescription>{RichText.render(item_description.richText)}</ItemDescription>
                                            )}
                                        </Content>
                                    </Item>
                                </LinkElement>
                            </React.Fragment>
                        )
                    })}
                    {docUrl && doc.data.button_text && (
                        <ButtonWrapper>
                            <Button buttonStyle={"outline"}
                                url={docUrl || '#'}
                            >
                                {doc.data.button_text}
                            </Button>
                        </ButtonWrapper>
                    )}
                    <Spacer />
                </>
            )}
        </>
    )
};

export default MobileNavigationItem