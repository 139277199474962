import { throttle } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFromSessionStorage, setToSessionStorage, isBrowser } from '@utils';
import NewsletterSignupForm from '../../../snippets/newsletter-signup-form';
import { SliceProps } from '../../types';

const StyledPopUp = styled.div<{ success: boolean }>`
    position: fixed;
    bottom: 16px;
    right: 8px;
    left: 8px;
    background-color: #2BB573;
    z-index: 9999;
    border-radius: 24px;
    padding: 24px 24px;

    ${({ success }) => success ? `
        background-color: var(--dark-green);
        min-height: 24px;
        ` : `
        background-color: var(--light-green);
    `}

    @media screen and (min-width: 768px) {
        left: unset;
        right: 16px;
        max-width: 375px;
        width: 100%;
    }
`

const SuccessMessage = styled.p`
    position: absolute;
    font-weight: bold;
    color: white;
`

const StyledCloseButton = styled.button`
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: block;
    margin: 0 16px 0 0;
    border: 0;
    position: absolute;
    right: 0;
`

const COOKIE_NAME = 'plbrsh_newsletter_popup_signup_tt';

const PopUp = (props: SliceProps<Queries.PrismicHomepageDataBodyEmailPopUp>) => {
    const { slice } = props;
    const { primary } = slice;
    const { delay, success_message, subscription_form } = primary;
    const { document } = subscription_form || {};

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);

    const inBrowser = isBrowser();

    // const handleScroll = (e: any) => {
    //     if (!inBrowser) return;

    //     if (window?.scrollY > 600) {
    //         setShow(true);

    //         if (inBrowser) window?.removeEventListener('scroll', throttledHandleScroll);
    //     }
    //     return;
    // };

    // const throttledHandleScroll = throttle(handleScroll, 50);

    useEffect(() => {
        const hasBeenShown = getFromSessionStorage(COOKIE_NAME);

        if (inBrowser && !hasBeenShown && !timer) {
            // window?.addEventListener('scroll', throttledHandleScroll);
            const timeoutId = setTimeout(() => { setShow(true) }, delay || 500);
            setTimer(timeoutId);
        }
        return () => {
            if (inBrowser) {
                // window?.removeEventListener('scroll', throttledHandleScroll);
                timer && clearTimeout(timer);
            }
        };
    }, [])

    const callBack = (): void => {
        setSuccess(true);
        setToSessionStorage(COOKIE_NAME, 'true');
        setTimeout(() => { setShow(false) }, 2000);
    }

    const close = () => {
        setShow(false);
        setToSessionStorage(COOKIE_NAME, 'true');
    }

    return (
        <>
            {show && (
                <StyledPopUp success={success}>
                    {success && <SuccessMessage>{success_message}</SuccessMessage>}
                    <StyledCloseButton onClick={close}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.186 5.071 5.043 19.213M19.186 19.213 5.043 5.07" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" />
                        </svg>
                    </StyledCloseButton>
                    {document && !success && (
                        <NewsletterSignupForm
                            data={document as Queries.PrismicSubscriptionForm}
                            useInlineButton={true}
                            callBack={callBack}
                        />
                    )}
                </StyledPopUp>
            )}
        </>
    );
}

export default PopUp;
