export default `
    mutation(
        $email: String!,
        $language: String!,
        $tags: [String]!,
    ) {
        newsletterSubscribe(
            email: $email
            language: $language
            tags: $tags
        ) {
            success
        }
    }
`