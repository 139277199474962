import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CleanVariant } from '../../utils/product/get-clean-variants';

import {
    setVariantsAction,
    setColorAction,
    setBrushAction,
    setBundleAction,
    setSizeAction
} from './product-actions';

export interface IState {
    variants?: CleanVariant[],
    selectedVariant?: CleanVariant,
    selectedColor: string,
    selectedBrush: string,
    selectedSize: string,
    selectedBundle: string,
}

const initialState: IState = {
    variants: [],
    selectedVariant: {} as CleanVariant,
    selectedColor: '',
    selectedBrush: '',
    selectedSize: '',
    selectedBundle: '',
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setVariants: (state, action: PayloadAction<Queries.PrismicProductVariantDataType[]>) => {
            return setVariantsAction(state, action.payload);
        },
        setColor: (state, action: PayloadAction<string>) => {
            state.selectedColor = action.payload;
            return setColorAction(state, action.payload);
        },
        setBrush: (state, action: PayloadAction<string>) => {
            state.selectedBrush = action.payload;
            return setBrushAction(state, action.payload);
        },
        setSize: (state, action: PayloadAction<string>) => {
            state.selectedSize = action.payload;
            return setSizeAction(state, action.payload);
        },
        setBundle: (state, action: PayloadAction<string>) => {
            state.selectedBundle = action.payload;
            return setBundleAction(state, action.payload);
        },
        setInitialProductState: (state) => {
            // @ts-ignore
            state = initialState;
            return state;
        },
        setNewInitialState: (state, action: PayloadAction<IState>) => {
            // @ts-ignore
            state = action.payload;
            return state;
        },
    }
})

export const {
    setVariants,
    setColor,
    setBrush,
    setSize,
    setBundle,
    setInitialProductState,
    setNewInitialState
} = productSlice.actions

export default productSlice.reducer