import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";
import { hasRichText, renderRich } from "@utils";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        margin: 120px auto 0;
    }

    // @media screen and (min-width: 1200px) {
    //     width: 1064px;
    // }
`

const CtaContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px;
    
    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        padding: 50px 40px;
    }
`

const Title = styled.div`
    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
    }
`

const CtaTeaser = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyCtaTeaser>) => {
    const { slice } = props;
    const { primary } = slice;
    const { button_link, button_text, cta_title } = primary;
    const [titleHasRichText, titleRichText] = hasRichText(cta_title?.richText);
    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    return (
        <Section id="Cta-teaser">
            <Wrapper>
                {(titleHasRichText || buttonTextHasRichText) && (
                    <CtaContainer>
                        {titleHasRichText && (
                            <Title>
                                {renderRich(titleRichText)}
                            </Title>
                        )}
                        {buttonTextHasRichText && (
                            <Button
                                url={button_link?.url || ''}
                                buttonStyle={'solid'}
                                buttonFullWidth={'full-mobile'}
                            >
                                {renderRich(buttonTextRichText)}
                            </Button>
                        )}
                    </CtaContainer>
                )}
            </Wrapper>
        </Section>
    )
};

export default CtaTeaser