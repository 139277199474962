import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { SliceProps } from "../../types";

const Wrapper = styled.section`
    max-width: 848px;
    padding: 40px 0;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        padding: 80px 80px 0;
    }
`

const TextWrapper = styled.div`
    max-width: 848px;
    margin: 0 auto;
    
    @media screen and (min-width: 768px) {
        margin: 0 auto;
    }
`

const Text = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;

    h3 {
        font-size: 24px;
        font-weight: 400;
        padding: 0 0 32px 0;
    }

    a {
        color: var(--light-green) !important;
        font-size: 16px;
    }

    @media screen and (min-width: 768px) {
        h3 {
            padding: 0 0 24px 0;
        }
    }
`

const BlogTextSection = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyPlainText>) => {
    const { slice } = props;
    const { primary } = slice;
    const { text } = primary;

    return (
        <Wrapper>
            <TextWrapper>
                {text?.richText && (
                    <Text className="rt">{RichText.render(text.richText)}</Text>
                )}
            </TextWrapper>
        </Wrapper>
    )
};

export default BlogTextSection