export const getFormattedProductQueryString = (
    index?: number,
    section_id?: string,
    page?: string,
    bundle_preselect_index?: number,
    preselect_type?: string,
) => {
    const returnValues = [];

    if (typeof index === 'number') {
        returnValues.push(`position=${index}`);
    }

    if (section_id) {
        returnValues.push(`section_id=${section_id}`);
    }

    if (page) {
        returnValues.push(`section_name=${page}`);
    }

    if (typeof bundle_preselect_index === 'number') {
        returnValues.push(`preselect=${bundle_preselect_index}`);
    }

    if (typeof preselect_type === 'string') {
        returnValues.push(`preselect_type=${preselect_type}`);
    }

    if (!returnValues.length) {
        return '';
    }

    return '?' + returnValues.join('&');
}