import React from "react";
import styled from "styled-components";
import PlusIcon from "../../../../icons/plus-icon";
import BundleListItem from "./bundle-list-item";
import ManualBundleListItem from "./manual-bundle-list-item";
import VariantBlock from "./variant-block";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    flex-wrap: wrap;
    row-gap: 32px;
	
	@media screen and (min-width: 768px) {
	}
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    margin: 0 8px;
	
	@media screen and (min-width: 768px) {

	}
`

const ManualBundleList = ({ items }: any) => {

    return (
        <Container>
            {items.map((item: any, index: number) => (
                <React.Fragment key={index}>
                    {index !== 0 && index !== 3 ? (
                        <IconContainer>
                            <PlusIcon />
                        </IconContainer>
                    ) : null}
                    <ManualBundleListItem item={item} />
                </React.Fragment>
            ))}
        </Container>
    )
}

export default ManualBundleList;