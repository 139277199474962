import React from "react";
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import Quotation from "../../../icons/quotation-icon";

import { hasRichText, renderRich } from "../../../../utils/common/rich-text";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Heading = styled.div`
    text-align: left;
    padding: 0 0 46px 0;

    @media screen and (min-width: 768px) {
        text-align: center;
    }
`

const TeamMembers = styled.div`
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
    }
`

const TeamMember = styled.article`
    display: flex;
    align-items: center;
    border-bottom: 2px solid #2BB573;
    padding: 20px 0;
    margin: 0 auto;

    @media screen and (min-width: 768px) {  
        display: block;
        border: none;  
        cursor: pointer;

        &:hover {
            .hoverbox {
                display: block;
                opacity: 1;
            }
            .avatar {
                opacity: 1;
                z-index: 8;
            }
        }
    }
`

const Avatar = styled(GatsbyImage)`
    z-index: 4;
    opacity: 1;
    flex: 0 0 80px;

    img {
        width: 80px !important;
        height: 80px !important;
        border-radius: 50%;
    }
    
    @media screen and (min-width: 768px) { 
        display: block;
        margin: 0 auto;
        opacity: .5;

        img {
            width: 103px !important;
            height: 103px !important;
        }
    }
`


const hoverBoxPadding = 40;
const Hoverbox = styled.div`
    display: none;
    position: absolute;
    background: white;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    padding: 59px ${hoverBoxPadding}px 53px;
    transform: translate(calc(-50% + ${hoverBoxPadding * 2}px), -52px);
    max-width: 361px;
    z-index: 6;
`

const Name = styled.div`
    padding: 24px 0 0 0;

    p {
        &:before {
            content:"— ";
        }
    }
`

const Description = styled.div`
    padding: 16px 0 0 0;
    
    p   {
        font-weight: 700;
    }

    @media screen and (min-width: 768px) { 
    }
`

const MobileNameWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;

    @media screen and (min-width: 768px) { 
        display: none;
    }
`

const MobileName = styled.div`
    padding: 0 0 0 24px;
    font-weight: 700;

    p {
        &:before {
            content:"— ";
        }
    }
`

const MobileDescription = styled.div`
    padding: 0 0 0 24px;
    width: 100%;

    @media screen and (min-width: 768px) { 
        display: none;
    }
`

const TeamGrid = (props: SliceProps<Queries.PrismicAboutPageDataBodyTeamGrid>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading } = primary;

    return (
        <Section id="team-grid">
            <Wrapper>
                {heading && heading?.richText && (
                    <Heading>
                        {RichText.render(heading.richText)}
                    </Heading>
                )}
                <TeamMembers>
                    {items && items.map((item, index: number) => {

                        const [employeeNameHasRichText, employeeNameRichText] = hasRichText(item?.employee_name?.richText);
                        const [descriptionHasRichText, descriptionRichText] = hasRichText(item?.description?.richText);

                        return (
                            <TeamMember key={`team-member-${index}`}>
                                <Avatar
                                    className="avatar"
                                    image={(item.image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                    alt={item.image?.alt || ''}
                                />
                                <MobileNameWrapper>
                                    {employeeNameHasRichText && <MobileName>{renderRich(employeeNameRichText)}</MobileName>}
                                    {descriptionHasRichText && <MobileDescription>{renderRich(descriptionRichText)}</MobileDescription>}
                                </MobileNameWrapper>
                                <Hoverbox className="hoverbox">
                                    <Quotation />
                                    {employeeNameHasRichText && <Name>{renderRich(employeeNameRichText)}</Name>}
                                    {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                                </Hoverbox>
                            </TeamMember>
                        )
                    })}
                </TeamMembers>
            </Wrapper>
        </Section>
    )
};

export default TeamGrid