import React from "react"
import styled from "styled-components";

import Button from "../../snippets/button";
import CloseIcon from "../../icons/close-icon";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDrawerState } from "../../../app/slices/cart-reducer";
import { navigate } from "gatsby";
import { PageContext } from "../../slices/types";
import LineItem from "../../sections/cart-page/components/line-item/line-item";
import { getTotalPriceFromCart } from "@utils";

const CartWrapper = styled.aside`
	position: fixed;
	display: grid;
	grid-template-rows: auto 1fr auto;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
    width: 100%;
	max-width: 500px;

	background-color: #fff;
`

const HeadingWrapper = styled.article`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 24px 24px 0 24px;
`

const Heading = styled.h4`
`

const Content = styled.div`
    padding: 0 24px 120px;
    overflow: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`

const EmptyCart = styled.div`
    text-align: center;
`

const EmptyHeading = styled.h3`
    margin: 0 0 24px 0;
`

const EmptyMessage = styled.p`
    margin: 0 0 40px 0; 
`

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 16px 0px #0000001A;
    padding: 24px 24px 16px 24px;
    align-self: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // z-index: 120;
    background: white;
`

const PriceWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: 0 0 16px 0;
    gap: 5px;
`

const PriceText = styled.span`
    font-weight: 700;
`

const ShippingText = styled.span`
    font-size: 10px;
    flex-grow: 1;

    &.free-shipping {
        color: #02A944;  
        text-transform: uppercase;
    }
`

const TotalPrice = styled.span`
    font-weight: 700;
`
const CloseIconHolder = styled.div`
    &:hover {
        cursor: pointer;
    }
`

interface QuickCartProps {
    translations: PageContext['translations'];
    settings: PageContext['settings'];
}

const QuickCart = ({ translations, settings }: QuickCartProps) => {
    const [cart, lang] = useAppSelector(state => [state.cart, state.general.lang]);
    const dispatch = useAppDispatch();

    const totalPrice = getTotalPriceFromCart(cart);

    const close = () => {
        dispatch(setDrawerState(false))
    }

    const goToCart = () => {
        close()
        navigate(`/${lang}/cart`);
    }

    const goToProducts = () => {
        close()
        navigate(settings?.link_to_products?.url as string || '/');
    }

    return (
        <CartWrapper>
            <HeadingWrapper>
                <Heading>
                    {translations.heading}
                </Heading>
                <CloseIconHolder onClick={close} >
                    <CloseIcon />
                </CloseIconHolder>
            </HeadingWrapper>
            <Content>
                {cart.lineItems.map((lineItem) => (
                    <LineItem key={lineItem.lineId} lineItem={lineItem} translations={translations} settings={settings} quickCart={true} />
                ))}
                {cart.lineItemCount === 0 && (
                    <EmptyCart>
                        <EmptyHeading>
                            {translations.empty_cart_title}
                        </EmptyHeading>
                        <EmptyMessage>
                            {translations.empty_cart_text}
                        </EmptyMessage>
                        <Button type="button" buttonStyle={"solid"} onClick={goToProducts}>
                            {translations.empty_cart_button_text}
                        </Button>
                    </EmptyCart>
                )}
            </Content>
            {cart.lineItemCount > 0 && (
                <FooterWrapper>
                    {/* <UpgradeBar translations={translations} /> */}
                    <PriceWrapper>
                        <PriceText>
                            {translations.total}
                        </PriceText>
                        <ShippingText className="free-shipping">
                            {translations.shipping_line}
                        </ShippingText>
                        <TotalPrice>
                            {totalPrice}
                        </TotalPrice>
                    </PriceWrapper>
                    <Button
                        buttonStyle={"solid"}
                        buttonFullWidth={'full'}
                        onClick={goToCart}
                    >
                        {translations.go_to_cart}
                    </Button>
                </FooterWrapper>
            )}
        </CartWrapper>
    )
};

export default QuickCart;
