import React from "react"
import styled from "styled-components";
import { useAppSelector } from "../../../../app/hooks";
import { PageContext } from "../../../slices/types";
import { constants, Audience } from "@constants";

const SwitcherWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	background: var(--bg-light-grey);
	border-radius: 100px;
    padding: 4px;
`

const SwitchButton = styled.button<{ active: boolean }>`
	text-decoration: none;
    border: 0;
    outline: 0;
    background-color: transparent;
    padding: 16px;
	border-radius: 100px;
	line-height: 17px;
    cursor: pointer;
    color: var(--dark-grey);

    ${({ active }) => active ? `
        background: var(--light-green);
        color: #fff;
    ` : ''}
`

interface SwitcherProps {
    translations: PageContext['translations'];
    goTo: (audience: Audience) => void;
}

const Switcher = ({ translations, goTo }: SwitcherProps) => {
    const audienceFromState = useAppSelector(state => state.general.audience);

    return (
        <SwitcherWrapper>
            <SwitchButton
                type="button"
                active={audienceFromState === constants.audience.KIDS}
                onClick={() => goTo(constants.audience.KIDS)}
            >
                {translations?.kids_translation || 'Kids'}
            </SwitchButton>
            <SwitchButton
                type="button"
                active={audienceFromState === constants.audience.ADULTS}
                onClick={() => goTo(constants.audience.ADULTS)}
            >
                {translations?.adults_translation || 'Adults'}
            </SwitchButton>
        </SwitcherWrapper>
    )
};

export default Switcher