import React from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";
import { RichText } from "prismic-reactjs";

import { SliceProps } from "../../types";

const Section = styled.section`
    gap: 80px;
    margin: 0 0 32px;
	
	@media screen and (min-width: 768px) {
		padding: 0 80px;
        margin: 0 auto;
        max-width: 848px;
	}
`

const Items = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const Item = styled.div`
    flex: 0 0 50%;

    p {
        padding: 16px 0 0 0;
    }
`

const Context = styled.div`
    margin: 0 0 40px;
`

const Image = styled(GatsbyImage)`
    @media screen and (min-width: 768px) {
        height: auto;
        width: 416px;
    }
`

const TwoColumnBlog = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyTwoColumnBlog>) => {
    const { slice } = props;
    const { items } = slice;

    return (
        <Section id="two-column-blog">
            <Items>
                {items?.map((
                    {
                        text,
                        image
                    },
                    index
                ) => (
                    <Item key={`${index}-item`}>
                        <Context>
                            {text?.richText && RichText.render(text.richText)}
                        </Context>
                        {image?.gatsbyImageData &&
                            <Image
                                key={`${index}-image`}
                                image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                alt={image?.alt || ''}
                            />
                        }
                    </Item>
                ))}
            </Items>
        </Section>
    )
}

export default TwoColumnBlog