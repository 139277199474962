import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const GroupIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.3478 24.5755C34.3478 23.2163 34.0801 21.8703 33.5599 20.6146C33.0397 19.3588 32.2773 18.2178 31.3162 17.2567C30.3551 16.2955 29.2141 15.5331 27.9583 15.013C26.7025 14.4928 25.3566 14.2251 23.9974 14.2251C22.6381 14.2251 21.2922 14.4928 20.0364 15.013C18.7807 15.5331 17.6397 16.2955 16.6785 17.2567C15.7174 18.2178 14.955 19.3588 14.4348 20.6146C13.9147 21.8703 13.647 23.2163 13.647 24.5755"
            stroke={color} />
        <circle cx="23.9973" cy="6.17479" r="5.25022"
            stroke={color} />
        <path d="M46.9962 22.2754C46.9962 21.2182 46.7879 20.1714 46.3834 19.1947C45.9788 18.218 45.3858 17.3305 44.6383 16.583C43.8907 15.8354 43.0033 15.2425 42.0266 14.8379C41.0499 14.4333 40.003 14.2251 38.9459 14.2251C37.8887 14.2251 36.8418 14.4333 35.8651 14.8379C34.8884 15.2425 34.001 15.8354 33.2534 16.583"
            stroke={color} />
        <circle cx="38.9439" cy="7.32479" r="4.10018"
            stroke={color} />
        <path d="M0.999929 22.2754C0.999929 21.2182 1.20816 20.1714 1.61272 19.1947C2.01729 18.218 2.61027 17.3305 3.35781 16.583C4.10535 15.8354 4.99281 15.2425 5.96952 14.8379C6.94623 14.4333 7.99306 14.2251 9.05024 14.2251C10.1074 14.2251 11.1543 14.4333 12.131 14.8379C13.1077 15.2425 13.9951 15.8354 14.7427 16.583"
            stroke={color} />
        <circle r="4.10018" transform="matrix(-1 0 0 1 9.05216 7.32479)"
            stroke={color} />
    </IconSvg>
);

export default GroupIcon;
