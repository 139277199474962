import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import getIcon from "../../../icons/icon-renderer";
import Wrapper from "../../../layout/wrapper";
import Button from "../../../snippets/button";
import { PrismicRichText } from "@prismicio/react";

const Section = styled.section`
    max-width: 1440px;
    padding: 120px 16px 0;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        padding: 80px 80px 0;
        flex-wrap: nowrap;
        margin: 0 auto;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        gap: 60px;
    }
`

const Content = styled.div`
    flex: 0 1 100%;

    @media screen and (min-width: 768px) {
        flex: 0 1 50%;
    }
`

const Heading = styled.div`
    margin: 0 auto 16px;

    h1 {
        font-weight: 400;
        font-size: 40px;
    }

    @media screen and (min-width: 768px) {
        h1 {
            margin: 0 0 40px 0;
            font-size: 56px;
            line-height: 130%;
        }
    }
`

const Description = styled.div`
    margin: 0 0 24px 0;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
    }

    @media screen and (min-width: 768px) {
        margin: 0 0 56px 0;

        p {
            font-size: 24px;
        }
    }

`

const Icon = styled.a`
    margin: 0 24px 0 0;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
    @media screen and (min-width: 768px) {
        margin-bottom: 40px;
    }
`

const TextAndContactform = (props: SliceProps<Queries.PrismicContactPageDataBodyTextContactform>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading, description, form_button_text } = primary;

    const openForm = () => {
        if (window.FreshworksWidget) {
            try {
                window.FreshworksWidget('open');
            } catch(e) {
                console.error(e);
            }
        }
    }

    return (
        <Section id="text-and-contactform">
            <Wrapper>
                <InnerWrapper>
                    <Content className={"rt"}>
                        <PrismicRichText field={heading?.richText} />
                        <PrismicRichText field={description?.richText} />
                        <IconWrapper>
                            {items && items.map((props, index) => {
                                const { social_media_icon, social_media_link } = props || {};
                                return (
                                    <Icon
                                        key={`${index}-icon`}
                                        href={social_media_link?.url || ''}
                                        target="_blank"
                                    >
                                        {social_media_icon && getIcon(social_media_icon, 'var(--light-green)', index)}
                                    </Icon>
                                )
                            })}
                        </IconWrapper>
                    </Content>
                    <ButtonContainer>
                        <Button onClick={openForm}>
                            {form_button_text}
                        </Button>
                    </ButtonContainer>
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default TextAndContactform