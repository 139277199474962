import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SanitizedBundle } from '../../utils';

import { setBundlesAction } from './bundles-actions';

const initialState: SanitizedBundle[][] = [];

const bundlesSlice = createSlice({
    name: 'bundles',
    initialState,
    reducers: {
        setBundles: (state, action: PayloadAction<SanitizedBundle[][]>) => {
            return setBundlesAction(state, action);
        },
    }
})

export const { setBundles } = bundlesSlice.actions
export default bundlesSlice.reducer