export const CURRENCY_PREFERENCE_KEY = '__pb-currency-preference';

export interface LanguageSelectOption {
    currency: string;
    language: string;
    country: string;
}

export const languageOptions: LanguageSelectOption[] = [
    {
        currency: 'EUR',
        language: 'DE',
        country: 'DE',
    },
    {
        currency: 'GBP',
        language: 'EN',
        country: 'GB',
    },
    // {
    //     currency: 'EUR',
    //     language: 'EN',
    //     country: 'EN',
    // }
]
