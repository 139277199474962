import { CompareBrushFeatures, VideoSlider } from "../../components/slices/how-it-works-page"

import { FeatureGrid, FeaturedProducts, TextImageRight, Testimonials, HorizontalScroll, VideoGrid, HeroImagecloud, NewsletterPopUp } from '../../components/slices/generic';
import AccordionWithFilters from '../../components/slices/generic/accordion-with-filters/accordion-with-filters';

export const components = {
    hero___imagecloud: HeroImagecloud,
    brush_features: CompareBrushFeatures,
    horizontal_scroll: HorizontalScroll,
    feature_grid: FeatureGrid,
    featured_products: FeaturedProducts,
    app_section: VideoGrid,
    testimonials: Testimonials,
    plain_text: TextImageRight,
    video_slider: VideoSlider,
    accordion_with_filters: AccordionWithFilters,
    'email_pop-up': NewsletterPopUp,
}