export default `
    query( $id: String!) {
        products(id: $id) {
            id
            description
            productId
            name
            image
            metadata
            tags
            prices {
                priceId
                type
                currency
                unitAmount
                unitAmountDecimal
            }
        }
    }
`