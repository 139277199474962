export default `
    mutation( $couponCode: String! ) {
        referralLink(couponCode: $couponCode) {
            couponCode {
                couponAmount
                couponRewardAmount
                couponText
                couponValidTill
                couponcode
                created
                email
                firstName
                id
                lastName
                modified
                userId
                vendorDbId
                couponLinkReferral
                couponLinkReferralKidsApp
                couponLinkReferralKidsWebsite
                couponLinkDiscount
                couponLinkDiscountKidsApp
                couponLinkDiscountKidsWebsite
                couponLinkDiscountFacebookKidsWebsite
                couponLinkDiscountEmailKidsWebsite
                couponLinkDiscountWhatsappKidsWebsite 
            }
        }
    }
`