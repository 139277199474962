export default `
    query(
        $order_by: [SortField]
        $where: [WhereField]
        $country: String
        $currency: String
        $language: String
    ) {
        v2Bundles (
            limit: 200
            orderBy: $order_by
            where: $where
            country: $country
            currency: $currency
            language: $language
        ) {
            bundleId
            name
            image
            translations {
                description
                nameOrder
                nameMarketing
                images
                labels
            }
            isCrossedPriceVisible
            prices {
                priceAmountPayNowCurrent
                priceAmountPayNowCrossed
                priceAmountOneTimeCurrent
                priceAmountOneTimeCrossed
                priceAmountPayAfterTrialCurrent
                priceAmountRecurringCurrent
                recurringTrialPeriodDays
                savings
            }
            productsExtended {
                image
                translations {
                    prodImageTitle
                    prodImageCornerNote
                    prodImageTooltipText
                }
            }
            tags
        }
    }
`