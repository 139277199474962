import React, { useEffect } from "react"
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setBrush, setColor } from "../../../../../app/slices/product-reducer";
import { VariantBlockProps } from "../../types";

const Container = styled.div`

	@media screen and (min-width: 768px) {

	}
`

const Header = styled.header`
    margin: 0 0 16px;
    display: flex;
    justify-content: space-between;

	@media screen and (min-width: 768px) {

	}
`

const Fieldset = styled.fieldset`
    display: flex;
    border: 0;
    padding: 0;
	
	@media screen and (min-width: 768px) {

	}
`

const BlockTitle = styled.h5`
    margin: 0 8px 0 0;
	
	@media screen and (min-width: 768px) {
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
	}
`

const BlockLink = styled.a`
    font-weight: 400;
    text-align: right;
	
	@media screen and (min-width: 768px) {
	}
`

const Label = styled.label`
    margin-right: 8px;
	
	@media screen and (min-width: 768px) {

	}
`

const Input = styled.input`
    display: none;

    // & + div:hover,
    // & + p:hover,
    // &:checked + div,
    // &:checked + p {
    //     border-color: var(--light-green);
    //     color: var(--light-green);
    //     cursor: pointer;
    // }
	
	@media screen and (min-width: 768px) {

	}
`

const ColorContainer = styled.div<{ selected: boolean }>`
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 100px;

    ${({ selected }) => selected ? `
        border-color: var(--light-green);
        color: var(--light-green);
        cursor: pointer;
    ` : ''}
	
	@media screen and (min-width: 768px) {

	}
`

const Color = styled.div<{ color: string; }>`
    position: absolute;
    top: 5px;
    right: 4px;
    bottom: 4px;
    left: 5px;
    background-color: ${({ color }) => color};
    border-radius: 100px;
	
	@media screen and (min-width: 768px) {

	}
`

const Brush = styled.p<{ selected: boolean, disabled?: boolean }>`
    border-radius: 100px;
    min-width: 80px;
    text-align: center;
    border: 1px solid;
    border-color: var(--light-green);
    padding: 3px 16px 3px;

    ${({ selected }) => selected ? `
        border-color: var(--light-green);
        color: var(--light-green);
        cursor: pointer;
    ` : ''}

    ${({ disabled }) => disabled ? `
        text-decoration: line-through;
        opacity: 0.4;
    ` : ''}
	
	@media screen and (min-width: 768px) {

	}
`


const VariantBlock = ({ forType, url, options, title, linkText, translations, currentlyAvailableBrushTypes = [] }: VariantBlockProps) => {
    const isForBrush = forType === 'brush';
    const [selectedColor, selectedBrush] = useAppSelector(state => [state.product.selectedColor, state.product.selectedBrush]);
    const dispatch = useAppDispatch();

    const containsVariant = (option: string): boolean => {
        return isForBrush ? selectedBrush === option : selectedColor === option;
    }

    const selectNewVariant = (v: string) => {
        isForBrush ? dispatch(setBrush(v)) : dispatch(setColor(v));
    }


    const getInput = (type: string, option: string) => {
        switch (type) {
            case 'color':
                return (
                    <ColorContainer selected={containsVariant(option)}>
                        <Color color={option} />
                    </ColorContainer>
                )
            case 'brush':
                return (
                    <Brush selected={containsVariant(option)} disabled={!currentlyAvailableBrushTypes.includes(option)}>
                        {/* @ts-ignore */}
                        {translations[`brush_hardness_${option?.toLowerCase()}`]}
                    </Brush>
                )
            case 'size':
                return (
                    <Brush selected={containsVariant(option)}>
                        {option}
                    </Brush>
                )
            default:
                return null;
        }
    }

    return (
        <Container>
            <Header>
                <BlockTitle>{title}</BlockTitle>
                {url && options.length > 1 && (
                    <BlockLink href={url}>{linkText}</BlockLink>
                )}
            </Header>
            <Fieldset id={forType}>
                {options && options.map((option: string, index: number) => (
                    <Label key={`${index}-option`} onClick={() => selectNewVariant(option)}>
                        <Input
                            type="radio"
                            value={option}
                            disabled={!currentlyAvailableBrushTypes.includes(option)}
                        />
                        {getInput(forType, option)}
                    </Label>
                ))}
            </Fieldset>
        </Container>
    )
}

export default VariantBlock;