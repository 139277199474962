import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from "styled-components";
import { SliceZone } from "@prismicio/react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"

import { components } from "./components"
import { PageContext } from "../../components/slices/types";
import ToTopButton from "../../components/snippets/to-top-button";
import { useAppSelector } from "../../app/hooks";
import { logPageView, getSanitizedBundles } from "@utils";
import linkResolver from '../../utils/prismic/link-resolver';

const PageContent = styled.section`
`

const AboutPage = (props: PageProps<Queries.AboutPageQuery, PageContext>): JSX.Element => {
    const globalAudience = useAppSelector(state => state.general.audience);
    const { data, location, pageContext } = props;
    const { prismicAboutPage, allPrismicHeader, prismicFooter, allPrismicProductVariant } = data;
    const { lang, data: aboutPageData } = prismicAboutPage || {};
    const { page_title, page_description, page_image, body } = aboutPageData || {}
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings } = pageContext;
    const bundles = getSanitizedBundles(allPrismicProductVariant.nodes as Queries.PrismicProductVariant[]);
    const page = 'about';
    const pageImage = page_image?.url || '';

    useEffect(() => {
        logPageView({ content_group: page });
    }, []);

    return (
        <Layout
            bundles={bundles}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            page={page}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={page_title || 'About'} description={page_description || 'About page'} lang={lang?.split('-')[0]} image={pageImage} canonicalUrl={`https://playbrush.com${location.pathname}`}/>
            <PageContent>
                <SliceZone
                    slices={body}
                    // @ts-ignore
                    components={components}
                    context={{
                        audience,
                        translations,
                        page,
                    }}
                />
                {translations?.back_to_top && <ToTopButton text={translations.back_to_top} />}
            </PageContent>
        </Layout>
    )
}

export const query = graphql`
    query AboutPage($id: String, $lang: String) {
        prismicAboutPage(id: {eq: $id}, lang: {eq: $lang}) {
            _previewable
            href
            url
            prismicId
            tags
            type
            lang
            uid
            data {
                page_title
                page_image {
                    alt
                    url
                }
                page_description
                body {
                    ... on PrismicAboutPageDataBodyEmailPopUp {
                        id
                        slice_type
                        primary {
                            delay
                            success_message
                            subscription_form {
                                document {
                                    ... on PrismicSubscriptionForm {
                                        id
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicAboutPageDataBodyHeroImagecloud {
                        id
                        slice_type
                        primary {
                            content_position
                            button_link {
                                url
                                target
                            }
                            button_style
                            button_text {
                                richText
                            }
                            heading {
                                richText
                            }
                            text {
                                richText
                            }
                            bluetooth_text {
                                richText
                            }
                            bluetooth_image {
                                alt
                                gatsbyImageData
                            }
                            facebook_username {
                                richText
                            }
                            instagram_username {
                                richText
                            }
                        }
                        items {
                            image {
                                alt
                                gatsbyImageData
                            }
                            desktop_position_y_axis
                            desktop_position_x_axis
                            desktop_z_index
                            desktop_width
                            mobile_position_y_axis
                            mobile_position_x_axis
                            mobile_z_index
                            mobile_width
                            hide_on_desktop
                            hide_on_mobile
                        }
                    }
                    ... on PrismicAboutPageDataBodyBenefitsGrid {
                        id
                        slice_type
                        items {
                            item_description {
                                richText
                            }
                            item_title {
                                richText
                            }
                            link {
                                url
                                target
                            }
                            link_text {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                            subtitle {
                                richText
                            }
                            text {
                                richText
                            }
                        }
                    }
                    ... on PrismicAboutPageDataBodyCtaTeaser {
                        id
                        slice_type
                        primary {
                            button_link {
                                url
                                target
                            }
                            button_text {
                                richText
                            }
                            cta_title {
                                richText
                            }
                        }
                    }
                    ... on PrismicAboutPageDataBodyFeatureGrid {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                        }
                        items {
                            feature {
                                richText
                            }
                            image {
                                alt
                                gatsbyImageData
                            }
                            subtitle {
                                richText
                            }
                            title {
                                richText
                            }
                        }
                    }
                    ... on PrismicAboutPageDataBodyHorizontalScroll {
                        id
                        slice_type
                        items {
                            background_color
                            body_text {
                                richText
                            }
                            cta_link {
                                url
                                target
                            }
                            cta_text
                            heading {
                                richText
                                text
                            }
                            image {
                                alt
                                gatsbyImageData
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                        }
                    }
                    ... on PrismicAboutPageDataBodyRelatedArticles {
                        id
                        items {
                            article_title {
                                richText
                            }
                            button_link {
                                url
                                target
                            }
                            button_text {
                                richText
                            }
                            description {
                                richText
                            }
                            image {
                                alt
                                gatsbyImageData
                            }
                            reading_time1 {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                            split_layout
                        }
                        slice_type
                    }
                    ... on PrismicAboutPageDataBodyTeamGrid {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                        }
                        items {
                            description {
                                richText
                            }
                            employee_name {
                               richText
                            }
                            image {
                                alt
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                    tags
                                                    price_recurring_trial_period_days
                                                    price_recurring_interval_count
                                                    price_recurring_interval
                                                    price_original_after_trial
                                                    price_original
                                                    price_amount_after_trial
                                                    price_amount
                                                    name
                                                    default_price
                                                    image
                                                    description
                                                    active
                                                    metadata {
                                                        prod_image_corner_note_EN
                                                        prod_image_title_DE
                                                        prod_image_title_EN
                                                        prod_image_tooltip_text_DE
                                                        prod_image_tooltip_text_EN
                                                        prod_marketing_title_DE
                                                        prod_marketing_title_EN
                                                        prod_order_title_DE
                                                        prod_order_title_EN
                                                        tags
                                                        prod_description_DE
                                                        prod_description_EN
                                                        prod_image_corner_note_DE
                                                    }
                                                    _id
                                                    description_de
                                                    description_en
                                                }
                                                product
                                                price_default_blob {
                                                    active
                                                    currency
                                                    _id
                                                    product
                                                    recurring_interval
                                                    recurring_interval_count
                                                    recurring_trial_period_days
                                                    type
                                                    unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                            price_amount_one_time
                                            price_amount_recurring
                                            price_original_after_trial
                                            price_original_one_time
                                            price_original_recurring
                                            price_shipping_eur
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        menu_bundle_type_preselect
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        bundle_preselect_index
                                        button_bundle_type_preselect
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                            bundle_type_preselect
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(
    AboutPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
    }]
);
