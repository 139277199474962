import React from "react";
import styled from "styled-components";
import { SliceProps } from "../types";
import { hasRichText, renderRich } from "@utils";
import Wrapper from "../../layout/wrapper";
import Button from "../../snippets/button";

const Section = styled.section`
    margin: 20px 80px 0;
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`

const CookieSettings = (props: SliceProps<Queries.PrismicTextPageDataBodyCookieSettings>) => {
    const { slice } = props;
    const { primary } = slice;
    const { button_text } = primary;

    const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

    const handleClick = () => {
        // @ts-ignore
        window?.UC_UI?.clearStorage();
        // @ts-ignore
        window?.UC_UI?.restartCMP();
    }

    return (
        <Section>
            <Wrapper>
                <InnerWrapper>
                    {buttonTextHasRichText && (<Button onClick={handleClick}>{renderRich(buttonTextRichText)}</Button>)}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
};

export default CookieSettings