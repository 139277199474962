import React, { useEffect, useState } from "react"
import styled from "styled-components";
import VariantSelector from './form-snippets/variant-selector';
import SubscriptionSelector from "./form-snippets/subscription-selector";
import AddToCart from "./form-snippets/add-to-cart";
import BundleList from "./form-snippets/bundle-list";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import Client from "../../../../utils/playbrush-client/client";
import { setCart } from "../../../../app/slices/cart-reducer";
import { getThankYouPage, langToCountry, langToLanguage, logEcommerceEvent } from "@utils";
import { setNewInitialState } from "../../../../app/slices/product-reducer";
import FloatingSelector from "./form-snippets/floating-selector";
import { PageContext, Settings } from "../../../slices/types";
import { getCleanVariants, getTrackingItems, getUpgradeContext } from "../../../../utils/product";
import { FrontendLineData } from "../../../../utils/playbrush-client/types";

const Form = styled.form`
	
	@media screen and (min-width: 768px) {

	}
`

const Spacer = styled.div`
	
	@media screen and (min-width: 768px) {
        margin: 0 0 40px;
        border: 1px solid var(--light-green);
        opacity: 0.2;
	}
`

const Footer = styled.footer`
    
    button {
        width: 100%;
        margin-bottom: 16px;
    }

	@media screen and (min-width: 768px) {

	}
`

const ShippingMessage = styled.div`
	
	@media screen and (min-width: 768px) {

	}
`

const BundleListContainer = styled.div`
    padding: 0 0 40px;
`

const getTrackingDataFromSearchParams = (location: { search?: string }): [number, string, string, number] => {
    const trackingPositionMatchData = ((location.search || '') as string)?.match(/position=([^&]+)/);
    const trackingPosition = parseInt(trackingPositionMatchData ? trackingPositionMatchData[1] : '0');

    const trackingSectionIdMatchData = ((location.search || '') as string)?.match(/section_id=([^&]+)/);
    const trackingSectionId = trackingSectionIdMatchData ? trackingSectionIdMatchData[1] : 'na';

    const trackingSectionNameMatchData = ((location.search || '') as string)?.match(/section_name=([^&]+)/);
    const trackingSectionName = trackingSectionNameMatchData ? trackingSectionNameMatchData[1] : 'na';

    const preselectMatchData = ((location.search || '') as string)?.match(/preselect=([^&]+)/);
    const preselect = parseInt(preselectMatchData ? preselectMatchData[1] : '1');

    return [trackingPosition, trackingSectionId, trackingSectionName, preselect];
}

const ProductForm = ({
    variants,
    isBundle,
    title,
    translations,
    location,
    settings,
    shouldShowCompareBrushes,
    showFloatingSelector,
}: {
    variants: Queries.PrismicProductDataVariant[];
    isBundle: boolean;
    title?: string;
    translations: PageContext['translations'];
    location: Record<string, unknown>;
    settings: Settings;
    shouldShowCompareBrushes: boolean;
    showFloatingSelector: boolean;
}) => {
    const [trackingPosition, trackingSectionId, trackingSectionName, preselect] = getTrackingDataFromSearchParams(location);
    const [cart, { selectedVariant, selectedBundle }, lang, audience] = useAppSelector(state => [state.cart, state.product, state.general.lang, state.general.audience]);
    const dispatch = useAppDispatch();

    const preselectIndex =  preselect !== 0 ? preselect - 1 : 0;

    const cleanVariants = getCleanVariants(variants);
    const initialBundles = cleanVariants?.[0]?.bundles;
    const firstBundle = initialBundles ? (initialBundles[preselectIndex] ?? initialBundles[0]) : undefined;
    const firstStripeBundle = firstBundle?.bundle;
    const initialId = firstStripeBundle?._id;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const newVariant = cleanVariants?.[0];

        if (newVariant) {
            dispatch(setNewInitialState({
                variants: cleanVariants,
                selectedVariant: newVariant,
                selectedColor: newVariant.color as string,
                selectedBrush: newVariant.brush_type as string,
                selectedSize: newVariant.size as string,
                selectedBundle: initialId as string,
            }))
    
            const trackingContext: FrontendLineData['trackingContext'] = {
                discount: (firstStripeBundle?.price_original || 0) - (firstStripeBundle?.price_amount || 0) / 100,
                index: trackingPosition,
                item_brand: bundle ? 'Smart Toothbrush' : 'Plan',
                item_category: selectedVariant?.stripe_variant?.name && /sonic/.test(selectedVariant?.stripe_variant?.name) ? 'Playbrush Smart Sonic' : 'Playbrush Smart',
                item_id: (initialId ? initialId : hardwarePrice) || '',
                item_list_id: trackingSectionId,
                item_list_name: trackingSectionName,
                item_name: firstBundle?.title ? firstBundle?.title : newVariant.stripe_variant?.name || 'na',
                item_variant: `${newVariant.color} / ${newVariant.brush_type}`,
                price: (displayPrice || 0) / 100,
                quantity: 1,
            }
    
            logEcommerceEvent([trackingContext], 'view_item');
        }
    }, [])

    const { stripe_variant } = selectedVariant || {};
    const { default_price: hardwarePrice } = stripe_variant || {};
    const bundles = selectedVariant?.bundles;
    const bundle = bundles?.find(bundle => bundle.bundle?._id === selectedBundle);

    const hardwareVariantPrice = selectedVariant?.stripe_variant?.price_amount || 0;
    const hardwareVariantOriginalPrice = selectedVariant?.stripe_variant?.price_original || 0;
    // @ts-ignore
    const displayPrice = bundle?.bundle?.[`price_amount_pay_now_current_${cart.currency.toLowerCase()}`];
    const bundleTags = bundle?.bundle?.tags
    const bundleIsOnlyBrushHeads = bundleTags?.includes('brush_heads_no_sub') && bundleTags?.includes('one_time');

    const onSubmit: any = async (e: any) => {
        e.preventDefault()
        setLoading(true);

        let payload: { lineId: string, quantity: number, frontendLineData?: string }[] = cart.lineItems.map((lineItem) => ({
            lineId: lineItem.lineId,
            quantity: lineItem.quantity,
            frontendLineData: JSON.stringify(lineItem.frontendLineData),
        }));

        const trackingContext: FrontendLineData['trackingContext'] = {
            discount: ((bundle?.bundle?.price_original || 0) - (bundle?.bundle?.price_amount || 0)) / 100,
            index: trackingPosition,
            item_brand: bundle ? 'Smart Toothbrush' : 'Plan',
            item_category: selectedVariant?.stripe_variant?.name && /sonic/.test(selectedVariant?.stripe_variant?.name) ? 'Playbrush Smart Sonic' : 'Playbrush Smart',
            item_id: (bundle?.bundle ? bundle.bundle?._id : hardwarePrice) || '',
            item_list_id: trackingSectionId,
            item_list_name: trackingSectionName,
            item_name: bundle?.title ? bundle?.title : selectedVariant?.stripe_variant?.name || 'na',
            item_variant: `${selectedVariant?.color} / ${selectedVariant?.brush_type}`,
            price: (displayPrice || 0) / 100,
            quantity: 1,
        }

        const upgradeContext = selectedVariant && getUpgradeContext(selectedVariant, selectedBundle);

        const frontendLineData = {
            trackingContext,
            upgradeContext,
            productContext: {
                page: location?.pathname + window?.location?.search || '',
            },
        };

        if (bundle?.bundle) {
            const bundleId = bundle?.bundle?._id || '';
            const lineItemIndex = payload.findIndex((lineItem) => lineItem.lineId === bundleId);
            const lineItemExists = lineItemIndex >= 0;

            if (lineItemExists) {
                payload[lineItemIndex].quantity += 1;
                frontendLineData.trackingContext.quantity = payload[lineItemIndex].quantity;
                payload[lineItemIndex].frontendLineData = JSON.stringify(frontendLineData);
            } else {
                payload.push({
                    lineId: bundleId,
                    quantity: 1,
                    frontendLineData: JSON.stringify(frontendLineData),
                })
            }
        } else {
            const lineItemIndex = payload.findIndex((lineItem) => lineItem.lineId === hardwarePrice);
            const lineItemExists = lineItemIndex >= 0;

            if (lineItemExists) {
                payload[lineItemIndex].quantity += 1;
                frontendLineData.trackingContext.quantity = payload[lineItemIndex].quantity;
                payload[lineItemIndex].frontendLineData = JSON.stringify(frontendLineData);
            } else {
                payload.push({
                    lineId: hardwarePrice as string,
                    quantity: 1,
                    frontendLineData: JSON.stringify(frontendLineData),
                })
            }
        }

        const frontendData = {
            ...(cart.frontendData as unknown) as Record<string, unknown>,
            thankYouPage: getThankYouPage(cart.lineItems, settings, audience),
        };

        const newCart = await Client.addProduct({
            itemList: payload,
            cartId: cart.cartId,
            couponCode: cart.couponCode,
            frontendData: JSON.stringify(frontendData),
            currency: cart.currency,
            language: langToLanguage(lang),
            country: langToCountry(lang),
        })


        if (newCart) {
            newCart.open = true;
            dispatch(setCart(newCart))

            const trackingItems = getTrackingItems(newCart);
            logEcommerceEvent(trackingItems, 'add_to_cart');
        };

        setLoading(false);
    };

    const showSelector = typeof showFloatingSelector === 'boolean' ? showFloatingSelector : true;

    return (
        <Form onSubmit={onSubmit}>
            {!selectedVariant?.bundles?.length && firstStripeBundle && (
                <>
                    <BundleListContainer>
                        <BundleList items={firstStripeBundle?.items_extended ?? []} />
                    </BundleListContainer>
                    <Spacer />
                </>
            )}
            <VariantSelector
                variants={cleanVariants}
                translations={translations}
                shouldShowCompareBrushes={shouldShowCompareBrushes}
            />
            <Spacer />
            {selectedVariant?.bundles?.length && (
                <SubscriptionSelector
                    translations={translations}
                    bundles={selectedVariant?.bundles}
                    locale={cart.locale}
                    currency={cart.currency}
                />
            )}
            <Footer>
                <AddToCart isLoading={loading} price={displayPrice as number} buttonText={translations.add_to_cart} />
                {!bundleIsOnlyBrushHeads && (
                    <ShippingMessage>
                        {translations.shipping_message}
                    </ShippingMessage>
                )}
            </Footer>
            {showSelector && selectedVariant?.bundles?.length && (
                <FloatingSelector
                    title={title}
                    selectedVariant={selectedVariant}
                    variants={cleanVariants}
                    translations={translations}
                    baseVariantPrice={{
                        price: hardwareVariantPrice,
                        priceOriginal: hardwareVariantOriginalPrice
                    }}
                />
            )}
        </Form>
    )
}

export default ProductForm;