import { PrismicRichTextProps } from "@prismicio/react"
import { isArray } from "lodash"
import { RichText, RichTextBlock } from "prismic-reactjs";

export const hasRichText = (subject?: PrismicRichTextProps): [boolean, RichTextBlock[]] | [false] => {
    if (!subject) return [false];
    if (!isArray(subject)) return [false];

    const firstRichTextItem = subject[0];
    if (!firstRichTextItem || subject.length === 0) return [false];

    if (!firstRichTextItem.text) return [false];
    if (typeof firstRichTextItem.text !== 'string') return [false];
    if (firstRichTextItem.text.trim() === '') return [false];

    return [true, subject]; 
}

export const renderRich = RichText.render;