import { createGlobalStyle } from 'styled-components';
// #06754A
const GlobalStyle = createGlobalStyle`
    :root {
        --light-green: #2BB573;
        --blue: #aaf7fe;

        --green: #aaf6cd;
        --dark-green: #00A944;
        --dark-green-hover: #04442B;
        --bg-green: #e5fcf0;
        --black: #231F20;

        --red: #fda4b1;
        --highlight-pink: #fc657d;
        
        --bg-light-grey: #F5F5F5;
        --dark-grey: #231F20;

        --bg-light-grey-two: #FAFAFA;
        
        --grey: #CDD4DC;

        --text-color: var(--dark-grey);
    }

    ::-moz-selection {
        background: var(--light-green);
        color: white;
    }
    ::selection {
        background: var(--light-green);
        color: white;
    }

    * {
        font-family: Gotham, serif;
        color: var(--text-color);
        margin: 0;
        scroll-behavior: smooth;
    }

    body {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        .small-body-text {
            @media screen and (min-width: 768px) {
                font-size: 14px;
            }
        }
    }

    h1,
    .h1 {
        font-weight: bold;
        font-size: 46px;
        line-height: 52px;
        // word-break: break-all;
        
        @media screen and (min-width: 768px) {
            font-size: 50px;
            line-height: 110%;
        }
    }

    h2,
    .h2 {
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.01em;

        @media screen and (min-width: 768px) {
            font-size: 30px;
            line-height: 36px;
            font-weight: bold;
        }
    }

    h3,
    .h3 {
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        
        @media screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 26px;
        }
        
        .regular {
            @media screen and (min-width: 768px) {
                font-weight: 400;
            }
        }
    }

    h4,
    .h4 {
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        
        @media screen and (min-width: 768px) {
            font-size: 14px;
            line-height: 26px;
        }
    }

    h5,
    .h5 {
        font-weight: bold;

        @media screen and (min-width: 768px) {
            font-size: 12px;
        }
    }

    p,
    .p,
    li,
    .li {
        word-wrap: break-word !important;
        @media screen and (min-width: 768px) {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .small-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        /* or 16px */

        @media screen and (min-width: 768px) {
            line-height:175%
        }
    }

    .big-text {
        @media screen and (min-width: 768px) {
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
        }
    }

    a,
    .a {
        font-size: 14px;
        
        @media screen and (min-width: 768px) {
        }
    }

    input {
        box-sizing: border-box;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .rt {
        h1 {
            font-size: 40px;
            font-weight: 400;
            line-height: 130%;
            margin-bottom: 40px;
    
            @media screen and (min-width: 768px) {
                font-size: 56px;
            }
        }
    
        h5,
        p,
        ul,
        ol {
            margin-bottom: 16px;
            line-height: 150%;
    
            &:last-child {
                margin-bottom: 16px;
            }
        }
    
        a {
            color: var(--light-green);
    
            &:visited {
                color: var(--dark-grey);
            }
        }
    }
`

export default GlobalStyle;
