import { constants } from "@constants";
import { getCookie, setCookie } from "@utils";
import React, { useState } from "react";
import Button from "../../../snippets/button";
import styled from "styled-components";
import { SliceProps } from "../../types";

const Wrapper = styled.section`
    max-width: 848px;
    padding: 40px 0;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        padding: 80px 80px 0;
    }
`

const Media = styled.div`
    border-radius: 16px;
    overflow: hidden;
    // padding: 24px;
    margin-bottom: 24px;
	
	@media screen and (min-width: 768px) {
		// padding: 0 80px 160px;
	}
`

const YouTubeEmbed = styled.div`
    line-height: 50%;

    iframe {
        max-width: 100%;
    }
`

const Consent = styled.div`
    width: 100%;
    height: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ConsentText = styled.p`
    margin: 0 0 16px;
    text-align: center;
    max-width: 320px;
`

const BlogVideo = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyTextVideo>) => {
    const { slice, context } = props;
    const { translations } = context;
    const { primary } = slice;
    const { video } = primary;
    const { document } = video as Queries.PrismicLinkType;
    const { src, use_autoplay, mute_audio, loop, plays_inline, placeholder_image, youtube_embed_url } = (document?.data || {}) as Queries.PrismicVideoDataType;

    const YoutubeEmbedUrl = youtube_embed_url;

    const [hasConsent, setConsent] = useState(!!getCookie(constants.cookies.YOUTUBE_COOKIE));

    const consent = () => {
        setConsent(true);
        setCookie(constants.cookies.YOUTUBE_COOKIE, 1);
    }

    return (
        <Wrapper>
            <Media>
                {YoutubeEmbedUrl &&
                    <>
                        {hasConsent
                            ? (
                                <Media>
                                    <YouTubeEmbed dangerouslySetInnerHTML={{ __html: YoutubeEmbedUrl }} />
                                </Media>
                            )
                            : (
                                <Consent>
                                    <ConsentText>
                                        {translations?.youtube_consent_text}
                                    </ConsentText>
                                    <Button type="button" onClick={consent}>
                                        {translations?.youtube_consent_button_text}
                                    </Button>
                                </Consent>
                            )
                        }
                    </>
                }
                {/* {video?.document?.data && (
                    <VideoPlayer
                        src={src}
                        use_autoplay={use_autoplay}
                        mute_audio={mute_audio}
                        loop={loop}
                        plays_inline={plays_inline}
                        placeholder_image={placeholder_image}
                        button_size={'medium'}
                    />
                )} */}
            </Media>
        </Wrapper>
    )
};

export default BlogVideo