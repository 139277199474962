import React from "react";
import styled from "styled-components";
import SubscriptionBlock from "./subscription-block";
import { SubscriptionSelectorProps } from "../../types";

const Container = styled.div``

const Fieldset = styled.fieldset`
    border: 0;
    padding: 0;
`

const Header = styled.header`
    margin: 0 0 16px;
    display: flex;
    justify-content: space-between;
`

const BlockTitle = styled.h5`
    margin: 0;
`

const SubscriptionSelector = ({ bundles, locale, currency, translations }: SubscriptionSelectorProps) => (
    <Container>
        <Header>
            <BlockTitle>{translations.select_plan}</BlockTitle>
        </Header>
        <Fieldset id="bundle">
            {bundles && bundles.map(({
                doc_id,
                bundle,
                description,
                title,
                tags,
                manual_bundle_items
            }, i) => (
                <SubscriptionBlock
                    key={`${doc_id}-${i}`}
                    bundle={bundle as Queries.PrismicBundleDataBundleIntegrationType}
                    tags={tags as Queries.PrismicBundleDataTags[]}
                    description={description as Queries.PrismicStructuredTextType}
                    title={title as string}
                    locale={locale}
                    currency={currency}
                    translations={translations}
                    manualBundleItems={manual_bundle_items as Queries.PrismicBundleDataManualBundleItems[]}
                />
            ))}
        </Fieldset>
    </Container>
)

export default SubscriptionSelector;