import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";

const Section = styled.section`
	margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    min-height: 100vh;
`

const Content = styled.div`
    position: relative;
    padding: 130px 16px 0;
    margin: 0;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding: 169px 0 0 80px;

        * {
            text-align: left;
        }
    }
`

const BackgroundImage = styled.div<{ background: string, position: string, hasMobileFallback: boolean }>`
    ${({ background, position, hasMobileFallback }) => `
        ${hasMobileFallback ? 'display: none;' : ''}
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: -1;
        height: 100vh;

        ${background ? `
            background: url('${background}');
            background-position: ${position};
            background-repeat: no-repeat;
            background-size: cover;
        ` : `
            background: none;
        `}

        .gatsby-image-wrapper {
            width: 100%;
            min-height: 100vh;
        }

        @media screen and (min-width: 768px) {
            height: auto;
            display: block;
        }
    `}
`

const MobileBackgroundImage = styled.div<{ background: string, position: string }>`
    ${({ background, position }) => `
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: -1;
        height: 100vh;

        ${background ? `
            background: url('${background}');
            background-position: ${position};
            background-repeat: no-repeat;
            background-size: cover;
        ` : `
            background: none;
        `}

        .gatsby-image-wrapper {
            width: 100%;
            min-height: 100vh;
        }

        @media screen and (min-width: 768px) {
            height: auto;
            display: none;
        }
    `}
`

const PagewideImage = (props: SliceProps<Queries.PrismicTextPageDataBodyPagewideImage>) => {
    const { slice } = props;
    const {
        primary: {
            desktop_image,
            mobile_image,
            desktop_position,
            mobile_position,
        }
    } = slice;

    const backgroundImage = desktop_image?.gatsbyImageData as unknown as IGatsbyImageData;
    const mobileBackgroundImage = mobile_image?.gatsbyImageData as unknown as IGatsbyImageData;
    const backgroundUrl = backgroundImage?.images?.fallback?.src || '';
    const mobileBackgroundUrl = mobileBackgroundImage?.images?.fallback?.src || '';
    const backgroundPosition = desktop_position || 'top left';
    const mobileBackgroundPosition = mobile_position || 'top left';

    const hasBackgroundImage = !!(desktop_image && desktop_image.gatsbyImageData);
    const hasMobileBackgroundImage = !!(mobile_image && mobile_image.gatsbyImageData);

    return (
        <Section>
            <Content>
                {hasBackgroundImage && (
                    <>
                        <BackgroundImage background={backgroundUrl} position={backgroundPosition} hasMobileFallback={hasMobileBackgroundImage} />
                        <MobileBackgroundImage background={mobileBackgroundUrl} position={mobileBackgroundPosition} />
                    </>
                )}
            </Content>
        </Section>
    )
}

export default PagewideImage
