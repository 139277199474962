import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import getIcon from "../../../icons/icon-renderer"
import { hasRichText } from "@utils";

import { SliceProps } from "../../types";

const Section = styled.section`
    gap: 80px;
    margin: 80px 0 0;
    background: rgba(43, 181, 115, 0.20);
	
	@media screen and (min-width: 768px) {
        margin: 120px 0;
	}
`

const InnerWrapper = styled.div`
    max-width: 920px;
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin: 0 auto;
    padding: 60px 20px;

    @media screen and (min-width: 768px) {
        gap: 56px;
        padding: 60px 100px;
    }
`

const Heading = styled.div`
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 150%;
`
const IconGrid = styled.div<{ numberOfItems: number }>`
    display: grid;
    column-gap: 0;
    row-gap: 24px;

    ${({ numberOfItems }) => `
        ${numberOfItems % 5 === 0 ? `
            grid-template-columns: repeat(1, 1fr);

            @media screen and (min-width: 425px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 1024px) {
                grid-template-columns: repeat(5, 1fr);
            }
        ` : numberOfItems % 4 === 0 ? `
            grid-template-columns: repeat(1, 1fr);

            @media screen and (min-width: 425px) {
                grid-template-columns: repeat(2, 1fr);
            }
            
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(4, 1fr);
            }
        ` : `
            grid-template-columns: repeat(1, 1fr);

            @media screen and (min-width: 425px) {
                grid-template-columns: repeat(2, 1fr);
            }
            
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
            }
        `}
    `}
`

const Icon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 140px;
    gap: 12px;
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
`

const IconBanner = (props: SliceProps<Queries.PrismicProductPageDataBodyIconBanner>) => {
const { slice } = props;
const { primary, items } = slice;
const { heading } = primary;
const numberOfItems = items.length;
const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    return (
        <Section id="icon-banner">
            <InnerWrapper>
                {headingHasRichText &&
                    <Heading>
                        {heading?.richText && RichText.render(headingRichText)}
                    </Heading>
                }
                <IconGrid numberOfItems={numberOfItems}>
                    {items && items?.map((
                        {
                            icon,
                            icon_title
                        },
                        index
                    ) => (
                        <Icon
                            key={`${index}-icon`}
                        >
                            {icon && getIcon(icon, 'black', index)}
                            {icon_title?.richText && RichText.render(icon_title.richText)}
                        </Icon>
                    ))}
                </IconGrid>
            </InnerWrapper>
        </Section>
    )
}

export default IconBanner