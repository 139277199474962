import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ThumbsUpCleanIcon = ({ color = '#FFF', size = '36' }: {  color?: string, size?: string | number }) => (
    <IconSvg 
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 48 49" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2851 22.2866L22.4851 6.08667C23.9172 6.08667 25.2907 6.65559 26.3034 7.66828C27.3161 8.68097 27.885 10.0545 27.885 11.4866V18.6866H38.073C38.5948 18.6807 39.1117 18.7883 39.5878 19.002C40.0639 19.2157 40.4878 19.5303 40.8303 19.9241C41.1727 20.3179 41.4254 20.7815 41.5709 21.2826C41.7164 21.7838 41.7512 22.3106 41.6729 22.8266L39.1889 39.0265C39.0588 39.8849 38.6227 40.6673 37.9612 41.2297C37.2996 41.792 36.4572 42.0963 35.589 42.0864H15.2851M15.2851 22.2866V42.0864M15.2851 22.2866H9.88513C8.93036 22.2866 8.01469 22.6658 7.33956 23.341C6.66444 24.0161 6.28516 24.9318 6.28516 25.8865V38.4865C6.28516 39.4412 6.66444 40.3569 7.33956 41.032C8.01469 41.7072 8.93036 42.0864 9.88513 42.0864H15.2851" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
);

export default ThumbsUpCleanIcon;