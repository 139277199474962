import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const Section = styled.section`
`

const InnerWrapper = styled.div`
        display: grid;
        gap: 16px;
        padding: 0;

        @media screen and (min-width: 768px) {
            padding: 0 80px;
            grid-template-columns: 2fr 1fr;
        }
`

const Image = styled(GatsbyImage)`
    height: auto;

    @media screen and (min-width: 768px) {
        height: 400px;
        width: 100%;
    }
`

const TwoImageGrid = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyTwoImagesGrid>) => {
    const { slice } = props;
    const { items } = slice;

    return (
        <Section id="two-image-grid">
            <InnerWrapper>
                {items?.map((
                    {
                        image
                    },
                    index
                ) => (
                    <Image
                        key={`${index}-image`}
                        image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                        alt={image?.alt || ''}
                    />
                ))}
            </InnerWrapper>
        </Section>
    )
};

export default TwoImageGrid