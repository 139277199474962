import { CleanVariant } from "./get-clean-variants"

interface UpgradeItem {
    _id?: string;
    id: string;
    position: number;
}

interface UpgradeContext {
    position: number;
    lib: UpgradeItem[];
}

// const upgradeBundles = selectedVariant?.bundles?.reduce<{ _id?: string, id: string, position: number }[]>(
//     (library, bundle, position) => {
//         const { document } = (bundle?.bundle || {});
//         const { id = '', data } = (document || {});
//         const { bundle: ub } = (data || {}) as Queries.PrismicBundleDataType;
//         if (id) {
//             if (!ub) {
//                 return [...library, { id: id || '', position }];
//             } else {
//                 const { _id } = ub;
//                 return [...library, { id: id || '', _id, position }];
//             }
//         }
//     },
//     [])

// const upgradeContext = {
//     position: upgradeBundles?.find(b => b?._id === bundle?._id)?.position,
//     lib: upgradeBundles,
// }

const getUpgradeLib = (bundles: CleanVariant['bundles']): UpgradeItem[] => {
    const lib: UpgradeItem[] = bundles?.reduce<UpgradeItem[]>((library, bundle, position) => {
        return [...library, {
            _id: bundle.bundle?._id as string | undefined,
            id: bundle?.doc_id || '',
            position,
        }]
    },
        []
    )

    return lib;
}

export const getUpgradeContext = (variant: CleanVariant, selectedBundleId: string): UpgradeContext => {
    const lib = getUpgradeLib(variant.bundles);
    const position = lib.findIndex((l: UpgradeItem) => l._id === selectedBundleId);

    return { position, lib };
}
