import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { PageContext } from "../../slices/types";
import Client from "../../../utils/playbrush-client/client";
import { useAppDispatch } from "../../../app/hooks";
import ReferralBox from "../../snippets/referral-box/referral-box";
import OrderInfo from "./components/order-info";
import { Cart, OrderContent } from "../../../utils/playbrush-client/types";
import { setInitialState } from "../../../app/slices/cart-reducer";
import { logEcommerceEvent, baseInterpolator } from "@utils";


const OrderSummary = styled.article``

const LineItemFallback = styled.h4`
    line-height: 32px;

    @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
`

interface ThankYouSectionProps {
    data: Queries.PrismicThankYouPageDataType;
    translations: PageContext['translations'];
    settings: PageContext['settings'];
    lang: string;
    location: Record<string, unknown>;
    showReferralSection?: boolean;
}

const getOrderId = (href: string) => {
    const newUrl = new URL(href);
    return newUrl.searchParams.get("orderId");
};

const getCheckoutId = (href: string) => {
    const newUrl = new URL(href);
    return newUrl.searchParams.get("checkoutId");
};

const getOrderAndCheckoutId = (location: { href: string }) => {
    if (!location?.href) return {};

    const checkoutId = getCheckoutId(location.href);
    const orderId = getOrderId(location.href);

    return { orderId, checkoutId };
};

const removeOrderId = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("orderId");
    history.pushState({}, "", newUrl);
};

const setCheckoutId = (checkoutId: string) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("checkoutId", checkoutId);
    history.pushState({}, "", newUrl);
};

const ThankYouSection = ({ data, showReferralSection, translations, settings, lang, location }: ThankYouSectionProps) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderContent>();
    const [cart, setCart] = useState<Cart>();

    const { orderId, checkoutId } = getOrderAndCheckoutId(location as { href: string });

    const getOrder = async () => {
        if (!orderId && !checkoutId) return;
        setLoading(true);

        const res = orderId ? await Client.getOrder({ orderId }) : await Client.getOrder({ checkoutId: checkoutId as string });
        const { order, cart } = res;

        if (order) {
            setOrder(order);
            setLoading(false);

            const trackingItems = order.orderItems.map((item, i) => ({
                item_id: item.productId,
                item_name: item.productName,
                discount: (item.priceDefaultAmount - item.priceAmount) / 100,
                index: i,
                item_category: /sonic/.test(item.productName) ? 'Playbrush Smart Sonic' : 'Playbrush Smart',
                item_brand: !item.bundleId ? 'Smart Toothbrush' : 'Plan',
                item_list_id: 'Thank you page',
                item_list_name: 'Thank you page',
                item_variant: '',
                price: (item.priceAmount) / 100,
                quantity: item.quantity,
            }));

            logEcommerceEvent(trackingItems, 'purchase', {
                value: (order.amountTotal || 0) / 100,
                tax: (order.amountTax || 0) / 100,
                shipping: (order.amountShipping || 0) / 100,
                currency: order.currency,
                transaction_id: orderId,
                affiliation: 'Web Shop',
                coupon: order.couponcode,
            });
        }

        if (orderId && cart?.checkoutId) {
            removeOrderId();
            setCheckoutId(cart?.checkoutId);
        }

        if (cart) {
            setCart(cart);
        }

        dispatch(setInitialState());
        setLoading(false);
    }

    useEffect(() => {
        getOrder();
    }, []);

    return (
        <OrderSummary>
            {!!order && !!cart && (
                <>
                    {showReferralSection && (
                        <ReferralBox
                            data={data}
                            order={order}
                            location={location}
                        />
                    )}
                    <OrderInfo data={data} order={order} translations={translations} settings={settings} />
                    {order && <LineItemFallback>{baseInterpolator(data?.order_items_temporary_replacement as string, { email: order.email})}</LineItemFallback>}
                    {/* <LineItems data={data} lineItems={cart?.lineItems as LineItem[]} translations={translations} /> */}
                </>
            )}
        </OrderSummary>
    )
};

export default ThankYouSection;