import { userTimeZoneToCountryCode } from "../location"

export const langToCountry = (lang: string): string => {
    // switch (lang) {
    //     case 'en-gb':
    //         return 'GB';
    //     case 'de-eu':
    //     default:
    //         return 'DE'
    // }

    return userTimeZoneToCountryCode();
}