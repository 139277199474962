import React, { useState } from "react"
import styled from "styled-components";
import { LineItem as LineItemInterface, PriceLineItem } from "../../../../../../utils/playbrush-client/types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import Client from "../../../../../../utils/playbrush-client/client";
import { OldProduct } from "../../cart-list";
import { setCart } from "../../../../../../app/slices/cart-reducer";
import { getTrackingItems } from "../../../../../../utils/product";
import { getThankYouPage, langToCountry, langToLanguage, logEcommerceEvent } from "@utils";
import BinIcon from "../../../../../icons/bin-icon";
import { getCurrentBundleFromLineItem, getUpgradeBundleFromLineItem } from "../../../../../snippets/upgrade/upgrade-box/utils";
import TagSection from "../../../../product-page/product/form-snippets/tag-section";
import LineItemQuantityControl from "./line-item-quantity-control";
import { PageContext } from "../../../../../../components/slices/types";

const Footer = styled.footer`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 16px;
    margin: 0 0 24px;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 180px;
        grid-template-rows: auto;
    }
`;

const UpgradeControls = styled.section`
    display: flex;
    justify-content: flex-start;
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    @media screen and (min-width: 768px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
`;

const TagContainer = styled.div`
    display: flex;
    align-items: center;
`

const Actions = styled.section`
    display: flex;
    justify-content: flex-end;
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    @media screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
`;

// Quantity control
const QuantityControl = styled.div`
    display: flex;
    margin-right: 24px;
`

const QuantityControlButton = styled.button`
    width: 40px;
    height: 40px;
    padding: 0;
    background: white;
    border: 1px solid #CDD4DC;
    border-radius: 12px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease;

    &:hover {
        background: #CDD4DC;
        cursor: pointer;
    }

    &:disabled {
        background: #CDD4DC;
        opacity: .4;
    }
`

const Quantity = styled.input`
    width: 40px;
    height: 40px;
    padding: 0;
    background: white;
    border: 0px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
`

const DeleteButton = styled.button`
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.15s ease;

    &:hover {
        transform: scale(1.15);
    }
`

interface LineItemProps {
    lineItem: LineItemInterface,
    // translations: PageContext['translations'];
    quickCart?: boolean
    settings: PageContext['settings'];
    onRemove?: (v: OldProduct) => void,
}

const LineItemFooter = ({ lineItem, quickCart, settings, onRemove }: LineItemProps) => {
    const [cart, bundles, lang, audience] = useAppSelector(state => [state.cart, state.bundles, state.general.lang, state.general.audience]);
    const [loading, setLoading] = useState(false);
    const currentBundle = getCurrentBundleFromLineItem(lineItem, bundles);
    const upgradeBundle = getUpgradeBundleFromLineItem(lineItem, bundles);

    const { productNameMarketing } = lineItem;
    const dispatch = useAppDispatch();

    const remove = async () => {
        setLoading(true);

        try {
            const lineId = lineItem.lineId;
            const payload: PriceLineItem[] = [];

            cart.lineItems.forEach((item) => {
                if (item.lineId !== lineId) {
                    payload.push({
                        lineId: item.lineId,
                        quantity: item.quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData)
                    });
                }
            });

            const frontendData = {
                ...(cart.frontendData as unknown) as Record<string, unknown>,
                thankYouPage: getThankYouPage(
                        cart.lineItems.filter((lineItem) => lineItem.lineId !== lineId),
                        settings,
                    ),
            };

            const newCart = await Client.addProduct({
                itemList: payload,
                cartId: cart.cartId,
                couponCode: cart.couponCode,
                frontendData: JSON.stringify(frontendData),
                currency: cart.currency,
                language: langToLanguage(lang),
                country: langToCountry(lang),
            });

            if (newCart) {
                onRemove && onRemove({ title: productNameMarketing, lineId, audience: lineItem.tags.includes('adults') ? 'adults' : 'kids' });
                setLoading(false);
                dispatch(setCart(newCart));

                const trackingItems = getTrackingItems(newCart);
                logEcommerceEvent(trackingItems, 'remove_from_cart');
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    return (
        <Footer>
            {!quickCart && (
                <UpgradeControls>
                    {currentBundle?.displayData.tags && (
                        <TagContainer>
                            <TagSection tags={currentBundle?.displayData.tags} />
                        </TagContainer>
                    )}
                    {/* <UpgradeButton lineItem={lineItem} translations={translations} /> */}
                </UpgradeControls>
            )}
            <Actions>
                <LineItemQuantityControl lineItem={lineItem} onRemove={onRemove} settings={settings} />
                {!quickCart && (
                    <DeleteButton
                        type="button"
                        disabled={loading}
                        onClick={remove}
                    >
                        <BinIcon />
                    </DeleteButton>
                )}
            </Actions>
        </Footer>
    )
};

export default LineItemFooter;