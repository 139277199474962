import React, { useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from "@prismicio/react"
import Layout from "../../components/layout/layout"
import styled from "styled-components";
import Seo from "../../components/layout/seo"

import { components } from "./components"
import { PageContext } from "../../components/slices/types"
import { useAppSelector } from "../../app/hooks"
import { logEcommerceEvent, logPageView, getSanitizedBundles } from "@utils";
import linkResolver from '../../utils/prismic/link-resolver';
import { BundlePB } from '../../components/slices/types';

const Products = styled.section`
    padding: 50px 0;
    
    @media screen and (min-width: 768px) {
        padding: 80px 80px 10px 80px;
    }
`

const getProductsFromFeaturedProducts = (slice: any) => {
    return slice?.items.reduce((products: any, slice: any) => {
        const variants = slice?.link?.document?.data?.prismic_product?.document?.data?.variant;
        const isBundle = slice?.link?.document?.data?.prismic_product?.document?.data?.is_bundle;
        const bundleFilter = slice?.link?.document?.data?.prismic_product?.document?.data?.bundle_filter;

        if (variants?.length) {
            let variant

            if (isBundle) {
                const bundles = variants.find((variant: any) => !!variant?.product_variant?.document)?.product_variant?.document?.data?.bundles;
                if (!bundles?.length) return products;
                variant = bundles[0]?.bundle?.document?.data?.bundle;
            } else {
                variant = variants.find((variant: any) => !!variant?.product_variant?.document)?.product_variant?.document?.data?.stripe_variant;
            }

            if (!variant) return products;

            const trackingContext = {
                discount: ((variant.price_original || 0) / (variant.price_amount || 0)) / 100,
                // index: 0,
                item_id: variant?._id || '',
                item_category: variant?.name ? (/sonic/.test(variant?.name) ? 'Playbrush Smart Sonic' : 'Playbrush Smart') : 'na',
                item_brand: isBundle ? 'Smart Toothbrush' : 'Plan',
                item_list_id: slice?.section_id || 'Product overview page',
                item_list_name: 'Product overview page',
                item_name: variant?.name || 'na',
                item_variant: 'na',
                price: (variant.price_amount || 0) / 100,
                quantity: 'na',
            }

            return [...products, trackingContext];
        } else {
            return products;
        }

    }, []) || [];
}

const getProductsFromProductGrid = (slice: any) => {
    return slice?.items.reduce((products: any, slice: any) => {
        const variants = slice?.link?.document?.data?.prismic_product?.document?.data?.variant;
        const isBundle = slice?.link?.document?.data?.prismic_product?.document?.data?.is_bundle;
        const bundleFilter = slice?.link?.document?.data?.prismic_product?.document?.data?.bundle_filter;

        if (variants?.length) {
            let variant

            if (isBundle) {
                const bundles = variants.find((variant: any) => !!variant?.product_variant?.document)?.product_variant?.document?.data?.bundles;
                if (!bundles?.length) return products;
                variant = bundles[0]?.bundle?.document?.data?.bundle;
            } else {
                variant = variants.find((variant: any) => !!variant?.product_variant?.document)?.product_variant?.document?.data?.stripe_variant;
            }

            if (!variant) return products;

            const trackingContext = {
                discount: ((variant.price_original || 0) / (variant.price_amount || 0)) / 100,
                // index: 0,
                item_id: variant?._id || '',
                item_category: variant?.name ? (/sonic/.test(variant?.name) ? 'Playbrush Smart Sonic' : 'Playbrush Smart') : 'na',
                item_brand: isBundle ? 'Smart Toothbrush' : 'Plan',
                item_list_id: slice?.section_id || 'Product overview page',
                item_list_name: 'Product overview page',
                item_name: variant?.name || 'na',
                item_variant: 'na',
                price: (variant.price_amount || 0) / 100,
                quantity: 'na',
            }

            return [...products, trackingContext];
        } else {
            return products;
        }

    }, []) || [];
}

// const getPageProductsFromSlices = (slices: any) => {
//     const products = slices.reduce((products: any, slice: any) => {
//         switch (slice.slice_type) {
//             case "featured_products":
//                 return [...products, ...getProductsFromFeaturedProducts(slice)]
//             case "product_grid":
//                 return [...products, ...getProductsFromProductGrid(slice)]
//             default:
//                 return products
//         }
//     }, []);

//     return products.map((product: any, index: number) => ({ ...product, index: index }));
// }

const ProductOverviewPage = (props: PageProps<Queries.ProductOverviewPageQuery, PageContext>) => {
    // const [config, setConfig] = useState();
    // const [filteredBundles, setFilteredBundles] = useState<BundlePB[]>();
    const globalAudience = useAppSelector(state => state.general.audience);
    const { data, location, pageContext } = props;
    const { prismicProductOverviewPage, allPrismicHeader, prismicFooter, allPrismicProductVariant } = data;
    const { lang, data: productOverviewPageData } = prismicProductOverviewPage || {};
    const { page_title, page_description, body, page_image, } = productOverviewPageData || {};
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings } = pageContext;
    const bundles = getSanitizedBundles(allPrismicProductVariant.nodes as Queries.PrismicProductVariant[]);
    const page = 'product-overview';
    const pageImage = page_image?.url || '';

    useEffect(() => {
        logPageView({ content_group: page });
        // fetch('/bundles/data.json').then(data => data.json()).then(async (bundleData: BundlePB[]) => {
        //     const config = await fetch('/bundles/config.json').then(res => res.json()).then((bundlesConfig: BundlesConfig) => bundlesConfig);

        //     setFilteredBundles(bundleData);
        //     setConfig(config);
        // });

        // const test = getPageProductsFromSlices(body);
        // test.length && logEcommerceEvent(test, 'view_item_list');
    }, []);

    return (
        <Layout
            bundles={bundles}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            page={page}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={page_title || 'Product Overview'} description={page_description || 'Product Overview page'} lang={lang?.split('-')[0]} image={pageImage} canonicalUrl={`https://playbrush.com${location.pathname}`} />
            <Products>
                <SliceZone
                    slices={body}
                    // @ts-ignore
                    components={components}
                    context={{
                        audience,
                        translations,
                        page,
                    }}
                />
            </Products>
        </Layout>
    )
}

export const query = graphql`
    query ProductOverviewPage($id: String, $lang: String) {
        prismicProductOverviewPage(id: {eq: $id}, lang: {eq: $lang}) {
            _previewable
            href
            url
            prismicId
            tags
            type
            lang
            uid
            data {
                page_title
                page_image {
                    alt
                    url
                }
                page_description
                body {
                    ... on PrismicProductOverviewPageDataBodyEmailPopUp {
                        id
                        slice_type
                        primary {
                            delay
                            success_message
                            subscription_form {
                                document {
                                    ... on PrismicSubscriptionForm {
                                        id
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicProductOverviewPageDataBodyFeaturedProducts {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                            description {
                                richText
                            }
                            section_id
                        }
                        items {
                            column_layout
                            background_color
                            use_gradient
                            description {
                                richText
                            }
                            link {
                                target
                                url
                                document {
                                    ... on PrismicProductPage {
                                        id
                                        data {
                                            prismic_product {
                                                document {
                                                    ... on PrismicProduct {
                                                        id
                                                        data {
                                                            is_bundle
                                                            bundle_filter
                                                            variant {
                                                                product_variant {
                                                                    document {
                                                                        ... on PrismicProductVariant {
                                                                            id
                                                                            data {
                                                                                stripe_variant {
                                                                                    default_price
                                                                                    name
                                                                                    price_amount
                                                                                    price_amount_pay_now_current_eur
                                                                                    price_amount_pay_now_current_gbp
                                                                                    price_amount_pay_now_current_usd
                                                                                    id
                                                                                    _id
                                                                                    price_original
                                                                                    }
                                                                                    bundles {
                                                                                        bundle {
                                                                                            document {
                                                                                                ... on PrismicBundle {
                                                                                                    id
                                                                                                    data {
                                                                                                    bundle {
                                                                                                        price_amount
                                                                                                        price_amount_one_time_current_eur
                                                                                                        price_amount_one_time_current_gbp
                                                                                                        price_amount_one_time_current_usd
                                                                                                        name
                                                                                                        price_original
                                                                                                        _id
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            button_text {
                                richText
                            }
                            price {
                                richText
                            }
                            product_image {
                                gatsbyImageData
                                alt
                            }
                            product_title {
                                richText
                            }
                            bundle_preselect_index
                            bundle_type_preselect
                        }
                    }
                    ... on PrismicProductOverviewPageDataBodyProductGrid {
                        id
                        slice_type
                        primary {
                            description {
                                richText
                            }
                            heading {
                                richText
                            }
                            section_id
                        }
                        items {
                            description {
                              richText
                            }
                            discount {
                              richText
                            }
                            link {
                              target
                              url
                              document {
                                ... on PrismicProductPage {
                                    id
                                    data {
                                        prismic_product {
                                            document {
                                                ... on PrismicProduct {
                                                    id
                                                    data {
                                                        is_bundle
                                                        variant {
                                                            product_variant {
                                                                document {
                                                                    ... on PrismicProductVariant {
                                                                        id
                                                                        data {
                                                                            stripe_variant {
                                                                                name
                                                                                price_amount
                                                                                price_original
                                                                                id
                                                                                _id
                                                                            }
                                                                            bundles {
                                                                                bundle {
                                                                                    document {
                                                                                        ... on PrismicBundle {
                                                                                            id
                                                                                            data {
                                                                                                bundle {
                                                                                                    name
                                                                                                    price_amount
                                                                                                    price_original
                                                                                                    id
                                                                                                    _id
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                }
                            }
                            link_text
                            price {
                              richText
                            }
                            product_image {
                              gatsbyImageData
                              alt
                            }
                            product_title {
                              richText
                            }
                            bundle_preselect_index
                        }
                    }
                    ... on PrismicProductOverviewPageDataBodyFeatureGrid {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                            section_id
                        }
                        items {
                            button_text {
                                richText
                            }
                            button_url {
                                url
                            }
                            feature {
                                richText
                            }
                            image {
                                gatsbyImageData
                                alt
                            }
                            subtitle {
                                richText
                            }
                            title {
                                richText
                            }
                        }
                    }
                    ... on PrismicProductOverviewPageDataBodyFloatingNavigation {
                        id
                        slice_type
                        items {
                            label {
                                richText
                            }
                        }
                    }
                }
            }
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                    tags
                                                    price_recurring_trial_period_days
                                                    price_recurring_interval_count
                                                    price_recurring_interval
                                                    price_original_after_trial
                                                    price_original
                                                    price_amount_after_trial
                                                    price_amount
                                                    name
                                                    default_price
                                                    image
                                                    description
                                                    active
                                                    metadata {
                                                        prod_image_corner_note_EN
                                                        prod_image_title_DE
                                                        prod_image_title_EN
                                                        prod_image_tooltip_text_DE
                                                        prod_image_tooltip_text_EN
                                                        prod_marketing_title_DE
                                                        prod_marketing_title_EN
                                                        prod_order_title_DE
                                                        prod_order_title_EN
                                                        tags
                                                        prod_description_DE
                                                        prod_description_EN
                                                        prod_image_corner_note_DE
                                                    }
                                                    _id
                                                    description_de
                                                    description_en
                                                }
                                                product
                                                price_default_blob {
                                                    active
                                                    currency
                                                    _id
                                                    product
                                                    recurring_interval
                                                    recurring_interval_count
                                                    recurring_trial_period_days
                                                    type
                                                    unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                            price_amount_one_time
                                            price_amount_recurring
                                            price_original_after_trial
                                            price_original_one_time
                                            price_original_recurring
                                            price_shipping_eur
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        menu_bundle_type_preselect
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        bundle_preselect_index
                                        button_bundle_type_preselect
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                            bundle_type_preselect
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(
    ProductOverviewPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
    }]
);