import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components";
import { isGeneratorObject } from "util/types";
import VariantBlock from "./variant-block";
import { useAppSelector } from "../../../../../app/hooks";

const bounceAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    0% {
        transform: scale(1);
    }
`

const shadowAnimation = keyframes`
    0% {
        box-shadow: 0px 0px 0px 0px rgb(4 39 81 / 0%);
    }
    50% {
        box-shadow: 2px 2px 3px 0px rgb(4 39 81 / 20%);
    }
    0% {
        box-shadow: 0px 0px 0px 0px rgb(4 39 81 / 00%);
    }
`

const ListItem = styled.li`
    position: relative;
    list-style: none;
	
	@media screen and (min-width: 768px) {

	}
`

const AnimationWrapper = styled.button`
    position: relative;
    animation: ${bounceAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
`

const Quantity = styled.div`
    position: absolute;
    top: -4.17%;
    right: -8.33%;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 4px;
    background-color: white;
    padding: 0 4px;
    
	@media screen and (min-width: 768px) {

	}
`

// const Image = styled(GatsbyImage)`
const Image = styled.img`
    width: 64px;
    height: 64px;
    animation: ${shadowAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`

const Text = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    max-width: 64px;

	@media screen and (min-width: 768px) {

	}
`

const Note = styled.p<{ left: string, right: string }>`
    ${({ left = '50%', right = '50%' }) => `
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        width: auto;

        position: absolute;
        top: 72px;
        left: ${left};
        right: ${right};
        // transform: translateX(-50%);
        background-color: var(--light-green);
        color: white;
        padding: 16px;
        border-radius: 16px;
        z-index: 9;
        cursor: pointer;

        @media screen and (min-width: 768px) {

        }
    `}
`

const Tail = styled.svg`
    position: absolute;
    top: 56px;
    left: 18px;
`

const BubbleTail = () => {

    return (
        <Tail
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5359 2.00001C12.0755 -0.666661 15.9245 -0.666667 17.4641 2L26.9904 18.5C28.53 21.1667 26.6055 24.5 23.5263 24.5L4.47373 24.5C1.39453 24.5 -0.529982 21.1667 1.00962 18.5L10.5359 2.00001Z"
                fill="#2BB573"
            />
        </Tail>
    )
}

function getKeysuffix(lang: string) {
    return lang === 'de-eu' ? 'DE' : 'EN';
}

function getBundleItemTranslation(prefix: string, translations: Record<string, string> = {}, lang: string = 'de-eu') {
    const suffix = getKeysuffix(lang);

    return translations[prefix + suffix];
}

const BundleListItem = ({ register, item }: any) => {
    const lang = useAppSelector(state => state.general.lang);

    const itemRef = useRef(null);
    const [display, setDisplay] = useState(false);

    // const [width, setWidth] = useState(false);
    const [left, setLeft] = useState('-80px');
    const [right, setRight] = useState('-80px');

    const translations = item?.metadata;
    const title = getBundleItemTranslation('prod_image_title_', translations, lang);
    const quantity = (getBundleItemTranslation('prod_image_corner_note_', translations, lang) || '').replaceAll('"', '');
    const note = getBundleItemTranslation('prod_image_tooltip_text_', translations, lang);

    const turnOnDisplay = () => setDisplay(true);
    const turnOffDisplay = () => setDisplay(false);

    useEffect(() => {
        const { innerWidth } = window || {};
        if (innerWidth < 768) {
            // @ts-ignore
            const { left, right } = itemRef?.current?.getBoundingClientRect() || {};
            const newLeft = left - 8;
            const newRight = (innerWidth - right) - 8;
            setLeft(`-${newLeft}px`);
            setRight(`-${newRight}px`);
        }
    }, []);

    return (
        <ListItem ref={itemRef}>
            <AnimationWrapper
                type="button"
                onMouseEnter={turnOnDisplay}
                onMouseLeave={turnOffDisplay}
                onClick={turnOnDisplay}
            >
                <Image src={item.image || 'https://via.placeholder.com/150'} alt={'item-image'} />
                <Quantity>
                    {`x${quantity}`}
                </Quantity>
            </AnimationWrapper>
            <Text>{title}</Text>
            {display && (
                <>
                    <BubbleTail></BubbleTail>
                    <Note
                        left={left}
                        right={right}
                        onClick={turnOffDisplay}
                    >
                        {note}
                    </Note>
                </>
            )}
        </ListItem>
    )
}

export default BundleListItem;