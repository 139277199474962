import { SanitizedBundle } from "../../../../../utils";
import { LineItem } from "../../../../../utils/playbrush-client/types";
import { getBundleCollection } from "./get-bundle-collection";

export const getCurrentBundleFromLineItem = (
    lineItem: LineItem,
    bundles: SanitizedBundle[][],
): SanitizedBundle | undefined => {
    const list = lineItem?.frontendLineData?.upgradeContext?.lib || []
    const position = (lineItem?.frontendLineData?.upgradeContext?.position || 0);

    const upgradeContext = list?.[position];
    const bundleCollections = getBundleCollection(bundles, upgradeContext);
    const bundleList = bundleCollections[0] || [];
    const currentBundle = bundleList[position];

    return currentBundle;
}