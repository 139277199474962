import React from "react";
import styled from "styled-components";

const FlagAvatar = styled.picture`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    margin-right: 8px;
`

const FlagImage = styled.img`
    border-radius: 100%;
    height: 24px;
    width: 24px;
`

const getFlagSource = (country: string) => {
    switch (country) {
        case 'GB':
            return "/images/flag-gb.png";
        case 'DE':
            return "/images/flag-germany.png";
        default:
            return "/images/flag-germany.png";
    }
}

const Flag = ({ country }: { country: string }) => {
    return (
        <FlagAvatar>
            <FlagImage src={getFlagSource(country)} />
        </FlagAvatar>
    )
};

export default Flag;