export default `
    mutation (
        $cartId: String,
        $customerId: String,
        $itemList: [LineItemInput]!,
        $frontendData: JSONString,
        $couponCode: String,
        $country: String,
        $currency: String,
        $language: String,
    ) {
        cart(
            currency: $currency
            cartId: $cartId
            customerId: $customerId
            lineItems: $itemList
            couponCode: $couponCode
            frontendData: $frontendData
            country: $country
            language: $language
        ) {
            amountDiscount
            amountShipping
            amountSubtotal
            amountTax
            amountTotal
            amountTotalAfter
            cartId
            couponCode
            currency
            currencySymbol
            customerId
            frontendData
            language
            lineItems {
                Cls
                currencySymbol
                description
                frontendLineData
                image
                infoDelivery
                infoExplainer
                infoPayment
                isSubscription
                lineContent {
                    Cls
                    currencySymbol
                    description
                    frontendLineData
                    image
                    infoDelivery
                    infoExplainer
                    infoPayment
                    isSubscription
                    lineId
                    priceAmount
                    priceAmountAfterTrial
                    priceAmountOneTime
                    priceAmountRecurring
                    priceDefaultAmount
                    priceOriginalAfterTrial
                    priceOriginalOneTime
                    priceOriginalRecurring
                    product
                    productName
                    productNameMarketing
                    productNameOrder
                    quantity
                    recurringInterval
                    recurringIntervalCount
                    recurringTrialPeriodDays
                    tags
                }
                lineId
                priceAmount
                priceAmountAfterTrial
                priceAmountOneTime
                priceAmountRecurring
                priceDefaultAmount
                priceOriginalAfterTrial
                priceOriginalOneTime
                priceOriginalRecurring
                product
                productName
                productNameMarketing
                productNameOrder
                quantity
                recurringInterval
                recurringIntervalCount
                recurringTrialPeriodDays
                tags
            }
        }
    }
`