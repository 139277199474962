import React, { FormEvent, useEffect, useState } from "react"
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setCart } from "../../../../app/slices/cart-reducer";
import { checkDiscountCode, addDiscountCode, discountCodeIsEmpty } from "../utils";

const Discountform = styled.form`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 8px;
    width: 100%;
    margin: 0 0 8px;
`

const DiscountInput = styled.input<{ flattenBottom: boolean, disabled: boolean }>`
    padding: 16px;
    margin: 0;
    background: transparent;
    text-align: left;
    border: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-radius: ${({ flattenBottom }) => flattenBottom ? '16px 16px 0 0' : '16px'};
    border: 1px solid #CDD4DC;
    background: #FFFFFF;

    ${({ disabled }) => disabled ? `
        pointer-events: none;
        opacity: .2;
        background-color: var(--light-grey-two);
    ` : ''}
`

const DiscountButton = styled.button<{ disabled: boolean }>`
    padding: 0 16px;
    margin: 0;
    background: black;
    border: 0;
    border-radius: 16px;
    font-weight: 600;
	font-size: 14px;
    color: white;
    cursor: pointer;
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    ${({ disabled }) => disabled ? `
        pointer-events: none;
        opacity: .2;
    ` : ''}
`

const DiscountCodeError = styled.p`
    background-color: rgba(255, 0, 0, .2);
    color: rgb(255, 0, 0);
    padding: 8px 16px;
    line-height: 1.4;
    border-radius: 0 0 14px 14px;
    font-weight: bold;
    font-size: 12px;
    margin-top: 0;
    text-align: left;
    grid-column: 1 / 2;
    grid-row: 2/3;
`

const OrderSummaryCouponForm = ({ data }: { data: Queries.PrismicCartPageDataType }) => {
    const [cart, lang] = useAppSelector(state => [state.cart, state.general.lang]);
    const dispatch = useAppDispatch();

    const [couponCode, setCouponCode] = useState(cart?.couponCode);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [couponError, setCouponError] = useState<boolean>(false);

    const addCoupon = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading || cart.couponCode === couponCode) return;
        if (discountCodeIsEmpty(couponCode)) return setCouponError(true);

        setLoading(true)
        const valid = await checkDiscountCode(couponCode);

        if (valid) {
            setCouponError(false);
            const newCart = await addDiscountCode(cart, couponCode, cart.currency, lang);

            dispatch(setCart(newCart));
            setLoading(false);
            setSubmitDisabled(true);
        } else {
            setLoading(false);
            setCouponError(true);
        }
    }

    useEffect(() => {
        if (couponCode === cart.couponCode) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    })

    return (
        <Discountform onSubmit={addCoupon}>
            <DiscountInput
                flattenBottom={!!couponError}
                value={couponCode}
                placeholder={data?.enter_discount_code as string}
                disabled={loading}
                onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
            />
            <DiscountButton type="submit" disabled={submitDisabled || loading}>
                {data?.apply}
            </DiscountButton>
            {couponError && data?.discount_code_error && (
                <DiscountCodeError>
                    {data?.discount_code_error}
                </DiscountCodeError>
            )}
        </Discountform>
    )
};

export default OrderSummaryCouponForm;