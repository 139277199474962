import { LineContent } from "../../playbrush-client/types";


export const getSubscriptionPrice = (
    lineContent: LineContent[]
): {
    price: number,
    renewalPrice: number,
} => {
    return lineContent.reduce((prices, item) => {
        return ({
            price: prices.price + item.priceAmount,
            renewalPrice: prices.price + (item.priceAmountRecurring || 0),
        })
    }, { price: 0, renewalPrice: 0 })
}