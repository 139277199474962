import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { SubscriptionBlockProps } from "../../types";
import BundleList from "./bundle-list";
import TagSection from "./tag-section";

import { getCleanPrismicBundleTags } from "@utils";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setBundle } from "../../../../../app/slices/product-reducer";
import ManualBundleList from "./manual-bundle-list";
import SubscriptionBlockPrices from "./subscription-block-prices";

const Label = styled.label<{ selected: boolean }>`
    ${({ selected }) => `
        display: block;
        margin: 0 0 12px;
        padding: 16px;
        border-radius: 12px;

        &:last-child {
            margin-bottom: 48px;
        }

        ${selected ? `
            border: 2px solid var(--light-green);
            padding: 15px;
            h5 {
                color: var(--light-green);
            }
        ` : `
            border: 1px solid var(--light-green);
        `}
        
        @media screen and (min-width: 768px) {
            padding: 24px;
        }
    `}
`

const Header = styled.header`
    display: grid;
    grid-template-columns: 5fr 2fr;
    column-gap: 16px;
`

const Title = styled.h5`
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 8px;
`

const Description = styled.div<{ addPadding: boolean, hasTags: boolean }>`
    ${({ addPadding, hasTags }) => `
        grid-column: 1 / 2;

        grid-row: ${hasTags ? '3 / 4' : '2 / 3'};
        
        ul {
            padding-inline-start: 16px;
        }
        @media screen and (min-width: 768px) {
            margin: 0 0 ${addPadding ? '24px' : '0px'};
            
            ul {
                padding-inline-start: 32px;
            }
        }
    `}
`

const TagContainer = styled.div`
    margin: 16px 0 0;
`

const Input = styled.input`
    display: none;
`

const SubscriptionBlock = ({
    bundle,
    description,
    title,
    tags = [],
    locale,
    currency,
    translations,
    manualBundleItems,
}: SubscriptionBlockProps) => {
    const selectedBundle = useAppSelector(state => state.product.selectedBundle);
    const dispatch = useAppDispatch();
    const { _id } = bundle || {};

    const cleanTags = getCleanPrismicBundleTags(tags);

    const selected = _id === selectedBundle;
    const hasTags = cleanTags.length > 0;
    const hasManualBundleItems = !!(manualBundleItems || [])[0]?.thumbnail_image?.gatsbyImageData;

    const setNewBundle = () => dispatch(setBundle(_id as string));

    return (
        <Label selected={selected} onClick={setNewBundle}>
            <Header>
                {title && <Title>{title}</Title>}
                {description?.richText && (
                    <Description addPadding={selected} hasTags={hasTags}>
                        {RichText.render(description.richText)}
                    </Description>
                )}
                <SubscriptionBlockPrices
                    bundle={bundle}
                    translations={translations}
                    currency={currency}
                    locale={locale}
                />
            </Header>
            <Input
                type="radio"
                value={_id as string}
            />
            {selected && hasManualBundleItems && <ManualBundleList items={manualBundleItems} />}
            {selected && bundle && !hasManualBundleItems && <BundleList items={bundle.items_extended} />}
            {hasTags && (
                <TagContainer>
                    <TagSection tags={cleanTags} />
                </TagContainer>
            )}
        </Label>
    )
}

export default SubscriptionBlock;