import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const MedalCleanIcon = ({ color = '#fff', size = '36' } : { color?: string, size?: string | number }) => (
    <IconSvg 
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 26 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9995 23.9959C19.3257 23.9959 24.4541 18.8675 24.4541 12.5413C24.4541 6.21507 19.3257 1.08667 12.9995 1.08667C6.67332 1.08667 1.54492 6.21507 1.54492 12.5413C1.54492 18.8675 6.67332 23.9959 12.9995 23.9959Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.79837 22.1793L4.81836 37.0867L13.0002 32.1776L21.1821 37.0867L19.2021 22.163" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
);

export default MedalCleanIcon;