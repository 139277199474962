import React from 'react'
import styled from "styled-components";

import { magnifyingGlassIconInline } from "../../../../icons/magnifying-glass-icon-inline";

const Bar = styled.div`
    margin: 0 auto 48px;
    max-width: 848px;
    background: white;
    border-radius: 16px;
    border: 1px solid #CDD4DC;

    @media screen and (min-width: 768px) {
        margin: 0 auto 80px;
    }
`

const Input = styled.input`
    width: 100%;
    height: auto;
    border: none;
    font-weight: 400;
    font-size: 16px;
    padding: 20px 28px 20px 48px;
    border-radius: 16px;
    background: transparent url(${magnifyingGlassIconInline('#CDD4DC')}) no-repeat 16px center;

    &::placeholder {
        color: #CDD4DC;
    }

    @media screen and (min-width: 768px) {
        font-size: 32px;
        padding: 20px 28px 20px 68px;
        background: transparent url(${magnifyingGlassIconInline('#CDD4DC')}) no-repeat 28px center;
    }
`

interface SearchBarProps {
    handleChange: (e: any) => void;
}

const SearchBar = ({ handleChange }: SearchBarProps) => (
    <Bar>
        <Input
            type="text"
            aria-label="Search"
            placeholder="..."
            onChange={handleChange}
        />
    </Bar>
);

export default SearchBar;