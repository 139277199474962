import React, { useEffect } from "react"

import "../../styles/fonts.css";

import Header from "./header/Header";
import Footer from "./footer/footer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setBundles } from "../../app/slices/bundles-reducer";
import styled from "styled-components";
import { BackgroundSVG } from "../slices/home/hero/background";
import { PageContext } from "../slices/types";
import { SanitizedBundle } from "../../utils/product/get-sanitized-bundles";
import { hasRichText } from "@utils";

const Main = styled.main<{ hasAnnouncement: boolean }>`
    ${({ hasAnnouncement }) => hasAnnouncement ? `
        padding-top: 0;

        @media screen and (min-width: 1140px) {
            padding-top: 40px;
        }

        & ${BackgroundSVG} {
            top: 0;
            
            @media screen and (min-width: 1140px) {
                top: 40px;
            }
        }
    ` : ``}
`

interface LayoutProps {
    children: JSX.Element[];
    bundles: SanitizedBundle[][];
    footerData: Queries.PrismicFooterDataType;
    headerData: Queries.PrismicHeaderDataType;
    location: any;
    page: string;
    audience: string;
    lang: string;
    hideFooter?: boolean;
    translations: PageContext['translations'];
    settings: PageContext['settings'];
}

const headerHasAnnouncement = (headerData: Queries.PrismicHeaderDataType): boolean => {
    const [announcementHasRichText] = hasRichText(headerData?.announcement?.richText);
    return announcementHasRichText;
}

const Layout = ({ children, bundles, hideFooter, footerData, headerData, location, audience, lang, page, translations, settings }: LayoutProps) => {
    const [stateBundles, notificationBannerOpen] = useAppSelector(state => [state.bundles, state.general.notificationBannerOpen]);
    const dispatch = useAppDispatch();
    const hasAnnouncement = headerHasAnnouncement(headerData);

    useEffect(() => {
        // if (stateBundles?.length === 0) dispatch(setBundles(bundles || []));
        dispatch(setBundles(bundles || []));
    }, []);

    return (
        <>
            {headerData && (
                <Header
                    headerData={headerData}
                    location={location}
                    audience={audience}
                    lang={lang}
                    translations={translations}
                    settings={settings}
                    page={page}
                />
            )}
            <Main className={hasAnnouncement && !notificationBannerOpen ? 'announcement--active' : ''} hasAnnouncement={hasAnnouncement && notificationBannerOpen}>
                {children}
            </Main>
            {(!hideFooter && footerData) && (
                <Footer footerData={footerData} location={location} lang={lang} />
            )}
        </>
    )
}

export default Layout
