import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const FacebookIcon = ({ color = '#fff' }: { color?: string }) => (
    <IconSvg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.33164 17.8638V9.75283H8.99883L9.41601 6.57471H6.3332V4.56846C6.3332 3.65127 6.5832 3.06377 7.91602 3.06377H9.5832V0.220019C9.3332 0.220019 8.3332 0.137207 7.16601 0.137207C4.74883 0.137207 3.16601 1.64189 3.16601 4.31846V6.57627H0.416016V9.75439H3.16601V17.8638H6.33164Z" 
            fill={color}/>
    </IconSvg>

);

export default FacebookIcon;