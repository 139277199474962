import { Accordion, NewsletterPopUp, DownloadSection } from '../../components/slices/generic';
import TextSectionSmall from '../../components/slices/generic/text-section-small/text-section-small';
import CookieSettings from '../../components/slices/text-page/cookie-settings';
import CookieDetails from '../../components/slices/text-page/cookie-details/cookie-details';
import PagewideImage from '../../components/slices/text-page/pagewide-image/pagewide-image';

export const components = {
    accordion: Accordion,
    download_section: DownloadSection,
    text_section_small: TextSectionSmall,
    cookie_settings: CookieSettings,
    'email_pop-up': NewsletterPopUp,
    cookie_details: CookieDetails,
    pagewide_image: PagewideImage,
}
