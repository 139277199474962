import React, { useState } from "react"
import styled from "styled-components";
import { concatMenuUrl } from "../mega-menu/utils";
import MobileNavigationItem from "./mobile-navigation-item";
import { getFormattedProductQueryString } from "@utils";

const NavBarList = styled.ul`
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: start;
    padding-inline-start: 0;
    font-weight: 700;
    font-size: 14px;
    margin: 24px 0 0 0;
`

const Dropdown = styled.div`
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    * {
        text-decoration: none;
        font-size: 24px;
    }

    a {
        width: 100%;
    }
`

const MenuLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    font-size: 24px;
    padding: 0;

    &:hover {
        color: var(--light-green);
    }
`

const Spacer = styled.div`
    margin: 24px 0;
    border-bottom: 1px solid #2BB57310;
`

const MobileNavigation = ({ menuData, page }: { menuData: Queries.PrismicHeaderDataMenuItems[], page: string }) => {
    const [menuId, setMenuId] = useState('');

    const handleActiveMenu = (id: string = ''): void => {
        if (id === menuId) return removeActiveMenu();
        setMenuId(id);
    }

    const removeActiveMenu = (): void => {
        setMenuId('');
    }

    return (
        <NavBarList>
            <Dropdown>
                {menuData.map((props, index) => {
                    const { link_text, link, mega_menu_items, link_to_section, menu_bundle_preselect_index, menu_bundle_type_preselect } = props;
                    const doc = mega_menu_items?.document;
                    const id = doc?.id;

                    const menuLink = 
                        (link?.document as Queries.PrismicProductPage)?.data && link?.url ?
                        `${
                            link.url
                        }${
                            getFormattedProductQueryString(
                                index + 1,
                                'mega-menu',
                                page,
                                menu_bundle_preselect_index as number,
                                menu_bundle_type_preselect as string
                            )
                        }` : link?.url

                    const url = concatMenuUrl(menuLink, link_to_section);

                    return (
                        <React.Fragment key={`${index}-${id}`}>
                            {doc ? (
                                <MobileNavigationItem
                                    link={link as Queries.PrismicLinkType}
                                    link_text={link_text || ''}
                                    page={page}
                                    mega_menu_items={doc as Queries.PrismicHeaderMenuItem}
                                    selectedId={menuId}
                                    handleActiveMenu={handleActiveMenu}
                                />
                            ) : (
                                <MenuLink href={url || '#'}>
                                    {link_text}
                                    <Spacer />
                                </MenuLink>
                            )}
                        </React.Fragment>
                    )
                })}
            </Dropdown>
        </NavBarList>
    )
};

export default MobileNavigation