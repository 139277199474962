import React from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Button from "../../../snippets/button";
import Wrapper from "../../../layout/wrapper";
import { hasRichText, renderRich } from "@utils";

import Slider from "react-slick";
import { SliceProps } from "../../types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from "./slide";

const Section = styled.section`
    gap: 80px;
    margin: 120px 0 0 0;    
    
    @media screen and (min-width: 768px) {
        margin: 120px 0 0 0;
    }
`

const SectionWrapper = styled.div`
    // margin: 0 0 0 16px;
    
    @media screen and (min-width: 768px) {
        max-width: 1440px;
        margin: 0 auto;
    }
`

const Heading = styled.div`
    margin: 0 0 40px;

    h1 {
        font-weight: 400;
        font-size: 56px;
        line-height: 73px;
    }
`

const DesktopWrapper = styled.div<{ split_layout: boolean }>`
    display: none;

    @media screen and (min-width: 768px) {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(auto-fill,minmax(320px,1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(320px,1fr);
        overflow-x: auto;

        ${({ split_layout }) => `   
            ${split_layout ? `
                @media screen and (min-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }
            ` : `
                @media screen and (min-width: 768px) {
                    grid-template-columns: 2fr 1fr;
                }
            `}
        `}
    }
`

const MobileWrapper = styled.div`
    display: block;

    @media screen and (min-width: 768px) {
        display: none;
    }
`

const SliderWrapper = styled(Slider)`
	margin: 0 auto;

    .slick-dots {
        bottom: -40px;

        li {
            width: 16px;
            height: 16px;
            margin: 0px 4px;

            button {
                &:before {
                    font-size: 8px;
                    color: #CDD4DC;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: var(--light-green);
                        opacity: 1;
                    }
                }
            }
        }
    }
`

const Article = styled.article`
    position: relative;
    border-radius: 16px;
    // overflow: hidden;
    padding: 24px;
    
    @media screen and (min-width: 768px) {
        display: grid;
        min-height: 524px;
        grid-template-columns: 1fr auto;
    }
`

const DesktopContentWrapper = styled.div`
    max-width: 309px;
    padding: 24px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(35px);
    border-radius: 16px;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-column: 2 / 3;
    }
`

const DesktopImage = styled(GatsbyImage)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
`

const Title = styled.div`
    padding: 0 0 8px 0;
    * {
        // word-break: break-all;
    }
`

const ReadingTime = styled.div`
    padding: 0 0 24px 0;
`

const Excerpt = styled.div`
    padding: 0 0 24px 0;
`

const FeaturedArticles = (props: SliceProps<Queries.PrismicMagazineBlogPageDataBodyRelatedArticles>) => {
    const {
        context: {
            audience
        },
        slice: {
            items,
            primary: {
                heading,
                split_layout
            },
        }
    } = props;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // prevArrow: false,
        // nextArrow: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    };

    return (
        <Section id="featured-articles">
            <Wrapper>
                <SectionWrapper>
                    {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                    <DesktopWrapper
                        split_layout={split_layout as boolean}
                    >
                        {items?.map((
                            {
                                article_title,
                                button_link,
                                button_text,
                                description,
                                image,
                                reading_time1
                            },
                            index
                        ) => {

                            const [articleTitleTitleHasRichText, articleTitleTitleRichText] = hasRichText(article_title?.richText);
                            const [readingTimeHasRichText, readingTimeRichText] = hasRichText(reading_time1?.richText);
                            const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
                            const [buttonTextHasRichText, buttonTextRichText] = hasRichText(button_text?.richText);

                            return (
                                <Article key={`${index}-article`}>
                                    {image?.gatsbyImageData && (
                                        <DesktopImage
                                            image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                            alt={image.alt || ''}
                                            objectFit={"cover"}
                                        />
                                    )}
                                    <DesktopContentWrapper>
                                        {articleTitleTitleHasRichText && <Title>{renderRich(articleTitleTitleRichText)}</Title>}
                                        {readingTimeHasRichText && <ReadingTime>{renderRich(readingTimeRichText)}</ReadingTime>}
                                        {descriptionHasRichText && <Excerpt>{renderRich(descriptionRichText)}</Excerpt>}
                                        {button_link?.url && button_text && buttonTextHasRichText &&
                                            <Button
                                                url={button_link?.url || ''}
                                                buttonStyle={'solid'}
                                            >
                                                {buttonTextHasRichText && renderRich(buttonTextRichText)}
                                            </Button>
                                        }
                                    </DesktopContentWrapper>
                                </Article>
                            )
                        })}
                    </DesktopWrapper>
                    <MobileWrapper>
                        <SliderWrapper {...settings}>
                            {items?.map((slide, index: number) => (
                                <Slide key={`${index}-slide`} {...slide} />
                            ))}
                        </SliderWrapper>
                    </MobileWrapper>
                </SectionWrapper>
            </Wrapper>
        </Section>
    )
}

export default FeaturedArticles