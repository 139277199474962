import Home from '../../templates/home/home';
import ProductPage from '../../templates/product-page/product-page';
import TextPage from '../../templates/text-page/text-page';
import HowItWorks from '../../templates/how-it-works-page/how-it-works-page';
import CartPage from '../../templates/cart-page/cart-page';
import AmbassadorPage from '../../templates/ambassador-page/ambassador-page';
import ProductOverviewPage from '../../templates/product-overview-page/product-overview-page';
import MagazineOverview from '../../templates/magazine-overview-page/magazine-overview-page';
import MagazineBlogPage from '../../templates/magazine-blog-page/magazine-blog-page';
import HelpCentrePage from '../../templates/help-centre-page/help-centre-page';
import ContactPage from '../../templates/contact-page/contact-page';
import AboutPage from '../../templates/about-page/about-page';
import PressAndMediaPage from '../../templates/press-and-media-page/press-and-media-page';
import CareersPage from '../../templates/careers-page/careers-page';
import DentistPage from '../../templates/dentist-page/dentist-page';
import ReferralsPage from '../../templates/referrals-page/referrals-page';
import ImHandelPage from '../../templates/im-handel-page/im-handel-page';
import ThankYouPage from '../../templates/thank-you-page/thank-you-page';

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

const componentResolver = componentResolverFromMap({
    homepage: Home,
    product_page: ProductPage,
    text_page: TextPage,
    how_it_works_page: HowItWorks,
    cart_page: CartPage,
    ambassador_page: AmbassadorPage,
    product_overview_page: ProductOverviewPage,
    magazine_overview_page: MagazineOverview,
    magazine_blog_page: MagazineBlogPage,
    help_centre_page: HelpCentrePage,
    contact_page: ContactPage,
    about_page: AboutPage,
    press_and_media_page: PressAndMediaPage,
    careers_page: CareersPage,
    dentist_page: DentistPage,
    referrals_page: ReferralsPage,
    im_handel_page: ImHandelPage,
    thank_you_page: ThankYouPage,
});

export default componentResolver;