import { getFromSessionStorage, supportsSessionStorage } from "@utils";
import { getOption } from "./get-option";
import { CURRENCY_PREFERENCE_KEY } from "./options";

export const getOptionFromLang = (lang: string) => {
    const currency = lang === 'en-gb' ? 'GBP' : 'EUR';
    const currencyPreference = supportsSessionStorage() ? getFromSessionStorage(CURRENCY_PREFERENCE_KEY) : false;
    const language = lang === 'en-gb' ? 'EN' : 'DE';

    return currencyPreference
        ? getOption(currencyPreference, language) || getOption(currency, language)
        : getOption(currency, language);
}