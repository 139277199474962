import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ArrowLeft = ({ color = 'white' }: { color?: string }) => (
    <IconSvg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 9.99971C28 9.7345 27.8946 9.48014 27.7071 9.29261C27.5196 9.10507 27.2652 8.99971 27 8.99971L3.414 8.99971L9.708 2.70771C9.89577 2.51994 10.0013 2.26526 10.0013 1.99971C10.0013 1.73416 9.89577 1.47949 9.708 1.29171C9.52023 1.10394 9.26555 0.998451 9 0.998451C8.73445 0.998451 8.47977 1.10394 8.292 1.29171L0.291996 9.29171C0.19887 9.38461 0.124985 9.49496 0.0745716 9.61645C0.0241585 9.73794 -0.001791 9.86818 -0.001791 9.99971C-0.001791 10.1312 0.0241585 10.2615 0.0745716 10.383C0.124985 10.5045 0.19887 10.6148 0.291996 10.7077L8.292 18.7077C8.47977 18.8955 8.73445 19.001 9 19.001C9.26555 19.001 9.52023 18.8955 9.708 18.7077C9.89577 18.5199 10.0013 18.2653 10.0013 17.9997C10.0013 17.7342 9.89577 17.4795 9.708 17.2917L3.414 10.9997L27 10.9997C27.2652 10.9997 27.5196 10.8944 27.7071 10.7068C27.8946 10.5193 28 10.2649 28 9.99971Z"
            fill={color}
        />
    </IconSvg>
);

export default ArrowLeft;
