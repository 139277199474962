import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`
const Quotation = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="34" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0956 12.6265C12.4165 13.2088 13.4468 14.0824 14.1865 15.2471C14.9262 16.4118 15.296 17.7882 15.296 19.3765C15.296 21.6529 14.5828 23.5059 13.1562 24.9353C11.7296 26.3118 9.90676 27 7.68765 27C5.46853 27 3.61927 26.2853 2.13986 24.8559C0.713287 23.4265 0 21.6 0 19.3765C0 18.3176 0.13209 17.2588 0.39627 16.2C0.660451 15.1412 1.24165 13.5529 2.13986 11.4353L6.7366 0H14.5828L11.0956 12.6265ZM29.7995 12.6265C31.1204 13.2088 32.1507 14.0824 32.8904 15.2471C33.6301 16.4118 34 17.7882 34 19.3765C34 21.6529 33.2867 23.5059 31.8601 24.9353C30.4336 26.3118 28.6107 27 26.3916 27C24.1725 27 22.3232 26.2853 20.8438 24.8559C19.4172 23.4265 18.704 21.6 18.704 19.3765C18.704 18.3176 18.8361 17.2588 19.1002 16.2C19.3644 15.1412 19.9456 13.5529 20.8438 11.4353L25.4406 0H33.2867L29.7995 12.6265Z"
            fill={color} />
    </IconSvg>
);

export default Quotation;
