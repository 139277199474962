import React, { MouseEventHandler, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { hasRichText, renderRich } from "../../../utils/common/rich-text"
import styled, { keyframes } from "styled-components";
import QuickCart from "./quick-cart";
import HamburgerIcon from "../../icons/hamburger-icon";
import { shouldSwitchAudience, shouldSwitchLang, Switcher } from "./audience-switcher";

import Navigation from "./navigation";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDrawerState } from "../../../app/slices/cart-reducer";
import { PageContext } from "../../slices/types";
import { Audience, constants } from "@constants";
import { setAudience, setLang, setNotificationBannerOpen } from "../../../app/slices/general-reducer";
import { Logo } from "./logo";
import { MobileNavigationDrawer } from "./mobile-navigation-drawer";
import CartButton from "./cart-button";
import { MegaMenu } from "./mega-menu";
import { getFormattedProductQueryString, getFromSessionStorage, setToSessionStorage } from "@utils";

const breakPoint = 1140;
const announcementHeight = 40;

const StyledHeader = styled.header`
    position: fixed;
	z-index: 99;
	width: 100%;
	margin: 0;
`

// const AnnouncementBar = styled.div<{ hide: boolean }>`
//     ${({ hide }) => `
//         display: ${hide ? 'none' : 'grid'};
//         grid-template-columns: 1fr auto;
//         height: ${announcementHeight}px;
//         background: linear-gradient(96.61deg, #2BB573 2.53%, #06754A 136.31%);
//         align-items: center;
//         text-align: center;
//         padding: 0 16px;
        
//         p {
//             color: white;
//             font-size: 14px;
//             line-height: 21px;
//             font-weight: 400;

//             a {
//                 color: white;
//                 text-decoration: underline;
//             }
//         }
//     `}
// `

const ticker = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
`

const AnnouncementBar = styled.div<{ hide: boolean }>`
    display: ${({ hide }) => hide ? 'none' : 'flex'};
    justify-content: center;
    height: ${announcementHeight}px;
    background: linear-gradient(96.61deg, #2BB573 2.53%, #06754A 136.31%);
    overflow: hidden;
    position: relative;

    p {
        color: white;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;

        a {
            color: white;
            text-decoration: underline;
        }

        em {
            text-decoration: underline;
            font-style: normal;
            color: white;
        }
    }
`

const TicksWrapper = styled.div`
    @media screen and (max-width: ${breakPoint}px) {
        position: relative;
        width: 90%;
        overflow: hidden;
    }
`

const TicksWrapperLink = styled(Link)`;
    text-decoration: none;

    @media screen and (max-width: ${breakPoint}px) {
        position: relative;
        width: 90%;
        overflow: hidden;
    }
`

const Ticks = styled.ul`
    display: flex;
    align-items: center;
    margin: 0;
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    height: ${announcementHeight}px;

    @media screen and (max-width: ${breakPoint}px) {
        position: absolute;
        left: 0;
        top: 0;
        animation-name: ${ticker};
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`

const Tick = styled.li`
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 11px;
    line-height: 28px;
    margin: 0 25px 0;
    white-space: nowrap;

    @media screen and (min-width: ${breakPoint}px) {
        &.hide-on-desktop {
            display: none;
        }
    }
`

const CloseAnnouncementBarButton = styled.button`
    padding: 8px 16px;
    margin: 0;
    background: transparent;
    border: 0;
    outline: 0;
    line-height: 0;
    justify-self: flex-end;
    z-index: 1;
`

const HeaderWrapper = styled.ul<{ hasAnnouncementBar: boolean }>`
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: ${({ hasAnnouncementBar }) => hasAnnouncementBar ? `${announcementHeight}` : `0`}px;

	display: flex;
	justify-content: space-between;
	column-gap: 10px;
	align-items: center;
	flex-wrap: wrap;
	margin: 12px 16px 0;
    padding: 0 32px 0 0;
	height: auto;
	border-radius: 44px;
	list-style: none;
    overflow: hidden;
	
	@media screen and (max-width: ${breakPoint}px) {
		padding: 0 4px 0 0;
	}
    @media screen and (min-width: ${breakPoint}px) {
        background: #fff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        column-gap: 40px;
    }
`

const LogoWrapper = styled.li`
    display: flex;
    align-items: center;
    background: var(--light-green);
    flex: 0 1 auto;
    height: 56px;

    @media screen and (min-width: ${breakPoint}px) {
		padding: 15px 5px 18px 18px;
        flex: 0 1 auto;
	}
`

const NavWrapper = styled.li`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 auto;
    gap: 20px;

    button {
        top: 4px;
    }
    @media screen and (min-width: ${breakPoint}px) {
        display: none;
    }
`

const CartButtonWrapper = styled.li`
    display: none;

    @media screen and (min-width: ${breakPoint}px) {
        display: block;
        // button {
        //     top: 4px;
        // }
    }
`

const AudienceSwitcherWrapper = styled.li`
	@media screen and (max-width: ${breakPoint}px) {
		display: none;
	}
`

const NavBar = styled.li`
	flex: 1 0 40%;

	@media screen and (max-width: ${breakPoint}px) {
			display: none;
	}
`

const DrawerCover = styled.div`
	position: fixed;
	top: 0;
	right: 500px;
	bottom: 0;
	left: 0;
	z-index: 999;

	background-color: var(--light-green);
    opacity: 0.4;
`

const MobileNavBar = styled.div`
	// flex: 0 0 10%;
    height: 48px;
    width: 48px;
    background: var(--light-green);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

	@media screen and (min-width: ${breakPoint}px) {
		display: none;
	}
`

const CloseArea = styled.div`
    z-index: 98;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--light-green);
    opacity: 0.4;
`

const Header = (
    props: {
        headerData: Queries.PrismicHeaderDataType,
        location: any,
        audience: string,
        page: string,
        lang: string,
        translations: PageContext['translations'],
        settings: PageContext['settings'],
    }) => {
    const [
        cart,
        audienceFromState,
        langFromState,
        notificationBannerOpen,
    ] = useAppSelector(state => [
        state.cart,
        state.general.audience,
        state.general.lang,
        state.general.notificationBannerOpen,
    ]);
    const dispatch = useAppDispatch();

    const { headerData, location, lang, page, translations, settings } = props;
    const [menu, setMenu] = useState<Queries.PrismicHeaderMenuItem['data']>();
    const [navOpen, setNavOpen] = useState<boolean>(false);

    const { bundle_preselect_index, bundle_type_preselect, announcement, announcement_link, menu_items } = headerData;
    const {url: announcementUrl, target: announcementTarget, document: announcementProductDocument } = announcement_link || {}

    const handleMenuChange = (newMenu ?: Queries.PrismicHeaderMenuItem['data']) => {
        if (
            newMenu?.button_text !== menu?.button_text ||
            menu?.button_url?.url !== newMenu?.button_url?.url) {
            setMenu(newMenu);
        } else {
            setMenu(undefined);
        }
    }

    const openCart = () => {
        dispatch(setDrawerState(true));
    }

    const closeCart = () => {
        dispatch(setDrawerState(false));
    }

    const goTo = (newAudience: Audience) => {
        if (audienceFromState === newAudience) return;

        const destination = `/${lang}/${newAudience}/`;
        let finalDesination = destination;

        if (!process.env.GATSBY_GOD_MODE_ENABLED) {
            if (newAudience === constants.audience.KIDS) {
                finalDesination = `https://playbrush.com${finalDesination}`;
            } else {
                finalDesination = `https://gumsmartone.com${finalDesination}`;
            }

            window.location.href = finalDesination;
        } else {
            dispatch(setAudience(newAudience));
            navigate(finalDesination);
        }

    }

    useEffect(() => {
        const { shouldSwitch, lang } = shouldSwitchLang(langFromState, location.pathname);
        const { shouldSwitchRoute, switchTo } = shouldSwitchAudience(audienceFromState, location.pathname);

        if (shouldSwitch) {
            dispatch(setLang(lang));
        }

        if (shouldSwitchRoute) {
            dispatch(setAudience(switchTo));
        }
    })

    const [announcementHasRichText, announcementRichText] = hasRichText(announcement?.richText);

    const closeAnnouncementBar = () => {
        dispatch(setNotificationBannerOpen(false))
    }

    const fullAnnouncementLink = 
        (announcementProductDocument as Queries.PrismicProductPage)?.data ?
        `${
            announcementUrl
        }${
            getFormattedProductQueryString(
                0,
                'notification-bar',
                page,
                bundle_preselect_index as number,
                bundle_type_preselect  as string,
            )
        }` : announcementUrl

    return (
        <>
            <StyledHeader>
                {announcementHasRichText && (
                    <AnnouncementBar hide={!notificationBannerOpen}>
                        {fullAnnouncementLink ? (
                            <TicksWrapperLink to={fullAnnouncementLink} target={announcementTarget as string}
                            >
                                <Ticks>
                                    <Tick>
                                        {renderRich(announcementRichText)}
                                    </Tick>
                                    <Tick className="hide-on-desktop">
                                        {renderRich(announcementRichText)}
                                    </Tick>
                                </Ticks>
                            </TicksWrapperLink>
                        ) : (
                            <TicksWrapper>
                                <Ticks>
                                    <Tick>
                                        {renderRich(announcementRichText)}
                                    </Tick>
                                    <Tick className="hide-on-desktop">
                                        {renderRich(announcementRichText)}
                                    </Tick>
                                </Ticks>
                            </TicksWrapper>
                        )}
                        <CloseAnnouncementBarButton type="button" onClick={closeAnnouncementBar}>
                            <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7165_27883)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.45725 0.0428986C1.06673 -0.347626 0.433563 -0.347625 0.0430389 0.0428986C-0.347485 0.433423 -0.347485 1.06659 0.0430389 1.45711L7.58593 9L0.0430402 16.5429C-0.347484 16.9334 -0.347484 17.5666 0.0430402 17.9571C0.433565 18.3476 1.06673 18.3476 1.45725 17.9571L9.00014 10.4142L16.543 17.9571C16.9336 18.3476 17.5667 18.3476 17.9572 17.9571C18.3478 17.5666 18.3478 16.9334 17.9572 16.5429L10.4144 9L17.9572 1.45711C18.3478 1.06659 18.3478 0.433421 17.9572 0.0428959C17.5667 -0.347629 16.9336 -0.347628 16.543 0.0428959L9.00014 7.58579L1.45725 0.0428986Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_7165_27883">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </CloseAnnouncementBarButton>
                    </AnnouncementBar>
                )}
                <HeaderWrapper hasAnnouncementBar={announcementHasRichText && notificationBannerOpen}>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                    <NavBar>
                        <Navigation
                            menuData={menu_items as Queries.PrismicHeaderDataMenuItems[]}
                            setMenu={handleMenuChange}
                        />
                    </NavBar>
                    <AudienceSwitcherWrapper>
                        <Switcher
                            translations={translations}
                            goTo={goTo}
                        />
                    </AudienceSwitcherWrapper>
                    <CartButtonWrapper>
                        <CartButton openCart={openCart} />
                    </CartButtonWrapper>
                    <NavWrapper>
                        <CartButton openCart={openCart} />
                        <MobileNavBar onClick={() => setNavOpen(true)}>
                            <HamburgerIcon />
                        </MobileNavBar>
                    </NavWrapper>
                    {menu && (
                        <MegaMenu menu={menu} page={page} />
                    )}
                </HeaderWrapper>
            </StyledHeader>
            {cart.open && (
                <>
                    <DrawerCover onClick={closeCart} />
                    <QuickCart
                        translations={translations}
                        settings={settings}
                    />
                </>
            )}
            {navOpen && (
                <MobileNavigationDrawer
                    menuData={headerData.menu_items as Queries.PrismicHeaderDataMenuItems[]}
                    translations={translations}
                    close={() => setNavOpen(false)}
                    page={page}
                    goTo={goTo}
                />
            )}
            {menu && <CloseArea onClick={() => setMenu(undefined)} />}
        </>
    )
};

export default Header