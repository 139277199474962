import React, { useState } from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import Button from "../../../snippets/button";
// import VideoPlayer from "../../../snippets/video-player/video-player";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import { hasRichText, renderRich, linksToProductFlow, getCookie, setCookie } from "@utils";
import { constants } from "@constants";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
        // max-width: 1330px;
    }
`

const InnerWrapper = styled.div<{ audience: string }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 10%;
    }


    // ${({ audience }) => `
    //     display: grid;
    //     grid-template-columns: 1fr;
    //     grid-template-rows: auto;
    //     gap: 5%;
        
    //     @media screen and (min-width: 768px) {
    //         grid-template-columns: ${audience === 'adults' ? '1fr' : '1fr 1fr'};
    //         grid-template-rows: 1fr;
    //     }

    //     @media screen and (max-width: 768px) {
    //         div:first-child {
    //             grid-row: 2 / 3;
    //         }
    //     }
    // `}
`

const TextColumn = styled.div<{ video_position: boolean }>`
    display: flex;
    align-items: flex-start;
    width: 100%;
    order: 2;

    @media screen and (min-width: 768px) {
        ${({ video_position }) => video_position ? `
            order: 1;
        ` : `
            order: 2;
        `}
    }
`

const VideoColumn = styled.div<{ video_position: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    order: 1;

    @media screen and (min-width: 768px) {
        ${({ video_position }) => video_position ? `
            order: 2;
        ` : `
            order: 1;
        `}
    }
`

const Content = styled.article<{ audience: string }>`
    // ${({ audience }) => audience === 'adults' ? `
    //     // padding: 24px;
    //     // width: 100%;
        
    //     @media screen and (min-width: 768px) {
    //         display: grid;
    //         grid-template-columns: 1fr auto;
    //     }
    // ` : `
    //     @media screen and (max-width: 768px) {
    //         div > button {
    //             width: 100%;
    //         }
    //     }
    // `}
`

const Heading = styled.div<{ audience: string }>`
    ${({ audience }) => `
        margin: 0 0 ${audience === 'adults' ? '16px' : '8px'};
        max-width: 524px;
    `}
`

const Body = styled.div`
    margin: 0 0 20px;
    max-width: 524px;

    @media screen and (min-width: 768px) {
        margin: 0 0 40px;
    }

`

const DesktopButton = styled.div``

const YouTubeEmbed = styled.div`
    line-height: 50%;
    width: 100%;
    height: auto;
    
    iframe {
        max-width: 100%;
    }
`

const TextVideo = ({ context, slice }: SliceProps<Queries.PrismicHomepageDataBodyTextVideo>) => {
    const { audience, translations } = context;
    const { primary } = slice;
    const { video_position, video, flair, heading, body_text, cta_text, cta_link } = primary;
    const { document } = video as Queries.PrismicLinkType;
    const { src, use_autoplay, mute_audio, loop, plays_inline, placeholder_image, youtube_embed_url } = (document?.data || {}) as Queries.PrismicVideoDataType;
    const showFlair = audience === 'kids';
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [bodyHasRichText, bodyRichText] = hasRichText(body_text?.richText);

    const buttonStyle = linksToProductFlow(cta_link) ? 'solid' : 'outline';

    const YoutubeEmbedUrl = youtube_embed_url;

    return (
        <Wrapper id="text-video">
            <Section>
                <InnerWrapper audience={audience}>
                    <TextColumn video_position={!!video_position}>
                        <Content audience={audience}>
                            {headingHasRichText && <Heading audience={audience}>{renderRich(headingRichText)}</Heading>}
                            {bodyHasRichText && <Body>{renderRich(bodyRichText)}</Body>}
                            {cta_text && cta_link?.url &&
                                <DesktopButton>
                                    <Button
                                        buttonStyle={buttonStyle}
                                        buttonFullWidth={'full-mobile'}
                                        url={cta_link.url}
                                        target={cta_link?.target || '_self'}
                                    >
                                        {cta_text}
                                    </Button>
                                </DesktopButton>
                            }
                        </Content>
                    </TextColumn>
                    <VideoColumn video_position={!!video_position}>
                        {YoutubeEmbedUrl &&
                            <YouTubeEmbed dangerouslySetInnerHTML={{ __html: YoutubeEmbedUrl }} />
                        }
                    </VideoColumn>
                </InnerWrapper>
            </Section>
        </Wrapper>
    )
}

export default TextVideo