import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from "styled-components";
import { SliceZone } from "@prismicio/react"
import { RichText } from "prismic-reactjs";

import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"

import { components } from "./components"
import { Link } from "gatsby";
import { PageContext } from "../../components/slices/types";
import ToTopButton from "../../components/snippets/to-top-button";

import { arrowRightIconSmallInline } from "../../components/icons/arrow-right-icon-small-inline";
import { useAppSelector } from "../../app/hooks";
import { logPageView, getSanitizedBundles } from "@utils";
import linkResolver from '../../utils/prismic/link-resolver';

const MagazineBlog = styled.section`
    padding: 120px 16px 0;
    
    @media screen and (min-width: 768px) {
        padding: 140px 80px 0;
    }
`

const BackButtonWrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;

    @media screen and (min-width: 768px) {
        margin: 0 80px;
    }
`

const BackButton = styled(Link)`
    display: flex;
    align-items: end;
    text-decoration: underline;
    color: var(--light-green);

    &:before {
        content: url(${arrowRightIconSmallInline('#fc657d')});
        transform: rotate(180deg);
    }
`

const HeadingWrapper = styled.div`
    margin: 40px auto 24px;

    @media screen and (min-width: 768px) {
        margin: 40px auto;
        max-width: 848px;
    }
`

const Heading = styled.div`
    padding: 0 0 10px 0;

    h1 {
        font-weight: 400;
        font-size: 40px;
        line-height: 130%;
        letter-spacing: -0.01em;
    }

    @media screen and (min-width: 768px) {
        padding: 0 0 10px 0;

        h1 {
            font-size: 56px;
        }
    }
`

const ReadingTime = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
`

const MagazineBlogPage = (props: PageProps<Queries.MagazineBlogQuery, PageContext>): JSX.Element => {
    const globalAudience = useAppSelector(state => state.general.audience);
    const { data, location, pageContext } = props;
    const { prismicMagazineBlogPage, prismicMagazineOverview, allPrismicHeader, prismicFooter, allPrismicProductVariant } = data;
    const { lang, data: magazineBlogPageData } = prismicMagazineBlogPage || {};
    const { page_title, page_description, page_image, body, blog_title, reading_time, tags } = magazineBlogPageData || {}
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings } = pageContext;
    const { url } = prismicMagazineOverview || {};
    const bundles = getSanitizedBundles(allPrismicProductVariant.nodes as Queries.PrismicProductVariant[]);
    const page = 'magazine-post';
    const pageImage = page_image?.url || '';

    useEffect(() => {
        logPageView({ content_group: page });
    }, []);

    return (
        <Layout
            bundles={bundles}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            page={page}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={page_title || 'Magazine Blog'} description={page_description || 'Magazine Blog page'} lang={lang?.split('-')[0]} image={pageImage} canonicalUrl={`https://playbrush.com${location.pathname}`}/>
            <MagazineBlog>
                <BackButtonWrapper>
                    {translations?.back_to_magazine && settings.link_to_magazine_overview?.url &&
                        <BackButton
                            to={settings.link_to_magazine_overview.url as string || ''}
                        >
                            {translations.back_to_magazine}
                        </BackButton>
                    }
                </BackButtonWrapper>
                <HeadingWrapper>
                    {blog_title && blog_title?.richText &&
                        <Heading>
                            {blog_title?.richText && RichText.render(blog_title.richText)}
                        </Heading>
                    }
                    {reading_time && reading_time?.richText &&
                        <ReadingTime>
                            {reading_time?.richText && RichText.render(reading_time.richText)}
                        </ReadingTime>
                    }
                </HeadingWrapper>
                <SliceZone
                    slices={body}
                    // @ts-ignore
                    components={components}
                    context={{
                        audience,
                        translations,
                        page,
                    }}
                />
                {translations?.back_to_top && <ToTopButton text={translations.back_to_top} />}
            </MagazineBlog>
        </Layout>
    )
}

export const query = graphql`
    query MagazineBlog($id: String, $lang: String) {
        prismicMagazineBlogPage(id: {eq: $id}, lang: {eq: $lang}) {
            _previewable
            href
            url
            prismicId
            tags
            type
            lang
            uid
            data {
                page_title
                page_image {
                    alt
                    url
                }
                page_description
                blog_title {
                    richText
                }
                reading_time {
                    richText
                }
                tags {
                    tag
                }
                body {
                    ... on PrismicMagazineBlogPageDataBodyEmailPopUp {
                        id
                        slice_type
                        primary {
                            delay
                            success_message
                            subscription_form {
                                document {
                                    ... on PrismicSubscriptionForm {
                                        id
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyCtaTeaser {
                        id
                        slice_type
                        slice_label
                        primary {
                            button_link {
                                url
                                target
                            }
                            button_text {
                                richText
                            }
                            cta_title {
                                richText
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyFeaturedProducts1 {
                        id
                        slice_type
                        items {
                            column_layout
                            background_color
                            use_gradient
                            description {
                                richText
                            }
                            link {
                                url
                                target
                                document {
                                    ... on PrismicProductPage {
                                        id
                                        data {
                                            prismic_product {
                                                document {
                                                    ... on PrismicProduct {
                                                        id
                                                        data {
                                                            is_bundle
                                                            bundle_filter
                                                            variant {
                                                                product_variant {
                                                                    document {
                                                                        ... on PrismicProductVariant {
                                                                            id
                                                                            data {
                                                                                stripe_variant {
                                                                                    default_price
                                                                                    name
                                                                                    price_amount
                                                                                    price_amount_pay_now_current_eur
                                                                                    price_amount_pay_now_current_gbp
                                                                                    price_amount_pay_now_current_usd
                                                                                    id
                                                                                    _id
                                                                                    price_original
                                                                                    }
                                                                                    bundles {
                                                                                        bundle {
                                                                                            document {
                                                                                                ... on PrismicBundle {
                                                                                                    id
                                                                                                    data {
                                                                                                    bundle {
                                                                                                        price_amount
                                                                                                        price_amount_one_time_current_eur
                                                                                                        price_amount_one_time_current_gbp
                                                                                                        price_amount_one_time_current_usd
                                                                                                        name
                                                                                                        price_original
                                                                                                        _id
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            button_text {
                                richText
                            }
                            price {
                                richText
                            }
                            product_image {
                                gatsbyImageData
                                alt
                            }
                            product_title {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyRelatedArticles {
                        id
                        slice_type
                        items {
                            article_title {
                                richText
                            }
                            button_link {
                                target
                                url
                            }
                            button_text {
                                richText
                            }
                            description {
                                richText
                            }
                            image {
                                gatsbyImageData
                            }
                            reading_time1 {
                                richText
                            }
                        }
                        primary {
                            heading {
                                richText
                            }
                            split_layout
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyTwoImagesGrid {
                        id
                        slice_type
                        items {
                            image {
                                alt
                                gatsbyImageData
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyCtaTeaser {
                        id
                        slice_type
                        primary {
                            button_link {
                                target
                                url
                            }
                            button_text {
                                richText
                            }
                            cta_title {
                                richText
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyPlainText {
                        id
                        slice_type
                        primary {
                            text {
                                richText
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyTwoColumnBlog {
                        id
                        slice_type
                        items {
                            image {
                                gatsbyImageData
                                alt
                            }
                            text {
                                richText
                            }
                        }
                    }
                    ... on PrismicMagazineBlogPageDataBodyTextVideo {
                        id
                        slice_type
                        primary {
                            video {
                                document {
                                    ... on PrismicVideo {
                                        id
                                        data {
                                            loop
                                            mute_audio
                                            plays_inline
                                            use_autoplay
                                            src
                                            youtube_embed_url
                                            placeholder_image {
                                                alt
                                                gatsbyImageData
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicMagazineOverview(lang: {eq: $lang}) {
            url
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                    tags
                                                    price_recurring_trial_period_days
                                                    price_recurring_interval_count
                                                    price_recurring_interval
                                                    price_original_after_trial
                                                    price_original
                                                    price_amount_after_trial
                                                    price_amount
                                                    name
                                                    default_price
                                                    image
                                                    description
                                                    active
                                                    metadata {
                                                        prod_image_corner_note_EN
                                                        prod_image_title_DE
                                                        prod_image_title_EN
                                                        prod_image_tooltip_text_DE
                                                        prod_image_tooltip_text_EN
                                                        prod_marketing_title_DE
                                                        prod_marketing_title_EN
                                                        prod_order_title_DE
                                                        prod_order_title_EN
                                                        tags
                                                        prod_description_DE
                                                        prod_description_EN
                                                        prod_image_corner_note_DE
                                                    }
                                                    _id
                                                    description_de
                                                    description_en
                                                }
                                                product
                                                price_default_blob {
                                                    active
                                                    currency
                                                    _id
                                                    product
                                                    recurring_interval
                                                    recurring_interval_count
                                                    recurring_trial_period_days
                                                    type
                                                    unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                            price_amount_one_time
                                            price_amount_recurring
                                            price_original_after_trial
                                            price_original_one_time
                                            price_original_recurring
                                            price_shipping_eur
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        menu_bundle_type_preselect
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        bundle_preselect_index
                                        button_bundle_type_preselect
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                            bundle_type_preselect
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(
    MagazineBlogPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
    }]
);
