/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

type SEOProps = {
    description?: string;
    lang?: string;
    meta?: Meta;
    title: string;
    image?: string;
    canonicalUrl: string;
}

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>

type PropertyMetaObj = {
    property: string
    content: string
}

type NameMetaObj = {
    name: string
    content: string
}

function Seo({ description, lang, meta, title, image, canonicalUrl }: SEOProps) {
    const cleanImage = image?.replace(/\?.+/, '');

    const customMetaData = [
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: description,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary_large_image`,
        },
        {
            name: `twitter:creator`,
            content: ``,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: description,
        },
        {
            name: `og:image:type`,
            content: `image/jpeg`,
        },
    ]

    if (cleanImage) {
        customMetaData.push({
            name: `og:image`,
            content: cleanImage,
        },)
    } else {
        customMetaData.push({
            name: `og:image`,
            content: `/images/social.jpg`,
        },)
    }

    const fullMetaData = meta ? customMetaData.concat(meta) : customMetaData;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            meta={fullMetaData}
        >
            <link rel="canonical" href={canonicalUrl} />
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
}

export default Seo
