import { BundlePB } from "src/components/slices/types"
import { convertPrice } from "../price-manipulation"


// Bundle price concept:
// one_time : the brush price
// recurring : the recurring part one time price

// pay_now: is the actual price you will pay at the checkout
// one_time + recurring = pay_now

// pay_after_trial: the price what the customer pays when renew the subscription

export const getPricesFromBundle = (
    locale: string,
    currency: string,
    bundle?: Queries.PrismicBundleDataBundleIntegrationType,
) => {

    function getBundleValueNumber(key_string: string) {
        if ( bundle !== undefined && bundle?.hasOwnProperty(key_string)){
            return bundle[key_string as keyof typeof bundle] as number
        }
        else {
            return 0
        }
    }
    function getBundleValueString(key_string: string) {
        if ( bundle !== undefined && bundle?.hasOwnProperty(key_string)){
            return bundle[key_string as keyof typeof bundle] as string
        }
        else {
            return ""
        }
    }

    const price_original_recurring = getBundleValueNumber("price_amount_recurring_crossed_"+currency.toLocaleLowerCase());
    const price_amount_recurring = getBundleValueNumber("price_amount_recurring_current_"+currency.toLocaleLowerCase());
    const price_shipping = getBundleValueNumber("price_shipping_"+currency.toLocaleLowerCase());
    const price_amount = getBundleValueNumber("price_amount_pay_now_current_"+currency.toLocaleLowerCase());
    const price_amount_after_trial = getBundleValueNumber("price_amount_pay_after_trial_current_"+currency.toLocaleLowerCase());
    const price_original = getBundleValueNumber("price_amount_pay_now_crossed_"+currency.toLocaleLowerCase());
    const price_original_after_trial = getBundleValueNumber("price_amount_pay_after_trial_crossed_"+currency.toLocaleLowerCase());
    const savings = getBundleValueString("savings_"+currency.toLocaleLowerCase());
    const recurring_trial_period_days = getBundleValueNumber("recurring_trial_period_days_"+currency.toLocaleLowerCase());

    return {
        price: convertPrice((price_amount || 0), locale, currency),
        price_amount: convertPrice((price_amount || 0), locale, currency),
        price_amount_after_trial: convertPrice((price_amount_after_trial || 0), locale, currency),
        price_original: convertPrice((price_original || 0), locale, currency),
        price_original_after_trial: convertPrice((price_original_after_trial || 0), locale, currency),
        price_original_recurring: convertPrice((price_original_recurring || 0), locale, currency),
        price_amount_recurring: convertPrice((price_amount_recurring || 0), locale, currency),
        price_shipping_eur: convertPrice((price_shipping || 0), locale, currency),
        savings,
        recurring_trial_period_days,
        has_after_trial: price_amount_after_trial,
    }
}

const capitalize = (str: string): string  => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const getPricesFromBundleNew = (
    locale: string,
    currency: string,
    translationKey: string,
    bundle?: BundlePB,
) => {

    function getBundleValueNumber(key_string: string) {
        if ( bundle !== undefined && bundle?.prices?.hasOwnProperty(key_string)) {
            return bundle?.prices?.[key_string]?.[translationKey] || 0
        }
        else {
            return 0
        }
    }

    function getBundleValueString(key_string: string) {
        if ( bundle !== undefined && bundle?.hasOwnProperty(key_string)){
            return bundle[key_string]
        }
        else {
            return ""
        }
    }

    const price_original_recurring = getBundleValueNumber("priceAmountRecurringCrossed");
    const price_amount_recurring = getBundleValueNumber("priceAmountRecurringCurrent");
    const price_shipping = getBundleValueNumber("priceShipping");
    const price_amount = getBundleValueNumber("priceAmountPayNowCurrent");
    const price_amount_after_trial = getBundleValueNumber("priceAmountPayAfterTrialCurrent");
    const price_original = getBundleValueNumber("priceAmountPayNowCrossed");
    const price_original_after_trial = getBundleValueNumber("priceAmountPayAfterTrialCrossed");
    const savings = getBundleValueString("savings");
    const recurring_trial_period_days = getBundleValueNumber("recurringTrialPeriodDays");

    return {
        price: convertPrice((price_amount || 0), locale, currency),
        price_amount: convertPrice((price_amount || 0), locale, currency),
        price_amount_after_trial: convertPrice((price_amount_after_trial || 0), locale, currency),
        price_original: convertPrice((price_original || 0), locale, currency),
        price_original_after_trial: convertPrice((price_original_after_trial || 0), locale, currency),
        price_original_recurring: convertPrice((price_original_recurring || 0), locale, currency),
        price_amount_recurring: convertPrice((price_amount_recurring || 0), locale, currency),
        price_shipping_eur: convertPrice((price_shipping || 0), locale, currency),
        savings,
        recurring_trial_period_days,
        has_after_trial: price_amount_after_trial,
    }
}