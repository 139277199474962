import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Wrapper from "../../../layout/wrapper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from "./slide";
import { SliceProps } from "../../types";
import { RichText } from 'prismic-reactjs';

const Section = styled.section`
    margin: 20px auto 0;

    @media screen and (min-width: 768px) {
        margin: 60px auto 0;
    }
`

const SliderWrapper = styled(Slider)`
	margin: 0 auto;

    .slick-dots {
        bottom: -40px;

        li {
            width: 16px;
            height: 16px;
            margin: 0px 4px;

            button {
                &:before {
                    font-size: 8px;
                    color: #CDD4DC;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: var(--light-green);
                        opacity: 1;
                    }
                }
            }
        }
    }

	@media screen and (min-width: 768px) {
		max-width: 980px;
        
        .slick-dots {
            bottom: -60px;

            li {
                width: 20px;
                height: 20px;
                margin: 0px 8px;
            }
        }
	}
`

const Heading = styled.div`
    margin: 0 0 40px 0;
`

const MediaSlider = ({ slice }: SliceProps<Queries.PrismicHomepageDataBodyMediaSlider>) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        adaptiveHeight: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        // prevArrow: false,
        // nextArrow: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        easing: 'easeOutElastic',
        pauseOnHover: true,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            }
        ],
    };
    return (
        <Section>
            <Wrapper>
                {slice.primary.heading?.richText && (
                    <Heading>
                        {RichText.render(slice.primary.heading.richText)}
                    </Heading>
                )}
                <SliderWrapper {...settings}>
                    {slice?.items?.map((slide, index: number) => (
                        <Slide key={`${index}-slide`} {...slide} />
                    ))}
                </SliderWrapper>
            </Wrapper>
        </Section>
    )
}

export default MediaSlider
