import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import { arrowRightIconInline } from "../../../../icons/arrow-right-icon-inline";
import { arrowLeftIconInline } from "../../../../icons/arrow-left-icon-inline"

const SliderWrapper = styled(Slider)`
    overflow: hidden;

    .slick-prev, .slick-next {
        z-index: 1;
        opacity: 1;
        width: auto;
        padding: 0 16px;
    }

    .slick-prev {
        left: 0;

        &:before {
            display: inline-block;
            content: url(${arrowLeftIconInline('white')});
            text-shadow: 1px;
            filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 0.4));
            vertical-align: 0%;
            opacity: 1;
        }
    }
    
    .slick-next {
        right: 0;

        &:before {
            display: inline-block;
            content: url(${arrowRightIconInline('white')});
            text-shadow: 1px;
            filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 0.4));
            vertical-align: 0%;
            opacity: 1;
        }
    }

    .slick-dots {
        bottom: 16px;

        .slick-active {
            button {
                background-color: #fff;
            }
        }

        button {
            border: 1px solid white;
            background-color: transparent;
            border-radius: 50%;
            width: 8px;
            height: 8px;

            &::before {
                content: unset;
            }
        }
    }

	@media screen and (min-width: 768px) {
		max-width: 980px;
	}
`

const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
};

const ProductSlider = ({ images }: { images: (Queries.PrismicProductDataType['images'] | string)[] }) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            // @ts-ignore
            sliderRef?.current?.slickGoTo(0);
        }, 500);
    }, [images]);

    return (
        <SliderWrapper {...sliderSettings} ref={sliderRef}>
            {images?.map((slide, index: number) => {
                if (typeof slide === 'string') {
                    return <img key={index} src={slide} />
                } else if (slide?.image?.gatsbyImageData) {
                    return <GatsbyImage key={index} image={(slide?.image?.gatsbyImageData as unknown) as IGatsbyImageData} alt={slide?.image?.alt || ''} />
                } else {
                    return null
                }
            })}
        </SliderWrapper>
    )
}

export default ProductSlider;