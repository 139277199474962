import React from "react";
import styled from "styled-components";

const Star = styled.svg`
	width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
`

const getStar = (type: string, color: string) => {
    switch (type) {
        case "full": return (
            <path
                d="M21.0284 7.31659L14.5817 6.37968L11.6999 0.53729C11.6211 0.377329 11.4916 0.247837 11.3317 0.169126C10.9305 -0.0289213 10.443 0.136118 10.2424 0.53729L7.36059 6.37968L0.913906 7.31659C0.736172 7.34198 0.573672 7.42577 0.449257 7.55272C0.298848 7.70732 0.215965 7.9153 0.218821 8.13097C0.221678 8.34665 0.31004 8.55236 0.464492 8.70292L5.12875 13.2504L4.0268 19.6717C4.00096 19.821 4.01749 19.9747 4.07451 20.1151C4.13154 20.2556 4.22678 20.3773 4.34943 20.4663C4.47209 20.5554 4.61725 20.6084 4.76846 20.6191C4.91967 20.6299 5.07087 20.5981 5.20493 20.5273L10.9711 17.4957L16.7374 20.5273C16.8948 20.6111 17.0776 20.6391 17.2528 20.6086C17.6946 20.5324 17.9917 20.1135 17.9155 19.6717L16.8135 13.2504L21.4778 8.70292C21.6047 8.5785 21.6885 8.416 21.7139 8.23827C21.7825 7.79393 21.4727 7.38261 21.0284 7.31659Z"
                fill={color}
            />
        );
        case "half": return (
            <path
                d="M10.024.452A.816.816 0 0111.428.36l.053.092 2.882 5.842 6.447.937a.81.81 0 01.685.922.809.809 0 01-.165.386l-.07.079-4.665 4.547 1.102 6.421a.811.811 0 01-1.081.9l-.097-.044-5.767-3.032-5.766 3.032a.81.81 0 01-1.19-.743l.012-.113 1.102-6.42L.246 8.617A.814.814 0 01.59 7.253l.104-.022 6.447-.937L10.024.452zm3.675 6.756l-2.947-5.974v15.047l5.895 3.098-1.127-6.563 4.769-4.651-6.59-.957z"
                fill={color}
                fillRule="nonzero"
            />
        );
        case "empty": return (
            <path
                d="M10.024.452A.816.816 0 0111.428.36l.053.092 2.882 5.842 6.447.937a.81.81 0 01.685.922.809.809 0 01-.165.386l-.07.079-4.665 4.547 1.102 6.421a.811.811 0 01-1.081.9l-.097-.044-5.767-3.032-5.766 3.032a.81.81 0 01-1.19-.743l.012-.113 1.102-6.42L.246 8.617A.814.814 0 01.59 7.253l.104-.022 6.447-.937L10.024.452zm3.675 6.756l-2.947-5.974-2.946 5.974-6.591.958 4.77 4.65-1.127 6.562 5.894-3.097 5.895 3.098-1.127-6.563 4.769-4.651-6.59-.957z"
                fill={color}
                fillRule="nonzero"
            />
        );
        default: return null;
    }

}

export const getStars = (rating: number, color: string = 'var(--light-green)') => {
    const fullStars = Math.floor(rating);
    const arr = new Array(5).fill(0).map((pf, idx) => {
        const starNumber = idx + 1;

        if (fullStars >= starNumber) {
            return 'full';
        } else if (fullStars + 1 >= starNumber) {
            return rating % 1 > 0.5 ? 'full' : 'half';
        } else {
            return 'empty';
        }
    });

    return arr.map((type, i) => (
        <Star key={`${type}-${i}-${i}`} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            {getStar(type, color || '#000000')}
        </Star>
    ))
}