import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const Dentist = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.153 5.74516C20.9128 2.76678 18.511 0.317202 15.5804 0.0284593C14.5235 -0.0676442 13.4668 0.0765106 12.506 0.508771C11.3051 1.03716 9.96003 1.03716 8.75894 0.508771C7.79811 0.0765111 6.7414 -0.0676339 5.68447 0.0284593C2.65823 0.316569 0.256514 2.76658 0.0161519 5.74516C-0.0799517 7.09021 0.256411 8.43526 0.92891 9.5402C1.64948 10.693 1.98583 11.9902 1.93781 13.3833V14.3922C1.93781 19.7728 3.85927 24 6.26124 24C6.74155 24 7.27014 23.8078 7.70238 23.4716C8.13464 23.1353 8.37488 22.6069 8.27879 22.0305C8.23053 21.4056 8.18248 20.7812 8.18248 20.1567C8.18248 16.4577 9.43144 13.9119 10.5845 13.9119C11.7373 13.9119 12.9864 16.4578 12.9864 20.1567C12.9864 20.7812 12.9384 21.4056 12.8423 22.0301C12.7942 22.5584 12.9864 23.1349 13.4187 23.4712C13.899 23.8076 14.3795 23.9996 14.8598 23.9996C17.2618 23.9996 19.1832 19.7723 19.1832 14.3918V13.3829C19.1352 11.9898 19.4716 10.6928 20.1921 9.53979C20.9127 8.38713 21.2489 7.09016 21.1528 5.74511L21.153 5.74516ZM19.3757 9.01166C18.5591 10.3086 18.1749 11.8459 18.2229 13.3832V14.344C18.2229 19.4361 16.4454 22.9908 14.8603 22.9908C14.572 22.9908 14.2839 22.8947 13.9956 22.6545C13.8514 22.5103 13.7553 22.3181 13.8034 22.1261C13.8995 21.4536 13.9475 20.781 13.9475 20.1085C13.9475 15.9773 12.5064 12.9028 10.5849 12.9028C8.66349 12.9028 7.22235 15.9773 7.22235 20.1085C7.22235 20.781 7.2704 21.4536 7.3665 22.1261C7.41455 22.3183 7.31845 22.5584 7.17429 22.6545C6.83773 22.9428 6.54961 23.0389 6.26133 23.0389C4.67602 23.0389 2.89873 19.4841 2.89873 14.392V13.4312C2.99484 11.8459 2.56237 10.3567 1.74592 9.05971C1.12145 8.09888 0.881187 6.99409 0.97728 5.84108C1.16949 3.34301 3.18707 1.22932 5.68493 0.989269C6.59768 0.893165 7.46242 1.03732 8.27888 1.37348C9.72002 1.99795 11.3533 1.99795 12.7945 1.37348C13.6112 1.03711 14.4759 0.893165 15.3884 0.989269C17.8865 1.22953 19.9041 3.34317 20.0961 5.84108C20.2404 6.94607 20.0002 8.05086 19.3757 9.01169L19.3757 9.01166Z"
            fill={color} />
    </IconSvg>
);

export default Dentist;
