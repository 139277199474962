import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const CartIcon = ({ color = 'var(--dark-grey)' }: { color?: string }) => (
    <IconSvg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.6297 13.4508H6.67265L7.37343 12.0234L19.0172 12.0023C19.4109 12.0023 19.7484 11.7211 19.8187 11.332L21.4312 2.30625C21.4734 2.06953 21.4101 1.82578 21.2555 1.64062C21.179 1.5495 21.0836 1.4761 20.976 1.42551C20.8683 1.37492 20.751 1.34835 20.632 1.34766L5.81953 1.29844L5.69297 0.703125C5.61328 0.323437 5.27109 0.046875 4.88203 0.046875H1.26094C1.04151 0.046875 0.831074 0.134041 0.675917 0.289198C0.52076 0.444355 0.433594 0.654794 0.433594 0.874219C0.433594 1.09364 0.52076 1.30408 0.675917 1.45924C0.831074 1.6144 1.04151 1.70156 1.26094 1.70156H4.21172L4.76484 4.33125L6.12656 10.9242L4.37344 13.7859C4.28239 13.9088 4.22756 14.0547 4.21513 14.2071C4.2027 14.3596 4.23319 14.5124 4.30312 14.6484C4.44375 14.9273 4.72734 15.1031 5.0414 15.1031H6.51328C6.19949 15.5199 6.03001 16.0275 6.03047 16.5492C6.03047 17.8758 7.10859 18.9539 8.43515 18.9539C9.76171 18.9539 10.8398 17.8758 10.8398 16.5492C10.8398 16.0266 10.6664 15.518 10.357 15.1031H14.1328C13.819 15.5199 13.6495 16.0275 13.65 16.5492C13.65 17.8758 14.7281 18.9539 16.0547 18.9539C17.3812 18.9539 18.4594 17.8758 18.4594 16.5492C18.4594 16.0266 18.2859 15.518 17.9766 15.1031H20.632C21.0867 15.1031 21.4594 14.7328 21.4594 14.2758C21.458 14.0566 21.37 13.8468 21.2146 13.6923C21.0591 13.5377 20.8489 13.4509 20.6297 13.4508ZM6.16406 2.92969L19.6594 2.97422L18.3375 10.3758L7.73671 10.3945L6.16406 2.92969ZM8.43515 17.2898C8.02734 17.2898 7.69453 16.957 7.69453 16.5492C7.69453 16.1414 8.02734 15.8086 8.43515 15.8086C8.84296 15.8086 9.17578 16.1414 9.17578 16.5492C9.17578 16.7456 9.09775 16.934 8.95885 17.0729C8.81996 17.2118 8.63158 17.2898 8.43515 17.2898ZM16.0547 17.2898C15.6469 17.2898 15.3141 16.957 15.3141 16.5492C15.3141 16.1414 15.6469 15.8086 16.0547 15.8086C16.4625 15.8086 16.7953 16.1414 16.7953 16.5492C16.7953 16.7456 16.7173 16.934 16.5784 17.0729C16.4395 17.2118 16.2511 17.2898 16.0547 17.2898Z"
            fill={color} />
    </IconSvg>
);

export default CartIcon;
