import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";

import ExternalLinkIcon from "../../../icons/external-link-icon";
import { hasRichText, renderRich } from "@utils";


const Section = styled.section`
    margin: 46px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Heading = styled.div`
    padding: 0 0 46px 0;
`

const Links = styled.div`
    @media screen and (min-width: 768px) {
        max-width: 900px;
    }
`

const Link = styled.div`
    padding: 32px 0;
    border-top: 2px solid var(--bg-green);

    &:last-child {
        border-bottom: 2px solid var(--bg-green);
    }
`

const LinkTitleUrl = styled.a`
    display: inline-flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
`

const LinkTitle = styled.div`
    display: inline-flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
`

const LinkDescription = styled.div`
    padding: 16px 0 0 0;
`

const DistributorsList = (props: SliceProps<Queries.PrismicImHandelPageDataBodyDistributorsList>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { heading } = primary;
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    return (
        <Section id="distributors-list">
            <Wrapper>
                {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                <Links>
                    {items && items.map((item, index: number) => {
                        const [titleHasRichText, titleRichText] = hasRichText(item.link_title?.richText);
                        const [descriptionHasRichText, descriptionRichText] = hasRichText(item.link_description?.richText);
                        const linkUrl = item.link?.url;

                        return (
                            <Link className="link" key={`link-${index}`}>
                                {titleHasRichText && (
                                    linkUrl
                                        ? <LinkTitleUrl href={linkUrl}>{renderRich(titleRichText)}<ExternalLinkIcon color="var(--light-green)" /> </LinkTitleUrl>
                                        : <LinkTitle>{renderRich(titleRichText)} </LinkTitle>
                                )}
                                {descriptionHasRichText && <LinkDescription>{renderRich(descriptionRichText)}</LinkDescription>}
                            </Link>
                        )
                    })}
                </Links>
            </Wrapper>
        </Section>
    )
};

export default DistributorsList