import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ThumbsDown = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6 14.6383H21C21.5304 14.6383 22.0391 14.4276 22.4142 14.0525C22.7893 13.6774 23 13.1687 23 12.6383V10.5343C23.0003 10.2729 22.9493 10.014 22.85 9.77228L19.755 2.25728C19.6795 2.07406 19.5513 1.91741 19.3866 1.8072C19.2219 1.69699 19.0282 1.6382 18.83 1.63828H2C1.73478 1.63828 1.48043 1.74364 1.29289 1.93117C1.10536 2.11871 1 2.37306 1 2.63828V12.6383C1 12.9035 1.10536 13.1579 1.29289 13.3454C1.48043 13.5329 1.73478 13.6383 2 13.6383H5.482C5.6421 13.6382 5.79986 13.6766 5.94203 13.7502C6.0842 13.8239 6.20662 13.9305 6.299 14.0613L11.752 21.7883C11.8209 21.886 11.9226 21.9557 12.0385 21.9849C12.1545 22.0141 12.277 22.0007 12.384 21.9473L14.198 21.0403C14.7085 20.7851 15.1163 20.363 15.3537 19.844C15.5911 19.325 15.6438 18.7404 15.503 18.1873L14.6 14.6383ZM7 12.0503V3.63828H18.16L21 10.5343V12.6383H14.6C14.2954 12.6383 13.9948 12.7079 13.7212 12.8418C13.4476 12.9757 13.2082 13.1703 13.0213 13.4108C12.8343 13.6513 12.7048 13.9313 12.6425 14.2295C12.5803 14.5276 12.5869 14.8361 12.662 15.1313L13.565 18.6793C13.5933 18.79 13.5828 18.907 13.5353 19.0109C13.4878 19.1147 13.4062 19.1992 13.304 19.2503L12.643 19.5803L7.933 12.9083C7.683 12.5543 7.363 12.2643 7 12.0503ZM5 11.6383H3V3.63828H5V11.6383Z"
            fill={color} />
    </IconSvg>
);

export default ThumbsDown;