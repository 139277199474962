import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const CheckIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7L7 13L17 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconSvg>
);

export default CheckIcon;
