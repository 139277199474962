import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const LightbulbIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9994 5.23002C17.2488 5.23002 17.4559 5.02593 17.4559 4.76914V0.460878C17.4559 0.209065 17.2538 0 16.9994 0C16.7454 0 16.543 0.204094 16.543 0.460878V4.76914C16.543 5.02559 16.75 5.23002 16.9994 5.23002Z" fill={color} />
        <path d="M10.4585 6.66011C10.5431 6.80722 10.6938 6.88806 10.8536 6.88806C10.9287 6.88806 11.0088 6.86917 11.0794 6.82643C11.2956 6.69821 11.3711 6.4179 11.2441 6.19956L9.11303 2.47079C8.98604 2.25244 8.70841 2.17623 8.49216 2.30446C8.2759 2.43268 8.20042 2.71299 8.32743 2.93133L10.4585 6.66011Z" fill={color} />
        <path d="M6.59851 10.5686L2.9054 8.41696C2.68914 8.28874 2.41152 8.36461 2.28452 8.58328C2.15753 8.80162 2.23268 9.08193 2.44926 9.21015L6.14237 11.3618C6.21292 11.4045 6.293 11.4234 6.36814 11.4234C6.52337 11.4234 6.67858 11.3426 6.76324 11.1955C6.89024 10.9771 6.81509 10.6968 6.59851 10.5686V10.5686Z" fill={color} />
        <path d="M0.45647 17.6273H4.72353C4.97294 17.6273 5.18 17.4232 5.18 17.1664C5.18 16.9146 4.97786 16.7056 4.72353 16.7056H0.45647C0.207064 16.7056 0 16.9097 0 17.1664C0 17.4183 0.202142 17.6273 0.45647 17.6273Z" fill={color} />
        <path d="M6.1423 22.966L2.44919 25.1176C2.23293 25.2458 2.15745 25.5261 2.28446 25.7445C2.36912 25.8916 2.51975 25.9724 2.67956 25.9724C2.75471 25.9724 2.83478 25.9535 2.90533 25.9108L6.59844 23.7592C6.8147 23.6309 6.89018 23.3506 6.76318 23.1323C6.63618 22.9139 6.35856 22.8377 6.1423 22.966V22.966Z" fill={color} />
        <path d="M27.4054 23.7641L31.0986 25.9157C31.1691 25.9585 31.2492 25.9774 31.3243 25.9774C31.4796 25.9774 31.6348 25.8965 31.7194 25.7494C31.8464 25.5311 31.7713 25.2508 31.5547 25.1225L27.8616 22.9709C27.6453 22.8427 27.3628 22.9186 27.2407 23.1372C27.1091 23.3556 27.1843 23.6359 27.4054 23.7641V23.7641Z" fill={color} />
        <path d="M28.8203 17.1668C28.8203 17.4186 29.0225 17.6277 29.2768 17.6277H33.5438C33.7932 17.6277 34.0003 17.4236 34.0003 17.1668C34.0003 16.915 33.7982 16.7059 33.5438 16.7059H29.2768C29.0228 16.7059 28.8203 16.9104 28.8203 17.1668Z" fill={color} />
        <path d="M27.6327 11.4287C27.7078 11.4287 27.7879 11.4098 27.8585 11.3671L31.5516 9.21545C31.7678 9.08723 31.8433 8.80693 31.7163 8.58858C31.5893 8.37024 31.3117 8.29403 31.0954 8.42226L27.4023 10.5739C27.1861 10.7021 27.1106 10.9824 27.2376 11.2008C27.3222 11.3479 27.4775 11.4287 27.6327 11.4287V11.4287Z" fill={color} />
        <path d="M22.9062 6.83078C22.9768 6.87352 23.0568 6.8924 23.132 6.8924C23.2872 6.8924 23.4424 6.81156 23.5271 6.66445L25.6581 2.93567C25.7851 2.71733 25.71 2.43703 25.4934 2.3088C25.2772 2.18058 24.9995 2.25645 24.8725 2.47513L22.7415 6.2039C22.6145 6.42225 22.69 6.70255 22.9062 6.83077V6.83078Z" fill={color} />
        <path d="M11.443 27.4834V29.8773C11.443 29.9581 11.4666 30.0293 11.504 30.091L12.1249 31.9388C12.1295 31.9576 12.1344 31.9769 12.1436 31.9911L14.0676 37.6909C14.1286 37.8761 14.3029 38.0044 14.5004 38.0044H19.4967C19.6896 38.0044 19.8636 37.8808 19.9295 37.6909L21.209 33.9005C21.2136 33.8862 21.2185 33.872 21.2231 33.8578L21.8535 31.991C21.863 31.9721 21.8676 31.9579 21.8722 31.9387L22.4931 30.0909C22.5259 30.0243 22.5541 29.953 22.5541 29.8772V27.5022C22.5541 26.158 23.1093 24.8042 24.1112 23.6929C25.9928 21.6126 26.8776 18.7959 26.5435 15.9743C26.0214 11.5806 22.4789 8.02312 18.1225 7.52412C15.3843 7.21068 12.6464 8.08473 10.6047 9.92291C8.56284 11.7658 7.38672 14.4068 7.38672 17.1664C7.38672 19.5937 8.28066 21.9163 9.89875 23.7022C10.8921 24.8042 11.4427 26.1484 11.4427 27.4833L11.443 27.4834ZM19.1675 37.0876H14.8253L14.4912 36.0899H19.5061L19.1675 37.0876ZM19.812 35.1685H14.1762L13.8376 34.1663H20.1464L19.812 35.1685ZM20.4615 33.2498H13.5268L13.1927 32.2571H20.7952L20.4615 33.2498ZM21.106 31.3403H12.8823L12.5436 30.3381H21.4393L21.106 31.3403ZM11.203 10.6111C13.0755 8.92 15.4983 8.15068 18.0103 8.44025C21.9574 8.89152 25.1612 12.107 25.6315 16.0876C25.9328 18.6478 25.1327 21.1937 23.4299 23.0797C22.2725 24.3623 21.6375 25.9344 21.6375 27.5065V29.4206L12.3512 29.4209V27.4876C12.3512 25.9201 11.7162 24.3576 10.5634 23.0846C9.09548 21.4647 8.29117 19.3654 8.29117 17.1661C8.29117 14.6676 9.35439 12.2784 11.2033 10.6111L11.203 10.6111Z" fill={color} />
    </IconSvg>


);

export default LightbulbIcon;
