import React from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import Button from "../../../snippets/button";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import { hasRichText, renderRich } from "@utils";


const Section = styled.section`
    margin: 80px 0 32px;
	
	@media screen and (min-width: 768px) {
		padding: 120px 0 0;
        margin: 0;
	}
}
`

const Heading = styled.div`
    margin: 0 0 40px;
    max-width: 753px;

    h2 a {
        font-size: 56px;
        line-height: 72.8px;
        font-weight: 400;
    
        &:after { 
            content:"→"
        }
    }
`

const InnerWrapper = styled.div<{ numberOfItems: number }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 60px;    

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 40px;    
    }

    ${({ numberOfItems }) => numberOfItems < 3 && `
        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    `}

    ${({ numberOfItems }) => numberOfItems > 3 && `
        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
        }
    `}
`

const Product = styled.a`
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    margin-bottom: 48px;
    background:#fff;
    text-decoration: none;
    
    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        min-height: 524px;
        margin: 0;
        padding: 16px;

        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0%);
        transition: box-shadow 0.25s ease;

        &:hover {
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 10%);
        }
    }
`

const Image = styled(GatsbyImage)`
    margin: 0 0 24px 0;
    overflow: hidden;
    
    @media screen and (min-width: 768px) {
        // margin: 0 0 16px 0;
        height: auto;
        width: auto;
    }
`

const Content = styled.div`
`

const Title = styled.div`
    margin: 0 0 16px;

    h3 {
        font-size: 20px;
    }
`

const Description = styled.div`
    margin: 0 0 16px 0;

    @media screen and (min-width: 768px) {
        margin-bottom: 40px;
    }
`

const From = styled.p`
    margin: 0 0 0;
`

const Price = styled.div`
    display: inline-flex;
    margin: 0 0 16px;
`

const Discount = styled.div`
    display: inline-flex;
    font-size: 14px;
    padding: 0 0 0 16px;

    p {
        color: var(--light-green);
    }
`

const NoButton = styled.div`
    display: inline-block;
    width: fit-content;
    background: var(--light-green);
    padding: 18px 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 100px;
    border: none;
    text-align: center;
    text-decoration: none;
    color: white;

    &:hover {
        background: var(--black);
    }
`

const ProductGrid = (props: SliceProps<Queries.PrismicProductOverviewPageDataBodyProductGrid>) => {
    const { context, slice } = props;
    const { primary, items } = slice || {};
    const { heading, description, section_id } = primary;
    const { audience, page } = context;

    const numberOfItems = items.length;

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);

    return (
        <Section id={`${section_id}`}>
            <Wrapper>
                {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                <InnerWrapper numberOfItems={numberOfItems}>
                    {items.map((
                        {
                            product_title,
                            product_image,
                            description,
                            price,
                            discount,
                            link_text,
                            link,
                            bundle_preselect_index,
                        },
                        index
                    ) => {

                        const [productTitleHasRichText, productTitleRichText] = hasRichText(product_title?.richText);
                        const [descriptionHasRichText, descriptionRichText] = hasRichText(description?.richText);
                        const [priceHasRichText, priceRichText] = hasRichText(price?.richText);
                        const [discountHasRichText, discountRichText] = hasRichText(discount?.richText);

                        return (
                            <Product key={`${index}-product`} 
                                href={
                                    link?.url
                                        ? `${link.url}?position=${index}&section_id=${section_id}&section_name=${page}${bundle_preselect_index ? `&preselect=${bundle_preselect_index}` : ''}`
                                        : ''
                            }>
                                {product_image?.gatsbyImageData && (
                                    <Image
                                        image={(product_image.gatsbyImageData as unknown) as IGatsbyImageData}
                                        alt={product_image.alt || ''}
                                    />
                                )}
                                <Content>
                                    {productTitleHasRichText && <Title>{renderRich(productTitleRichText)}</Title>}
                                    {descriptionHasRichText && <Description>{renderRich(descriptionRichText)}</Description>}
                                    {priceHasRichText && <Price>{renderRich(priceRichText)}</Price>}
                                    {discountHasRichText && <Discount>{renderRich(discountRichText)}</Discount>}
                                </Content>
                                {link?.url && link_text &&
                                    // <ButtonContainer>
                                        <NoButton>
                                            {link_text}
                                        </NoButton>
                                    // </ButtonContainer>
                                }
                            </Product>
                        )
                    })}
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default ProductGrid