import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { hasRichText, renderRich } from "@utils";

const Wrapper = styled.section`
        max-width: 1440px;
        padding: 120px 16px;
        margin: 0 auto;

        @media screen and (min-width: 768px) {
            padding: 40px 80px;
        }
`

const TextWrapper = styled.div<{ fullWidth: boolean }>`
    ${({ fullWidth }) => `
        ${!fullWidth ? `
            max-width: 100%;
            margin: 0 auto;
            
            @media screen and (min-width: 768px) {
                max-width: 60%;
                margin: 0;
            }
        ` : ''
        }
    `}
`

const Text = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;

    h2+p {
        margin: 24px 0 16px 0;
        font-size: 24px;
    }
`

const TextSection = (props: SliceProps<Queries.PrismicProductPageDataBodyPlainText>) => {
    const { slice } = props;
    const { primary } = slice;
    const { text, full_width } = primary;

    const [textHasRichText, textRichText] = hasRichText(text?.richText);

    return (
        <>
            {textHasRichText && (
                <Wrapper>
                    <TextWrapper fullWidth={full_width as boolean}>
                        <Text className="rt">{renderRich(textRichText)}</Text>
                    </TextWrapper>
                </Wrapper>
            )}
        </>
    )
};

export default TextSection