export const convertCurrency = (currency: string = 'eur'): [string, string] => {
    switch (currency) {
        case 'eur':
            return ['de-AT', 'EUR'];
        case 'gbp':
            return ['en-GB', 'GBP'];
        default:
            return ['de-AT', 'EUR'];
    }
}
