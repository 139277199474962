export const convertPrice = (
    amount: number,
    locale: string,
    currency: string,
): string => {
    return (amount / 100).toLocaleString(
        locale,
        {
            style: 'currency',
            currency: currency,
        }
    )
}