export const externalLinkIconInline = (color: any): string => {
    const encodedColor: string = color.replace('#', '%23');
    return (`"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 3L21 3V10.9296' 
        stroke='${
            encodedColor
        }'
        strokeWidth='2' strokeLinecap='round'/%3E%3Cpath d='M20.364 3.63604L13 11' 
        stroke='${
            encodedColor
        }' 
        strokeWidth='2' strokeLinecap='round'/%3E%3Cpath d='M8 5H5.46154C4.10207 5 3 6.10207 3 7.46154V18.5385C3 19.8979 4.10207 21 5.46154 21H16.5385C17.8979 21 19 19.8979 19 18.5385V16' 
        stroke='${
            encodedColor
        }'
        strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A"
    `)
};