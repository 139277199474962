import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const PlayIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.667969 16.5117C0.667969 17.3008 1.53912 17.779 2.20484 17.3554L14.0089 9.84374C14.6264 9.45079 14.6264 8.54937 14.0089 8.15642L2.20485 0.644761C1.53912 0.221117 0.667969 0.699331 0.667969 1.48842V16.5117Z"
            fill={color}
        />
    </IconSvg>
);

export default PlayIcon;