import { Accordion, CtaTeaserImage, IconGrid, NewsletterPopUp } from '../../components/slices/generic';
import ReferralTimeline from '../../components/slices/referrals-page/referral-timeline/referral-timeline';
import ReferralHero from '../../components/slices/referrals-page/refferal-hero/refferal-hero';

export const components = {
    accordion: Accordion,
    cta_teaser_image: CtaTeaserImage,
    icon_grid: IconGrid,
    referral_timeline: ReferralTimeline,
    referral_hero: ReferralHero,
    'email_pop-up': NewsletterPopUp,
}
