import { convertPrice } from "@utils";
import { hasFreeTrial } from "../../../../utils/cart/line-items";
import { Cart, LineItem } from "src/utils/playbrush-client/types";

const hasCoupon = (coupon: string) => {
    return !!coupon && typeof coupon === 'string' && coupon !== '';
}

const hasFreeMonthInCart = (cart: Cart) => {
    return cart.lineItems.find((lineItem) => {
        return !!hasFreeTrial(lineItem);
    })
}

const hasBrushHeadsSubscription = (cart: Cart) => {
    return cart.lineItems.reduce<boolean>((bool, lineItem) => {
        const isSubscription = lineItem.isSubscription;
        const hasBrushHeads = lineItem.tags.includes('brush_heads');

        return (isSubscription && hasBrushHeads) || bool;
    }, false);
}

const getRenewalSentence = (
    data: Queries.PrismicCartPageDataType,
    cart: Cart,
) => {
    return (data?.subscription_renewal || '')
        // @ts-ignore
        .replace(/{\s{0,}price\s{0,}}/, convertPrice((cart?.amountTotal || 0), cart.locale, cart.currency))
        // @ts-ignore
        .replace(/{\s{0,}renewal\s{0,}}/, convertPrice((cart?.amountTotalAfter || 0), cart.locale, cart.currency));
}

export const getPricingInfo = (
    data: Queries.PrismicCartPageDataType,
    cart: Cart,
) => {
    const coupon = cart.couponCode;

    return ({
        coupon,
        hasCoupon: hasCoupon(coupon),
        freeMonthExists: hasFreeMonthInCart(cart),
        hasSubscriptionBrushHeads: hasBrushHeadsSubscription(cart),
        renewalSentence: getRenewalSentence(data, cart),
    })
}