import { toQue } from "./to-que"

export const logPageView = (data: {
    content_group: string
    email?: string
    phone?: string
    account_id?: string
}) => {
    const pageData: any = {};

    if (data.content_group) pageData.content_group = data.content_group;
    if (data.email) pageData.email = data.email;
    if (data.phone) pageData.phone = data.phone;
    if (data.account_id) pageData.account_id = data.account_id;

    toQue({
        event: 'page_data',
        page_data: pageData,
    });
}