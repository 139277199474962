import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { RichText } from 'prismic-reactjs';
import { useAppSelector } from '../../../../app/hooks';

const Units = styled.div`
	display: flex;
	flex-wrap: nowrap;
	gap: 40px;
`

const Unit = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	font-size: 20px;
	font-weight: 400;

	&:last-child {
		display: none;
	}

	@media screen and (min-width: 768px) {
		font-size: 32px;

		&:last-child {
			display: flex;
		}
	}
`

const UnitValue = styled.span`
`

const UnitDescription = styled.span`
	color: white;
	font-size: 16px;
	font-weight: normal;
	margin-top: 0px;

	@media screen and (min-width: 768px) {
		margin-top: 8px;
	}
`

const EndText = styled.span`
	font-size: 24px;
	line-height: 32px;
	text-align: center;
`

interface TimeLeft {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface CountdownProps {
	targetDate: any;
	endText: any;
}

const Counter: React.FC<CountdownProps> = ({ targetDate, endText }) => {
	const lang = useAppSelector((state) => state.general.lang);

	const calculateTimeLeft = (): TimeLeft => {
		const difference = +new Date(targetDate) - +new Date();
		
		let timeLeft: TimeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearTimeout(timer);
	}, [timeLeft]);

	const timerComponents: JSX.Element[] = [];

	Object.entries(timeLeft).forEach(([interval, value]) => {
		if (interval === 'days' && value === 0) return;

		let intervalText = '';

		if (interval === 'seconds') {
			intervalText = lang === 'en-gb' ? 'seconds' : 'sekunden';
		} else if (interval === 'minutes') {
			intervalText = lang === 'en-gb' ? 'minutes' : 'minuten';
		} else if (interval === 'hours') {
			intervalText = lang === 'en-gb' ? 'hours' : 'stunden';
		} else if (interval === 'days') {
			intervalText = lang === 'en-gb' ? 'days' : 'tage';
		}

		timerComponents.push(
			<Unit key={interval}>
				<UnitValue>{String(value).padStart(2, '0')}</UnitValue>
				<UnitDescription>{intervalText}</UnitDescription>{" "}
			</Unit>
		);
	});

	const isTimeUp = Object.values(timeLeft).every(value => value === 0);

	return (
		<Units>
			{!isTimeUp ? timerComponents : <EndText>{RichText.render(endText?.richText)}</EndText>}
		</Units>
	);
};

export default Counter;
