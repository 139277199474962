import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import Button from "../../../snippets/button";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { SliceProps } from "../../types";
import { useAppSelector } from "../../../../app/hooks";

const BREAKPOINT_LARGE = '1140px';

const Section = styled.section<{ $notificationBannerOpen: boolean }>`
	margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    background-color: var(--bg-light-grey);
    padding: ${({ $notificationBannerOpen }) => $notificationBannerOpen ? '40px' : '0'} 0 54px;
	
	@media screen and (min-width: ${BREAKPOINT_LARGE}) {
		padding: 0;
	}
`

const InnerWrapper = styled.div`
	display: grid;
    grid-template-rows: auto auto;

    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
		grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
	}
`

const Image = styled(GatsbyImage)`
    height: calc(100vw - 32px);

    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
        height: unset;
        min-height: 632px;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
`

const Content = styled.div`
    position: relative;
    padding: 12px 0 48px;
    margin: 0;
    width: 100%;
    max-width: 500px;

    * {
        color: var(--dark-grey);
        text-align: center;
        text-shadow: 0px 0px 24px rgba(4, 39, 81, 0.2);
    }

    span a { 
        color: var(--dark-grey);
    }

    a {
        color: white;
    }

    h1 {
        margin-bottom: 24px;

        @media screen and (max-width: ${BREAKPOINT_LARGE}) {
            font-size: 33.18px;
            line-height: 36px;
            margin-bottom: 20px;
        }
    }
    
    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
        padding: 0 0 40px;
        width: 80%;
    }
`

const Preline = styled.h5`
    margin: 0 0 16px;

    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
        margin: 0 0 24px;
    }
`

const ButtonContainer = styled.div`
    z-index: 2;
    margin: 32px auto 0;
    
    display: flex;
    justify-content: center;

    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
        margin-bottom: 0;
        display: block;
    }    
`

const Hero = (props: SliceProps<Queries.PrismicHomepageDataBodyHero>) => {
    const notificationBannerOpen = useAppSelector(state => state.general.notificationBannerOpen);
    const { slice, context: { audience } } = props;
    const {
        primary: {
            body_text,
            cta_text,
            cta_link,
            rolling_text_preline,
            preline,
            background_image,
            background_image_mobile,
        }
    } = slice;

    const backgroundImage = background_image?.gatsbyImageData as unknown as IGatsbyImageData;
    const mobileBackgroundImage = background_image_mobile?.gatsbyImageData as unknown as IGatsbyImageData;
    const backgroundUrl = backgroundImage?.images?.fallback?.src || '';
    const mobileBackgroundUrl = mobileBackgroundImage?.images?.fallback?.src || '';

    return (
        <Section $notificationBannerOpen={notificationBannerOpen}>
            <InnerWrapper>
                {backgroundImage && (
                    <Image image={backgroundImage} alt="" />
                )}
                <ContentWrapper>
                    <Content>
                        {preline && (
                            <Preline>{preline}</Preline>
                        )}
                        {rolling_text_preline?.richText && (
                            RichText.render(rolling_text_preline.richText)
                        )}
                        {body_text?.richText && (
                            RichText.render(body_text.richText)
                        )}
                        {cta_text && cta_link?.url && (
                            <ButtonContainer>
                                <Button url={cta_link.url}>
                                    {cta_text}
                                </Button>
                            </ButtonContainer>
                        )}
                    </Content>
                </ContentWrapper>
            </InnerWrapper>
        </Section>
    )
}

export default Hero