import { TextImageRightFull, NewsSection } from "../../components/slices/press-and-media-page"

import { MediaSlider } from "../../components/slices/home"
import { BenefitsGrid, NewsletterPopUp, DownloadSection } from "../../components/slices/generic"

export const components = {
    download_section: DownloadSection,
    news_section: NewsSection,
    press_slider: MediaSlider,
    text___image_right__full_width_: TextImageRightFull,
    benefits_grid: BenefitsGrid,
    'email_pop-up': NewsletterPopUp,
}
