import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const BrushIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="15" height="29" viewBox="0 0 15 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1226 1.8717C11.1941 1.60497 11.4682 1.44668 11.735 1.51815L12.7348 1.78605C12.9948 1.85572 13.153 2.11875 13.0926 2.3811L7.26026 27.7355C7.19679 28.0114 6.91707 28.1797 6.64358 28.1064L4.74684 27.5981C4.48011 27.5267 4.32182 27.2525 4.39329 26.9858L11.1226 1.8717Z" stroke={color} />
        <rect x="11.0415" y="3.14382" width="0.5" height="2.5" rx="0.25" transform="rotate(105 11.0415 3.14382)" stroke={color} strokeWidth="0.5" />
        <rect x="10.5219" y="5.0757" width="0.5" height="2.5" rx="0.25" transform="rotate(105 10.5219 5.0757)" stroke={color} strokeWidth="0.5" />
        <rect x="10.0063" y="7.00759" width="0.5" height="2.5" rx="0.25" transform="rotate(105 10.0063 7.00759)" stroke={color} strokeWidth="0.5" />
        <rect x="10.7798" y="4.10988" width="0.5" height="3.5" rx="0.25" transform="rotate(105 10.7798 4.10988)" stroke={color} strokeWidth="0.5" />
        <rect x="10.2641" y="6.04177" width="0.5" height="3.5" rx="0.25" transform="rotate(105 10.2641 6.04177)" stroke={color} strokeWidth="0.5" />
        <rect x="9.74459" y="7.97365" width="0.5" height="3.5" rx="0.25" transform="rotate(105 9.74459 7.97365)" stroke={color} strokeWidth="0.5" />
        <rect x="11.2993" y="2.178" width="0.5" height="3.5" rx="0.25" transform="rotate(105 11.2993 2.178)" stroke={color} strokeWidth="0.5" />
    </IconSvg>
);

export default BrushIcon;