import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const TrophyIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0.666667C4 0.489856 4.07024 0.320287 4.19526 0.195262C4.32029 0.070238 4.48986 0 4.66667 0H19.3333C19.5101 0 19.6797 0.070238 19.8047 0.195262C19.9298 0.320287 20 0.489856 20 0.666667V1.33333H23.3333C23.5101 1.33333 23.6797 1.40357 23.8047 1.5286C23.9298 1.65362 24 1.82319 24 2V6C24 6.88406 23.6488 7.7319 23.0237 8.35702C22.3986 8.98214 21.5507 9.33333 20.6667 9.33333H19.5447C18.5173 12.24 15.858 14.376 12.6667 14.6393V18.6667H17.3333C17.5101 18.6667 17.6797 18.7369 17.8047 18.8619C17.9298 18.987 18 19.1565 18 19.3333V23.3333C18 23.5101 17.9298 23.6797 17.8047 23.8047C17.6797 23.9298 17.5101 24 17.3333 24H6.66667C6.48986 24 6.32029 23.9298 6.19526 23.8047C6.07024 23.6797 6 23.5101 6 23.3333V19.3333C6 19.1565 6.07024 18.987 6.19526 18.8619C6.32029 18.7369 6.48986 18.6667 6.66667 18.6667H11.3333V14.6393C8.14267 14.376 5.48267 12.24 4.45533 9.33333H3.33333C2.44928 9.33333 1.60143 8.98214 0.976311 8.35702C0.351189 7.7319 0 6.88406 0 6V2C0 1.82319 0.070238 1.65362 0.195262 1.5286C0.320287 1.40357 0.489856 1.33333 0.666667 1.33333H4V0.666667ZM18.6667 6.66667V1.33333H5.33333V6.66667C5.33333 10.3487 8.318 13.3333 12 13.3333C15.682 13.3333 18.6667 10.3487 18.6667 6.66667ZM20 2.66667V8H20.6667C21.1971 8 21.7058 7.78929 22.0809 7.41421C22.456 7.03914 22.6667 6.53043 22.6667 6V2.66667H20ZM1.33333 2.66667H4V8H3.33333C2.8029 8 2.29419 7.78929 1.91912 7.41421C1.54405 7.03914 1.33333 6.53043 1.33333 6V2.66667ZM7.33333 20V22.6667H16.6667V20H7.33333Z"
            fill={color}
        />
    </IconSvg>
);

export default TrophyIcon;