import React, { useState } from "react"
import styled from "styled-components";
import { LineItem as LineItemInterface, PriceLineItem } from "../../../../../../utils/playbrush-client/types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import Client from "../../../../../../utils/playbrush-client/client";
import { OldProduct } from "../../cart-list";
import { setCart } from "../../../../../../app/slices/cart-reducer";
import { getTrackingItems } from "../../../../../../utils/product";
import { getThankYouPage, langToCountry, langToLanguage, logEcommerceEvent } from "@utils";
import { PageContext } from "../../../../../../components/slices/types";

// Quantity control
const QuantityControl = styled.div`
    display: flex;

    & + button {
        margin-left: 24px;
    }
`

const QuantityControlButton = styled.button`
    width: 40px;
    height: 40px;
    padding: 0;
    background: white;
    border: 1px solid #CDD4DC;
    border-radius: 12px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease;

    &:hover {
        background: #CDD4DC;
        cursor: pointer;
    }

    &:disabled {
        background: #CDD4DC;
        opacity: .4;
    }
`

const Quantity = styled.input`
    width: 40px;
    height: 40px;
    padding: 0;
    background: white;
    border: 0px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
`

interface LineItemProps {
    lineItem: LineItemInterface,
    settings: PageContext['settings'];
    onRemove?: (v: OldProduct) => void,
}

const LineItemQuantityControl = ({ lineItem, settings, onRemove }: LineItemProps) => {
    const [cart, lang, audience] = useAppSelector(state => [state.cart, state.general.lang, state.general.audience]);
    const [loading, setLoading] = useState(false);

    const { productNameMarketing } = lineItem;
    const dispatch = useAppDispatch();

    const add = async () => {
        setLoading(true);

        try {
            setLoading(true);
            const lineId = lineItem.lineId;
            const payload: PriceLineItem[] = [];

            cart.lineItems.forEach((item) => {
                if (item.lineId === lineId) {
                    const quantity = item.quantity + 1
                    quantity && payload.push({
                        lineId,
                        quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData),
                    });
                } else {
                    payload.push({
                        lineId: item.lineId,
                        quantity: item.quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData)
                    });
                }
            });

            const frontendData = {
                ...(cart.frontendData as unknown) as Record<string, unknown>,
                thankYouPage: ((cart.frontendData as unknown) as { thankYouPage: string })?.thankYouPage || '',
            };

            const newCart = await Client.addProduct({
                itemList: payload,
                cartId: cart.cartId,
                couponCode: cart.couponCode,
                frontendData: JSON.stringify(frontendData),
                currency: cart.currency,
                language: langToLanguage(lang),
                country: langToCountry(lang),
            });

            if (newCart) {
                dispatch(setCart(newCart));
                setLoading(false);

                const trackingItems = getTrackingItems(newCart);
                logEcommerceEvent(trackingItems, 'add_to_cart');
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const subtract = async () => {
        setLoading(true);

        try {
            const lineId = lineItem.lineId;
            const payload: PriceLineItem[] = [];

            cart.lineItems.forEach((item) => {
                if (item.lineId === lineId) {
                    const quantity = item.quantity - 1
                    quantity && payload.push({
                        lineId,
                        quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData),
                    });
                } else {
                    payload.push({
                        lineId: item.lineId,
                        quantity: item.quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData)
                    });
                }
            });

            const frontendData = {
                ...(cart.frontendData as unknown) as Record<string, unknown>,
                thankYouPage: ((cart.frontendData as unknown) as { thankYouPage: string })?.thankYouPage || '',
            };

            const newCart = await Client.addProduct({
                itemList: payload,
                cartId: cart.cartId,
                couponCode: cart.couponCode,
                frontendData: JSON.stringify(frontendData),
                currency: cart.currency,
                language: langToLanguage(lang),
                country: langToCountry(lang),
            });

            if (newCart) {
                dispatch(setCart(newCart));
                setLoading(false);

                const trackingItems = getTrackingItems(newCart);
                logEcommerceEvent(trackingItems, 'remove_from_cart');
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const remove = async () => {
        setLoading(true);

        try {
            const lineId = lineItem.lineId;
            const payload: PriceLineItem[] = [];

            cart.lineItems.forEach((item) => {
                if (item.lineId !== lineId) {
                    payload.push({
                        lineId: item.lineId,
                        quantity: item.quantity,
                        frontendLineData: JSON.stringify(item.frontendLineData)
                    });
                }
            });
            
            const frontendData = {
                ...(cart.frontendData as unknown) as Record<string, unknown>,
                thankYouPage: getThankYouPage(
                    cart.lineItems.filter((lineItem) => lineItem.lineId !== lineId),
                    settings,
                ),
            };

            const newCart = await Client.addProduct({
                itemList: payload,
                cartId: cart.cartId,
                couponCode: cart.couponCode,
                frontendData: JSON.stringify(frontendData),
                currency: cart.currency,
                language: langToLanguage(lang),
                country: langToCountry(lang),
            });

            if (newCart) {
                onRemove && onRemove({ title: productNameMarketing, lineId, audience: lineItem.tags.includes('adults') ? 'adults' : 'kids' });
                setLoading(false);
                dispatch(setCart(newCart));

                const trackingItems = getTrackingItems(newCart);
                logEcommerceEvent(trackingItems, 'remove_from_cart');
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    return (
        <QuantityControl>
            <QuantityControlButton type="button" disabled={loading} onClick={lineItem.quantity === 1 ? remove : subtract}>-</QuantityControlButton>
            <Quantity type="number" value={lineItem.quantity} disabled />
            <QuantityControlButton type="button" disabled={loading} onClick={add}>+</QuantityControlButton>
        </QuantityControl>
    )
};

export default LineItemQuantityControl;