import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const BoxIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M29.4751 32.9999H0.52489C0.235427 32.9999 0 32.767 0 32.4808V11.4207C0 11.1345 0.235419 10.9016 0.52489 10.9016H29.4751C29.7646 10.9016 30 11.1344 30 11.4207V32.4808C30 32.767 29.7649 32.9999 29.4751 32.9999ZM1.04965 31.9617H28.9503V11.9396H1.04965V31.9617Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.527339 11.9399C0.491001 11.9399 0.454973 11.9399 0.418634 11.931C0.13818 11.8684 -0.042895 11.591 0.0204661 11.3133L2.44583 0.402698C2.50018 0.169868 2.70827 0 2.9527 0H27.0528C27.2973 0 27.5054 0.170174 27.5597 0.402698L29.9851 11.3133C30.0484 11.5907 29.8674 11.8684 29.5869 11.931C29.3064 11.9937 29.0257 11.8146 28.9623 11.5372L26.6367 1.02924H3.37853L1.04357 11.5365C0.98922 11.7782 0.772118 11.9395 0.52769 11.9395L0.527339 11.9399Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.7835 20.5772C19.6748 20.5772 19.5754 20.5504 19.4847 20.4878L14.9958 17.3909L10.5069 20.4878C10.3442 20.5953 10.1358 20.6131 9.96405 20.5237C9.79198 20.4343 9.68359 20.2552 9.68359 20.0673L9.6839 11.4213C9.6839 11.1351 9.91932 10.9022 10.2088 10.9022C10.4983 10.9022 10.7337 11.1351 10.7337 11.4213V19.0738L14.6976 16.3351C14.8787 16.2098 15.1138 16.2098 15.2949 16.3351L19.2588 19.0738L19.2585 11.4213C19.2585 11.1351 19.4939 10.9022 19.7834 10.9022C20.0729 10.9022 20.3083 11.1351 20.3083 11.4213V20.0586C20.3083 20.2466 20.1996 20.4257 20.0278 20.5151C19.9465 20.5599 19.8651 20.5777 19.7834 20.5777L19.7835 20.5772Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2192 11.6804H10.1649C9.8754 11.6537 9.667 11.3942 9.70333 11.1165L10.7894 0.859326C10.8165 0.573048 11.0789 0.366938 11.3597 0.402874C11.6491 0.429597 11.8575 0.689153 11.8212 0.966837L10.7351 11.224C10.7081 11.4835 10.4817 11.6804 10.2192 11.6804Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.7801 11.6805C19.5177 11.6805 19.2916 11.4836 19.2642 11.2152L18.1781 0.958022C18.1511 0.671743 18.3592 0.421093 18.6397 0.394059C18.9291 0.367335 19.1826 0.573134 19.2099 0.850512L20.296 11.1077C20.323 11.3939 20.1149 11.6446 19.8345 11.6716C19.8165 11.6805 19.7985 11.6805 19.7801 11.6805V11.6805Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.7821 25.473H10.2163C9.92683 25.473 9.69141 25.2402 9.69141 24.9539C9.69141 24.6676 9.92683 24.4348 10.2163 24.4348H19.7821C20.0716 24.4348 20.307 24.6676 20.307 24.9539C20.307 25.2402 20.0719 25.473 19.7821 25.473Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.9754 28.0601H7.02098C6.73152 28.0601 6.49609 27.8273 6.49609 27.541C6.49609 27.2547 6.73151 27.0219 7.02098 27.0219H22.9762C23.2656 27.0219 23.5011 27.2547 23.5011 27.541C23.5007 27.8362 23.2656 28.0601 22.9759 28.0601H22.9754Z" fill={color} />
    </IconSvg>


);

export default BoxIcon;
