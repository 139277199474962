const timeZoneCityToCountryJson = require('./time-zone-to-country.json');

export const userTimeZoneToCountryCode = () => {
    let userRegion;
    let userCity;
    let userCountry = "AT";
    let userTimeZone;

    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let tzArr = userTimeZone.split("/");
        userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountryJson[userCity];
    }

    return userCountry;
};
