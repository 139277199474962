import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { RichText } from 'prismic-reactjs';
import Counter from "./counter";

const Section = styled.section`
    background: var(--light-green);
    padding: 24px 0;
    margin: 80px auto 0;

    * {
        color: white;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media screen and (min-width: 768px) {
        gap: 32px;
    }
`


const Heading = styled.div`
    text-align: center;
    
    h3 {
        font-size: 2.25rem;
    }

    p {
        font-size: 1.5rem;
        margin: 0 0 8px;
    }
`

const Countdown = ({ slice }: SliceProps<Queries.PrismicHomepageDataBodyCountdown>) => {
    
    return (
        <Section>
            <Wrapper>
                <InnerWrapper>
                    {slice?.primary?.heading?.richText && (
                        <Heading>
                            {RichText.render(slice.primary.heading.richText)}
                        </Heading>
                    )}
                    {slice?.primary?.end_date && slice?.primary?.end_text &&
                        <Counter 
                            targetDate={slice.primary.end_date}
                            endText={slice.primary.end_text}
                        />
                    }
                </InnerWrapper>
            </Wrapper>
        </Section>
    )
}

export default Countdown
