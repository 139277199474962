import React, { useState } from "react"
import styled from "styled-components";
import { dropdownIconInline } from "../../icons/drowndown-icon-inline";
import { concatMenuUrl } from "./mega-menu/utils";

const NavBarList = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: start;
    padding-inline-start: 0;
    font-weight: 700;
    font-size: 14px;
    margin: 24px 0 0 0;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
        margin: 0;
    }
`

const PullDown = styled.div`
    overflow: hidden;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    
    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
    }
    
`

const MenuLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    font-size: 24px;

    &:hover {
        color: var(--light-green);
    }

    &.dropdown:after {
        content: url(${dropdownIconInline('#2BB573')});
        padding: 0 0 0 8px;
    }


    @media screen and (min-width: 768px) {
		width: auto;
        font-size: 14px;
    }
`

const Navigation = ({
    menuData,
    setMenu,
}: {
    menuData: Queries.PrismicHeaderDataMenuItems[],
    setMenu: (menu?: Queries.PrismicHeaderMenuItem['data']) => void;
}) => {

    const handleActiveMenu = (menu?: Queries.PrismicHeaderMenuItem['data']): void => {
        setMenu(menu);
    }

    return (
        <NavBarList>
            <PullDown>
                {menuData?.map(({ link_text, link, mega_menu_items, link_to_section }, index) => {
                    const doc = mega_menu_items?.document;
                    const id = doc?.id;
                    const url = concatMenuUrl(link?.url, link_to_section);

                    return (
                        <React.Fragment key={`${index}-${id}`}>
                            {doc ? (
                                <MenuLink className="dropdown" onClick={() => handleActiveMenu(doc?.data as Queries.PrismicHeaderMenuItem['data'])}>
                                    {link_text}
                                </MenuLink>
                            ) : (
                                <MenuLink href={url || '#'} target={link?.target || "_self"}>
                                    {link_text}
                                </MenuLink>
                            )}
                        </React.Fragment>
                    )
                })}
            </PullDown>
        </NavBarList>
    )
};

export default Navigation