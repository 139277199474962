import { FrontendLineData } from "../playbrush-client/types";
import { getEcommerceEvent } from "./get-ecommerce-event";
import { toQue } from "./to-que";
import { LogConfig, GEvent } from "./types";

export const logEcommerceEvent = (
    li: FrontendLineData['trackingContext'][],
    eventName: GEvent,
    config?: LogConfig,
) => {
    const event = getEcommerceEvent(eventName, li, config);

    toQue(event);
}