import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const SwissIcon = ({ color = 'var(--light-green)' }: { color?: string }) => (
    <IconSvg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask 
            id="path-1-inside-1_2823_77070" 
            fill="white"
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M12.4219 0.5C11.3173 0.5 10.4219 1.39543 10.4219 2.5V10.9211H2C0.895431 10.9211 0 11.8165 0 12.9211V24.079C0 25.1835 0.895431 26.079 2 26.079H10.4219V34.5C10.4219 35.6046 11.3173 36.5 12.4219 36.5H23.5798C24.6843 36.5 25.5798 35.6046 25.5798 34.5V26.079H34C35.1046 26.079 36 25.1835 36 24.079V12.9211C36 11.8165 35.1046 10.9211 34 10.9211H25.5798V2.5C25.5798 1.39543 24.6843 0.5 23.5798 0.5H12.4219Z"
                />
        </mask>
        <path 
            d="M10.4219 10.9211V11.9211H11.4219V10.9211H10.4219ZM10.4219 26.079H11.4219V25.079H10.4219V26.079ZM25.5798 26.079V25.079H24.5798V26.079H25.5798ZM25.5798 10.9211H24.5798V11.9211H25.5798V10.9211ZM11.4219 2.5C11.4219 1.94772 11.8696 1.5 12.4219 1.5V-0.5C10.765 -0.5 9.42188 0.843147 9.42188 2.5H11.4219ZM11.4219 10.9211V2.5H9.42188V10.9211H11.4219ZM2 11.9211H10.4219V9.92106H2V11.9211ZM1 12.9211C1 12.3688 1.44771 11.9211 2 11.9211V9.92106C0.343146 9.92106 -1 11.2642 -1 12.9211H1ZM1 24.079V12.9211H-1V24.079H1ZM2 25.079C1.44772 25.079 1 24.6312 1 24.079H-1C-1 25.7358 0.343148 27.079 2 27.079V25.079ZM10.4219 25.079H2V27.079H10.4219V25.079ZM11.4219 34.5V26.079H9.42188V34.5H11.4219ZM12.4219 35.5C11.8696 35.5 11.4219 35.0523 11.4219 34.5H9.42188C9.42188 36.1569 10.765 37.5 12.4219 37.5V35.5ZM23.5798 35.5H12.4219V37.5H23.5798V35.5ZM24.5798 34.5C24.5798 35.0523 24.1321 35.5 23.5798 35.5V37.5C25.2366 37.5 26.5798 36.1569 26.5798 34.5H24.5798ZM24.5798 26.079V34.5H26.5798V26.079H24.5798ZM34 25.079H25.5798V27.079H34V25.079ZM35 24.079C35 24.6312 34.5523 25.079 34 25.079V27.079C35.6569 27.079 37 25.7358 37 24.079H35ZM35 12.9211V24.079H37V12.9211H35ZM34 11.9211C34.5523 11.9211 35 12.3688 35 12.9211H37C37 11.2642 35.6569 9.92106 34 9.92106V11.9211ZM25.5798 11.9211H34V9.92106H25.5798V11.9211ZM24.5798 2.5V10.9211H26.5798V2.5H24.5798ZM23.5798 1.5C24.1321 1.5 24.5798 1.94772 24.5798 2.5H26.5798C26.5798 0.843146 25.2366 -0.5 23.5798 -0.5V1.5ZM12.4219 1.5H23.5798V-0.5H12.4219V1.5Z" 
            fill={color}
            mask="url(#path-1-inside-1_2823_77070)"
        />
    </IconSvg>
);

export default SwissIcon;

