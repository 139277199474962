import { SanitizedBundle } from "../../../../../utils";

export const getBundleCollection = (
    bundles: SanitizedBundle[][],
    upgradeContext: {
        _id: string,
        id: string,
        position: number,
    },
) => {
    return bundles.reduce<SanitizedBundle[][]>((testArray, bundleArray) => {
        const fits = !!bundleArray.find((bundle) => (
            bundle?.bundle?._id === upgradeContext?._id &&
            bundle.id === upgradeContext.id
        ))

        return fits ? [...testArray, bundleArray] : testArray;
    }, []);
}