import { Audience, constants } from "@constants";

const kidsAudiencePattern = /^\/\w{2}-\w{2}\/kids\/?/m;
const adultsAudiencePattern = /^\/\w{2}-\w{2}\/adults\/?/m;

export const shouldSwitchAudience = (
    currentAudience: Audience,
    path?: string
): {
    shouldSwitchRoute: boolean,
    switchTo: Audience
} => {
    if (!path) return { shouldSwitchRoute: false, switchTo: currentAudience };

    const pathHasKids = kidsAudiencePattern.test(path);
    const pathHasAdults = adultsAudiencePattern.test(path);

    const shouldSwitchToKids = pathHasKids && currentAudience !== constants.audience.KIDS;
    const shouldSwitchToAdults = pathHasAdults && currentAudience !== constants.audience.ADULTS;

    return ({
        shouldSwitchRoute: shouldSwitchToKids || shouldSwitchToAdults,
        switchTo: pathHasKids ? constants.audience.KIDS : constants.audience.ADULTS,
    });
}
