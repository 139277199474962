import React from "react";
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import { RichText } from "prismic-reactjs";
import { SliceProps } from "../../types";

const NavigationWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    height: 72px;
    width: calc(100% - 18px);
    z-index: 99;

    @media screen and (min-width: 768px) {
        max-width: 632px;    
        margin: 0 auto;
    }
`

const NavigationLabel = styled.a`
    font-weight: 700;
    text-decoration: none;

    p {
        &:after {
            content:"⬊";
            padding-left: 12px;
        }
    }
`
const FloatingNavigation = (props: SliceProps<Queries.PrismicProductOverviewPageDataBodyFloatingNavigation>) => {
    const {
        context: {
            audience
        },
        slice: {
            items
        }
    } = props;

    return (

        <NavigationWrapper>
            {items?.map((
                {
                    label
                },
                index


            ) => (
                // <NavigationLabel href={`#${RichText.render(label?.richText)}`} key={`${index}-label`}>
                <NavigationLabel key={`${index}-label`}>
                    {label?.richText && RichText.render(label.richText)}
                </NavigationLabel>
            ))}
        </NavigationWrapper>
    )
}

export default FloatingNavigation