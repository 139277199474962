import React, { useState } from "react"
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import Client from "../../../../../utils/playbrush-client/client";
import { setCart } from "../../../../../app/slices/cart-reducer";
import { convertPrice, getThankYouPage, langToCountry, langToLanguage, logEcommerceEvent } from "@utils";
import { PageContext, Settings } from "../../../../slices/types";
import { getTrackingItems } from "../../../../../utils/product";
import { FrontendLineData } from "../../../../../utils/playbrush-client/types";

const Item = styled.li`
    list-style: none;
    display: grid;
    grid-template-columns: 153px auto;
    grid-template-rows: 1fr auto;
    gap: 24px;
    margin: 0 0 24px;
    padding: 16px 0;

    &:last-child {
        margin: 0;
    }
	
	@media screen and (min-width: 768px) {
        padding: 16px;
	}
`

const Image = styled.img`
    width: 153px;
    max-height: 222px;
    object-fit: cover;
    grid-row: 1 / 3;
	
	@media screen and (min-width: 768px) {

	}
`

const Details = styled.div`
	@media screen and (min-width: 768px) {

	}
`

const Title = styled.h4`
    margin: 0 0 8px;
    max-width: 280px;
    font-size: 1rem;
    line-height: 140%;
	
	@media screen and (min-width: 768px) {
	}
`

const TitleLink = styled.a`
    margin: 0 0 8px;
    max-width: 280px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 140%;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
	
	@media screen and (min-width: 768px) {
	}
`

const Description = styled.p`
    margin: 0 0 8px;
    max-width: 280px;
    line-height: 140%;
	
	@media screen and (min-width: 768px) {

	}
`

const Price = styled.p`
    margin: 0;
    font-weight: 700;
	
	@media screen and (min-width: 768px) {

	}
`

const PriceCrossed = styled.span`
    opacity: 0.4;
    margin-right: 4px;
    text-decoration: line-through;
	
	@media screen and (min-width: 768px) {

	}
`

const buttonWrapperStyle = `
    grid-column: 2 / 3;
    grid-row: 2 / 3;
`

const Form = styled.form`${buttonWrapperStyle}`;
const QuantityWrapper = styled.div`${buttonWrapperStyle}`;

const AddToCart = styled.button<{ $loading: boolean }>`
    background: transparent;
    padding: 8px 12px;
    border: 1px solid black;
    border-radius: 16px;
    font-weight: 600;

    ${({ $loading }) => $loading ? `
        pointer-events: none;
        opacity: .4;
    ` : `
        cursor: pointer;
    `}

    &:hover {
        color: white;
        background: black;
        transition: all .1s ease;
    }
	
	@media screen and (min-width: 768px) {

	}
`

const QuantityElement = styled.span<{ $loading: boolean }>`
    display: inline-flex;
    align-items: center;
    background: var(--bg-light-grey);
    padding:4px  8px 3px;
    border-radius: 16px;
    font-weight: 600;

    ${({ $loading }) => $loading ? `
        pointer-events: none;
        opacity: .4;
    ` : `
    `}
`

const QuantityInput = styled.button`
    background: transparent;
    border: 0;
    outline: 0;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    font-weight: 16px;
`

const Quantity = styled.div`
    padding: 0 10px;
    pointer-events: none;

`

interface ProductUpsellItemProps {
    position: number;
    externalLinkOverride?: string;
    title: string;
    description: string;
    bundle: Queries.PrismicBundleDataBundleIntegrationType;
    translations: PageContext['translations'];
    location: Record<string, unknown>;
    settings: Settings;
}

const ProductUpsellItem = ({
    position,
    externalLinkOverride,
    title,
    description,
    bundle,
    location,
    translations,
    settings,
}: ProductUpsellItemProps) => {   
    const [cart, lang, audience] = useAppSelector(state => [state.cart, state.general.lang, state.general.audience]);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    // @ts-ignore
    const pricePayNow = bundle?.[`price_amount_pay_now_current_${cart?.currency.toLowerCase()}`] || 0;
    // @ts-ignore
    const crossedPricePayNow = bundle?.[`price_amount_pay_now_crossed_${cart?.currency.toLowerCase()}`] || 0;

    const lineItemFromCart = cart.lineItems.find((lineItem) => lineItem.lineId === bundle?._id);
    const hasLineItemFromCart = !!lineItemFromCart;

    const handleAdd = (e: any) => {
        e.preventDefault()

        handleSubmit((lineItemFromCart?.quantity || 0) + 1);
    }

    const handleRemove = (e: any) => {
        e.preventDefault()

        handleSubmit((lineItemFromCart?.quantity || 1) - 1);
    }

    const handleSubmit: any = async (quantity: number) => {
        setLoading(true);

        const bundleId = bundle?._id || '';

        let payload: { lineId: string, quantity: number, frontendLineData?: string }[] = cart.lineItems.map((lineItem) => ({
            lineId: lineItem.lineId,
            quantity: lineItem.quantity,
            frontendLineData: JSON.stringify(lineItem.frontendLineData),
        }));

        const trackingContext: FrontendLineData['trackingContext'] = {
            discount: crossedPricePayNow - pricePayNow,
            index: position,
            item_brand: bundle ? 'Smart Toothbrush' : 'Plan',
            item_category: 'upsell',
            item_id: bundleId,
            item_list_id: 'upsell',
            item_list_name: 'upsell',
            item_name: title || 'na',
            item_variant: 'na',
            price: (pricePayNow || 0) / 100,
            quantity: 1,
        }

        const frontendLineData = {
            trackingContext,
            productContext: {
                page: location?.pathname + window?.location?.search || '',
            },
        };

        const lineItemIndex = payload.findIndex((lineItem) => lineItem.lineId === bundleId);
        const lineItemExists = lineItemIndex >= 0;

        if (lineItemExists) {
            if (quantity < (lineItemFromCart?.quantity || 0)) {
                payload[lineItemIndex].quantity -= 1;
            } else {
                payload[lineItemIndex].quantity += 1;
            }
            frontendLineData.trackingContext.quantity = payload[lineItemIndex].quantity;
            payload[lineItemIndex].frontendLineData = JSON.stringify(frontendLineData);
        } else if (quantity > 0) {
            payload.push({
                lineId: bundleId,
                quantity: quantity,
                frontendLineData: JSON.stringify(frontendLineData),
            })
        }

        payload = payload.filter((lineItem) => lineItem.quantity > 0);

        const frontendData = {
            ...(cart.frontendData as unknown) as Record<string, unknown>,
            thankYouPage: getThankYouPage(cart.lineItems, settings, audience),
        };

        const newCart = await Client.addProduct({
            itemList: payload,
            cartId: cart.cartId,
            couponCode: cart.couponCode,
            frontendData: JSON.stringify(frontendData),
            currency: cart.currency,
            language: langToLanguage(lang),
            country: langToCountry(lang),
        })


        if (newCart) {
            newCart.open = !hasLineItemFromCart;
            dispatch(setCart(newCart))

            const trackingItems = getTrackingItems(newCart);
            logEcommerceEvent(trackingItems, quantity < (lineItemFromCart?.quantity || 0) ? 'remove_from_cart' : 'add_to_cart');
        };

        setLoading(false);
    };

    return (
        <Item>
            {bundle?.image && <Image src={bundle?.image} />}
            <Details>
                {title && (
                    externalLinkOverride && externalLinkOverride !== ''
                        ? <TitleLink href={externalLinkOverride} target="_blank">{title}</TitleLink>
                        : <Title>{title}</Title>
                    )}
                {description && <Description>{description}</Description>}
                <Price>
                    {
                        crossedPricePayNow !== pricePayNow &&
                        crossedPricePayNow > 0 && (
                            <PriceCrossed>
                                {convertPrice((crossedPricePayNow), cart.locale, cart.currency)}
                            </PriceCrossed>
                        )
                    }
                    {convertPrice((pricePayNow), cart.locale, cart.currency)}
                </Price>
            </Details>
            {lineItemFromCart ? (
                <QuantityWrapper>
                    <QuantityElement $loading={loading}>
                        <QuantityInput onClick={handleRemove}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M5 12H19"
                                    stroke="#64656A"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </QuantityInput>
                        <Quantity>
                            {lineItemFromCart.quantity}
                        </Quantity>
                        <QuantityInput type="button" onClick={handleAdd}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12 5V19"
                                    stroke="#696466"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5 12H19"
                                    stroke="#64656A"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                           </svg>
                        </QuantityInput>
                    </QuantityElement>
                </QuantityWrapper>
            ) : (
                <Form onSubmit={handleAdd}>
                    <AddToCart $loading={loading}>
                        + {translations.add_to_cart}
                    </AddToCart>
                </Form>
            )}
        </Item>
    )
}

export default ProductUpsellItem;