import React, { useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setSize, setBrush, setBundle, setColor } from "../../../../../app/slices/product-reducer";
import { convertPrice } from "../../../../../utils";
import { CleanVariant } from "../../../../../utils/product/get-clean-variants";
import CarratIcon from "../../../../icons/carrat";
import CartIcon from "../../../../icons/cart-icon";
import { Translations } from "../../../../slices/types";

const Wrapper = styled.aside`
    position: fixed;
    bottom: 48px;
    right: 0;
    left: 0;
    height: 72px;
    padding: 0;
    z-index: 90;
    margin: 0 auto;
    max-width: 1064px;

    @media screen and (max-width: 820px) {
        display: none;
    }
`

const Selector = styled.article`
    width: 100%;
    max-width: 1064px;
    height: inherit;
    margin: 0 auto;
    border-radius: 16px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: grid;
    grid-template-columns: 92px 1fr auto;
    grid-gap: 24px;
    // justify-items: center;
    align-items: center;
`

const SubSelection = styled.div<{ row?: boolean }>`
    position: absolute;
    bottom: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    display: flex;
    align-items: center;
    ${({ row }) => row ? `
        flex-direction: row;
    ` : `
        flex-direction: column;
    `}
    padding: 8px;
    // transform: translateX(-20%);
`;

const VariantImage = styled.img`
    transform: translateY(-1px);
    width: 92px;
    height: inherit;
    border-radius: 16px 0 0 16px;
    overflow: hidden;
`

const Selectors = styled.div<{ showBrushes: boolean }>`
    ${({ showBrushes }) => `
        display: grid;
        grid-template-columns: ${showBrushes ? 'auto 120px 300px auto' : 'auto 300px auto'};
        grid-column-gap: 8px;
        padding: 8px 0;
    `}
`;

const SelectorButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 0 8px 8px;
    border: 1px solid #CDD4DC;
    border-radius: 12px;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
`;

const BrushSelectorButton = styled(SelectorButton)`
    display: grid;
    grid-template-columns: 1fr auto auto;
    cursor: pointer;
`;

const ButtonContent = styled.div`
    position: relative;
    padding: 0 2px 0 8px;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%);
    }
`;

const BundleButtonContent = styled(ButtonContent)`
    display: inline-block;
    font-weight: bold;
    
    width: auto;

    & + span {
        width: auto;
    }
`;

const SubmitButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 18px;
    // border: 1px solid #CDD4DC;
    border: 0;
    border-radius: 12px;
    background-color: var(--light-green);
    margin-right: 8px;
    cursor: pointer;
`

const Carrat = styled.div<{ selected: boolean }>`
    padding: 0 12px 0 12px;
    transform-origin: center center;
    transition: all 0.25s ease;

    ${({ selected }) => selected ? `
        transform: rotate(180deg);
    ` : ''}
`;

const ColorSwatch = styled.div<{ color: string }>`
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: ${({ color }) => color};
`;

const ColorSelector = styled.button<{ selected: boolean }>`
    border-radius: 12px;
    border: 1px solid ${({ selected }) => selected ? '#FC657D' : 'transparent'};
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
`;

const TextSelector = styled.button<{ selected: boolean }>`
    border-radius: 12px;
    padding: 8px 16px;
    border: 0;
    background-color: ${({ selected }) => selected ? '#EAF0F6' : 'transparent'};
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:disabled {
        text-decoration: line-through;
        opacity: 0.4;
        pointer-events: none;
    }
`;

interface IFloatingSelector {
    title?: string;
    selectedVariant?: CleanVariant;
    variants: CleanVariant[];
    translations: Translations;
    baseVariantPrice: {
        price: number;
        priceOriginal: number;
    }
}

const FloatingSelector = ({ baseVariantPrice, variants, selectedVariant, title, translations }: IFloatingSelector) => {
    const [selected, setSelected] = useState('');
    const [product, cart] = useAppSelector(state => [state.product, state.cart]);
    const dispatch = useAppDispatch();
    const basePrice = product.selectedVariant?.stripe_variant?.price_amount;

    const cleanBundles = selectedVariant?.bundles;
    const currentBundle = product.selectedVariant?.bundles?.find(b => b?.bundle?._id === product.selectedBundle)
    // @ts-ignore
    const currentPrice = currentBundle?.bundle?.[`price_amount_pay_now_current_${cart.currency.toLowerCase()}`];

    const colors = product?.variants?.reduce<string[]>((colors, variant) => {
        if (colors.includes(variant?.color || '')) return colors;
        return [...colors, variant?.color || ''];
    }, []);

    const brushTypes = product?.variants?.reduce<string[]>((brushTypes, variant) => {
        if (brushTypes.includes(variant?.brush_type || '')) return brushTypes;
        return [...brushTypes, variant?.brush_type || ''];
    }, []);

    const sizes = product?.variants?.reduce<string[]>((sizes, variant) => {
        if (sizes.includes(variant?.size || '')) return sizes;
        return [...sizes, variant?.size || ''];
    }, []);

    const showBrushes = !!brushTypes && brushTypes?.length > 1;
    const showSizes = !!sizes && sizes.length > 1;

    const currentlyAvailableBrushTypes = variants?.reduce((brushTypeArr: string[], variant: CleanVariant) => {
        if (!variant?.brush_type) return brushTypeArr;

        if (variant.color === product.selectedColor) {
            return brushTypeArr.includes(variant.brush_type) ? brushTypeArr : [...brushTypeArr, variant.brush_type];
        } else {
            return brushTypeArr;
        }
    }, []) || [];

    const toggleWindow = (v: string) => {
        if (v === selected) {
            setSelected('');
        } else {
            setSelected(v);
        };
    }

    const selectNewColor = (color: string) => {
        setSelected('');
        dispatch(setColor(color));
    }

    const selectNewBrush = (brush: string) => {
        setSelected('');
        dispatch(setBrush(brush));
    }

    const selectNewSize = (size: string) => {
        setSelected('');
        dispatch(setSize(size));
    }

    const selectNewBundle = (bundle: string) => {
        setSelected('');
        dispatch(setBundle(bundle));
    }

    return (
        <Wrapper>
            <Selector>
                <VariantImage src={selectedVariant?.stripe_variant?.image || ''} />
                <h4>
                    {title}
                </h4>
                <Selectors showBrushes={showBrushes}>
                    <div>
                        <SelectorButton type="button" onClick={() => toggleWindow('color')}>
                            <ColorSwatch color={product.selectedColor} />
                            <Carrat selected={selected === 'color'}>
                                <CarratIcon />
                            </Carrat>
                        </SelectorButton>
                        {selected === 'color' && (
                            <SubSelection row>
                                {colors?.map((color, index) => (
                                    <ColorSelector
                                        key={`color-${index}`}
                                        type="button"
                                        selected={color === product.selectedColor}
                                        onClick={() => selectNewColor(color)}
                                    >
                                        <ColorSwatch color={color} />
                                    </ColorSelector>
                                ))}
                            </SubSelection>
                        )}
                    </div>
                    {showBrushes && (
                        <div style={{ display: 'flex', }}>
                            <SelectorButton type="button" onClick={() => toggleWindow('brush')}>
                                <ButtonContent>
                                    {/* @ts-ignore */}
                                    {translations?.[`brush_hardness_${(product.selectedBrush || '')?.toLowerCase()}`]}
                                </ButtonContent>
                                <Carrat selected={selected === 'brush'}>
                                    <CarratIcon />
                                </Carrat>
                            </SelectorButton>
                            {selected === 'brush' && (
                                <SubSelection>
                                    {brushTypes?.map((brush, index) => (
                                        <TextSelector
                                            key={`brush-${index}`}
                                            type="button"
                                            selected={brush === product.selectedBrush}
                                            disabled={!currentlyAvailableBrushTypes.includes(brush)}
                                            onClick={() => selectNewBrush(brush)}
                                        >
                                            {/* @ts-ignore */}
                                            {translations?.[`brush_hardness_${(brush || '')?.toLowerCase()}`]}
                                        </TextSelector>
                                    ))}
                                </SubSelection>
                            )}
                        </div>
                    )}
                    {showSizes && (
                        <div style={{ display: 'flex', }}>
                            <SelectorButton type="button" onClick={() => toggleWindow('size')}>
                                <ButtonContent>
                                    {product.selectedSize}
                                </ButtonContent>
                                <Carrat selected={selected === 'size'}>
                                    <CarratIcon />
                                </Carrat>
                            </SelectorButton>
                            {selected === 'size' && (
                                <SubSelection>
                                    {sizes?.map((size, index) => (
                                        <TextSelector
                                            key={`size-${index}`}
                                            type="button"
                                            selected={size === product.selectedBrush}
                                            onClick={() => selectNewBrush(size)}
                                        >
                                            {size}
                                        </TextSelector>
                                    ))}
                                </SubSelection>
                            )}
                        </div>
                    )}
                    <div style={{ display: 'flex', }}>
                        <BrushSelectorButton type="button" onClick={() => toggleWindow('bundle')}>
                            <BundleButtonContent>
                                {currentBundle?.title}
                            </BundleButtonContent>
                            <ButtonContent>
                                — {convertPrice(currentPrice || 0, cart.locale, cart.currency)}
                            </ButtonContent>
                            <Carrat selected={selected === 'bundle'}>
                                <CarratIcon />
                            </Carrat>
                        </BrushSelectorButton>
                        {selected === 'bundle' && (
                            <SubSelection>
                                {cleanBundles?.map(({ bundle, title }, index) => {
                                    // @ts-ignore
                                    const price = bundle?.[`price_amount_pay_now_current_${cart.currency.toLowerCase()}`];

                                    return (
                                        <TextSelector
                                            key={`bundle-${index}`}
                                            type="button"
                                            selected={bundle?._id === product.selectedBundle}
                                            onClick={() => selectNewBundle(bundle?._id as string)}
                                        >
                                            {title + ` — ${convertPrice(price || 0, cart.locale, cart.currency)}`}
                                        </TextSelector>
                                    )
                                })}
                            </SubSelection>
                        )}
                    </div>
                    <SubmitButton type="submit">
                        <CartIcon color={'white'} />
                    </SubmitButton>
                </Selectors>
            </Selector>
        </Wrapper>
    );
}

export default FloatingSelector;