import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import Wrapper from "../../../layout/wrapper";
import { hasRichText, renderRich } from "@utils";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const Section = styled.section`
    margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px 188px 0 188px;
    }
`

const Heading = styled.div`
    margin: 0 0 40px 0;
    
    @media screen and (min-width: 768px) {
        margin: 0 0 56px 0;
    }
`

const Image = styled(GatsbyImage)`
    display: none !important;
    
    @media screen and (min-width: 768px) {
        display: block !important;
    }
`

const MobileImage = styled(GatsbyImage)`
    display: block !important;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        display: none !important;
    }
`

const ReferralTimeline = (props: SliceProps<Queries.PrismicReferralsPageDataBodyReferralTimeline>) => {
    const { slice } = props;
    const { primary } = slice;
    const { heading, image, mobile_image } = primary;
    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);

    return (
        <Section id="referral-timeline">
            <Wrapper>
                {headingHasRichText && <Heading>{renderRich(headingRichText)}</Heading>}
                <Image
                    image={(image?.gatsbyImageData as unknown) as IGatsbyImageData}
                    alt={image?.alt || ''}
                    object-fit={"fill"}
                />
                <MobileImage
                    image={(mobile_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                    alt={mobile_image?.alt || ''}
                    objectFit={"contain"}
                />
            </Wrapper>
        </Section>
    )
};

export default ReferralTimeline