import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import Client from '../../utils/playbrush-client/client';
import Button from './button';
import { useAppSelector } from '../../app/hooks';

const Container = styled.div<{ isLoading: boolean }>`
    ${({ isLoading }) => isLoading ? `
        & > * {
            opacity: 0.6;
            pointer-events: none;
        }
    ` : `
    `}

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

const StyledText = styled.div`
    font-size: 14px;
    line-height: 1.3rem;
    font-weight: 300;
    
    * {
        color: white;
    }

    h3 {
        font-size: 17px;
        line-height: 24px;
    }
`

const StyledLabel = styled.label`
    display: grid;
    grid-template-columns: 1fr auto;
    flex: 1 1 auto;
    width: 100%;
    padding: 8px 0;
    border-radius: 100px;
    border: 1px solid white;
    color: var(--dark-grey);

    @media screen and (min-width: 768px) {
        width: auto;
    }
`

const StyledInput = styled.input`
    background-color: transparent;
    color: white;
    padding: 8px 0px 8px 16px;
    border: 0;
    outline: 0;
    flex: 1 0 auto;
    font-size: 14px;

    &::placeholder {
        color: white;
        font-size: 14px;
    }
`

const StyledButton = styled.button<{ optedin: boolean }>`
    background-color: transparent;
    color: white;
    text-decoration: underline;
    padding: 0 24px 3px;
    font-size: 14px;
    line-height: 1;
    border: 0;

    &:hover {
        cursor: pointer;
    }

    * {
        color: white;
    }

    ${({ optedin }) => optedin ? `

    ` : `
        opacity: .4;
    `}
`

const StyledOptinLabel = styled.label`
    display: flex;
`

const StyledOptinInput = styled.span<{ optedin: boolean }>`
    position: relative;
    height: 16px;
    flex: 0 0 16px;
    margin-right: 16px;
    // background-color: white;
    box-shadow: 0px 0px 1px 1px rgb(255 255 255 / 80%);
    border-radius: 4px;

    &:hover {
        cursor: pointer;
    }

    ${({ optedin }) => optedin ? `
        &::after {
            content: '';
            position: absolute;
            top: 4px;
            right: 4px;
            bottom: 4px;
            left: 4px;
            background-color: white;
            border-radius: 50%;
        }
    ` : ``}
`

const StyledOptin = styled.span`
    color: white;
    line-height: 1.3;
    
    * {
        color: white;
        font-size: 12px;
        font-weight: 400;
    }

    a {
        color: white;
        line-height: 1.2;
        text-decoration: underline;

        &:hover {
            color: white;
        }
    }
`

const StyledEmailError = styled.p`
    background-color: rgba(255, 0, 0, .2);
    color: rgb(255, 0, 0);
    padding: 8px 16px;
    line-height: 1.4;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 16px;
`

const StyledOptinError = styled.p`
    background-color: rgba(255, 0, 0, .2);
    color: rgb(255, 0, 0);
    padding: 8px 16px;
    line-height: 1.4;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    margin-top: 16px;
`

const LowerSubmit = styled.div`
    width: 100%;

    button {
        width: 100%;
        background: white;
        border: none;

        &:hover {
            background: var(--dark-grey);

            p {
                color: white;
            }
        }

        p {
            color: var(--dark-grey);
        }
    }
    @media screen and (min-width: 768px) {
       width: auto;    
    }
`

const EMAIL_PATTERN = /^\w+([\.\+-]?\w+)*\+?@\w+([\.\+-]?\w+)*(\.\w{2,3})+$/;

const NewsletterSignupForm = (props: {
    data: Queries.PrismicSubscriptionForm,
    useInlineButton?: boolean;
    callBack?: () => void,
    errorCallBack?: () => void,
}) => {
    const language = useAppSelector((state) => state.general.lang?.split('-')[0]);
    const { data: { data }, useInlineButton, callBack, errorCallBack } = props;
    const { list, tags, button_text, text, optin, field_placeholder, optin_error, email_format_error } = data || {};

    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [optedin, setOptedin] = useState(false);
    const [optinError, setOptinError] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState('');

    const submitEmail = async (e: any) => {
        e.preventDefault();

        // Reset errors
        setEmailError(undefined);
        setOptinError(undefined);

        // Is correct format
        if (!EMAIL_PATTERN.test(email.trim())) {
            setEmailError(email_format_error as string);
            // errorCallBack && errorCallBack();
        }

        // Has opted in
        if (!optedin) {
            setOptinError(optin_error as string);
            // errorCallBack && errorCallBack();
        }

        // Subscribe
        if (optedin && EMAIL_PATTERN.test(email.trim())) {
            setLoading(true);
            await Client.subscribeToNewsletter(
                {
                    email,
                    language,
                    tags: tags?.map((tag) => tag?.tag) as string[]
                },
                () => {
                    setLoading(false);
                    callBack && callBack();
                },
                (errorMessage) => {
                    console.error(e);
                    setLoading(false);
                    setEmailError(errorMessage);
                    errorCallBack && errorCallBack();
                }
            );
        }
    }

    const handleInput = (value: string): void => {
        setEmail(value);
        setOptinError(undefined);
        setEmailError(undefined);
    }

    return (
        <Container isLoading={loading}>
            {text?.richText && (
                <StyledText>
                    {RichText.render(text?.richText)}
                </StyledText>
            )}
            <StyledOptinLabel>
                <StyledOptinInput
                    optedin={optedin as boolean}
                    onClick={() => setOptedin(!optedin)}
                />
                <StyledOptin>
                    {optin?.richText && RichText.render(optin?.richText)}
                </StyledOptin>
            </StyledOptinLabel>
            {optinError && (
                <StyledOptinError>
                    {optinError}
                </StyledOptinError>
            )}
            <StyledLabel>
                <StyledInput placeholder={field_placeholder as string} onChange={(e) => handleInput(e.target.value)} />
                {useInlineButton && (
                    <StyledButton onClick={submitEmail} optedin={optedin}>
                        {button_text?.richText && RichText.render(button_text?.richText)}
                    </StyledButton>
                )}
            </StyledLabel>
            {emailError && (
                <StyledEmailError>
                    {emailError}
                </StyledEmailError>
            )}
            {!useInlineButton && (
                <LowerSubmit>
                    <Button buttonStyle="outline" onClick={submitEmail}>
                        {button_text?.richText && RichText.render(button_text?.richText)}
                    </Button>
                </LowerSubmit>
            )}
        </Container>
    );
}

export default NewsletterSignupForm;
