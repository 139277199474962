import { setToSessionStorage } from "./set-to-session-storage";
import { supportsCookies } from "./supports-cookies";
import { supportsSessionStorage } from "./supports-session-storage";

export const setCookie = (key: string, value: string | number) => {
    if (supportsCookies()) {
        const now = new Date();
        const future = new Date(now.getTime() + (7 * 24 * 60 * 60 * 1000));
        document.cookie = `${key}=${value};expires=${future.toUTCString()};path=/;`
    } else if (supportsSessionStorage()) {
        setToSessionStorage(key, value);
    }
}