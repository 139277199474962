import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const EnveloppeIconAt = ({ color = 'white' }: { color?: string }) => (
    <IconSvg xmlns="http://www.w3.org/2000/svg" 
        // x="0px" y="0px"
	    viewBox="0 0 35 32"
        width="35"
        height="32"
    >
        <path 
            d="M21,7.5c0,0.3,0,0.7,0,1c-0.1,0.6-0.6,1-1.2,1.1c-0.2,0-0.4,0-0.6,0c-0.4-0.1-0.7-0.3-0.9-0.6c-0.1-0.1-0.1-0.1-0.2-0.1
            c-0.3,0-0.5,0-0.8,0c-0.8,0-1.3-0.6-1.4-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0.5-1.1,1.1-1.2c0.3,0,0.7,0,1,0c0.7,0.1,1.2,0.6,1.2,1.3
            c0,0.4,0,0.9,0,1.3c0,0.1,0,0.3,0.2,0.4c0.2,0.1,0.4,0.1,0.6,0c0,0,0.1-0.1,0.1-0.2c0-0.6,0-1.3,0-1.9c-0.1-1-0.9-1.7-2-1.8
            c-0.4,0-0.7,0-1.1,0c-1,0.1-1.9,0.9-1.9,1.9c0,0.5,0,1,0.1,1.5c0.2,0.9,1,1.5,1.9,1.6c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0.3,0.5,0.6
            c0,0.3-0.2,0.5-0.5,0.5c-0.7,0-1.3,0-1.9-0.4c-1-0.6-1.6-1.5-1.6-2.7c0-0.6,0-1.2,0.1-1.8c0.4-1.3,1.6-2.2,2.9-2.2c0.5,0,1,0,1.5,0
            C19.9,4,21,5.3,21,6.7C21,7,21,7.2,21,7.5C21,7.5,21,7.5,21,7.5L21,7.5z M18.1,7.8c0-0.3,0-0.7,0-1c0-0.1-0.1-0.2-0.3-0.2
            c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3C17.5,7.9,17.8,7.8,18.1,7.8L18.1,7.8z"
            fill={color}
        />
        <g>
        <g>
            <path 
                d="M0.3,28.5V8.5c0,0,0-0.1,0-0.1c0.2-1,0.9-1.6,2-1.6c2.9,0,5.8,0,8.6,0h0.2C11.4,3.5,14,1.1,17,0.9
                c1.5-0.1,2.9,0.3,4.1,1.1c1.6,1.2,2.5,2.8,2.8,4.8h0.2c2.9,0,5.8,0,8.7,0c1.2,0,2,0.8,2,2c0,6.5,0,13.1,0,19.6c0,1.1-0.8,2-2,2
                c-10.2,0-20.3,0-30.5,0c-0.1,0-0.1,0-0.2,0c-0.7,0-1.3-0.4-1.6-1C0.4,29,0.4,28.8,0.3,28.5L0.3,28.5z M13.6,19.2
                C9.8,22.5,6,25.9,2.2,29.3h30.7c-3.8-3.4-7.6-6.7-11.4-10.1c-0.1,0-0.1,0.1-0.1,0.1c-0.8,0.7-1.6,1.4-2.4,2.1
                c-0.8,0.7-1.9,0.7-2.7,0c-0.4-0.4-0.8-0.7-1.3-1.1C14.5,19.9,14.1,19.6,13.6,19.2L13.6,19.2z M23.9,7.7c0,0.3-0.1,0.5-0.1,0.8
                c-0.5,2.8-3.1,5-5.9,5.1c-1.4,0-2.7-0.3-3.9-1.1c-1.6-1.1-2.5-2.6-2.8-4.6c0-0.2-0.1-0.2-0.2-0.2c-2.8,0-5.7,0-8.5,0
                c-0.1,0-0.1,0-0.2,0c0.1,0.1,0.1,0.1,0.2,0.2c2.7,2.4,5.4,4.8,8.1,7.1c2.1,1.9,4.2,3.7,6.3,5.6c0.5,0.4,0.9,0.4,1.4,0
                c4.8-4.3,9.7-8.5,14.5-12.8c0,0,0.1-0.1,0.1-0.1L23.9,7.7L23.9,7.7z M22.2,18.5c3.8,3.4,7.6,6.8,11.5,10.1V8.4
                C29.8,11.7,26,15.1,22.2,18.5L22.2,18.5z M1.4,8.4v20.2c3.8-3.4,7.6-6.7,11.5-10.1C9,15.1,5.2,11.7,1.4,8.4z M17.5,12.6
                c2.9,0,5.3-2.4,5.4-5.3c0-3-2.4-5.4-5.4-5.4c-3,0-5.3,2.4-5.4,5.1C12.1,10.1,14.4,12.6,17.5,12.6z"
                fill={color}
            />
        </g>
    </g>
    </IconSvg>
);

export default EnveloppeIconAt;

