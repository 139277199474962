import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const BluetoothIcon = ({ color = '#FFF', size = '36' }: { color?: string, size?: string | number }) => (
    <IconSvg
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15 15.0867L33 33.0867L24 42.0867V6.08667L33 15.0867L15 33.0867" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
);

export default BluetoothIcon;
