import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const HamburgerIcon = ({ color = 'white' }: { color?: string }) => (
    <IconSvg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.75 7C1.75 6.51675 2.14175 6.125 2.625 6.125H25.375C25.8582 6.125 26.25 6.51675 26.25 7C26.25 7.48325 25.8582 7.875 25.375 7.875H2.625C2.14175 7.875 1.75 7.48325 1.75 7Z" 
            fill={color}/>
        <path d="M1.75 14C1.75 13.5168 2.14175 13.125 2.625 13.125H25.375C25.8582 13.125 26.25 13.5168 26.25 14C26.25 14.4832 25.8582 14.875 25.375 14.875H2.625C2.14175 14.875 1.75 14.4832 1.75 14Z" 
            fill={color}/>
        <path d="M1.75 21C1.75 20.5168 2.14175 20.125 2.625 20.125H25.375C25.8582 20.125 26.25 20.5168 26.25 21C26.25 21.4832 25.8582 21.875 25.375 21.875H2.625C2.14175 21.875 1.75 21.4832 1.75 21Z" 
            fill={color}/>
    </IconSvg>

);

export default HamburgerIcon;