import ProductGrid from '../../components/slices/product-overview-page/product-grid/product-grid';
import FloatingNavigation from '../../components/slices/magazine-overview-page/floating-navigation/floating-navigation';

import { FeaturedProducts, FeatureGrid, NewsletterPopUp } from '../../components/slices/generic';

export const components = {
    product_grid: ProductGrid,
    featured_products: FeaturedProducts,
    feature_grid: FeatureGrid,
    floating_navigation: FloatingNavigation,
    'email_pop-up': NewsletterPopUp,
}