import Client from "../../../../utils/playbrush-client/client";
import { CheckDiscountReturn } from "../../../../utils/playbrush-client/types";

export const checkDiscountCode = async (couponCode: string): Promise<boolean> => {
    return new Promise(async (resolve) => {
        const data = await Client.checkDiscount({ couponCode })
        const { valid } = (data || {}) as CheckDiscountReturn;

        return resolve(valid);
    })
}
