import React from "react"
import styled from "styled-components";
import CloseIcon from "../../../icons/close-icon";
import { Switcher } from "../audience-switcher";

import MobileNavigation from './mobile-navigation';
import { PageContext } from "../../../slices/types";
import { Audience } from "@constants";

const MobileMenu = styled.aside`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	padding: 32px 16px;
	background-color: #fff;
	overflow: scroll;
`

const TopBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 24px 16px;
	border-bottom: 1px solid #2BB57310;
`

const CloseIconHolder = styled.div`
`

interface MobileNavigationDrawerProps {
    menuData: Queries.PrismicHeaderDataMenuItems[];
    translations: PageContext['translations'];
    page: string;
    close: () => void;
    goTo: (newAudience: Audience) => void;
}

const MobileNavigationDrawer = ({ menuData, translations, page, goTo, close }: MobileNavigationDrawerProps) => (
    <MobileMenu>
        <TopBar>
            <CloseIconHolder onClick={close}>
                <CloseIcon />
            </CloseIconHolder>
            <Switcher
                translations={translations}
                goTo={goTo}
            />
        </TopBar>
        <MobileNavigation menuData={menuData} page={page}/>
    </MobileMenu>
)

export default MobileNavigationDrawer;