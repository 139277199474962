import React from "react";
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const ShippingIcon = ({ color = '#fff' }: { color?: string }) => (
    <IconSvg width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.5 6.5H36.7229L41.0979 13.5H29.5V6.5Z"
            stroke={color} />
        <circle cx="34" cy="23" r="4.5"
            stroke={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M39 23H41V14.4142L40.5858 14H30V19.9995C29.3721 20.8353 29 21.8742 29 23V14V13H30H41L42 14V23V24H41H38.9C38.9656 23.6769 39 23.3425 39 23ZM29.1 24H29V23C29 23.3425 29.0344 23.6769 29.1 24Z"
            fill={color} />
        <circle cx="9" cy="23" r="4.5"
            stroke={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M6 1H29V23H14C14 23.3425 13.9656 23.6769 13.9 24H29H30V23V1V0H29H6C2.68629 0 0 2.68629 0 6V23V24H1H4.10002C4.03443 23.6769 4 23.3425 4 23H1V6C1 3.23858 3.23858 1 6 1Z"
            fill={color} />
    </IconSvg>
);

export default ShippingIcon;