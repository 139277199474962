import { CtaTeaserImage, NewsletterPopUp } from "../../components/slices/generic"

import { FeaturedArticles, MagazineCatalog } from "../../components/slices/magazine-overview-page"

export const components = {
    related_articles: FeaturedArticles,
    magazine_catalog: MagazineCatalog,
    cta_teaser_image: CtaTeaserImage,
    'email_pop-up': NewsletterPopUp,
}
