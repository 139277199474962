import React, { useEffect, useState } from "react"
import styled from "styled-components";
import Wrapper from "../../../layout/wrapper";
import { RichText } from "prismic-reactjs";
import { SliceProps } from "../../types";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { isEqual } from "lodash";

import { arrowRightIconSmallInline } from "../../../icons/arrow-right-icon-small-inline";

const Catalog = styled.section`
    margin: 80px 0 0 0;    

    @media screen and (min-width: 768px) {
        margin: 120px 0 0 0;
    }
`

const InnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
    }
`

const TagsWrapper = styled.div`
    flex: 0 0 100%;

    @media screen and (min-width: 768px) {
        flex: 0 0 290px;
}
`
const TagList = styled.ul`
    padding-inline-start: 0;
`

const Tag = styled.li`
    display: inline-block;
    list-style-type: none;
    width: auto;
    height: 52px;
    margin: 0 8px 8px 0;
    
    &:hover {
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        display: block;
        width: 100%;
        margin: 0 0 24px 0;
    }
`
    
const TagText = styled.span<{ isSelected: boolean }>`
    border: 1px solid var(--light-green);
    padding: 16px;
    border-radius: 16px;

    ${({ isSelected }) => isSelected ? `
        background-color: var(--light-green);
        color: white;
    ` : `
        background: transparent;
    `}
`

const Title = styled.h4`
    margin: 0 0 36px 0;
    font-size: 24px;
`

const ItemsWrapper = styled.div``

const Items = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

const Item = styled(Link)`
    display: flex;
    flex-wrap: wrap;
    align-item: space-evenly;
    padding: 16px;
    text-decoration: none;

    &:hover {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(30px);
        cursor: pointer;

        & .read-more {
            visibility: visible;
        }
    }
`

const Image = styled(GatsbyImage)`
    margin: 0 0 10px 0;
    max-height: 217px;
`


const BlogTitle = styled.h5`
    margin: 0 0 8px 0;
`

const ReadingTime = styled.div`
    width: 100%;
`

const ReadMore = styled.div`
    visibility: hidden;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    text-decoration: underline;
    color: var(--light-green);
    text-align: right;
    width: 100%;
    margin: 10px 0 0 0;
    
    &:after {
        padding: 0;
        content: url(${arrowRightIconSmallInline('#2BB573')});
        vertical-align: -25%;
    }
`

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
`

const Previous = styled.button`
    background: none;
    border: none;
    font-size: 16px;
    
    p {
        color: var(--light-green);
        text-decoration: underline;
        
        &:hover {
            cursor: pointer;
        }

        &:disabled {
            opacity: 0.4;
        }

        &:before {
            display: inline-block;
            content: url(${arrowRightIconSmallInline('#fc657d')});
            vertical-align: 0%;
            transform: rotate(180deg);
        }
    }
`

const Next = styled(Previous)`
    p {
        &::before {
            content: unset;
        }

        &:after {
            content: url(${arrowRightIconSmallInline('#fc657d')});
            vertical-align: -25%;
        }
    }
`

const multiplyBlogs = (blogs: Queries.PrismicMagazineBlogPage[], times: number): Queries.PrismicMagazineBlogPage[] => {
    const newBlogs: Queries.PrismicMagazineBlogPage[] = [];

    for (let i = 0; i < times; i++) {
        blogs.forEach(blog => newBlogs.push(blog));
    }
    
    return newBlogs;
}

const ARTICLES_PER_PAGE = 9;

const MagazineCatalog = (props: SliceProps<Queries.PrismicMagazineOverviewDataBodyMagazineCatalog>) => {
    const {
        context: {
            audience,
            blogs
        },
        slice: {
            primary 
        }
    } = props;

    const testBlogs = multiplyBlogs(blogs as Queries.PrismicMagazineBlogPage[], 20); // Remove and replace with blogs;

    const tags: string[] = blogs?.reduce<string[]>((tags, blog) => {
        const newTags = tags;
        blog?.data?.tags?.forEach(tag => (tag?.tag && !newTags.includes(tag.tag)) && newTags.push(tag.tag));
        return newTags;
    }, []) || [];

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [filteredBlogs, setFilteredBlogs] = useState<Queries.PrismicMagazineBlogPage[]>(testBlogs);

    const getPaginatedBlogs = (pageIndex: number, filtered = filteredBlogs): Queries.PrismicMagazineBlogPage[] => {
        const position = pageIndex * ARTICLES_PER_PAGE;
        return filtered?.slice(position, position + ARTICLES_PER_PAGE) || [];
    }

    const [paginatedBlogs, setPaginatedBlogs] = useState<Queries.PrismicMagazineBlogPage[]>(getPaginatedBlogs(currentPage));
    const [selectedTag, setSelectedTag] = useState<string | void>();

    useEffect(() => {
        let filtered: Queries.PrismicMagazineBlogPage[] = filteredBlogs;
        let page: number = currentPage;
        let paginated: Queries.PrismicMagazineBlogPage[] = paginatedBlogs;

        if (testBlogs) {
            filtered = filterBlogs(testBlogs);
            if (!isEqual(filtered, filteredBlogs)) page = 0;
        }

        if (filtered) {
            paginated = getPaginatedBlogs(page, filtered);
        }

        setFilteredBlogs(filtered);
        setCurrentPage(page);
        setPaginatedBlogs(paginated);
    }, [selectedTag, currentPage])

    const filterBlogs = (blogs: Queries.PrismicMagazineBlogPage[]) => (
        !selectedTag ? blogs : blogs.filter(blog => {
            return blog?.data?.tags?.find(tag => tag?.tag && tag.tag === selectedTag);
        })
    )

    const toggleTag = (tag: string): void => tag === selectedTag ? setSelectedTag(undefined) : setSelectedTag(tag);
    const updatePagination = (pageIndex: number) => pageIndex >= 0 && setCurrentPage(pageIndex);

    return (
        <Catalog>
            <Wrapper>
                <InnerWrapper>
                    <TagsWrapper>
                        <Title>Further reads</Title>
                        <TagList>
                            {tags.map((tag, index) => (
                                <Tag
                                    key={`blurp-${index}`}
                                    onClick={() => toggleTag(tag)}
                                    >
                                    <TagText isSelected={tag === selectedTag}>
                                        {tag}
                                    </TagText>
                                </Tag>)
                            )}
                        </TagList>
                    </TagsWrapper>
                    <ItemsWrapper>
                        <Items>
                            {paginatedBlogs?.map((data, index) => (
                                <Item key={`blog-${index}`} to={data?.url || ''}>
                                    {data.data?.page_image?.gatsbyImageData && (
                                        <Image
                                            image={(data?.data?.page_image?.gatsbyImageData as unknown) as IGatsbyImageData}
                                            alt={data.data.page_image?.alt || ''}
                                            objectFit={"cover"}
                                        />
                                    )}
                                    {data.data?.blog_title &&
                                        <BlogTitle>
                                            {data.data.blog_title?.text}
                                        </BlogTitle>
                                    }
                                    {data.data?.reading_time?.richText &&
                                        <ReadingTime>
                                            {RichText.render(data.data.reading_time.richText)}
                                        </ReadingTime>
                                    }
                                    <ReadMore className="read-more">
                                        Read more
                                    </ReadMore>
                                </Item>)
                            )}
                        </Items>
                        <Navigation>
                            <Previous onClick={() => updatePagination(currentPage - 1)} disabled={currentPage === 0}>{primary.previous_button_text?.richText && RichText.render(primary.previous_button_text.richText)}</Previous>
                            <Next onClick={() => updatePagination(currentPage + 1)} disabled={(currentPage + 1) * ARTICLES_PER_PAGE > (filteredBlogs || []).length}>{primary.next_button_text?.richText && RichText.render(primary.next_button_text.richText)}</Next>
                        </Navigation>
                    </ItemsWrapper>
                </InnerWrapper>
            </Wrapper>
        </Catalog>
    )
}

export default MagazineCatalog