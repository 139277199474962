import React from "react";
import styled from 'styled-components';

export const BackgroundSVG = styled.svg`
    position: absolute;
    top: 0;
    z-index: -1;
`

interface BackgroundProps {
    backColor: string;
    frontColor: string;
}

const Background = ({ backColor, frontColor }: BackgroundProps) => (
    <>
        <BackgroundSVG viewBox="0 0 1440 424" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M856.359 112.519C1191.62 167.663 1446.21 335.702 1531.59 412.828L1572.05 423.668L1698.75 -49.1866L-9.83257 -507L-71.7753 -275.826C97.9124 -169.354 521.102 57.3754 856.359 112.519Z"
                fill={backColor}
            />
        </BackgroundSVG>
        <BackgroundSVG viewBox="0 0 1440 1001" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.5"
                d="M917.304 661.048C1172.9 529.589 1528.59 455.908 1649.72 489.844L1656.19 463.065L1432.16 -513.348L-517.773 9.13603L-310.159 998.064C-27.7877 1024.38 597.816 825.372 917.304 661.048Z"
                fill={frontColor}
            />
        </BackgroundSVG>
    </>
);

export default Background;
