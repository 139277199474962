import React from "react";
import styled from "styled-components";
import { SliceProps } from "../../types";
import { hasRichText, renderRich } from "@utils";
import CookieDetailRow from "./cookie-detail-row";
import Wrapper from "../../../layout/wrapper";

const Section = styled.section`
    padding: 24px 16px;
    
    @media screen and (min-width: 768px) {
        padding: 64px 80px;
        max-width: 60%;
    }
`
const RichWrapper = styled.div`
    overflow: visible;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    padding: 64px 1px 1px;

    h1 {
        font-size: 40px;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 40px;

        @media screen and (min-width: 768px) {
            font-size: 56px;
        }
    }

    h5,
    p,
    ul,
    ol {
        margin-bottom: 16px;
        line-height: 150%;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    a {
        color: var(--light-green);

        &:visited {
            color: var(--green);
        }
    }
`

const CookieDetails = (props: SliceProps<Queries.PrismicTextPageDataBodyCookieDetails>) => {
    const { slice } = props;
    const { primary, items } = slice;
    const { cookie_list_description } = primary;

    const [descriptionHasRichText, descriptionRichText] = hasRichText(cookie_list_description?.richText);

    return (
        <Section>
            {descriptionHasRichText && renderRich(descriptionRichText)}
            <RichWrapper>
                {items.map((item, index) => <CookieDetailRow isHeader={index === 0} row={item} />)}
            </RichWrapper>
        </Section>
    )
};

export default CookieDetails