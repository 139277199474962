import React from "react";
import styled from "styled-components";
import VariantBlockWithFilter from "./variant-block-with-filter";
import { useAppSelector } from "../../../../../app/hooks";
import { BundlesConfig, Translations } from "../../../../../components/slices/types";

const Container = styled.div``

const Spacer = styled.div`
    margin: 40px 0;
    border: 1px solid var(--light-green);
    opacity: 0.2;
	
	@media screen and (min-width: 768px) {
        display: none;
	}
`

interface VariantSelectorProps {
    config: BundlesConfig;
    translations: Translations;
    shouldShowCompareBrushes: boolean;
    brushModal: any;
};

const VariantSelector = ({ config, translations, shouldShowCompareBrushes, brushModal }: VariantSelectorProps) => {
    const [colors, brushes, sizes] = useAppSelector((state => [state.filter.colors, state.filter.brushes, state.filter.sizes]));

    return (
        <>
            {(
                <Container>
                    {brushes && brushes.length > 0 && (
                        <>
                            <VariantBlockWithFilter
                                title={translations.select_brush}
                                linkText={translations.compare_brushes}
                                options={brushes}
                                forType={'brush'}
                                url={shouldShowCompareBrushes ? '#compare-brushes' : undefined}
                                translations={translations}
                                config={config}
                                brushModal={brushModal}
                            />
                            <Spacer />
                        </>
                    )}
                    {colors && colors.length > 0 && (
                        <>
                            <VariantBlockWithFilter
                                title={translations.select_color}
                                options={colors}
                                forType={'color'}
                                translations={translations}
                                config={config}
                            />
                            <Spacer />
                        </>
                    )}
                    {sizes && sizes.length > 0 && (
                        <>
                            <VariantBlockWithFilter
                                title={translations.select_size}
                                options={sizes}
                                forType={'size'}
                                translations={translations}
                                config={config}
                            />
                            <Spacer />
                        </>
                    )}
                </Container>
            )}
        </>
    )
}

export default VariantSelector;