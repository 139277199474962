export const baseInterpolator = (text: string, data: Record<string, unknown>) => {
    if (!text || !data) return text;
    let newText = text;

    Object.keys(data).forEach((key: string) => {
        const pattern = new RegExp(`{\\s{0,}${key}\\s{0,}}`, 'g');
        newText = newText.replaceAll(pattern, `${data[key]}`);
    })

    return newText;
}