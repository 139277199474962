import React, { createRef, useEffect, useRef, useState } from "react"
import styled from "styled-components";

import Wrapper from "../../../layout/wrapper";

import Slider, { Settings } from "react-slick";

import { arrowLeftIconInline } from "../../../icons/arrow-left-icon-inline";
import { arrowRightIconInline } from "../../../icons/arrow-right-icon-inline";

import { SliceProps } from "../../types";

import { getCookie, hasRichText, renderRich, setCookie } from "@utils";

import VideoPlayer from "../../../snippets/video-player/video-player";
import Quotation from "../../../icons/quotation-icon";
import { constants } from "@constants";
import Button from "../../../snippets/button";

const Section = styled.section`
    padding: 0;
    overflow: hidden;
	margin: 80px auto 0;

    @media screen and (min-width: 768px) {
        margin: 120px auto 0;
    }
`

const Heading = styled.div`
    margin: 0 0 40px 0;
`

const SliderWrapper = styled(Slider) <{ numberOfItems: number }>`
    ${({ numberOfItems }) => numberOfItems < 2 && `
        .slick-track .slick-cloned {
            display: none;
        }
    `}

	.slick-list {
        -webkit-mask-image: linear-gradient(to left, transparent, black 0%, black 80%, transparent 100%);
        -webkit-mask-image: linear-gradient(to right, transparent, black 0%, black 80%, transparent 100%);
        margin: 0 0 16px 0;
    }

	.slick-slide {
        display: flex;
        align-items: center;
		height: auto;     
        transform: scale(0.7);
        transition: all .5s ease-in-out;
        opacity: .5;
    }

    .slick-center {
        transform: scale(1);
        opacity: 1;
    }

	.slick-track {
		display: flex;
        justify-content: flex-start;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
	}

    .slick-prev, .slick-next {
        display: none;
    }

    .slick-dots {
        bottom: 0;
        position: relative;

        li {
            width: 16px;
            height: 16px;
            margin: 0px 4px;

            button {
                &:before {
                    font-size: 8px;
                    color: #CDD4DC;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: var(--light-green);
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .slick-prev, .slick-next {
            border-radius: 12px;
            border: 1px solid var(--light-green);
            border-radius: 12px;
            width: 64px;
            height: 48px;
            background: transparent;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }

        .slick-next {
            right: 80px;

            &::before {
                content: url(${arrowRightIconInline('#fc657d')});
            }
        }

        .slick-prev {
            left: 80px;

            &::before {
                content: url(${arrowLeftIconInline('#fc657d')});
            }
        }
        
        .slick-list {
            overflow: visible;
            -webkit-mask-image: linear-gradient(to left, transparent, black 20%, black 80%, transparent 100%);
            -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%);
    }
`

const VideoItem = styled.div`
    max-width: 648px;
    border-radius: 16px;
`

const QuoteWrapper = styled.div`
    display: block;
    margin: 40px 0 0 0;

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 20px;
    }
`

const IconWrapper = styled.div`
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 0 16px 0;

    svg {
        width: 22px;
    }

    @media screen and (min-width: 768px) {
        display: inline-flex;
        width: auto;
        flex: 0 0 auto;
        margin: 0;

        svg {
            width: 34px;
        }
    }
`

const Quote = styled.div`
    flex: 0 0 auto;
    text-align: center;
    max-width: 100%;
    
    @media screen and (min-width: 768px) {
        max-width: 594px;
    }
`

const QuoteText = styled.div`
    margin: 0 0 16px 0;    
    text-align: center;
    font-size: 16px;

    @media screen and (min-width: 768px) {
        p {
            font-size: 24px;
        }
    }
`

const QuotedPerson = styled.div`
    text-align: center;
`

const YouTubeEmbed = styled.div`
    line-height: 50%;
    display: flex;
    justify-content: center;

    iframe {
        max-width: 100%;
        overflow: hidden;
    }
`

const Consent = styled.div`
    width: 100%;
    height: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ConsentText = styled.p`
    margin: 0 0 16px;
    text-align: center;
`

const VideoSlider = (props: SliceProps<Queries.PrismicHowItWorksPageDataBodyVideoSlider>) => {
    const { context, slice } = props;
    const { translations } = context;
    const { primary, items } = slice;
    const { heading } = primary;
    const sliderRef = useRef<Slider | null>(null)
    const sliderSettings: Settings = {
        dots: true,
        arrows: true,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.1,
                    arrows: false,
                }
            }
        ]
    };

    const videoRef = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
        videoRef.current = videoRef.current.slice(0, items.length);
    }, [items]);

    const [headingHasRichText, headingRichText] = hasRichText(heading?.richText);
    const numberOfItems = items.length;

    const pauseOtherVideos = (playingIndex: number) => {
        videoRef.current.forEach((ref: any, index: number) => playingIndex !== index && ref?.pause());
    }

    return (
        <Section id="VideoSlider">
            <Wrapper>
                {headingHasRichText && (<Heading>{renderRich(headingRichText)}</Heading>)}
            </Wrapper>
            <SliderWrapper ref={sliderRef} {...sliderSettings} numberOfItems={numberOfItems}>
                {items && items.map((item, index: number) => {
                    const [quoteHasRichText, quoteRichText] = hasRichText(item?.quote?.richText);
                    const [quotedPersonHasRichText, quotedPersonRichText] = hasRichText(item?.quoted_person?.richText);
                    const { video } = item;
                    const { document } = video as Queries.PrismicLinkType;
                    const { src, use_autoplay, mute_audio, loop, plays_inline, placeholder_image, youtube_embed_url } = (document?.data || {}) as Queries.PrismicVideoDataType;
                    const videoHasSource = src;

                    const YoutubeEmbedUrl = youtube_embed_url;

                    const [hasConsent, setConsent] = useState(!!getCookie(constants.cookies.YOUTUBE_COOKIE));

                    const consent = () => {
                        setConsent(true);
                        setCookie(constants.cookies.YOUTUBE_COOKIE, 1);
                    }

                    return (
                        <VideoItem
                            key={`${index}`}
                        >
                            {item?.video?.document?.data && YoutubeEmbedUrl &&
                                <>
                                    {hasConsent
                                        ? (
                                            <YouTubeEmbed dangerouslySetInnerHTML={{ __html: YoutubeEmbedUrl }} />
                                        )
                                        : (
                                            <Consent>
                                                <ConsentText>
                                                    {translations?.youtube_consent_text}
                                                </ConsentText>
                                                <Button type="button" onClick={consent}>
                                                    {translations?.youtube_consent_button_text}
                                                </Button>
                                            </Consent>
                                        )
                                    }
                                </>
                            }
                            {/* {item?.video?.document?.data && videoHasSource && (
                                <VideoPlayer
                                    ref={(el: HTMLDivElement) => videoRef.current[index] = el}
                                    src={src || ''}
                                    use_autoplay={use_autoplay}
                                    mute_audio={mute_audio}
                                    loop={loop}
                                    plays_inline={plays_inline}
                                    placeholder_image={placeholder_image}
                                    button_size={'medium'}
                                    pauseSiblings={pauseOtherVideos}
                                    index={index}
                                />
                            )} */}
                            {quoteHasRichText && quotedPersonHasRichText &&
                                <QuoteWrapper>
                                    <IconWrapper>
                                        <Quotation />
                                    </IconWrapper>
                                    <Quote>
                                        <QuoteText>{renderRich(quoteRichText)}</QuoteText>
                                        <QuotedPerson>{renderRich(quotedPersonRichText)}</QuotedPerson>
                                    </Quote>
                                </QuoteWrapper>
                            }
                        </VideoItem>
                    )
                })}
            </SliderWrapper>
        </Section >
    )
}

export default VideoSlider
